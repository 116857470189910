import { createTheme } from '@mui/material/styles';

const primaryColor = '#FEDC2A';
export const secondaryColor = '#0451C4';
const errorColor = '#CE1515';
const successColor = '#21A546';
export const neutralBodyColor = '#000A18';
const neutralBodyColor2 = '#525862';
const secondaryColor5 = '#6897DC';
const secondaryColor8 = '#FFFFFF';

const typography = {
  fontFamily: 'Mulish',
  'h-1': {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontStyle: 'normal',
  },
  'h-2': { fontWeight: 600, fontSize: '1.125rem', lineHeight: '1.5rem' },
  'h-3': {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontStyle: 'normal',
  },
  'h-4': {
    fontWeight: 800,
    fontSize: '1.125rem',
    lineHeight: '1.25rem',
    fontStyle: 'normal',
  },
  'body-1': { fontWeight: 400, fontSize: '1rem', lineHeight: '1.5rem' },
  'body-2': { fontWeight: 700, fontSize: '0.875rem', lineHeight: '1.25rem' },
  Caption: {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontStyle: 'normal',
  },
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  overline: undefined,
};

export const customTheme = createTheme({
  palette: {
    success: {
      main: successColor,
    },
    error: {
      main: errorColor,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '2.5rem',
          minWidth: '6.563rem',
          padding: '0.5rem 1.5rem',
          fontSize: '1rem',
          fontWeight: 700,
          lineHeight: '1.5rem',
          textTransform: 'none',
          borderRadius: 0,
        },
        containedPrimary: {
          backgroundColor: secondaryColor,
          '&:hover': {
            backgroundColor: primaryColor,
            color: secondaryColor,
          },
          ':disabled': {
            backgroundColor: secondaryColor5,
            color: secondaryColor8,
          },
          color: 'white',
        },
        outlined: {
          borderColor: secondaryColor,
          color: secondaryColor,
          '&:hover': {
            backgroundColor: primaryColor,
            color: secondaryColor,
          },
          '&:disabled': {
            borderColor: secondaryColor5,
            color: secondaryColor5,
          },
        },
        text: {
          color: secondaryColor,
          '&:disabled': {
            color: neutralBodyColor2,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: '2.5rem',
          minWidth: '16rem',
        },
      },
    },
  },
  typography,
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'h-1': React.CSSProperties;
    'h-2': React.CSSProperties;
    'h-3': React.CSSProperties;
    'h-4': React.CSSProperties;
    'body-1': React.CSSProperties;
    'body-2': React.CSSProperties;
    Caption: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'h-1': React.CSSProperties;
    'h-2': React.CSSProperties;
    'h-3': React.CSSProperties;
    'h-4': React.CSSProperties;
    'body-1': React.CSSProperties;
    'body-2': React.CSSProperties;
    Caption: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: false;
    h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    body1: false;
    body2: false;
    caption: false;
    overline: false;
    Caption: true;
    'h-1': true;
    'h-2': true;
    'h-3': true;
    'h-4': true;
    'body-1': true;
    'body-2': true;
  }
}
