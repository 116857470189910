import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import SearchField from '../common/SearchField';
import PaginationItem from '@mui/material/PaginationItem';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { filterData } from '../../utils/utils';
import { previousIcon, nextIcon } from '../User/ListUsers';
import { getOrgHandlerForm } from '../../api/form';
import { useNavigate } from 'react-router';
import { entityInfo } from '../../utils/StateStore';
import { EntityInfo } from '../../types/types';

type Props = {};

const EntityFormAssigned = (props: Props) => {
  const navigate = useNavigate();
  const [formAssigned, setFormAssigned] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [formListData, setFormListData] = useState<any>([]);
  const [orgInfo] = useState<any>(() => entityInfo?.value?.orgInfo);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(formListData.length / rowsPerPage)
  );
  const filteredData = filterData(formListData, searchText, 'assignedForms');

  let resetHandler = (textRef: any) => {
    setSearchText('');
  };
  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleFormFill = (id: string, formName: string) => {
    console.log('id' + id);
    navigate(id ? id : 'id', {
      state: { formName, formId: id, orgInfo: orgInfo },
    });
  };
  useEffect(() => {
    //[TODO] Add condition to update setFormAssigned
    setFormAssigned(true);
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
    const fetchData = async () => {
      const _info: EntityInfo = entityInfo?.value;
      const response = await getOrgHandlerForm(orgInfo.id, orgInfo.project_id);
      if (response) {
        setFormListData(response.forms);
        entityInfo?.next({
          ..._info,
          formInfo: response.forms,
        });
        setNoOfPages(Math.ceil(response.forms.length / rowsPerPage));
      }
    };
    if (orgInfo.id) fetchData();
  }, []);

  return (
    <Box>
      <Box marginLeft="2rem" marginY="2rem">
        <Typography variant="h-1">Assigned forms</Typography>
      </Box>
      <Box>
        {formAssigned ? (
          <Box>
            <SearchField
              onchange={inputHandler}
              onClear={resetHandler}
              defaultValue={searchText}
            />

            <TableContainer sx={{ width: '95%', m: '2rem' }} component={Paper}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      width="3.96%"
                      style={{ fontWeight: '700' }}
                    ></TableCell>

                    <TableCell
                      width="39.2%"
                      style={{ fontWeight: '700' }}
                      align="left"
                    >
                      Form Name
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: '700', width: '39.2%' }}
                      align="left"
                    >
                      Project Name
                    </TableCell>
                    <TableCell
                      width="21.4%"
                      style={{ fontWeight: '700' }}
                      align="left"
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((val: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {(page - 1) * rowsPerPage + index + 1 + '.'}
                        </TableCell>
                        <TableCell
                          sx={{ padding: '18px', fontSize: '14px' }}
                          align="left"
                          onClick={() => handleFormFill(val.id, val.name)}
                        >
                          {val.name}
                        </TableCell>
                        <TableCell
                          sx={{ padding: '18px', fontSize: '14px' }}
                          align="left"
                        >
                          {val.project_name}
                        </TableCell>
                        <TableCell
                          sx={{ padding: '18px', fontSize: '14px' }}
                          align="left"
                        >
                          <div className={`status ${val.status}`}>
                            {val.status}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mr="2rem" mt="2rem" pb="1rem">
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="end"
              >
                {filteredData.length > rowsPerPage && (
                  <Typography variant="body-1">
                    {(page - 1) * rowsPerPage + 1}-
                    {filteredData.length < page * rowsPerPage
                      ? filteredData.length
                      : page * rowsPerPage}{' '}
                    of {filteredData.length} forms
                  </Typography>
                )}
                {filteredData.length > 0 && (
                  <Pagination
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: previousIcon,
                          next: nextIcon,
                        }}
                        {...item}
                      />
                    )}
                    page={page}
                    count={noOfPages}
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                )}
                {filteredData.length === 0 && (
                  <Typography variant="body-1">
                    Zero templates present
                  </Typography>
                )}
              </Stack>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginTop="10%"
          >
            <Box>
              <svg
                width="125"
                height="124"
                viewBox="0 0 125 124"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="80.0001"
                  height="99"
                  rx="5"
                  transform="matrix(-0.994522 0.104529 0.104529 0.994522 91.0625 15)"
                  fill="#6897DC"
                />
                <path
                  d="M113.5 8.00175C113.5 5.23964 111.26 3.00079 108.498 3.00176L87.8744 3.00901L38.5 3.00901C35.7386 3.00901 33.5 5.24759 33.5 8.00901L33.5 102C33.5 104.761 35.7386 107 38.5 107L108.5 107C111.261 107 113.5 104.761 113.5 102L113.5 26.4304L113.5 8.00175Z"
                  fill="#F0F5FB"
                />
                <rect
                  x="43.5"
                  y="15"
                  width="60"
                  height="3"
                  rx="1.5"
                  fill="#2C6DCD"
                />
                <rect
                  x="43.5"
                  y="28"
                  width="60"
                  height="3"
                  rx="1.5"
                  fill="#2C6DCD"
                />
                <rect
                  x="43.5"
                  y="41"
                  width="60"
                  height="3"
                  rx="1.5"
                  fill="#2C6DCD"
                />
                <rect
                  x="43.5"
                  y="54"
                  width="32"
                  height="3"
                  rx="1.5"
                  fill="#2C6DCD"
                />
                <rect
                  x="43.5"
                  y="67"
                  width="45"
                  height="3"
                  rx="1.5"
                  fill="#2C6DCD"
                />
              </svg>
            </Box>
            <Box>
              <Typography variant="h-2">You have no assigned forms</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EntityFormAssigned;
