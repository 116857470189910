import Box from '@mui/material/Box';
import {
  FormGroup,
  Select,
  TextField,
  Typography,
  TextareaAutosize,
} from '@mui/material';
import { FieldProjectType } from '../../../types/types';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FieldTypes } from '../../../utils/const';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from './preview.module.css';
import ImageVideo from '../../../pages/common/ImageVideo';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const getFieldForProject = (
  type: string,
  field: FieldProjectType,
  pos?: any,
  subSectionPos?: any,
  editable = true,
  updateField?: any,
  updateFiles?: (file: File[], action: string, pos?: string) => void,
  files?: any
) => {
  const updateImageFiles = (file: File[], action: string, name?: string) => {
    if (updateFiles) {
      updateFiles(file, action, name);
    }
  };
  const updateImageField = (field: any, pos: number) => {
    if (editable) updateField({ ...field }, pos, subSectionPos);
  };
  const handleChange = (e: any) => {
    let value = e?.target?.value;
    if (typeof value === 'string' && field?.options) {
      for (let one of field.options) {
        if (one?.value === value) {
          let values = [{ ...one }];
          if (editable) updateField({ ...field, values }, pos, subSectionPos);
        }
      }
    }
  };
  const handleDateChange = (e: any) => {
    let value = e?.target?.value;
    //check for date key

    let values = [
      {
        key: Array.isArray(field.label)
          ? field.label[0].key
            ? field.label[0].key
            : ''
          : '',
        value,
      },
    ];
    if (editable) updateField({ ...field, values }, pos, subSectionPos);
  };
  const handleOptionsChange = (e: any, position: number) => {
    let values = (field && field.values) || [];
    if (e.target.type === 'checkbox' && values && field?.options) {
      values[position] =
        values[position] && values[position].value
          ? {}
          : field.options[position];
    } else if (
      (e.target.type === 'text' || e.target.type === 'textarea') &&
      values &&
      field?.label
    ) {
      values[position] = {
        key: field.label[position].key,
        value: e.target.value,
      };
    }
    if (editable) updateField({ ...field, values }, pos, subSectionPos);
  };
  switch (type) {
    case FieldTypes.TEXTBOX:
      return (
        <Box sx={{ mt: '1.5rem', width: '100%' }}>
          {Array.isArray(field.label) && (
            <Box style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              {field.label.map(
                (label: any, i: number) =>
                  Object.keys(label).length > 0 && (
                    <Box
                      mx="2rem"
                      display="flex"
                      width="100%"
                      flexDirection="column"
                      flex="1"
                    >
                      <Typography variant="Caption">{label.value}</Typography>
                      <input
                        className={styles.inputTextField}
                        style={
                          {
                            // width:
                            //   field.label.length > 2
                            //     ? `${(20.5 * 2) / field.label.length}rem`
                            //     : '20.5rem',
                          }
                        }
                        onChange={(e) => handleOptionsChange(e, i)}
                        value={
                          (field &&
                            field.values &&
                            Array.isArray(field.values) &&
                            field.values[i] &&
                            field.values[i].value) ||
                          ''
                        }
                        disabled={!editable}
                      />
                    </Box>
                  )
              )}
            </Box>
          )}
        </Box>
      );

    case FieldTypes.TEXTAREA:
      return (
        <Box sx={{ mt: '1.5rem', width: '100%' }}>
          {Array.isArray(field.label) && (
            <Box display="flex" width="100%">
              {field.label.map(
                (label: any, i: number) =>
                  Object.keys(label).length > 0 && (
                    <Box
                      mx="2rem"
                      display="flex"
                      width="100%"
                      flexDirection="column"
                    >
                      <Typography variant="Caption">{label.value}</Typography>
                      <TextareaAutosize
                        maxRows={6}
                        minRows={4}
                        value={
                          (field &&
                            field.values &&
                            Array.isArray(field.values) &&
                            field.values[i] &&
                            field.values[i].value) ||
                          ''
                        }
                        onChange={(e) => handleOptionsChange(e, i)}
                        name="option"
                        aria-label="empty textarea"
                        placeholder="Add Textarea..."
                        className={styles.textAreaField}
                        disabled={!editable}
                      />
                    </Box>
                  )
              )}
            </Box>
          )}
        </Box>
      );

    case FieldTypes.CHECKBOX:
      return (
        <Box
          ml="2rem"
          display="flex"
          flexDirection="column"
          sx={{ width: '328px', mt: '1.5rem' }}
        >
          <Typography variant="Caption">{field!.label![0]!.value}</Typography>
          {field!.options!.length > 0 ? (
            <FormGroup>
              {field!.options!.map(
                (option: any, i: number) =>
                  Object.keys(option).length > 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            field &&
                            field.values &&
                            Array.isArray(field.values) &&
                            field.values[i] &&
                            field.values[i].value
                              ? true
                              : false
                          }
                          onChange={(e) => handleOptionsChange(e, i)}
                          disabled={!editable}
                        />
                      }
                      label={option.value}
                    />
                  )
              )}
            </FormGroup>
          ) : (
            <></>
          )}
        </Box>
      );

    case FieldTypes.DROPDOWN:
      return (
        <Box
          ml="2rem"
          display="flex"
          flexDirection="column"
          sx={{ width: '328px', mt: '1.5rem' }}
        >
          <Typography variant="Caption">{field!.label![0]!.value}</Typography>
          {field!.options && field!.options.length > 0 ? (
            <Select
              inputProps={{ 'aria-label': 'Without label' }}
              value={
                field &&
                field.values &&
                Array.isArray(field.values) &&
                field.values[0] &&
                field.values[0].value /* || field!.options![0].value */
              }
              sx={{ height: '40px' }}
              onChange={handleChange}
              disabled={!editable}
              MenuProps={MenuProps}
            >
              {field!.options!.map(
                (option: any) =>
                  Object.keys(option).length > 0 && (
                    <MenuItem value={option.value}>{option.value}</MenuItem>
                  )
              )}
            </Select>
          ) : (
            <></>
          )}
        </Box>
      );

    case FieldTypes.MULTIPLECHOICE:
      return (
        <FormControl sx={{ ml: '2rem', mt: '1.5rem' }}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            {field!.label![0]!.value}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={
              field &&
              field.values &&
              Array.isArray(field.values) &&
              field.values[0] &&
              field.values[0].value
            }
            onChange={handleChange}
          >
            {field!.options &&
              field!.options.length > 0 &&
              field!.options!.map(
                (option: any, i: number) =>
                  Object.keys(option).length > 0 && (
                    <FormControlLabel
                      value={option.value}
                      control={<Radio />}
                      label={option.value}
                      disabled={!editable}
                    />
                  )
              )}
          </RadioGroup>
        </FormControl>
      );
    case FieldTypes.DATE:
      return (
        <Box sx={{ mt: '1.5rem' }}>
          {Array.isArray(field.label) && (
            <Box display="flex">
              {field!.label &&
                field!.label.length > 0 &&
                field.label.map((label: any, i: number) => (
                  <Box mx="2rem" display="flex" flexDirection="column">
                    <Typography variant="Caption">{label.value}</Typography>
                    <TextField
                      type="date"
                      sx={{ bgcolor: 'white', width: '328px', height: '40px' }}
                      value={
                        field.values && field.values[0].value
                          ? field.values[0].value
                          : new Date().toISOString().split('T')[0]
                      }
                      onChange={handleDateChange}
                      disabled={!editable}
                    />
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      );
    case FieldTypes.IMAGE:
      return (
        <ImageVideo
          fieldData={field}
          updateField={updateImageField}
          pos={pos}
          updateFiles={updateImageFiles}
          type={FieldTypes.IMAGE}
          editable={editable}
          editFromData={false}
        />
      );
    case FieldTypes.VIDEO:
      return (
        <ImageVideo
          fieldData={field}
          updateField={updateImageField}
          pos={pos}
          updateFiles={updateImageFiles}
          type={FieldTypes.VIDEO}
          editable={editable}
          editFromData={false}
        />
      );
    default:
      console.log('Unwanted Type ');
  }
};
