import { LoginInfo, UpdatePassword, userData } from '../types/types';
import { token } from '../utils/StateStore';
import { requestAPI } from '../utils/fetch';
import { REQUEST_GET, REQUEST_POST } from '../utils/const';
import {
  getRandomString,
  getUserInfoFromToken,
  updateAlert,
} from '../utils/utils';

export const loginUser = async (
  body: LoginInfo
): Promise<userData | boolean> => {
  try {
    const xstate = getRandomString(16);

    const loggedInInfo = await requestAPI({
      type: REQUEST_POST,
      url: '/auth/token',
      data: body,
      headers: {
        'x-nonce': getRandomString(10),
        'x-state': xstate,
      },
      isAuthRequired: false,
    });

    if (!loggedInInfo) {
      return false;
    }
    if (loggedInInfo['state'] !== xstate) {
      console.error(
        '[Login] Returned xstate does not matched with the send xstate'
      );
      return false;
    }
    if (loggedInInfo !== undefined && loggedInInfo !== null && loggedInInfo) {
      const userData: userData = getUserInfoFromToken(
        loggedInInfo.access_token
      );

      // token?.next(userData);
      token?.next({
        ...userData,
        userInfo: {
          ...userData.userInfo!,
          permissions: [],
          roles: [],
        },
      });

      return userData;
    }
    return false;
  } catch (error: any) {
    updateAlert({ message: error.message, severity: 'error', state: true });
  }
  return false;
};

export const refreshToken = async () => {
  try {
    const xstate = getRandomString(16);
    const refreshData = await requestAPI({
      type: REQUEST_POST,
      url: '/auth/token-refresh',
      headers: {
        'x-nonce': getRandomString(10),
        'x-state': xstate,
      },
      isAuthRequired: false,
    });

    if (!refreshData) {
      return false;
    }
    if (refreshData['state'] !== xstate) {
      console.error(
        '[Login] Returned xstate does not matched with the send xstate'
      );
      return false;
    }
    const userData: any = getUserInfoFromToken(refreshData.access_token);
    token?.next({
      ...userData,
      userInfo: {
        ...userData.userInfo!,
        permissions: [],
        roles: [],
      },
    });
    return refreshData.access_token
  } catch (error: any) {
    console.error(
      '[Auth] Error while Refreshing user token - ' + error.message
    );
  }
};

export const logoutUser = async () => {
  try {
    await requestAPI({
      type: REQUEST_GET,
      url: '/auth/token-delete',
    });
    token?.next({ accessToken: null, pwdExpiry: null, userInfo: null });
    return true;
  } catch (error) {
    console.error(
      '[Auth] Error while Logging out user. Unable to delete cookie '
    );
    return false;
  }
};

export const updateForgotPassword = async (body: UpdatePassword) => {
  try {
    const updatedPassword = await requestAPI({
      type: REQUEST_POST,
      url: '/auth/update-password',
      data: body,
      isAuthRequired: false,
    });
    return updatedPassword.password_updated ? true : false;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
    return false;
  }
};

export const firstPasswordUpdate = async (body: { new_password: string }) => {
  try {
    const updatedPassword = await requestAPI({
      type: REQUEST_POST,
      url: '/api/update-first-user-login-password',
      data: body,
      isAuthRequired: true,
    });
    return updatedPassword.password_updated ? true : false;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
    return false;
  }
};

export const updateLoggedInPasswordHandler = async (body: {
  new_password: String;
  old_password: String;
}) => {
  try {
    const updatedPassword = await requestAPI({
      type: REQUEST_POST,
      url: '/api/update-password',
      data: body,
    });
    return updatedPassword.password_updated ? true : false;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
    return false;
  }
};
