import { useEffect, useState } from 'react';
import { REQUEST_DELETE, REQUEST_GET } from '../../utils/const';
import { requestAPI } from '../../utils/fetch';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Confirm from '../../components/modal/Confirm';
import {
  filterData,
  getPermissionForPopup,
  updateAlert,
  updateSnackbar,
} from '../../utils/utils';
import './RoleListingStyles.css';
import Typography from '@mui/material/Typography';
import PaginationItem from '@mui/material/PaginationItem';
import { secondaryColor } from '../../theme';
import SearchField from '../common/SearchField';
import Spinner from '../../components/layout/Spinner';

function Roles() {
  const [role, setRole] = useState<any>([]);
  const rowsPerPage = 20;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(role.length / rowsPerPage)
  );
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await requestAPI({
        type: REQUEST_GET,
        url: '/api/roles',
      });
      //TODO: handling code acc to response status
      if (response) {
        setRole(response);
        setIsLoading(false);
        setNoOfPages(Math.ceil(response.length / rowsPerPage));
      } else {
        setIsLoading(false);
        console.error(response);
      }
    };
    fetchData();
  }, []);
  const [testOpen, setTestOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const testClose = async (confirm: boolean) => {
    setTestOpen(confirm);
    if (confirm === true) {
      const response = await requestAPI({
        type: REQUEST_DELETE,
        url: '/api/role',
        data: { role_id: idToDelete },
      });
      if (response) {
        const rolesResponse = await requestAPI({
          type: REQUEST_GET,
          url: '/api/roles',
        });
        if (rolesResponse) {
          setRole(rolesResponse);
          setTestOpen(false);
          updateSnackbar({
            state: true,
            severity: 'error',
            message: 'Role removed',
          });
        } else {
          console.error('error in getting roles');
        }
      } else {
        console.error('error in deleting role');
      }
    }
  };
  const [page, setPage] = useState(1);

  const handleChangePage = (event: any, newPage: number) => {
    console.log('Called Page change');
    setPage(newPage);
  };
  const navigate = useNavigate();
  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const filteredData = filterData(role, inputText, 'roles');
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);
  const removeClickHandler = async (id: string) => {
    const hasPerm = await getPermissionForPopup('Roles', 'd');
    if (hasPerm) {
      setTestOpen(true);
      setIdToDelete(id);
    } else {
      updateAlert({
        message: 'You are not authorized to delete roles',
        severity: 'warning',
        state: true,
      });
    }
  };

  interface UserInterface {
    permissions: any;
    permission: string;
    name: string;
    id: string;
    description: string;
    action: string;
  }

  const editClickHandler = async (id: string) => {
    const hasPerm = await getPermissionForPopup('Roles', 'u');
    hasPerm
      ? navigate('/editRole', { state: { roleId: id } })
      : updateAlert({
          message: 'You are not authorized to edit roles',
          severity: 'warning',
          state: true,
        });
  };
  let resetHandler = (textRef: any) => {
    setInputText('');
    textRef.current.value = '';
  };
  const createRoleHandler = async () => {
    const hasPerm = await getPermissionForPopup('Roles', 'c');
    hasPerm
      ? navigate('/createRole')
      : updateAlert({
          message: 'You are not authorized to create roles',
          severity: 'warning',
          state: true,
        });
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div style={{ width: window.innerWidth - 284 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="2rem"
        >
          <Typography variant="h-1">Roles</Typography>
          <Button
            onClick={createRoleHandler}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        </Box>
        <SearchField
          onchange={inputHandler}
          onClear={resetHandler}
          defaultValue={inputText}
        />
        <TableContainer sx={{ width: '95%', m: '2rem' }} component={Paper}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  width="3.96%"
                  style={{ fontWeight: '700' }}
                ></TableCell>

                <TableCell
                  width="27.2%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ fontWeight: '700', width: '27.2%' }}
                  align="left"
                >
                  Description
                </TableCell>
                <TableCell
                  width="27.2%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Permission
                </TableCell>
                <TableCell
                  width="14.4%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((val: UserInterface, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {(page - 1) * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell
                      sx={{ padding: '18px', fontSize: '14px' }}
                      align="left"
                    >
                      {val.name}
                    </TableCell>
                    <TableCell
                      sx={{ padding: '18px', fontSize: '14px' }}
                      align="left"
                    >
                      {val.description}
                    </TableCell>
                    <TableCell
                      sx={{ padding: '18px', fontSize: '14px' }}
                      align="left"
                    >
                      {val.permissions.map((permission: any, index: any) => (
                        <div key={index}>
                          {/* added key above to resolve warning */}
                          <span>{permission.description}</span>
                          <br />
                        </div>
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: 'flex' }}>
                        <button
                          className="editButton"
                          onClick={() => editClickHandler(val.id)}
                        >
                          Edit
                        </button>
                        <button
                          className="removeButton"
                          onClick={() => removeClickHandler(val.id)}
                        >
                          Remove
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mr="2rem" mt="2rem" pb="1rem">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="end"
          >
            {filteredData.length > rowsPerPage && (
              <Typography variant="body-1">
                {(page - 1) * rowsPerPage + 1}-
                {role.length < page * rowsPerPage
                  ? role.length
                  : page * rowsPerPage}{' '}
                of {role.length} items
              </Typography>
            )}
            {filteredData.length > 0 && (
              <Pagination
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: previousIcon,
                      next: nextIcon,
                    }}
                    {...item}
                  />
                )}
                page={page}
                count={noOfPages}
                shape="rounded"
                onChange={handleChangePage}
              />
            )}
            {filteredData.length === 0 && (
              <Typography variant="body-1">Zero roles present</Typography>
            )}
          </Stack>
        </Box>
      </div>
      <Confirm
        open={testOpen}
        handleClose={testClose}
        buttonText={'Remove'}
        mainHeading={'Remove this role?'}
        secondaryHeading={'Are you sure you want to remove this role?'}
      />
    </>
  );
}

export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};
export default Roles;
