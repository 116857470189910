import React, { useEffect, useState } from 'react';
import TextInput from '../../components/Input/TextInput/TextInput';
import { fieldMaxLimits } from '../../utils/const';
import { FieldProjectType } from '../../types/types';
import { Typography } from '@mui/material';
import Options from './Option';

type Props = {
  updateField: (field: FieldProjectType, pos: number) => void;
  fieldData: any;
  pos: number;
  callFrom?: string;
  editable?: boolean;
};

const OptionField = ({
  updateField,
  pos,
  fieldData,
  callFrom,
  editable,
}: Props) => {
  const [dropDown, setDropDown] = useState<FieldProjectType>({
    ...fieldData,
  });
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDropDown({
      ...dropDown,
      label: [{ key: '', value: event.target.value }],
    });
  };
  const addOption = (pos: number) => {
    if (dropDown.options) {
      let slice1 = dropDown?.options?.slice(0, pos + 1);
      let slice2 = dropDown?.options?.slice(pos + 1);

      setDropDown({
        ...dropDown,
        options: [...slice1, { key: '', value: '' }, ...slice2],
      });
    } else {
      if (Array.isArray(dropDown.options))
        setDropDown({
          ...dropDown,
          options: [...dropDown?.options, { key: '', value: '' }],
        });
    }
  };
  const removeOption = (pos: number) => {
    if (dropDown?.options?.length === 1) {
      return;
    }
    dropDown?.options?.splice(pos, 1);
    setDropDown({ ...dropDown, options: dropDown.options });
  };
  const updateOptionValue = (
    value: {},
    pos: number,
    valueType: string = 'options'
  ) => {
    if (valueType === 'values') {
      dropDown!.values![pos] = value;
      setDropDown({ ...dropDown, values: dropDown.values });
    } else {
      dropDown!.options![pos] = value;
      setDropDown({
        ...dropDown,
        options: dropDown.options,
        values: dropDown.values,
      });
    }
  };
  const getValues = (option: any, i: number) => {
    let key =
      (dropDown?.values &&
        dropDown?.values[0] &&
        dropDown?.values[0].key === option?.key &&
        dropDown?.values[0].key) ||
      '';
    let value =
      (dropDown?.values &&
        dropDown?.values[0] &&
        dropDown?.values[0].key === option?.key &&
        dropDown?.values[0].value) ||
      '';
    if (dropDown?.type === 'checkbox') {
      key =
        (dropDown?.values && dropDown?.values[i] && dropDown?.values[i].key) ||
        '';
      value =
        (dropDown?.values &&
          dropDown?.values[i] &&
          dropDown?.values[i].value) ||
        '';
    }
    return {
      values: {
        key,
        value,
      },
    };
  };
  useEffect(() => {
    updateField(dropDown, pos);
  }, [dropDown]);
  return (
    <div>
      {fieldData.type !== 'textbox' && editable && (
        <TextInput
          value={
            Array.isArray(fieldData.label)
              ? fieldData.label[0].value
              : fieldData.label
          }
          onChange={onChange}
          name="label"
          placeholder={
            callFrom === 'formCreation' || callFrom === 'formEdit'
              ? 'Add Question'
              : 'Add label Name'
          }
          paddingLeft="16px"
          editable={editable}
          callFrom={callFrom}
        />
      )}
      <div
        style={{
          marginLeft: '20px',
          display: editable ? 'inherit' : 'inline-flex',
          marginBottom: editable ? ' 0px' : '12px',
        }}
      >
        {!editable && (
          <Typography
            style={{
              fontSize: '14px',
              color: '#36454F',
              fontWeight: '700',
              fontStyle: 'Mulish',
            }}
          >
            {fieldData.label[0].value} :&nbsp;
          </Typography>
        )}
        {dropDown.options &&
          dropDown.options.map((option, i) => {
            return (
              <Options
                addOption={addOption}
                removeOption={removeOption}
                pos={i}
                option={
                  typeof option === 'string'
                    ? option
                    : {
                        options: {
                          key: option?.key || '',
                          value: option?.value || '',
                        },
                        ...getValues(option, i),
                      }
                }
                displayRemove={
                  !!(
                    dropDown.options !== undefined &&
                    dropDown?.options?.length > 1
                  )
                }
                updateOptionValue={updateOptionValue}
                displayAdd={
                  !!(
                    dropDown.options !== undefined &&
                    dropDown?.options?.length < fieldMaxLimits.DROPDOWNOPTION
                  )
                }
                callFrom={callFrom}
                editable={editable}
                fieldType={fieldData.type}
              />
            );
          })}
      </div>
      {!dropDown.options && (
        <Options
          addOption={addOption}
          removeOption={removeOption}
          pos={1}
          option={{
            options: { key: '', value: '' },
            values: { key: '', value: '' },
          }}
          displayRemove={false}
          updateOptionValue={updateOptionValue}
          displayAdd={true}
          fieldType={fieldData.type}
          callFrom={callFrom}
          editable={editable}
        />
      )}
    </div>
  );
};

export default OptionField;
