import Box from '@mui/material/Box';
import SearchField from '../common/SearchField';
import { useEffect, useState } from 'react';
import { filterData } from '../../utils/utils';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PaginationItem from '@mui/material/PaginationItem';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { secondaryColor } from '../../theme';
import {
  getAllProjects,
  updateProject,
  getProjectData,
} from '../../api/project';
import { useNavigate } from 'react-router';
import { filters } from '../../utils/const';
import ListItemText from '@mui/material/ListItemText';
import DropdownTick from '../../assets/png/DropdownTick.png';
import PreviewWithAccordian from '../../components/modal/Preview/PreviewWithAccordion';
import BasicMenu from '../common/ProjectMenuItem';
import Spinner from '../../components/layout/Spinner';

type Props = {};

const AllProject = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any>([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(projects.length / rowsPerPage)
  );
  const [searchFilter, setSearchFilter] = useState('All');
  const [templateId, setTemplateId] = useState('');
  const [previewModalView, setPreviewModalView] = useState(false);
  const navigate = useNavigate();

  let filteredData: any;
  filteredData = filteredData ? filteredData : projects;
  if (searchFilter === 'Active') {
    filteredData = filteredData.filter(
      (item: any, i: number) => projects[i].disabled === false
    );
  } else if (searchFilter === 'Deactivated') {
    filteredData = filteredData.filter(
      (item: any, i: number) => projects[i].disabled === true
    );
  }
  filteredData = filterData(filteredData, searchText, 'project');
  const inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);

  const handleSearchFilterChange = (event: SelectChangeEvent) => {
    setSearchFilter(event.target.value as string);
  };
  const editModalHandler = (id: string) => {
    //Modal for project preview
    setTemplateId(id);
    setPreviewModalView(true);
  };
  const prevClickHandler = (id: string) => {
    navigate('/preview-project', { state: { projectId: id } });
  };
  let resetHandler = (textRef: any) => {
    setSearchText('');
    textRef.current.value = '';
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllProjects();
      if (data) {
        setProjects(data);
        setNoOfPages(Math.ceil(data.length / rowsPerPage));
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const handleSwitchChange = async (index: number, id: string) => {
    const arr = [...projects];
    const currentProject = arr.filter((pro) => pro._id === id);
    currentProject[0].disabled = !currentProject[0].disabled;
    await updateProject({ id: id, disabled: currentProject[0].disabled }, []);
    setProjects(arr);
  };
  const createHandler = () => {
    navigate('/create-project');
  };

  function jsonToCsv(items: any) {
    var csv =
      'Project' +
      ',' +
      'Section' +
      ',' +
      'Sub-section' +
      ',' +
      'Field Type' +
      ',' +
      'Fields' +
      '\n';
    csv += items.name + '\n';
    if (items.sections) {
      items.sections.map((sec: any) => {
        csv += ',' + sec.title;
        csv += '\n';
        if (sec.subsections) {
          sec.subsections.map((subsec: any) => {
            csv += ',' + ',' + subsec.title;
            csv += '\n';
            if (subsec.fields) {
              subsec.fields.map((field: any) => {
                csv += ',' + ',' + ',' + field.type;
                if (field.label.length === 1) {
                  csv += ',' + field.label[0].value + ':';
                  if (field.type === 'textarea') {
                    csv += ',' + '"' + field.values[0].value + '"';
                  } else {
                    Array.isArray(field.values) &&
                      field.values.map((val: any) => {
                        if (val != null) csv += ',' + val.value;
                      });
                  }
                  if (Array.isArray(field.medias))
                    csv +=
                      ',' +
                      field.medias[0].media_path +
                      field.medias[0].name +
                      ',';
                } else {
                  field.label.map((label: any, index: number) => {
                    csv += ',' + label.value + ':';
                    if (field.values[index] != null)
                      csv += ',' + field.values[index].value + ',';
                  });
                }
                csv += '\n';
              });
              csv += '\n';
            }
          });
        }
      });
    }
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = items.name + '.csv';
    hiddenElement.click();
  }
  const convertToCSV = async (e: any, projectId: string) => {
    if (projectId && projectId !== '') {
      const data = await getProjectData(projectId);
      if (typeof data !== 'boolean') {
        jsonToCsv(data);
      }
    }
  };
  return isLoading ? (
    <Spinner />
  ) : (
    <Box>
      <Box
        sx={{ m: ['2.25rem 2rem 2rem 2rem'] }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h-1">Create Project</Typography>
        <Button variant="contained" onClick={createHandler}>
          Create
        </Button>
      </Box>
      <Box sx={{ m: ['2.25rem 0 2rem 0'] }} display="flex">
        <SearchField
          onchange={inputHandler}
          onClear={resetHandler}
          defaultValue={searchText}
        />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchFilter}
          renderValue={(selected) => `View: ${selected}`}
          onChange={handleSearchFilterChange}
          sx={{
            ml: '5rem',
            width: '17.813rem',
            height: '2.5rem',
          }}
        >
          {filters.map((name) => (
            <MenuItem key={name} value={name}>
              <ListItemText primary={name} />
              {name === searchFilter && (
                <img src={DropdownTick} alt="dropdown tick" />
              )}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box>
        <TableContainer sx={{ width: '95%', m: '2rem' }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  width="3.96%"
                  style={{ fontWeight: '700' }}
                ></TableCell>

                <TableCell
                  width="27.2%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Project Name
                </TableCell>
                <TableCell
                  style={{ fontWeight: '700', width: '27.2%' }}
                  align="left"
                >
                  Project Key
                </TableCell>
                <TableCell
                  width="27.2%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((val: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {(page - 1) * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '18px',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }}
                      align="left"
                      onClick={() => prevClickHandler(val._id)}
                    >
                      {val.name}
                    </TableCell>
                    <TableCell
                      sx={{ padding: '18px', fontSize: '14px' }}
                      align="left"
                    >
                      {val.alias}
                    </TableCell>
                    <TableCell align="left">
                      <Box>
                        <Switch
                          inputProps={{ 'aria-label': 'Switch demo' }}
                          checked={!filteredData[index].disabled}
                          onChange={() => handleSwitchChange(index, val._id)}
                        />
                        <BasicMenu
                          previewModalHandler={() => editModalHandler(val._id)}
                          projectId={val._id}
                          callFrom="allProject"
                        />
                        <Button
                          onClick={(e) => convertToCSV(e, val._id)}
                          sx={{ left: '-20px' }}
                        >
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 17H18V19H0V17ZM10 11.172L13.071 8.1L14.485 9.514L9 15L3.515 9.515L4.929 8.1L8 11.17V0H10V11.172Z"
                              fill="#0451C4"
                            />
                          </svg>
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mr="2rem" mt="2rem" pb="1rem">
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="end"
        >
          {filteredData.length > rowsPerPage && (
            <Typography variant="body-1">
              {(page - 1) * rowsPerPage + 1}-
              {projects.length < page * rowsPerPage
                ? projects.length
                : page * rowsPerPage}{' '}
              of {projects.length} items
            </Typography>
          )}
          {filteredData.length > 0 && (
            <Pagination
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: previousIcon,
                    next: nextIcon,
                  }}
                  {...item}
                />
              )}
              page={page}
              count={noOfPages}
              shape="rounded"
              onChange={handleChangePage}
            />
          )}
          {filteredData.length === 0 && (
            <Typography variant="body-1">Zero projects present</Typography>
          )}
        </Stack>
      </Box>
      {previewModalView && (
        <PreviewWithAccordian
          open={previewModalView}
          handleClose={(previewOpen: boolean) => {
            setPreviewModalView(previewOpen);
          }}
          modalId={templateId}
          modalType="project"
          isNewProjectCreation={false}
        />
      )}
    </Box>
  );
};

export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};
export default AllProject;
