import { Box } from '@mui/system';
import { Stack } from '@mui/material';
import React from 'react';
import Collapsed from '../../../assets/png/Collapsed.png';
import Expanded from '../../../assets/png/Expanded.png';

import LeftDrawerMenuItems from './LeftDrawerMenuItems';
interface Props {
  expandDrawer: boolean;
  setExpandDrawer: React.Dispatch<React.SetStateAction<any>>;
}
const LeftDrawer = ({ setExpandDrawer, expandDrawer }: Props) => {
  return (
    <Box mt="1rem">
      <Box
        mb="1.5rem"
        sx={{ clear: 'both', height: '10px' }}
        display="flex"
        justifyContent={expandDrawer ? 'flex-end' : 'center'}
      >
        <Box
          mr="0.5rem"
          height="1.5rem"
          width="1.5rem"
          onClick={() => setExpandDrawer(!expandDrawer)}
          sx={{
            float: 'right',
            backgroundImage: `url(${expandDrawer ? Collapsed : Expanded})`,
            backgroundRepeat: 'no-repeat',
            objectFit: 'contain',
            backgroundSize: 'cover',
          }}
        />
      </Box>
      <Box mt="1rem">
        <Stack spacing={'1rem'}>
          <LeftDrawerMenuItems expandDrawer={expandDrawer} />
        </Stack>
      </Box>
    </Box>
  );
};

export default LeftDrawer;
