import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PaginationItem from '@mui/material/PaginationItem';
import Typography from '@mui/material/Typography';
import { secondaryColor } from '../../theme';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import Confirm from '../../components/modal/Confirm';
import { createFormCopy, removeForm } from '../../api/form';
import { updateSnackbar } from '../../utils/utils';
import { getForm } from '../../api/form';
import FormPreviewAndDataFilling from '../../components/modal/form/FormPreviewAndDataFilling';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Spinner from '../../components/layout/Spinner';

type Props = {};

const FormList = (props: Props) => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const { projectName } = state || '';
  const { projectId } = state || '';
  const { projectAlias } = state || '';
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [formToRemove, setFormToRemove] = useState('');
  const [forms, setForms] = useState<any>([]);
  const rowsPerPage = 20;

  useEffect(() => {
    const fetchData = async () => {
      const data = await getForm({ projectId });
      if (data) {
        setForms(data);
        setIsLoading(false);
        setNoOfPages(Math.ceil(data.length / rowsPerPage));
      }
    };
    fetchData();
  }, []);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(forms.length / rowsPerPage)
  );
  const [formPreviewModal, setFormPreviewModal] = useState(false);
  const [formId, setFormId] = useState('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  let filteredData: any;
  filteredData = filteredData ? filteredData : forms;
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);

  const backClickHandler = () => {
    navigate(-1);
  };

  const editFormHandler = (form_id: any) => {
    navigate('/edit-form', {
      state: { templateId: projectId, alias: projectAlias, formId: form_id },
    });
  };

  const createFormHandler = () => {
    navigate('/create-form', {
      state: { templateId: projectId, alias: projectAlias, formId: '' },
    });
  };
  const handleFormPublish = (projectId: any, projectName: any, formId: any) => {
    navigate('/assign-form', {
      state: {
        projectId: projectId,
        projectName: projectName,
        callFrom: 'previewForm',
        formId: formId,
      },
    });
  };
  const removeFormHandler = (id: string) => {
    setRemoveModalOpen(true);
    setFormToRemove(id);
  };

  const removeModalHandleClose = async (confirm: boolean) => {
    setRemoveModalOpen(confirm);
    if (confirm === true) {
      const formData = {
        id: formToRemove,
      };
      const formRemoved = await removeForm(formData);
      if (formRemoved) {
        setRemoveModalOpen(false);
        window.location.reload();
        updateSnackbar({
          state: true,
          severity: 'success',
          message: 'Form removed successfully',
        });
      }
    }
  };

  const handleTripleDotClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleTripleDotMenuClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleFormCopy = async () => {
    const formStructCreated = await createFormCopy({ form_id: formId });
    window.location.reload();
    if (!formStructCreated) {
      updateSnackbar({
        state: true,
        severity: 'success',
        message: 'Form copy made',
      });
    }
  };
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      {forms && forms.length === 0 ? (
        <Box>
          <Box
            sx={{ m: ['2.25rem 2rem 2rem 2rem'] }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                onClick={backClickHandler}
                style={{ paddingRight: '8px', cursor: 'pointer' }}
                src={ArrowLeft}
                alt="left arrow"
              />
              <Box>
                <Typography variant="h-1">Forms</Typography>
                <Typography display={'block'} variant="Caption">
                  {projectName}
                </Typography>
              </Box>
            </div>
          </Box>
          <Box sx={{ padding: '5%' }}>
            <Box sx={{ marginLeft: '40%' }}>
              <svg
                width="125"
                height="124"
                viewBox="0 0 125 124"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="64.7525"
                  height="99.6984"
                  rx="5"
                  transform="matrix(-0.965926 0.258819 0.258819 0.965926 70.0469 8.9375)"
                  fill="#6897DC"
                />
                <path
                  d="M104.225 14.9325L92.5 2L42.5 2.00001C39.7386 2.00001 37.5 4.23858 37.5 7.00001L37.5 117C37.5 119.761 39.7386 122 42.5 122L112.5 122C115.261 122 117.5 119.761 117.5 117L117.5 29L104.225 14.9325Z"
                  fill="#F0F5FB"
                />
                <rect
                  x="47.5"
                  y="20"
                  width="60"
                  height="25"
                  rx="1"
                  fill="#DCE7F7"
                />
                <path
                  d="M104.573 14.9269L92.5 2L92.5 24C92.5 26.7614 94.7386 29 97.5 29L117.5 29L104.573 14.9269Z"
                  fill="#6897DC"
                />
                <rect
                  x="47.5"
                  y="55"
                  width="40"
                  height="3"
                  rx="1.5"
                  fill="#2C6DCD"
                />
                <rect
                  x="47.5"
                  y="66"
                  width="24"
                  height="3"
                  rx="1.5"
                  fill="#2C6DCD"
                />
              </svg>
            </Box>
            <div style={{ marginLeft: '34%', padding: '15px' }}>
              You haven't created any forms
            </div>
            <Button
              variant="contained"
              onClick={createFormHandler}
              sx={{ marginLeft: '40%' }}
            >
              Create Form
            </Button>
          </Box>
          {/* {createOrgModalView && (
            <CreateOrgStructure
              open={createOrgModalView}
              handleClose={(modalOpen: boolean) => {
                setCreateOrgModalView(modalOpen);
              }}
              projectId={projectId}
              isEdit={false}
            />
          )} */}
        </Box>
      ) : (
        <Box>
          <Box
            sx={{ m: ['2.25rem 2rem 2rem 2rem'] }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                onClick={backClickHandler}
                style={{ paddingRight: '8px', cursor: 'pointer' }}
                src={ArrowLeft}
                alt="left arrow"
              />
              <Box>
                <Typography variant="h-1">Forms</Typography>
                <Typography display={'block'} variant="Caption">
                  {projectName}
                </Typography>
              </Box>
            </div>
            <div>
              <Button variant="contained" onClick={createFormHandler}>
                Add Form
              </Button>
            </div>
          </Box>
          <Box>
            <TableContainer sx={{ width: '95%', m: '2rem' }}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      width="3.96%"
                      style={{ fontWeight: '700' }}
                    ></TableCell>

                    <TableCell
                      // width="27.2%"
                      style={{ fontWeight: '700' }}
                      align="left"
                    >
                      Form Name
                    </TableCell>
                    <TableCell
                      width="37.2%"
                      style={{ fontWeight: '700' }}
                      align="left"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {filteredData.length !== 0 ? (
                  <TableBody>
                    {filteredData
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map((val: any, index: number) => (
                        <TableRow key={index.toString() + '' + val.name}>
                          <TableCell component="th" scope="row">
                            {(page - 1) * rowsPerPage + index + 1}.
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {val.name}
                          </TableCell>

                          <TableCell align="left">
                            <Box display="flex">
                              <Button
                                sx={{
                                  padding: 0,
                                  fontWeight: 700,
                                  marginLeft: '-30px',
                                }}
                                onClick={() => {
                                  editFormHandler(val.id);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                sx={{
                                  padding: 0,
                                  fontWeight: 700,
                                  color: 'red',
                                }}
                                onClick={() => {
                                  removeFormHandler(val.id);
                                }}
                              >
                                Remove
                              </Button>
                              <Button
                                sx={{ padding: 0, fontWeight: 700 }}
                                onClick={() => {
                                  setFormId(val.id);
                                  setFormPreviewModal(true);
                                }}
                              >
                                Preview
                              </Button>
                              <>
                                <Button
                                  onClick={(e) => {
                                    handleTripleDotClick(e);
                                    setFormId(val.id);
                                  }}
                                >
                                  <MoreVertIcon color="action" />
                                </Button>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleTripleDotMenuClose}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                >
                                  <MenuItem onClick={handleFormCopy}>
                                    Make a copy
                                  </MenuItem>
                                </Menu>
                              </>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  <TableRow sx={{ backgroundColor: '#FAFAFA' }}>
                    <TableCell
                      align="center"
                      colSpan={8}
                      sx={{ color: '#525862' }}
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </Box>
          <Box mr="2rem" mt="2rem" pb="1rem">
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="end"
            >
              {filteredData.length > rowsPerPage && (
                <Typography variant="body-1">
                  {(page - 1) * rowsPerPage + 1}-
                  {forms.length < page * rowsPerPage
                    ? forms.length
                    : page * rowsPerPage}{' '}
                  of {forms.length} items
                </Typography>
              )}
              {filteredData.length > 0 && (
                <Pagination
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        previous: previousIcon,
                        next: nextIcon,
                      }}
                      {...item}
                    />
                  )}
                  page={page}
                  count={noOfPages}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              )}
            </Stack>
          </Box>
          <FormPreviewAndDataFilling
            open={formPreviewModal}
            handleClose={() => setFormPreviewModal(false)}
            handlePublish={() =>
              handleFormPublish(projectId, projectName, formId)
            }
            formId={formId}
            projectId={projectId}
            publish={true}
          />
          {removeModalOpen && (
            <Confirm
              open={removeModalOpen}
              handleClose={removeModalHandleClose}
              buttonText={'Delete'}
              mainHeading={'Delete Form'}
              secondaryHeading={`Are you sure you want to delete this form?`}
            />
          )}
        </Box>
      )}
    </>
  );
};

export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};
export default FormList;
