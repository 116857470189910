import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserRoleInfo } from '../../types/types';
import { token } from '../../utils/StateStore';
function UsersRole() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      const resp = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT_SP_WORKSPACE}/api/users?emailId=abhi@raj.me&roles=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.value}`,
          },
        }
      );
      const response = await resp.json();
      //TODO: handling code acc to response status
      if (response.status === 'ok') {
        setUsers(response.data);
      } else {
        console.error(response.status);
      }
    })();
  }, []);

  return (
    <>
      <Link to="/">
        <button>Home Page</button>
      </Link>
      <div>
        {users.map((val: UserRoleInfo, index) => (
          <div key={index}>
            {val.email}
            {val.roles.map((value: any, ind) => (
              <li key={ind}>{value.name}</li>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default UsersRole;
