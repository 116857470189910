import { Box } from '@mui/system';
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';
import LeftDrawer from './drawer/LeftDrawer';
import { useEffect, useState } from 'react';
import { spinner, token } from '../../utils/StateStore';
import Spinner from './Spinner';
import { useNavigate } from 'react-router';
import BasicSnackbar from '../../pages/common/BasicSnackbar/BasicSnackbar';

const CommonLayout = (props: any) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<null | boolean>(null);
  const [isFirstTime, setIsFirstTime] = useState<null | boolean>(false);
  const [isLoading, setIsLoading] = useState<null | boolean>(false);
  const [expandDrawer, setExpandDrawer] = useState(true);
  useEffect(() => {
    let isMounted = true;
    const subscribedToken = token?.subscribe((x) => {
      if (x?.userInfo?.first_login === 'pending' || x?.userInfo?.first_login === 'partiallyLoggedIn') {
        navigate(x?.userInfo?.first_login === 'pending' ? '/first-time' : '/first-time-template');
      }
      if (x?.accessToken && isMounted) {
        setIsAuthenticated(true);
        setIsFirstTime(x?.userInfo?.first_login === 'pending');
      }
    });

    const subscribedSpinner = spinner?.subscribe((x) => {
      setIsLoading(x!);
    });
    return () => {
      isMounted = false;
      subscribedToken?.unsubscribe();
      subscribedSpinner?.unsubscribe();
    };
  }, [isLoading]);
  return (
    <Box height="100%">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Navbar isAuthenticated={isAuthenticated!} />
          <BasicSnackbar />
          <Box height="100%" display="flex" width="100%">
            {(!isFirstTime) ? (
              <Box
                height="100%"
                width={expandDrawer ? '23%' : '3.5%'}
                boxShadow="1px 0px 0px rgba(24, 25, 31, 0.12)"
              >
                <LeftDrawer
                  expandDrawer={expandDrawer}
                  setExpandDrawer={setExpandDrawer}
                />
              </Box>
            ) : (
              <></>
            )}

            <Box height="100%" width="100%">
              {isAuthenticated ? <Outlet /> : <>Please Login first</>}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CommonLayout;
