import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SubmitButtonProps } from '../../types/types';

export const SubmitButton = ({
  onClick,
  disabled,
  text,
}: SubmitButtonProps) => {
  const useStyles = makeStyles({
    submitButton: {
      fontFamily: 'Mulish',
      background: '#0451c4',
      color: '#FFFFFF',
      borderRadius: '0px',
      textTransform: 'none',
      lineHeight: '24px',
      fontSize: '16px',
      fontWeight: '700',
      padding: '8px 24px',
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        height: '40px',
        backgroundColor: '#fedc2a',
        color: '#0451c4',
        lineHeight: '24px',
        size: '16px',
        fontWeight: '700',
        boxShadow: 'none',
      },
      '&:disabled': {
        color: '#FFFFFF',
        backgroundColor: '#6897DC',
      },
    },
  });
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={classes.submitButton}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default SubmitButton;
