import { CreateFlow, ResendOTP, VerifyFlow } from '../types/types';
import { REQUEST_POST } from '../utils/const';
import { requestAPI } from '../utils/fetch';
import { flow } from '../utils/StateStore';
import { updateAlert } from '../utils/utils';

export const createFlow = async (body: CreateFlow) => {
  try {
    const flowCretionInfo = await requestAPI({
      type: REQUEST_POST,
      url: '/auth/flow',
      data: body,
      isAuthRequired: false,
    });
    if (!flowCretionInfo) {
      return false;
    }
    //[TODO] Check if flow creation failed ..handle it
    flow?.next({
      flowId: flowCretionInfo.flow_id,
      flowType: flowCretionInfo.flow_type,
      email: flowCretionInfo.email,
      phone: flowCretionInfo.phone,
      userId: flowCretionInfo.user_id,
    });

    return flowCretionInfo;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
  return false;
};

export const verifyFlow = async (body: VerifyFlow) => {
  try {
    const flowVerification = await requestAPI({
      type: REQUEST_POST,
      url: '/auth/verify-otp-flow',
      data: body,
      isAuthRequired: false,
    });
    if (!flowVerification) {
      return false;
    }
    if (flowVerification.message) {
      return flowVerification.message;
    }
    //check response if failed or not don't just send true
    return flowVerification.flow_verified;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
  return false;
};

export const resendOTP = async (body: ResendOTP) => {
  try {
    const otpSent = await requestAPI({
      type: REQUEST_POST,
      url: '/auth/send-otp',
      data: body,
      isAuthRequired: false,
    });
    if (!otpSent) {
      return false;
    }
    return otpSent.sent;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
  return false;
};
