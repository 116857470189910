import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import {
  // FieldType,
  FieldProjectType,
  // SubSectionTemplateType,
  SubSectionProjectType,
  // ProjectTemplateSectionType,
  ProjectSectionProjectType
} from '../../../types/types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getFieldForProject } from '../../../components/modal/Preview/Preview'; 
import { useState, useEffect } from 'react';
type Props = {
  modalType: any;
  sectionData: any;
  pos: number;
  updateFiles?: (file: File[], action: string, pos?: string) => void;
  updateSectionsData?: (
    pos: number,
    section: ProjectSectionProjectType
  ) => void;
  editable?:boolean;
  setExpanded?:(index:number | false) =>void;
  expanded?:number | boolean; 
  callFrom?:string
};

const accordianData = (section:any,editable:boolean,updateField:any,updateFiles:any,callFrom:string | undefined) => {
  return  <Box sx={{ maxHeight: '22rem', overflowY: callFrom === 'editTemplate' ? 'inherit' : 'auto' }}>
  <Box sx={{ m: '0 0 2rem 2rem' }}>
    <Typography variant="h-3">
      {section.title ? section.title : 'Section Name'}
    </Typography>
  </Box>
  {section.subsections &&
    section.subsections.length > 0 &&
    section.subsections.map(
      (subsection: SubSectionProjectType, subSectionPos: number) => (
        <Box key={subSectionPos}>
          <Box sx={{ m: '2rem 0 2rem 2rem' }}>
            <Typography variant="body-2" color="#B8BABE">
              {subsection.title
                ? subsection.title
                : 'Sub-Section Title'}
            </Typography>
          </Box>
            <Box>
              {subsection.fields &&
                subsection.fields.length > 0 &&
                subsection.fields.map((field: any, i: number) =>
                  getFieldForProject(
                    field.type,
                    field,
                    i,
                    subSectionPos,
                    editable,
                    updateField,
                    updateFiles
                  )
                )}
            </Box>
          <Divider sx={{ m: '3.75rem 2rem 2.5rem 2rem' }} />
        </Box>
      )
    )}
</Box>
}

const SectionPreviewAccordian = ({
  modalType,
  pos,
  sectionData,
  updateFiles,
  updateSectionsData,
  editable=true,
  expanded,
  setExpanded,
  callFrom
}: Props) => {
  // const [expanded, setExpanded] = useState<number>(0);
  const [section, setSection] = useState<ProjectSectionProjectType>(
    sectionData
  );
  const handleChange = (panel: number) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded && setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    updateSectionsData && updateSectionsData(pos, section);
  }, [pos, section]);
  const updateSubSection = (
    subsection: SubSectionProjectType,
    subSectionPos: number
  ) => {
    let updatePosArray = section.subsections;
    updatePosArray[subSectionPos] = { ...subsection };
    setSection({
      ...section,
      subsections: updatePosArray,
    });
  };
  const updateField = (
    field: FieldProjectType,
    pos: number,
    subSectionPos: number
  ) => {
    let subSection = section?.subsections[subSectionPos];
    let updatePosArray = subSection.fields;
    updatePosArray[pos] = field;
    updateSubSection(
      {
        ...subSection,
        fields: updatePosArray,
      },
      subSectionPos
    );
  };
  return (
    <Box
      sx={{
        m: '2rem',
        border: callFrom === 'editTemplate' ? '0px solid #B8BABE' : '1px solid #B8BABE',
      }}
      key={pos}
    >
      {callFrom !== 'editTemplate' && <Accordion
        expanded={expanded === pos}
        onChange={handleChange(pos)}
      >
       <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            variant="h-3"
            display={expanded === pos ? 'none' : 'block'}
          >
            {section.title ? section.title : 'Section Name'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        {accordianData(section,editable,updateField,updateFiles,callFrom)}
        </AccordionDetails>
      </Accordion> ||
      accordianData(section,editable=false,updateField,updateFiles,callFrom)
      }
    </Box>
  );
};

export default SectionPreviewAccordian;
