import { Checkbox, FormGroup } from '@mui/material';
import Box from '@mui/material/Box';
import { FieldProjectType } from '../../../types/types';
import { FieldTypes } from '../../../utils/const';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { projectTypeInfo } from '../../../utils/StateStore';

type Props = {
  field: FieldProjectType;
  index: number;
  valueInput?: boolean;
  updateField?: (field: any, index: number) => void;
};

const FormAccordianContent = ({
  field: fieldData,
  index,
  valueInput = false,
  updateField,
}: Props) => {
  const [field, setField] = useState<FieldProjectType>(fieldData);
  const [projectTypeData] = useState(
    projectTypeInfo &&
      projectTypeInfo.value.filter((proType) => proType.type === 'ORC')[0] //[TODO] Later when we proper implement type of template and project then we remove this hardcoding
  );
  const handleChange = (event: any, type: String, i: number = 0) => {
    //update field value
    const fieldData = projectTypeData.fields.filter(
      (field) => field.type === type
    )[0];
    const fieldoptions =
      fieldData && fieldData.options ? fieldData.options : [];
    let valuesData = (field && field.values) || [];
    const fieldNum = fieldoptions.filter(
      (opt) => opt.key === event.target.value
    )[0];
    const fieldNumValue = fieldNum ? fieldNum.valueNum : -1;
    if (
      (type === 'text' || type === 'textarea') &&
      valuesData &&
      field?.label
    ) {
      valuesData[i] = {
        key: field.label[i].key,
        value: event.target.value,
      };
    } else if (
      type === FieldTypes.DROPDOWN ||
      type === FieldTypes.MULTIPLECHOICE
    ) {
      valuesData[i] = {
        key:
          (field.options &&
            field.options.filter((op) => op.key === event.target.value)[0]
              .key) ||
          '',
        value:
          (field.options &&
            field.options.filter((op) => op.key === event.target.value)[0]
              .value) ||
          '',
        valueNum: fieldNumValue,
      };
    } else if (type === FieldTypes.CHECKBOX) {
      if (
        valuesData &&
        valuesData.length &&
        valuesData[i] &&
        valuesData[i].key === event.target.value
      ) {
        valuesData.splice(i, 1);
      } else {
        valuesData[i] = {
          key:
            (field.options &&
              field.options.filter((op) => op.key === event.target.value)[0]
                .key) ||
            '',
          value: event.target.checked,
        };
      }
    } else if (type === FieldTypes.DATE) {
      let value = event?.target?.value;
      valuesData = [
        {
          key: Array.isArray(field.label)
            ? field.label[0].key
              ? field.label[0].key
              : ''
            : '',
          value,
        },
      ];
    }
    setField({ ...field, values: valuesData });
    if (updateField) {
      updateField({ ...field, values: valuesData }, index);
    }
  };

  const getForm = () => {
    switch (field.type) {
      case FieldTypes.CHECKBOX:
        return (
          <Box
            sx={{
              mt: '1rem',
              width: '100%',
              border: '1px solid #B8BABE',
              borderRadius: '0.25rem',
            }}
          >
            <Box
              ml="1rem"
              display="flex"
              flexDirection="column"
              sx={{ width: '328px', mt: '1rem' }}
            >
              <Box mb="0.75rem">
                <Typography variant="body-1">
                  Q. {field!.label![0]!.value}
                </Typography>
              </Box>
              <Box ml="1rem">
                {field && field.options && field.options.length > 0 ? (
                  <FormGroup>
                    {field!.options!.map(
                      (option: any, i: number) =>
                        Object.keys(option).length > 0 && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  field &&
                                  field.values &&
                                  Array.isArray(field.values) &&
                                  field.values[i] &&
                                  field.values[i].value
                                    ? true
                                    : false
                                }
                                value={field.options && field.options[i].key}
                                onChange={(e) =>
                                  handleChange(e, FieldTypes.CHECKBOX, i)
                                }
                              />
                            }
                            label={option.value}
                          />
                        )
                    )}
                  </FormGroup>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        );
      case FieldTypes.DROPDOWN:
        return (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              mt: '1rem',
              width: '100%',
              border: '1px solid #B8BABE',
              borderRadius: '0.25rem',
            }}
          >
            <Box m="1rem">
              <Box mb="0.75rem">
                <Typography variant="body-1">
                  Q. {field!.label![0]!.value}
                </Typography>
              </Box>
              <Box ml="1rem">
                {field!.options && field!.options.length > 0 ? (
                  <Select
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={
                      (field &&
                        field.values &&
                        Array.isArray(field.values) &&
                        field.values[0] &&
                        field.values[0].key) ||
                      '' /* || field!.options![0].value */
                    }
                    sx={{ height: '40px' }}
                    onChange={(event: SelectChangeEvent) =>
                      handleChange(event, FieldTypes.DROPDOWN)
                    }
                    disabled={!valueInput}
                  >
                    {field!.options!.map(
                      (option: any) =>
                        Object.keys(option).length > 0 && (
                          <MenuItem value={option.key}>
                            {option.value}{' '}
                          </MenuItem>
                        )
                    )}
                  </Select>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        );
      case FieldTypes.MULTIPLECHOICE:
        return (
          <Box
            sx={{
              mt: '1rem',
              width: '100%',
              border: '1px solid #B8BABE',
              borderRadius: '0.25rem',
            }}
          >
            <FormControl sx={{ ml: '1rem', mt: '1rem' }}>
              <Box mb="0.75rem">
                <Typography variant="body-1">
                  Q. {field!.label![0]!.value}
                </Typography>
              </Box>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={
                  (field &&
                    field.values &&
                    Array.isArray(field.values) &&
                    field.values[0] &&
                    field.values[0].key) ||
                  ''
                }
                onChange={(event: React.SyntheticEvent) =>
                  handleChange(event, FieldTypes.MULTIPLECHOICE)
                }
              >
                <Box display="flex" flexDirection="column">
                  {field!.options &&
                    field!.options.length > 0 &&
                    field!.options!.map(
                      (option: any, i: number) =>
                        Object.keys(option).length > 0 && (
                          <Box>
                            <FormControlLabel
                              control={<Radio />}
                              label={option.value}
                              value={field.options && field.options[i].key}
                              name={field.options && field.options[i].value}
                            />
                          </Box>
                        )
                    )}
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        );
      case FieldTypes.TEXTBOX:
        return (
          <>
            <Box
              sx={{
                mt: '1rem',
                width: '100%',
                border: '1px solid #B8BABE',
                borderRadius: '0.25rem',
              }}
            >
              {Array.isArray(field.label) && (
                <Box
                  m="1rem"
                  style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                >
                  {field.label.map(
                    (label: any, i: number) =>
                      Object.keys(label).length > 0 && (
                        <Box
                          display="flex"
                          width="100%"
                          flexDirection="column"
                          flex="1"
                        >
                          <Box mb="0.75rem">
                            <Typography variant="body-1">
                              Q. {label.value}
                            </Typography>
                          </Box>
                          <Box mx="1.5rem">
                            <input
                              style={{
                                height: '2.5rem',
                                width: '100%',
                              }}
                              value={
                                (field &&
                                  field.values &&
                                  Array.isArray(field.values) &&
                                  field.values[i] &&
                                  field.values[i].value) ||
                                ''
                              }
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => handleChange(event, FieldTypes.TEXTAREA, i)}
                              disabled={!valueInput}
                            />
                          </Box>
                        </Box>
                      )
                  )}
                </Box>
              )}
            </Box>
          </>
        );
      case FieldTypes.TEXTAREA:
        return (
          <>
            <Box
              sx={{
                width: '100%',
                mt: '1rem',
                border: '1px solid #B8BABE',
                borderRadius: '0.25rem',
              }}
            >
              {Array.isArray(field.label) && (
                <Box display="flex" width="100%" m="1rem">
                  {field.label.map(
                    (label: any, i: number) =>
                      Object.keys(label).length > 0 && (
                        <Box display="flex" width="100%" flexDirection="column">
                          <Box mb="0.75rem">
                            <Typography variant="body-1">
                              Q. {label.value}
                            </Typography>
                          </Box>
                          <TextareaAutosize
                            maxRows={6}
                            minRows={4}
                            value={
                              (field &&
                                field.values &&
                                Array.isArray(field.values) &&
                                field.values[i] &&
                                field.values[i].value) ||
                              ''
                            }
                            name="option"
                            aria-label="empty textarea"
                            placeholder="Add Text Here..."
                            // className={styles.textAreaField}
                            disabled={!valueInput}
                            style={{
                              marginRight: '2rem',
                            }}
                            onChange={(event: any) =>
                              handleChange(event, FieldTypes.TEXTAREA)
                            }
                          />
                        </Box>
                      )
                  )}
                </Box>
              )}
            </Box>
          </>
        );
      case FieldTypes.DATE:
        return (
          <Box
            sx={{
              width: '100%',
              mt: '1rem',
              border: '1px solid #B8BABE',
              borderRadius: '0.25rem',
            }}
          >
            {Array.isArray(field.label) && (
              <Box display="flex" m="1rem">
                {field!.label &&
                  field!.label.length > 0 &&
                  field.label.map((label: any, i: number) => (
                    <Box display="flex" flexDirection="column">
                      <Box mb="0.75rem">
                        <Typography variant="body-1">
                          Q. {label.value}
                        </Typography>
                      </Box>
                      <TextField
                        type="date"
                        sx={{
                          bgcolor: 'white',
                          width: '328px',
                          height: '40px',
                        }}
                        value={
                          field.values && field.values[0].value
                            ? field.values[0].value
                            : new Date().toISOString().split('T')[0]
                        }
                        onChange={(event: any) =>
                          handleChange(event, FieldTypes.DATE)
                        }
                        disabled={!valueInput}
                      />
                    </Box>
                  ))}
              </Box>
            )}
          </Box>
        );
      default: {
        console.log('NO choice');
      }
    }
    return <>Field options</>;
  };
  useEffect(() => {
    const existingOptions =
      (projectTypeData &&
        projectTypeData.fields.filter(
          (tempField) => tempField.type === field.type
        )[0]?.options) ||
      [];
    setField({ ...field, options: existingOptions });
  }, []);

  return <Box>{getForm()}</Box>;
};

export default FormAccordianContent;
