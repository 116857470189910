import { Box } from '@mui/material';
import PreviewNode from './PreviewNode';
import { NodeI } from './PreviewNode';

type Props = {
  structure: string[];
  previewData: any;
  currLevel: number;
  formSublevelData: any;
  updateSingleSrcObj: (updatedObj: NodeI, pathArr: string[]) => void;
  currPath: string[];
  callFrom?: any;
};

const Preview = ({
  structure,
  previewData,
  currLevel,
  formSublevelData,
  updateSingleSrcObj,
  currPath,
  callFrom,
}: Props) => {
  const getFormSubLevelData = (id: string) => {
    if (formSublevelData) {
      const data = formSublevelData.filter(
        (formSublevel: any) => formSublevel.sublevel_id === id
      );
      return { ...data[0] };
    } else
      return {
        sublevel_id: '',
        checked: false,
        child: [],
        all_child: false,
      };
  };

  return (
    <Box ml="2rem">
      {previewData &&
        previewData.length > 0 &&
        previewData.map((node: any, index: number) => (
          <Box key={index}>
            <PreviewNode
              structure={structure}
              node={node}
              currLevel={currLevel}
              formSublevelData={getFormSubLevelData(
                node.id ? node.id : node.sublevel_id
              )}
              updateSingleSrcObj={updateSingleSrcObj}
              currPath={currPath.concat(node.id)}
              callFrom={callFrom}
            />
          </Box>
        ))}
    </Box>
  );
};

export default Preview;
