import { Box, TextField } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
interface textinput {
  placeholder: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  name: string;
  value: string;
  type?: string;
  width?: string;
  paddingLeft?: string;
  customClassName?: any;
  editable?: boolean;
  callFrom?: string;
}
const TextInput = ({
  value,
  onChange,
  name,
  placeholder,
  width = '21.625rem',
  type = 'text',
  customClassName,
  paddingLeft,
  editable,
  callFrom,
}: textinput) => {
  const location = useLocation();
  return (
    <>
      {callFrom === 'formCreation' || callFrom === 'formEdit' ? (
        <>
          <Box
            component="div"
            sx={{
              display: 'inline',
              fontWeight: '700',
              verticalAlign: 'middle',
              marginLeft:
                location?.pathname === '/login' ||
                  location?.pathname === '/first-time'
                  ? '0px'
                  : '36px',
            }}
          >
            Q.
          </Box>
          <TextField
            required
            id={name}
            size="small"
            value={value}
            type={type}
            onChange={onChange}
            name={name}
            placeholder={
              callFrom === 'formCreation' || callFrom === 'formEdit'
                ? 'Add Question'
                : 'Add label Name'
            }
            sx={{
              width: '90%',
              marginBottom: '.7rem',
              marginLeft:
                location?.pathname === '/login' ||
                  location?.pathname === '/first-time'
                  ? '0px'
                  : '20px',
            }}
            className={customClassName}
            disabled={editable === false ? true : false}
          />
        </>
      ) : (
        <TextField
          required
          id={name}
          size="small"
          value={value}
          type={type}
          onChange={onChange}
          name={name}
          sx={{
            width: width,
            height: '2.5rem',
            marginBottom: '.7rem',
            paddingLeft: paddingLeft,
            marginLeft:
              location?.pathname === '/login' ||
                location?.pathname === '/first-time'
                ? '0px'
                : '16px',
          }}
          className={customClassName}
          disabled={editable === false ? true : false}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              e.preventDefault()
            }
          }}
        />
      )}
    </>
  );
};

export default TextInput;
