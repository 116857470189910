import { useEffect, useState } from 'react';
import { REQUEST_DELETE, REQUEST_GET } from '../../utils/const';
import { requestAPI } from '../../utils/fetch';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { secondaryColor } from '../../theme';
import Confirm from '../../components/modal/Confirm';
import {
  updateSnackbar,
  updateAlert,
  getPermissionForPopup,
  filterData,
} from '../../utils/utils';
import SearchField from '../common/SearchField';
import Spinner from '../../components/layout/Spinner';

function ListUsers() {
  const [users, setUsers] = useState<any>([]);
  const rowsPerPage = 20;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(users.length / rowsPerPage)
  );
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await requestAPI({
        type: REQUEST_GET,
        url: '/api/users?roles=true',
      });
      if (response) {
        setUsers(response);
        setIsLoading(false);
        setNoOfPages(Math.ceil(response.length / rowsPerPage));
      } else {
        setIsLoading(false);
        console.error(response);
      }
    };
    fetchData();
  }, []);
  const [handleModalOpen, sethandleModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
  const [nameToDelete, setNameToDelete] = useState('');
  const handleModalClose = async (confirm: boolean) => {
    sethandleModalOpen(confirm);
    if (confirm === true) {
      const response = await requestAPI({
        type: REQUEST_DELETE,
        url: '/api/user',
        data: { user_id: idToDelete },
      });
      if (response) {
        const usersResponse = await requestAPI({
          type: REQUEST_GET,
          url: '/api/users',
        });
        if (usersResponse) {
          setUsers(usersResponse);
          sethandleModalOpen(false);
          updateSnackbar({
            state: true,
            severity: 'error',
            message: 'User removed',
          });
        } else {
          console.error('error in getting users');
        }
      } else {
        console.error('error in deleting user');
      }
    }
  };
  const [page, setPage] = useState(1);
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await requestAPI({
        type: REQUEST_GET,
        url: '/api/users?roles=true',
      });
      if (response) {
        setUsers(response);
      } else {
        console.error(response);
      }
    };
    fetchData();
  }, []);

  const editClickHandler = async (emailid: string, id: string) => {
    const hasPerm = await getPermissionForPopup('Users', 'u');
    hasPerm
      ? navigate('/editUser', { state: { email: emailid, user_id: id } })
      : updateAlert({
          message: 'You are not authorized to edit roles',
          severity: 'warning',
          state: true,
        });
  };
  let resetHandler = (textRef: any) => {
    setInputText('');
    textRef.current.value = '';
  };
  const removeClickHandler = async (
    id: string,
    first_name: string,
    last_name: string
  ) => {
    console.log('>>> remove click handler entry');
    const hasPerm = await getPermissionForPopup('Roles', 'd');
    console.log('>>> remove click handler permission check', hasPerm);
    if (hasPerm) {
      setNameToDelete(first_name + ' ' + last_name);
      sethandleModalOpen(true);
      setNameToDelete(first_name + ' ' + last_name);
      setIdToDelete(id);
    } else {
      updateAlert({
        message: 'You are not authorized to delete users',
        severity: 'warning',
        state: true,
      });
    }
  };
  interface UserInterface {
    roles: any;
    email: string;
    first_name: string;
    id: string;
    last_name: string;
    phone: string;
    pwd_expiry: string;
  }

  const createRoleHandler = () => {
    navigate('/createUser');
  };
  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const filteredData = filterData(users, inputText, 'users');
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div style={{ width: window.innerWidth - 283 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="2rem"
        >
          <Typography variant="h-1">Users</Typography>
          <Button onClick={createRoleHandler} variant="contained">
            Create
          </Button>
        </Box>
        <SearchField
          onchange={inputHandler}
          onClear={resetHandler}
          defaultValue={inputText}
        />
        <TableContainer
          sx={{
            width: '95%',
            m: '2rem',
          }}
          component={Paper}
        >
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  width="3.96%"
                  style={{ fontWeight: '700' }}
                ></TableCell>
                <TableCell width="24.59%" style={{ fontWeight: '700' }}>
                  Full Name
                </TableCell>
                <TableCell
                  width="24.59%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Email
                </TableCell>
                <TableCell
                  width="16.21%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Phone No.
                </TableCell>
                <TableCell
                  width="16.21%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Role
                </TableCell>
                <TableCell
                  width="14.41%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((val: UserInterface, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {(page - 1) * rowsPerPage + index + 1}.
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {val.first_name}&nbsp;{val.last_name}
                    </TableCell>
                    <TableCell align="left">{val.email}</TableCell>
                    <TableCell align="left">{val.phone}</TableCell>
                    <TableCell align="left">
                      {val?.roles?.map((roles: any, index: any) => (
                        <div key={roles + index}>
                          <span>{roles.name}</span>
                          <br />
                        </div>
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      <button
                        style={{
                          color: '#0451C4',
                          backgroundColor: 'white',
                          border: 'none',
                          fontWeight: '700',
                          fontFamily: 'Mulish',
                          fontSize: '16px',
                          lineHeight: '24px',
                          cursor: 'pointer',
                        }}
                        onClick={() => editClickHandler(val.email, val.id)}
                      >
                        Edit
                      </button>
                      <button
                        style={{
                          color: '#CE1515',
                          backgroundColor: 'white',
                          border: 'none',
                          fontWeight: '700',
                          fontFamily: 'Mulish',
                          fontSize: '16px',
                          lineHeight: '24px',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          removeClickHandler(
                            val.id,
                            val.first_name,
                            val.last_name
                          )
                        }
                      >
                        Remove
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mr="2rem" mt="2rem" pb="1rem">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="end"
          >
            {filteredData.length > rowsPerPage && (
              <Typography variant="body-1">
                {(page - 1) * rowsPerPage + 1}-
                {users.length < page * rowsPerPage
                  ? users.length
                  : page * rowsPerPage}{' '}
                of {users.length} items
              </Typography>
            )}
            {filteredData.length > 0 && (
              <Pagination
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: previousIcon,
                      next: nextIcon,
                    }}
                    {...item}
                  />
                )}
                page={page}
                count={noOfPages}
                shape="rounded"
                onChange={handleChangePage}
              />
            )}
            {filteredData.length === 0 && (
              <Typography variant="body-1">Zero users present</Typography>
            )}
          </Stack>
        </Box>
      </div>
      <Confirm
        open={handleModalOpen}
        handleClose={handleModalClose}
        buttonText={'Remove'}
        mainHeading={'Remove this user?'}
        secondaryHeading={
          'Are you sure you want to remove this user (' + nameToDelete + ')?'
        }
      />
    </>
  );
}
export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};
export default ListUsers;
