import {
  API_BASE_URL,
  REQUEST_DELETE,
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
} from './const';
import { token } from '../utils/StateStore';
import { isExpired, refreshTokenAndPermission } from './utils';
import { RequestApiParam } from '../types/types';

const getApiRequest = async (url: string, headers?: { [key: string]: any }) => {
  try {
    if (!url || !url.startsWith('/')) throw Error('invalid API url');
    const reqUrl = `${API_BASE_URL}${url}`;
    const resp = await fetch(reqUrl, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'include', // include, *same-origin, omit
      headers,
    });

    return resp;
  } catch (err) {
    console.error(err);
  }

  return null;
};

const postApiRequest = async (
  url: string,
  data: any, // { [key: string]: any },
  headers?: { [key: string]: any },
  multipartPresent?: boolean
) => {
  try {
    if (!url || !url.startsWith('/')) throw Error('invalid API url');

    const reqUrl = `${API_BASE_URL}${url}`;
    const resp = await fetch(reqUrl, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include', // include, *same-origin, omit
      headers,
      body: !multipartPresent ? JSON.stringify(data) : data,
    });

    return resp;
  } catch (err) {
    console.error(err);
  }

  return null;
};

const putApiRequest = async (
  url: string,
  data: any, //{ [key: string]: any },
  headers?: { [key: string]: any },
  multipartPresent?: boolean
) => {
  try {
    if (!url || !url.startsWith('/')) throw Error('invalid API url');

    const reqUrl = `${API_BASE_URL}${url}`;
    const resp = await fetch(reqUrl, {
      method: 'PUT',
      cache: 'no-cache',
      credentials: 'include', // include, *same-origin, omit
      headers,
      body: !!!multipartPresent ? JSON.stringify(data) : data,
    });

    return resp;
  } catch (err) {
    console.error(err);
  }

  return null;
};

const deleteApiRequest = async (
  url: string,
  data: any, //{ [key: string]: any },
  headers?: { [key: string]: any },
  multipartPresent?: boolean
) => {
  try {
    if (!url || !url.startsWith('/')) throw Error('invalid API url');

    const reqUrl = `${API_BASE_URL}${url}`;
    const resp = await fetch(reqUrl, {
      method: 'DELETE',
      cache: 'no-cache',
      credentials: 'include', // include, *same-origin, omit
      headers,
      body: !multipartPresent ? JSON.stringify(data) : data,
    });

    return resp;
  } catch (err) {
    console.error(err);
  }

  return null;
};

const requestAPIHandler = async (
  type: string,
  url: string,
  data: any, //{ [key: string]: any }
  headers?: { [key: string]: any },
  multipartPresent?: boolean
) => {
  // add autherization as an when required
  //common header
  const getNewHeader = () => {
    if (!multipartPresent) {
      return { ...headers, 'Content-Type': 'application/json' };
    }
    return { ...headers };
  };
  switch (type) {
    case REQUEST_GET:
      return await getApiRequest(url, headers);
    case REQUEST_POST: {
      return postApiRequest(url, data, getNewHeader(), multipartPresent);
    }
    case REQUEST_PUT: {
      return putApiRequest(url, data, getNewHeader(), multipartPresent);
    }
    case REQUEST_DELETE: {
      return deleteApiRequest(url, data, getNewHeader(), multipartPresent);
    }
    default:
      console.log('[Request API Handler ] Wrong Input');
  }
};

export const requestAPI = async ({
  type,
  url,
  data = {},
  headers = {},
  isAuthRequired = true,
  fileData = [],
  multipartPresent = undefined,
}: RequestApiParam) => {
  try {
    if (isAuthRequired) {
      if (isExpired(token?.value?.pwdExpiry!)) {
        await refreshTokenAndPermission();
      }
      if (headers) {
        headers['authorization'] = 'Bearer  ' + token?.value?.accessToken!;
      }
    }
    let formData = data;
    if (multipartPresent) {
      formData = new FormData();
      formData.append('update_data', JSON.stringify(data));
      for (let file of fileData) {
        formData.append('medias', file);
      }
    }

    let datas = await requestAPIHandler(
      type,
      url,
      formData,
      headers,
      multipartPresent
    );
    return await verifyAndGetStatusResponse(datas);
  } catch (error) {
    throw error;
  }
};

const verifyAndGetStatusResponse = async (
  responseData: Response | null | undefined
) => {
  const data = await responseData?.json();
  if (data.status === 'error') {
    throw data;
  }
  return data.data;
};
