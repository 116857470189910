import { Modal, Box, Typography, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  getOrganizationLevels,
  getOrganizationStructure,
} from '../../api/organisation';
import CancelIcon from '@mui/icons-material/Cancel';
import { getFormSublevel } from '../../api/form-sublevel';
import { NodeI } from './PreviewNode';
import Preview from './Preview';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '35%',
  bgcolor: 'white',
  height: '95.51%',
  borderRadius: '8px',
};

type Props = {
  openModal: boolean;
  handleModalClose: (modalOpen: boolean) => void;
  projectId?: string;
  organizationId?: string;
  previewData?: any;
  orgStructure?: string[];
  isDisabled?: boolean;
  uploadHandler?: () => void;
  callFrom?: string;
  formId?: string;
  createFormSublevelRelation?: (orgId: string, orgData: any) => void;
  orgInfo?: any;
};

const PreviewOrgStructure = ({
  openModal,
  handleModalClose,
  projectId,
  organizationId,
  previewData,
  orgStructure,
  isDisabled,
  uploadHandler,
  callFrom,
  formId,
  createFormSublevelRelation,
  orgInfo,
}: Props) => {
  const [orgDetails, setOrgDetails] = useState<any>({});
  const [structure, setStructure] = useState<any>({});
  const [formSublevelSingleSrc, setFormSublevelSingleSrc] = useState<any[]>([]);
  const [clearSelect, setClearSelect] = useState<'Select' | 'Clear'>('Select');

  const getLevelObj = (level: any) => {
    let tempObj: NodeI = {
      id: '',
      sublevel_id: '',
      checked: false,
      child: [],
      all_child: false,
    };
    const childNode = level.child.map((childN: any) => getLevelObj(childN));
    tempObj.child = childNode;
    tempObj.sublevel_id = level._id;
    return tempObj;
  };
  //create an object
  const createSingleSrcObj = (
    check: { org: boolean; form: boolean },
    baseObj: any
  ) => {
    let checkedCount = 0;
    let childCount = 0;
    if (check.form && baseObj) {
      //using already created data to create an object
      // we can directly use formSublevelData to create an intial object
      if (orgInfo.state !== 'partial') {
        const updatedObj = updateAllChildCheck(
          baseObj,
          orgInfo.state === 'complete' ? true : false
        );
        setFormSublevelSingleSrc(JSON.parse(JSON.stringify(updatedObj)));
      } else {
        setFormSublevelSingleSrc(JSON.parse(JSON.stringify(baseObj)));
      }
      childCount = baseObj.length;
      baseObj.forEach((lev: any) => {
        if (lev.checked) {
          checkedCount++;
        }
      });
    } else if (check.org && baseObj) {
      //create entirely new obj with the help of orgDetails
      const tempObj = baseObj?.levels.map((level: NodeI) => getLevelObj(level));

      if (orgInfo.state !== 'partial') {
        const updatedObj = updateAllChildCheck(
          tempObj,
          orgInfo.state === 'complete' ? true : false
        );
        setFormSublevelSingleSrc(JSON.parse(JSON.stringify(updatedObj)));
      } else {
        setFormSublevelSingleSrc(JSON.parse(JSON.stringify(tempObj)));
      }
      childCount = tempObj.length;
      tempObj.forEach((lev: any) => {
        if (lev.checked) {
          checkedCount++;
        }
      });
    }
    setClearSelect(checkedCount === childCount ? 'Clear' : 'Select');
  };

  const updateAllChildCheck = (node: NodeI[], updatedCheck: boolean) => {
    const updatedNode = node.map((childNode) => {
      let upadtedChilds: NodeI[] = [];
      if (childNode.child && childNode.child.length > 0) {
        upadtedChilds = updateAllChildCheck(childNode.child, updatedCheck);
      }
      return {
        ...childNode,
        checked: updatedCheck,
        all_child: updatedCheck,
        child: upadtedChilds ? upadtedChilds : [],
      };
    });
    return updatedNode;
  };

  const updateParentCheck = (currNode: NodeI) => {
    let childCheckedLen = 0;
    currNode &&
      currNode.child.forEach((node: NodeI) => {
        if (node.checked) {
          childCheckedLen++;
        }
      });
    if (childCheckedLen === currNode.child.length) {
      currNode.checked = true;
      currNode.all_child = true;
    } else {
      currNode.checked = false;
      currNode.all_child = false;
    }
    return currNode;
  };
  const updateFormSublevelObj = (
    currNode: NodeI,
    updatedObj: NodeI,
    pathArr: string[],
    currLevel: number
  ) => {
    const updatedFormSublevelObj = currNode.child.map((sublevel: NodeI) => {
      if (
        (sublevel.sublevel_id === pathArr[currLevel] &&
          pathArr.length - 1 === currLevel) ||
        pathArr.length === 1
      ) {
        //0. when reached parent then verify child checks and update its own value then and there

        //1. update check of the current
        sublevel.checked = updatedObj.checked;
        //2. update check of all the child
        const updatedChild = updateAllChildCheck(
          sublevel.child,
          updatedObj.checked
        );
        //return fully updated Obj
        // simply loop and

        sublevel.child = updatedChild;
        return sublevel;
      } else if (
        sublevel.sublevel_id === pathArr[currLevel] &&
        pathArr.length - 2 >= currLevel
      ) {
        const updatedValue = updateFormSublevelObj(
          sublevel,
          updatedObj,
          pathArr,
          currLevel + 1
        );
        const updatedCurrNode = updateParentCheck(updatedValue);
        return updatedCurrNode;
      } else {
        return sublevel;
      }
    });
    const updateCurrNode: NodeI = {
      ...currNode,
      child: updatedFormSublevelObj,
    };
    return updateCurrNode;
  };
  //update data
  const updateSingleSrcObj = (updatedObj: NodeI, pathArr: string[]) => {
    //trace to the path and do 3 things

    let currLevel = 0;
    const updatedFormSublevelObj = formSublevelSingleSrc.map(
      (sublevel: NodeI) => {
        if (sublevel.sublevel_id === pathArr[currLevel]) {
          const updatedValue = updateFormSublevelObj(
            sublevel,
            updatedObj,
            pathArr,
            currLevel + 1
          );

          const updatedCurrNode = updateParentCheck(updatedValue);
          return updatedCurrNode;
        } else return sublevel;
      }
    );
    let count = 0;
    updatedFormSublevelObj.forEach((level) => {
      if (level.checked) {
        count++;
      }
    });
    setClearSelect(
      count === updatedFormSublevelObj.length ? 'Clear' : 'Select'
    );

    setFormSublevelSingleSrc(
      JSON.parse(JSON.stringify(updatedFormSublevelObj))
    );
  };

  useEffect(() => {
    if (organizationId) {
      getOrganizationLevels(organizationId).then((res) => {
        if (res) {
          setOrgDetails(res.organization);
          if (formId) {
            getFormSublevelData(res.organization);
          }
        }
      });
    }
    if (projectId) {
      getOrganizationStructure(projectId).then((res) => {
        if (res) setStructure(res[0]);
      });
    } else if (orgStructure) {
      setStructure(orgStructure);
    }
    async function getFormSublevelData(orgDetails?: any) {
      if (formId && organizationId) {
        const formData = await getFormSublevel(
          formId,
          organizationId,
          projectId ? projectId : ''
        );
        //new attribute pass to this compoennt to check if already full string data is added or not
        if (
          typeof orgInfo.partialData === 'boolean' &&
          orgInfo.partialData &&
          formData.child.length > 0
        ) {
          createSingleSrcObj({ org: false, form: true }, formData.child);
        } else if (previewData && previewData.length > 0) {
          createSingleSrcObj({ org: false, form: true }, previewData);
        } else if (formData && formData.child.length > 0) {
          createSingleSrcObj({ org: false, form: true }, formData.child);
        } else {
          createSingleSrcObj({ org: true, form: false }, orgDetails);
        }
        //should have some pre-value for formSublevelSingleSrc in case of updation
      }
    }
  }, [formId, projectId, organizationId, orgStructure]);

  const handleSave = () => {
    //Send Parent info about the current org details
    if (createFormSublevelRelation) {
      createFormSublevelRelation(
        organizationId ? organizationId : '',
        formSublevelSingleSrc
      );
    }
    handleModalClose(false);
  };

  const handleSelectClearAll = () => {
    //if select is there then select all and set clearSelect as Clear
    //if clear then clear all and set clearSelect as Select
    const updatedObj = updateAllChildCheck(
      formSublevelSingleSrc,
      clearSelect === 'Select'
    );
    setFormSublevelSingleSrc(JSON.parse(JSON.stringify(updatedObj)));
    setClearSelect(clearSelect === 'Clear' ? 'Select' : 'Clear');
  };

  return (
    <Modal open={openModal} onClose={() => handleModalClose(false)}>
      <Box sx={style}>
        <Box overflow="auto" height="88%">
          <Box
            mt="1.5rem"
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Box>
              {orgDetails &&
                orgDetails.attributes &&
                orgDetails.attributes.length > 0 &&
                orgDetails.attributes.map((org: any, index: number) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    mb="1.5rem"
                    ml="2rem"
                    key={index}
                  >
                    <Typography variant="Caption">
                      {structure &&
                        structure.attributes &&
                        structure.attributes[index].label}
                    </Typography>
                    <Typography variant="body-1">{org.value}</Typography>
                  </Box>
                ))}
            </Box>
            <Box mr="1rem">
              <CancelIcon
                fontSize="small"
                onClick={() => handleModalClose(false)}
              />
            </Box>
          </Box>
          {formSublevelSingleSrc && (
            <Preview
              currLevel={0}
              structure={structure.Sublevel ? structure.Sublevel : []}
              previewData={orgDetails.levels}
              formSublevelData={formSublevelSingleSrc}
              updateSingleSrcObj={updateSingleSrcObj}
              currPath={[]}
              callFrom={callFrom ? callFrom : ''}
            />
          )}
        </Box>
        {uploadHandler ? (
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{
              marginBottom: '20px',
              marginLeft: 'auto',
              marginRight: '20px',
              marginTop: '20px',
            }}
          >
            <Button variant="outlined" onClick={() => handleModalClose(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: '20px' }}
              onClick={uploadHandler}
              disabled={isDisabled}
            >
              Upload
            </Button>
          </Box>
        ) : (
          <></>
        )}
        {(callFrom === 'editForm' || callFrom === 'previewForm') && (
          <Box
            display="flex"
            justifyContent="space-between"
            style={{
              marginBottom: '20px',
              marginLeft: 'auto',
              marginRight: '20px',
              marginTop: '20px',
            }}
          >
            <Button onClick={handleSelectClearAll}>{clearSelect} All</Button>
            <Button
              variant="contained"
              style={{ marginLeft: '20px' }}
              disabled={isDisabled}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PreviewOrgStructure;
