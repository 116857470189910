import { useEffect, useState } from 'react';
import { token } from '../../utils/StateStore';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import CommonLayout from './CommonLayout';
import { pagesRoutes, resourceNames } from '../../utils/const';
import Components from '../../utils/DynamicComponents';
import { fetchOrganisationData } from '../../utils/utils';
import Home from '../../pages/Home/Home';

const ProtectedApp = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [routes, setRoutes] = useState<any[]>([]);

  useEffect(() => {
    const subscribedToken = token?.subscribe(async (x) => {
      if (!x?.accessToken) {
        navigate('/login');
        return;
      }
      const permissions = x.userInfo?.permissions;
      //filtering routes to be render based on assigned permission's to the user/ render all in case of superAdmin
      const route = pagesRoutes.filter((x) => {
        return (
          x.path &&
          x.element &&
          permissions?.some(
            (p) =>
              p.resource_type === resourceNames.All.toLowerCase() ||
              (p.resource_name?.toLowerCase() ===
                x.resourcePermission?.toLowerCase() &&
                (p.access_mode.includes('c') ||
                  p.access_mode.includes('r') ||
                  p.access_mode.includes('u') ||
                  p.access_mode.includes('d')))
          )
        );
      });
      //Retreive and set Organisation Info assigned to user into rxjs store only if entity's permissions are granted.
      if (
        permissions?.some(
          (per) =>
            per.resource_type === resourceNames.All.toLowerCase() ||
            per.resource_name.toLowerCase() ===
              resourceNames.Entity.toLowerCase()
        )
      )
        await fetchOrganisationData();
      setRoutes(route);
      setIsAuthenticated(true);
    });
    return () => {
      subscribedToken?.unsubscribe();
    };
  }, []);

  return isAuthenticated ? (
    <Routes>
      <Route path="/" element={<CommonLayout />}>
        <Route path="/" element={<Home />} />
        {routes?.map((x: any, index: number) => {
          const DynamicComponet = Components[x.element];
          return (
            <Route
              key={index}
              path={x.path}
              element={<DynamicComponet {...x.props} />}
            />
          );
        })}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Routes>
  ) : null;
};
export default ProtectedApp;
