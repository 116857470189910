import { Grid, InputLabel, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ProjectSectionProjectType,
  SubSectionProjectType,
  FieldProjectType,
} from '../../types/types';
import AddDivider from '../ProjectTemplate/AddDivider';

import styles from '../Project/SectionProject.module.css';
import Subsection from './Subsection';
import { FieldTypes } from '../../utils/const';

type Props = {
  pos: number;
  key: string;
  updateFiles: (file: File[], action: string, pos?: string) => void;
  deleteSection: (confirm: number) => void;
  updateSectionsData: (pos: number, section: ProjectSectionProjectType) => void;
  sectionData: ProjectSectionProjectType;
  callFrom?: string;
  editable?: boolean;
  projectEdit?: string;
};

const Section = ({
  sectionData,
  updateSectionsData,
  deleteSection,
  key,
  updateFiles,
  pos,
  callFrom,
  editable,
  projectEdit,
}: Props) => {
  const [section, setSection] =
    useState<ProjectSectionProjectType>(sectionData);
  const [addFieldWithoutSub, setAddFieldWithoutSub] = useState<boolean>(
    sectionData.subsections &&
      sectionData.subsections.length > 0 &&
      sectionData.subsections[0].title === ''
      ? true
      : false
  );
  useEffect(() => {
    updateSectionsData(pos, section);
  }, [pos, section]);

  const updateAllSubsections = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  const updateSubSection = (subsection: SubSectionProjectType, pos: number) => {
    let updatePosArray = section.subsections;
    updatePosArray[pos] = { ...subsection };
    setSection({
      ...section,
      subsections: updatePosArray,
    });
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSection({ ...section, [event.target.name]: event.target.value });
  };

  const addNewSubSection = (addFieldWithoutSubFlag: boolean) => {
    setAddFieldWithoutSub(addFieldWithoutSubFlag);
    if (!addFieldWithoutSub) {
      const newField = {
        key: '',
        label: [{ key: '', value: '' }],
        options: [{ key: '', value: '' }],
        type: FieldTypes.TEXTBOX,
        seq: 1,
      } as FieldProjectType;

      let newSubSection = {
        title: '',
        project_id: '',
        fields: [newField],
      } as SubSectionProjectType;

      if (callFrom === 'templateCreate' || callFrom === 'templateEdit') {
        newSubSection = {
          title: '',
          project_template_id: '',
          fields: [newField],
        } as SubSectionProjectType;
      }

      setSection({
        ...section,
        subsections: [...section.subsections, newSubSection],
      });
    }
  };

  const deleteSubSection = (pos: number) => {
    let updateSubSections = section.subsections;
    updateSubSections.splice(pos, 1);
    setSection({
      ...section,
      subsections: updateSubSections,
    });
  };
  return (
    <>
      <Grid item sx={{ width: '100%', padding: '16px' }}>
        {editable ? (
          <>
            {' '}
            <InputLabel
              sx={{
                lineHeight: '16px',
                fontSize: '12px',
                fontFamily: 'Mulish',
              }}
            >
              {callFrom &&
              (callFrom === 'formCreation' || callFrom === 'formEdit')
                ? 'Category Name'
                : 'Section title'}
            </InputLabel>
            <TextField
              name="title"
              size="small"
              fullWidth
              sx={{ width: '100%', marginBottom: '20px' }}
              onChange={onChange}
              value={section.title}
              disabled={!editable}
            />
          </>
        ) : (
          <Typography
            style={{
              marginLeft: '16px',
              color: '#525862',
              marginBottom: '36px',
              fontWeight: '700',
              fontSize: '18px',
              fontStyle: 'Mulish',
            }}
          >
            {section.title}
          </Typography>
        )}
        {callFrom !== 'formCreation' &&
          callFrom !== 'formEdit' &&
          addFieldWithoutSub &&
          editable && (
            <>
              <label className={styles.addLabel}>ADD SUBSECTION</label>
              <AddDivider
                clickHandler={() => addNewSubSection(false)}
              ></AddDivider>
            </>
          )}
        <form onSubmit={updateAllSubsections}>
          {section.subsections.length > 0 &&
            section.subsections.map(
              (subsection: SubSectionProjectType, i: any) => (
                <Subsection
                  subSectionData={subsection}
                  updateSubSection={updateSubSection}
                  deleteSubSection={deleteSubSection}
                  key={'attribute' + i}
                  pos={i}
                  updateFiles={updateFiles}
                  withoutTitle={addFieldWithoutSub}
                  callFrom={callFrom}
                  editable={editable}
                  projectEdit={projectEdit}
                />
              )
            )}
        </form>
        {callFrom !== 'formCreation' &&
          callFrom !== 'formEdit' &&
          !addFieldWithoutSub &&
          editable && (
            <>
              <label className={styles.addLabel}>ADD SUBSECTION</label>
              <AddDivider
                clickHandler={() => addNewSubSection(false)}
              ></AddDivider>
            </>
          )}
      </Grid>
      {section.subsections.length <= 0 && !addFieldWithoutSub && editable && (
        <Grid item className={styles.addFieldsSection}>
          <button
            onClick={() => addNewSubSection(true)}
            className={
              callFrom && callFrom === 'formCreation'
                ? styles.addQuesBtn
                : styles.addFieldsBtn
            }
          >
            <span
              className={
                callFrom && callFrom === 'formCreation'
                  ? styles.addQuesBtnText
                  : styles.addFieldsBtnText
              }
            >
              {callFrom && callFrom === 'formCreation'
                ? 'Add Questions'
                : 'Add fields'}
            </span>
          </button>
        </Grid>
      )}
    </>
  );
};

export default Section;
