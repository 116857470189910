import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PaginationItem from '@mui/material/PaginationItem';
import Typography from '@mui/material/Typography';
import { secondaryColor } from '../../theme';
import { Button, Checkbox } from '@mui/material';
import {
  deleteOrganizationData,
  deleteOrganizationStructure,
  getOrganizationList,
  getOrganizationStructure,
} from '../../api/organisation';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateOrgStructure from '../../components/modal/CreateOrgStructure';
import ViewOrgStructure from '../../components/modal/ViewOrgStructure';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import PreviewOrgStructure from '../../components/modal/PreviewOrgStructure';
import CreateOrganization from '../../components/modal/CreateOrganization';
import Confirm from '../../components/modal/Confirm';
import { updateSnackbar } from '../../utils/utils';
import {
  createFormSublevel,
  getFormSublevels,
  updateFormSublevel,
} from '../../api/form-sublevel';

type Props = {};

const ProjectList = (props: Props) => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const { projectId, projectName, callFrom, formId } = state;
  const [organisations, setorganisations] = useState<any>([]);
  const [orgStructure, setOrgStructure] = useState<any>({});
  const [createOrgModalView, setCreateOrgModalView] = useState(false);
  const [viewOrgStructModal, setViewOrgStructModal] = useState(false);
  const [delOrgStructModal, setDelOrgStructModal] = useState(false);
  const [delOrgDataModal, setDelOrgDataModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [assignForm, setAssignForm] = useState(false);
  const [previewOrgId, setPreviewOrgId] = useState('');
  const [orgToDelId, setOrgToDelId] = useState('');
  const [addOrgModal, setAddOrgModal] = useState(false);
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(organisations.length / rowsPerPage)
  );
  const [formOrgRelData, setFormOrgRelData] = useState<FormOrgRelI>({
    form_id: '',
    project_id: '',
    organizations: [],
  });
  const [formSublevels, setFormSublevels] = useState<
    {
      organisationId: string;
      child: any;
    }[]
  >([]);

  // Create new array for checked state
  //array of object 1. org Id  2. its state
  // state update on updation of whole parent
  const [orgCheck, setOrgCheck] = useState<
    {
      organisationId: string;
      check: string;
      allSublevel: boolean;
    }[]
  >([]);

  const [updatedIds, setUpdatedIds] = useState<string[]>([]);
  let filteredData: any;
  filteredData = filteredData ? filteredData : organisations;
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);

  const handleCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    //update
    //1. orgCheck
    const updatedCheck = orgCheck.map((org) => {
      return org.organisationId === id
        ? {
            ...org,
            check: event.target.checked ? 'complete' : 'none',
            allSublevel: event.target.checked,
          }
        : org;
    });
    setOrgCheck(updatedCheck);
    //update formOrgRelData with sublevel_all as checked
    const updatedFormSub = formOrgRelData.organizations.map((org) => {
      return org.organization_id === id
        ? { ...org, all_sublevel: event.target.checked }
        : org;
    });
    setFormOrgRelData({ ...formOrgRelData, organizations: updatedFormSub });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        callFrom === 'editForm' ||
        (callFrom === 'previewForm' &&
          organisations &&
          organisations.length > 0)
      ) {
        if (formId && formId !== '') {
          var orgIds = organisations.reduce(function (
            accumulator: any,
            currentValue: any
          ) {
            accumulator.push(currentValue.id);
            return accumulator;
          },
          []);
          const data = await getFormSublevels({
            form_id: formId,
            organization_ids: orgIds.join('&organization_ids[]='),
            projectId: projectId,
          });
          if (data) {
            if (data.sublevels.length > 0) {
              //get parent item and set it
              // and update its check on change
              setFormSublevels(data.sublevels);
              const updatedformOrgDetails = data.sublevels.map((org: any) => {
                let stateCount: any = 0;
                if (org.child.length > 0) {
                  org.child.map((child: any) => {
                    if (child.checked) {
                      stateCount++;
                    }
                  });
                }
                return {
                  organization_id: org.organisationId,
                  child: org.child,
                  state:
                    stateCount === 0
                      ? 'none'
                      : stateCount === org.child.length
                      ? 'complete'
                      : 'partial',
                  partial_data: true,
                  all_sublevel: false,
                };
              });
              setFormOrgRelData({
                ...formOrgRelData,
                form_id: formId,
                project_id: projectId,
                organizations: updatedformOrgDetails,
              });
            }
          }
        }
      }
    };

    fetchData();
  }, [organisations]);

  const getOrgList = async () => {
    const data = await getOrganizationList(projectId);
    if (data) {
      let reqData: any = [];
      data.map((org: any) => {
        if (org.project_id && org.project_id === projectId) {
          reqData.push(org);
        }
        return reqData;
      });
      setorganisations(reqData);
      setNoOfPages(Math.ceil(reqData.length / rowsPerPage));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (projectId && projectId !== '') {
        getOrgList();
      }
    };

    fetchData();
    setFormOrgRelData({
      form_id: formId,
      project_id: projectId,
      organizations: [],
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await getOrganizationStructure(projectId);
        if (data) {
          setOrgStructure(data[0]);
        }
      } catch (err: any) {
        console.error('Inside error', err);
      }
    })();
  }, []);

  const structModalHandler = () => {
    setCreateOrgModalView(true);
  };

  const viewStructModalHandler = () => {
    setViewOrgStructModal(true);
  };

  const onboardUserHandler = () => {
    navigate('/onboard-user', { state: { projectId } });
  };

  const attachUserHandler = (name: any, organisationId: string) => {
    navigate('/attach-user', {
      state: { orgName: name, organisationId },
    });
  };

  const addOrgModalHandler = () => {
    setAddOrgModal(true);
  };

  const backClickHandler = () => {
    navigate(-1);
  };
  const getSeparateJson = (formOrgRelData: FormOrgRelI) => {
    let newOrgList: string[] = [];
    formSublevels.forEach((formSublevel) => {
      if (formSublevel.child.length === 0) {
        newOrgList.push(formSublevel.organisationId);
      }
    });
    let newList: {
      organization_id: string;
      child: any[];
      all_sublevel?: boolean;
    }[] = [];
    let existingList: {
      organization_id: string;
      child: any[];
      all_sublevel?: boolean;
    }[] = [];

    //Remove unncessary org data from the list
    formOrgRelData.organizations.forEach((org) => {
      if (org.child.length > 0 || org.all_sublevel) {
        if (newOrgList.includes(org.organization_id)) {
          newList.push({
            organization_id: org.organization_id,
            child: org.child,
            all_sublevel: org.all_sublevel,
          });
        } else if (updatedIds.includes(org.organization_id)) {
          existingList.push({
            organization_id: org.organization_id,
            child: org.child,
            all_sublevel: org.all_sublevel,
          });
        }
      }
    });

    return {
      newOrg: {
        form_id: formOrgRelData.form_id,
        project_id: formOrgRelData.project_id,
        organizations: newList,
      },
      updateOrg: {
        form_id: formOrgRelData.form_id,
        project_id: formOrgRelData.project_id,
        organizations: existingList,
      },
    };
  };
  const assignFormHandler = async () => {
    //Create two Json
    //1. For existing or already created formSublevel
    //2. For fresh formSublevel
    const { newOrg, updateOrg } = getSeparateJson(formOrgRelData);
    let formSublevelCreated = {};
    let formSublevelUpdated = {};
    if (newOrg.organizations.length > 0) {
      formSublevelCreated = await createFormSublevel(newOrg);
    }
    if (updateOrg.organizations.length > 0) {
      formSublevelUpdated = await updateFormSublevel(updateOrg);
    }
    if (formSublevelUpdated || formSublevelCreated) {
      updateSnackbar({
        state: true,
        severity: 'success',
        message: 'Form assigned successfully',
      });
      navigate(-1);
    }
  };

  const delOrgStructModalClose = async (confirm: boolean) => {
    setDelOrgStructModal(confirm);
    if (confirm === true) {
      let structData = {
        project_id: projectId,
        structure_id: orgStructure.id,
      };
      let orgStructDeleted = await deleteOrganizationStructure(structData);
      if (
        orgStructDeleted &&
        orgStructDeleted.message &&
        orgStructDeleted.message === 'Structure deleted successfully!'
      ) {
        setDelOrgStructModal(false);
        setViewOrgStructModal(false);
        setOrgStructure({});
      }
    }
  };

  const delOrgDataModalOpen = async (orgId: any) => {
    setOrgToDelId(orgId);
    setDelOrgDataModal(true);
  };

  const delOrgDataModalClose = async (confirm: boolean) => {
    setDelOrgDataModal(confirm);
    if (confirm === true) {
      let orgData = {
        organization_id: orgToDelId,
      };
      let orgDataDeleted = await deleteOrganizationData(orgData);
      if (
        orgDataDeleted &&
        orgDataDeleted.message &&
        orgDataDeleted.message === 'Organization deleted successfully!'
      ) {
        setDelOrgDataModal(false);
        const afterDeleting = organisations.filter(
          (org: any) => org.id !== orgToDelId
        );
        setorganisations(afterDeleting);
        updateSnackbar({
          state: true,
          severity: 'success',
          message: 'Organisation removed successfully',
        });
      }
    }
  };

  const createFormSublevelRelation = (orgId: string, orgData: any) => {
    updatedIds.push(orgId);
    setUpdatedIds([...updatedIds, orgId]);
    const orgExist = formOrgRelData.organizations.filter(
      (org) => org.organization_id === orgId
    );
    let count = 0;
    orgData.forEach((level: any) => {
      if (level.checked) {
        count++;
      }
    });
    const allSublevel = count === orgData.length;
    if (orgExist.length === 0) {
      setFormOrgRelData({
        ...formOrgRelData,
        organizations: [
          ...formOrgRelData.organizations,
          {
            organization_id: orgId,
            child: orgData,
            partial_data: false,
            all_sublevel: allSublevel,
          },
        ],
      });
    } else if (orgExist.length > 0) {
      const updatedOrgFormList = formOrgRelData.organizations.map((org) => {
        if (org.organization_id === orgId) {
          return {
            organization_id: orgId,
            child: orgData,
            partial_data: false,
            all_sublevel: allSublevel,
          };
        } else return org;
      });
      setFormOrgRelData({
        ...formOrgRelData,
        organizations: updatedOrgFormList,
      });
    }

    console.log('data' + JSON.stringify(formOrgRelData));
  };

  const getPreviewData = (orgId: string) => {
    const previewData = formOrgRelData.organizations.filter(
      (org) => org.organization_id === orgId
    );
    return previewData[0] && previewData[0].child ? previewData[0].child : [];
  };
  const getOrgInfo = (orgId: string) => {
    //TODO future try to better this code and use one common state
    const previewData = orgCheck.filter((org) => org.organisationId === orgId);
    const previewGlobal = formOrgRelData.organizations.filter(
      (org) => org.organization_id === orgId
    );
    return {
      partialData: previewGlobal[0] ? previewGlobal[0].partial_data : false,
      state: previewData[0] ? previewData[0].check : 'none',
    };
  };
  const getCheckedState = (orgId: string, type: string) => {
    const previewData = orgCheck.filter((org) => org.organisationId === orgId);
    return previewData[0] && previewData[0].check
      ? previewData[0].check === type
      : false;
  };

  useEffect(() => {
    const updatedCheck = formOrgRelData.organizations.map((org) => {
      let count = 0;
      org.child.forEach((element: any) => {
        if (element.checked) {
          count++;
        }
      });
      return {
        organisationId: org.organization_id,
        check: org.all_sublevel
          ? 'complete'
          : count === 0
          ? 'none'
          : count === org.child.length
          ? 'complete'
          : 'partial',
        allSublevel: org.all_sublevel,
      };
    });
    setOrgCheck(updatedCheck);
  }, [formOrgRelData]);

  const handleGlobalCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    //update every item with orgCheck
    const updatedOrgChecks = orgCheck.map((org) => {
      return {
        ...org,
        check: event.target.checked ? 'complete' : 'none',
        allSublevel: event.target.checked,
      };
    });
    setOrgCheck(updatedOrgChecks);
    //update formOrgRelData with sublevel_all as checked
    const updatedFormSub = formOrgRelData.organizations.map((org) => {
      return { ...org, all_sublevel: event.target.checked };
    });
    setFormOrgRelData({ ...formOrgRelData, organizations: updatedFormSub });
    //handle sublevel_all in existing cases as well
  };
  const getGlobalCheck = (type: string) => {
    let count = 0;
    orgCheck.forEach((org) => {
      if (org.check !== 'none') {
        count++;
      }
    });
    return type === 'complete'
      ? count === orgCheck.length
      : count > 0 && count < orgCheck.length;
  };

  return (
    <>
      {orgStructure && Object.keys(orgStructure).length === 0 ? (
        <Box>
          <Box
            sx={{ m: ['2.25rem 2rem 2rem 2rem'] }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                onClick={backClickHandler}
                style={{ paddingRight: '8px', cursor: 'pointer' }}
                src={ArrowLeft}
                alt="left arrow"
              />
              <Box>
                <Typography variant="h-1">Organisation List</Typography>
                <Typography display={'block'} variant="Caption">
                  {projectName}
                </Typography>
              </Box>
            </div>
          </Box>
          <Box sx={{ padding: '5%' }}>
            <Box sx={{ marginLeft: '40%' }}>
              <svg
                width="124"
                height="124"
                viewBox="0 0 124 124"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="38"
                  y="4"
                  width="72"
                  height="116"
                  rx="5"
                  fill="#F0F5FB"
                />
                <rect
                  x="46"
                  y="12"
                  width="26"
                  height="13"
                  rx="1"
                  fill="#DCE7F7"
                />
                <rect
                  x="46"
                  y="28"
                  width="26"
                  height="13"
                  rx="1"
                  fill="#DCE7F7"
                />
                <rect
                  x="46"
                  y="60"
                  width="26"
                  height="13"
                  rx="1"
                  fill="#DCE7F7"
                />
                <rect
                  x="46"
                  y="44"
                  width="26"
                  height="13"
                  rx="1"
                  fill="#DCE7F7"
                />
                <rect
                  x="76"
                  y="12"
                  width="26"
                  height="13"
                  rx="1"
                  fill="#DCE7F7"
                />
                <rect
                  x="76"
                  y="28"
                  width="26"
                  height="13"
                  rx="1"
                  fill="#DCE7F7"
                />
                <rect
                  x="76"
                  y="60"
                  width="26"
                  height="13"
                  rx="1"
                  fill="#DCE7F7"
                />
                <rect
                  x="76"
                  y="76"
                  width="26"
                  height="13"
                  rx="1"
                  fill="#DCE7F7"
                />
                <rect
                  x="76"
                  y="44"
                  width="26"
                  height="13"
                  rx="1"
                  fill="#DCE7F7"
                />
                <rect
                  x="14"
                  y="38"
                  width="58"
                  height="82"
                  rx="5"
                  fill="#2C6DCD"
                />
                <rect
                  x="20"
                  y="44"
                  width="20"
                  height="9"
                  rx="1"
                  fill="#6897DC"
                />
                <rect
                  x="20"
                  y="57"
                  width="20"
                  height="9"
                  rx="1"
                  fill="#6897DC"
                />
                <rect
                  x="20"
                  y="70"
                  width="20"
                  height="9"
                  rx="1"
                  fill="#6897DC"
                />
                <rect
                  x="20"
                  y="83"
                  width="20"
                  height="9"
                  rx="1"
                  fill="#6897DC"
                />
                <rect
                  x="46"
                  y="44"
                  width="20"
                  height="9"
                  rx="1"
                  fill="#6897DC"
                />
                <rect
                  x="46"
                  y="57"
                  width="20"
                  height="9"
                  rx="1"
                  fill="#6897DC"
                />
                <rect
                  x="46"
                  y="70"
                  width="20"
                  height="9"
                  rx="1"
                  fill="#6897DC"
                />
                <rect
                  x="46"
                  y="83"
                  width="20"
                  height="9"
                  rx="1"
                  fill="#6897DC"
                />
              </svg>
            </Box>
            <div style={{ marginLeft: '29%', padding: '15px' }}>
              You haven't created any organisation structure
            </div>
            <Button
              variant="contained"
              onClick={structModalHandler}
              sx={{ marginLeft: '38%' }}
            >
              Create Structure
            </Button>
          </Box>
          {createOrgModalView && (
            <CreateOrgStructure
              open={createOrgModalView}
              handleClose={(modalOpen: boolean) => {
                setCreateOrgModalView(modalOpen);
              }}
              projectId={projectId}
              isEdit={false}
            />
          )}
        </Box>
      ) : (
        <Box>
          <Box
            sx={{ m: ['2.25rem 2rem 2rem 2rem'] }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                onClick={backClickHandler}
                style={{ paddingRight: '8px', cursor: 'pointer' }}
                src={ArrowLeft}
                alt="left arrow"
              />
              {callFrom === 'editForm' || callFrom === 'previewForm' ? (
                <Box>
                  <Typography variant="h-1">Assign Form</Typography>
                  <Typography display={'block'} variant="Caption">
                    {projectName}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography variant="h-1">Organisation List</Typography>
                  <Typography display={'block'} variant="Caption">
                    {projectName}
                  </Typography>
                </Box>
              )}
            </div>
            {callFrom === 'editForm' || callFrom === 'previewForm' ? (
              <Button
                variant="contained"
                sx={{ marginRight: '30px' }}
                onClick={assignFormHandler}
              >
                Send
              </Button>
            ) : (
              <div>
                <Button
                  variant="contained"
                  sx={{ marginRight: '10px' }}
                  onClick={onboardUserHandler}
                >
                  Onboard User
                </Button>
                <Button
                  variant="contained"
                  sx={{ marginRight: '10px' }}
                  onClick={viewStructModalHandler}
                >
                  View Structure
                </Button>
                <Button variant="contained" onClick={addOrgModalHandler}>
                  Add Organisation
                </Button>
              </div>
            )}
          </Box>
          <Box>
            <TableContainer sx={{ width: '95%', m: '2rem' }}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {callFrom === 'editForm' || callFrom === 'previewForm' ? (
                      <TableCell width="3.96%" style={{ fontWeight: '700' }}>
                        <Checkbox
                          checked={getGlobalCheck('complete')}
                          indeterminate={getGlobalCheck('partial')}
                          onChange={(e) => handleGlobalCheck(e)}
                        />
                      </TableCell>
                    ) : null}
                    {callFrom === 'editForm' || callFrom === 'previewForm' ? (
                      <TableCell width="8.96%" style={{ fontWeight: '700' }}>
                        Sr. No.
                      </TableCell>
                    ) : (
                      <TableCell
                        width="3.96%"
                        style={{ fontWeight: '700' }}
                      ></TableCell>
                    )}

                    <TableCell
                      // width="27.2%"
                      style={{ fontWeight: '700' }}
                      align="left"
                    >
                      Organisation Name
                    </TableCell>
                    {callFrom === 'editForm' || callFrom === 'previewForm'
                      ? null
                      : orgStructure &&
                        orgStructure.attributes &&
                        orgStructure.attributes.map((attr: any, index: any) => {
                          if (attr && attr.label && attr.label !== '') {
                            return (
                              <TableCell
                                style={{ fontWeight: '700' }}
                                align="left"
                                key={index.toString() + '' + attr.label}
                              >
                                {attr.label ? attr.label : attr}
                              </TableCell>
                            );
                          }

                          if (
                            typeof attr === 'string' &&
                            attr !== '' &&
                            callFrom !== 'editForm'
                          ) {
                            return (
                              <TableCell
                                style={{ fontWeight: '700' }}
                                align="left"
                              >
                                {attr}
                              </TableCell>
                            );
                          }
                        })}
                    <TableCell
                      width="27.2%"
                      style={{ fontWeight: '700' }}
                      align="left"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {filteredData.length !== 0 ? (
                  <TableBody>
                    {filteredData
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map((val: any, index: number) => (
                        <TableRow key={index.toString() + '' + val.name}>
                          {callFrom === 'editForm' ||
                          callFrom === 'previewForm' ? (
                            <TableCell component="th" scope="row">
                              <Checkbox
                                indeterminate={getCheckedState(
                                  val.id,
                                  'partial'
                                )}
                                onChange={(e) => handleCheckChange(e, val.id)}
                                checked={getCheckedState(val.id, 'complete')}
                              />
                            </TableCell>
                          ) : null}
                          <TableCell component="th" scope="row">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {val.name}
                          </TableCell>
                          {callFrom === 'editForm' || callFrom === 'previewForm'
                            ? null
                            : val.attributes.map((attr: any, index: any) => (
                                <TableCell
                                  sx={{
                                    padding: '18px',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                  }}
                                  align="left"
                                  key={index.toString() + '' + attr.label}
                                >
                                  {attr.value}
                                </TableCell>
                              ))}

                          {callFrom === 'editForm' ||
                          callFrom === 'previewForm' ? (
                            <TableCell align="left" sx={{ padding: 0 }}>
                              <Box display="flex">
                                <Button
                                  sx={{ padding: 0, fontWeight: 700 }}
                                  onClick={() => {
                                    setPreviewOrgId(val.id);
                                    setAssignForm(true);
                                  }}
                                >
                                  Assign
                                </Button>
                              </Box>
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              <Box display="flex">
                                <Button
                                  sx={{ padding: 0, fontWeight: 700 }}
                                  onClick={() => {
                                    attachUserHandler(val.name, val.id);
                                  }}
                                >
                                  Attach User
                                </Button>
                                <Button
                                  sx={{ padding: 0, fontWeight: 700 }}
                                  onClick={() => {
                                    setPreviewOrgId(val.id);
                                    setPreviewModal(true);
                                  }}
                                >
                                  Preview
                                </Button>
                                <Button
                                  sx={{
                                    padding: 0,
                                    fontWeight: 700,
                                    color: 'red',
                                  }}
                                  onClick={() => {
                                    delOrgDataModalOpen(val.id);
                                  }}
                                >
                                  Remove
                                </Button>
                                <Button
                                  sx={{ padding: 0, fontWeight: 700 }}
                                  onClick={() => {
                                    setPreviewOrgId(val.id);
                                    setAddOrgModal(true);
                                  }}
                                >
                                  Edit
                                </Button>
                              </Box>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  <TableRow sx={{ backgroundColor: '#FAFAFA' }}>
                    <TableCell
                      align="center"
                      colSpan={8}
                      sx={{ color: '#525862' }}
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </Box>
          <Box mr="2rem" mt="2rem" pb="1rem">
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="end"
            >
              {filteredData.length > rowsPerPage && (
                <Typography variant="body-1">
                  {(page - 1) * rowsPerPage + 1}-
                  {organisations.length < page * rowsPerPage
                    ? organisations.length
                    : page * rowsPerPage}{' '}
                  of {organisations.length} items
                </Typography>
              )}
              {filteredData.length > 0 && (
                <Pagination
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        previous: previousIcon,
                        next: nextIcon,
                      }}
                      {...item}
                    />
                  )}
                  page={page}
                  count={noOfPages}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              )}
            </Stack>
          </Box>

          {viewOrgStructModal && (
            <ViewOrgStructure
              open={viewOrgStructModal}
              handleClose={(modalOpen: boolean) => {
                setViewOrgStructModal(modalOpen);
                setDelOrgStructModal(modalOpen);
              }}
              handleEdit={(modalOpen: boolean) => {
                setViewOrgStructModal(modalOpen);
                setCreateOrgModalView(!modalOpen);
              }}
              handleDelete={(modalOpen: boolean) => {
                setDelOrgStructModal(modalOpen);
              }}
              projectId={projectId}
              jsonData={orgStructure}
            />
          )}
          {delOrgStructModal && (
            <Confirm
              open={delOrgStructModal}
              handleClose={delOrgStructModalClose}
              buttonText={'Delete'}
              mainHeading={'Delete structure'}
              secondaryHeading={`All your structure data will be deleted.`}
            />
          )}
          {assignForm && (
            <PreviewOrgStructure
              openModal={assignForm}
              handleModalClose={(modalOpen: boolean) => {
                setAssignForm(modalOpen);
              }}
              organizationId={previewOrgId}
              projectId={projectId}
              callFrom={callFrom}
              formId={formId}
              createFormSublevelRelation={createFormSublevelRelation}
              previewData={getPreviewData(previewOrgId)}
              orgInfo={getOrgInfo(previewOrgId)}
            />
          )}
          {delOrgDataModal && (
            <Confirm
              open={delOrgDataModal}
              handleClose={delOrgDataModalClose}
              buttonText={'Delete'}
              mainHeading={'Delete Organisation'}
              secondaryHeading={`Are you sure you want to delete Organisation?`}
            />
          )}
          {createOrgModalView && (
            <CreateOrgStructure
              open={createOrgModalView}
              handleClose={(modalOpen: boolean) => {
                setCreateOrgModalView(modalOpen);
              }}
              projectId={projectId}
              jsonData={orgStructure}
              isEdit={true}
            />
          )}
          {previewModal && (
            <PreviewOrgStructure
              openModal={previewModal}
              handleModalClose={(modalOpen: boolean) => {
                setPreviewModal(modalOpen);
              }}
              organizationId={previewOrgId}
              projectId={projectId}
            />
          )}
          {addOrgModal && (
            <CreateOrganization
              open={addOrgModal}
              handleClose={(modalOpen: boolean) => {
                setAddOrgModal(modalOpen);
                setTimeout(() => {
                  getOrgList();
                }, 1000);
                setPreviewOrgId('');
              }}
              projectId={projectId}
              orgStructure={orgStructure}
              organizationId={previewOrgId}
              projectName={projectName}
            />
          )}
        </Box>
      )}
    </>
  );
};

export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};
export default ProjectList;

type FormOrgRelI = {
  form_id: string;
  project_id: string;
  organizations: any[];
};
