import { REQUEST_GET, REQUEST_POST, REQUEST_PUT } from '../utils/const';
import { requestAPI } from '../utils/fetch';
import { updateAlert } from '../utils/utils';

export const createFormSublevel = async (formData: any) => {
  try {
    const formSublevelCreated = await requestAPI({
      type: REQUEST_POST,
      url: '/api/form-sublevel',
      data: formData,
    });
    if (!formSublevelCreated) {
      return false;
    }
    return formSublevelCreated;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const updateFormSublevel = async (formData: any) => {
  try {
    const formResponse = await requestAPI({
      type: REQUEST_PUT,
      url: `/api/form-sublevel`,
      data: formData,
    });
    if (!formResponse) {
      return false;
    }
    return formResponse;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const getFormSublevels = async (formBody: any) => {
  try {
    const formResponse = await requestAPI({
      type: REQUEST_GET,
      url: `/api/form-sublevels?form_id=${formBody.form_id}&organization_ids[]=${formBody.organization_ids}&project_id=${formBody.projectId}`,
    });
    if (!formResponse) {
      return false;
    }
    return formResponse;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const getFormSublevel = async (
  formId: string,
  organizationId: string,
  projectId: string
) => {
  try {
    const formSublevelCreated = await requestAPI({
      type: REQUEST_GET,
      url: `/api/form-sublevel?form_id=${formId}&organization_id=${organizationId}&project_id=${projectId}`,
    });
    if (!formSublevelCreated) {
      return false;
    }
    return formSublevelCreated;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};
