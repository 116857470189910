import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useState, useRef, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button, InputLabel, TextField } from '@mui/material';
import CloseIcon from '../../assets/png/CrossIcon.png';
import FileIcon from '../../assets/png/CSVFileIcon.png';
import styles from './AddOrganizationStructue.module.css';
import * as XLSX from 'xlsx';
import {
  createOrganization,
  getOrganizationLevels,
  updateOrganization,
} from '../../api/organisation';
import PreviewOrgStructure from './PreviewOrgStructure';
import { updateSnackbar } from '../../utils/utils';
import { getProjectAlias } from '../../pages/Project/CreateProject';

type Props = {
  open: boolean;
  handleClose: (confirm: boolean) => void;
  projectId: string;
  orgStructure: any;
  organizationId?: string;
  projectName: string;
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '35%',
  bgcolor: 'white',
  height: '95.51%',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};
const CreateOrganization = ({
  open,
  handleClose,
  orgStructure,
  projectId,
  organizationId = '',
  projectName,
}: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedItem, setSelectedItem] = useState('');
  const [fileName, setFileName] = useState<any>();
  const [attributes, setAttributes] = useState<any>([]); // {key:"attributename",value:"atttribute value"}
  const [subLevels, setSubLevels] = useState<any>([]);
  const [organizationName, setOrganizationName] = useState('');
  const [previewModal, setPreviewModal] = useState(false);
  const [existingOrganization, setExistingOrganization] = useState<any>({});

  const getProjectName = () => {
    return projectName.substring(0, 15).replace(' ', '_');
  };

  const makeSubLevelArray = (pos: number, value: any, allSubLevels: any) => {
    if (pos === 0) {
      return allSubLevels && allSubLevels.length > 0
        ? [...allSubLevels, { value }]
        : [{ value }];
    } else {
      let [subLevelValue] = allSubLevels || [];
      if (subLevelValue && subLevelValue.value) {
        if (subLevelValue.child && subLevelValue.child.length > 1) {
          let firstChilds = [...subLevelValue.child] || [];
          firstChilds.pop();
          let x: any = makeSubLevelArray((pos = pos - 1), value, [
            subLevelValue.child[subLevelValue.child.length - 1],
          ]);
          return [
            { value: subLevelValue.value, child: [...firstChilds, ...x] },
          ];
        } else {
          let x: any = makeSubLevelArray(
            (pos = pos - 1),
            value,
            subLevelValue?.child
          );
          return [{ value: subLevelValue?.value, child: x }];
        }
      }
    }
  };

  const createSubLevels = (data: any) => {
    let allSubLevels = [];
    let { Sublevel = [] } = orgStructure || {};
    let level = -1;
    for (let one of data) {
      if (Sublevel && Sublevel[0] && one[Sublevel[0]]) {
        level += 1;
      }
      for (let i = 0; i < Sublevel.length; i++) {
        if (Sublevel[i] && one[Sublevel[i]]) {
          let oneSubLevel = makeSubLevelArray(
            i,
            one[Sublevel[i]],
            allSubLevels[level]
          );
          allSubLevels[level] = oneSubLevel;
        }
      }
    }
    if (allSubLevels && allSubLevels.length > 0) {
      allSubLevels = allSubLevels.map((el) => el && el[0]);
    }
    setSubLevels(allSubLevels);
  };

  useEffect(() => {
    let attributes = [];
    //use updated structure
    if (orgStructure && orgStructure.attributes) {
      for (let one of orgStructure.attributes) {
        attributes.push({ key: one.key, value: '' });
      }
    }
    setAttributes(attributes);

    if (organizationId) {
      getOrganizationLevels(organizationId).then((res) => {
        if (res) {
          setExistingOrganization(JSON.parse(JSON.stringify(res.organization)));
          setOrganizationName(res.organization.name);
          setSubLevels(JSON.parse(JSON.stringify(res.organization.levels)));
          setAttributes(
            JSON.parse(JSON.stringify(res.organization.attributes))
          );
        }
      });
    }
  }, []);
  const handleClick = () => {
    // open file input box on click of other element
    fileRef!.current!.click();
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    const data = await file?.arrayBuffer();
    const excelFile = XLSX.read(data);
    const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]];
    const excelHeaders = XLSX.utils.sheet_to_json(excelSheet, { header: 1 });
    const excelJson = XLSX.utils.sheet_to_json(excelSheet);
    if (
      excelHeaders &&
      excelHeaders[0] &&
      JSON.stringify(orgStructure.Sublevel) === JSON.stringify(excelHeaders[0])
    ) {
      let arr = [];
      for (let one of Object.keys(excelSheet)) {
        if (
          one &&
          one[0] &&
          one[0].charCodeAt(0) - 65 >= orgStructure.Sublevel.length
        )
          arr.push(one);
      }
      if (arr.length > 0)
        updateSnackbar({
          state: true,
          severity: 'warning',
          message: `Un-necessary data is present in uploaded file at column's ${arr.join(
            ', '
          )}.`,
        });
      setFileName(file?.name);
      createSubLevels(excelJson);
    } else {
      updateSnackbar({
        state: true,
        severity: 'error',
        message:
          "Uploaded file doesn't contain valid data! Please upload valid file",
      });
    }
  };
  const handleDownloadTemplate = () => {
    let data = orgStructure?.Sublevel || [];
    var csv = '';
    for (let one of data) {
      csv += one + ',';
    }
    csv += '\n';
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${getProjectName()}_OrganizationTemplate.csv`;
    hiddenElement.click();
  };
  const removeFile = () => {
    setFileName('');
  };

  //[TODO] add field for organization name
  const saveChangesHandler = () => {
    createOrganization({
      organizationData: {
        org: {
          name: organizationName,
          alias: getProjectAlias(organizationName, 'org'),
        },
        project_id: projectId,
        attributes: attributes,
        subLevels: subLevels,
      },
    });
    updateSnackbar({
      state: true,
      severity: 'success',
      message: 'File uploaded',
    });
    setAttributes([]);
    setSubLevels([]);
    handleClose(false);
  };
  const uploadHandler = () => {
    saveChangesHandler();
    setPreviewModal(false);
  };
  const handleOrgUpdate = async () => {
    updateOrganization({
      organizationData: {
        name: organizationName,
        organization_id: organizationId,
        attributes: attributes,
        sub_levels: subLevels,
        sublevel_updated:
          JSON.stringify(existingOrganization.levels) !==
          JSON.stringify(subLevels),
      },
    });
    updateSnackbar({
      state: true,
      severity: 'success',
      message: 'Organization Data updated',
    });
    setAttributes([]);
    setSubLevels([]);
    handleClose(false);
  };
  const handleSelectedItem = (item?: string) => {
    setSelectedItem(item || '');
  };
  const handleAttributes = (e: any, pos: number) => {
    let allAttributes = attributes || [];
    if (allAttributes[pos] && allAttributes[pos].key)
      allAttributes[pos].value = e.target.value;

    setAttributes([...allAttributes]);
  };
  // const handleSubLevels = () => {
  //     setSubLevels([])
  // }
  console.log(
    '?>?>> orgStructure ',
    orgStructure,
    attributes,
    'subLevels ',
    subLevels
  );
  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Box className={styles.attributesWrapper}>
          <Typography variant="h-1">Add Organization</Typography>
        </Box>

        <Box className={styles.createOrgBody}>
          <Box>
            <InputLabel
              sx={{
                lineHeight: '16px',
                fontSize: '12px',
                fontFamily: 'Mulish',
              }}
            >
              Organization Name
            </InputLabel>
            <TextField
              name="title"
              size="small"
              fullWidth
              sx={{ width: '100%', marginBottom: '10px' }}
              onChange={(e) => setOrganizationName(e.target.value)}
              placeholder={'Organization'}
              value={organizationName}
            />
          </Box>
          {(selectedItem === 'attributes' && (
            <Box className={styles.attributesPrimaryWrapper}>
              <Box className={styles.attributeSecondaryWrapper}>
                <Typography className={styles.collapsedText}>
                  Attributes
                </Typography>
                <Typography
                  onClick={() => handleSelectedItem()}
                  className={styles.collapsedRightText}
                >
                  Close
                </Typography>
              </Box>
              {attributes &&
                attributes.map((elem: any, pos: number) => (
                  <>
                    <InputLabel
                      sx={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        fontFamily: 'Mulish',
                      }}
                    >
                      {orgStructure.attributes[pos].label}
                    </InputLabel>
                    <TextField
                      name="title"
                      size="small"
                      fullWidth
                      sx={{ width: '100%', marginBottom: '10px' }}
                      onChange={(e) => handleAttributes(e, pos)}
                      placeholder={
                        'Enter ' + orgStructure.attributes[pos].label
                      }
                      value={elem?.value || ''}
                    />
                  </>
                ))}
            </Box>
          )) || (
            <Box className={styles.attributesCollapsedWrapper}>
              <Typography className={styles.collapsedText}>
                Attributes
              </Typography>
              <Typography
                onClick={() => handleSelectedItem('attributes')}
                className={styles.collapsedRightText}
              >
                Edit
              </Typography>
            </Box>
          )}
          {(selectedItem === 'subLevels' && (
            <Box className={styles.subLevelExpandedWrapper}>
              <Box className={styles.subLevelExpandedOne}>
                <Typography className={styles.collapsedText}>
                  Sub Levels
                </Typography>
                <Typography
                  onClick={() => handleSelectedItem()}
                  className={styles.collapsedRightText}
                >
                  Close
                </Typography>
              </Box>
              <InputLabel
                sx={{
                  lineHeight: '16px',
                  fontSize: '12px',
                  fontFamily: 'Mulish',
                  fontWeight: '700',
                  color: '#525862',
                  marginTop: '20px',
                  marginBottom: '15px',
                }}
              >
                Import CSV
              </InputLabel>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '5px',
                }}
              >
                <Typography
                  onClick={handleDownloadTemplate}
                  className={styles.downloadCsvTemplate}
                >
                  Download CSV Template
                </Typography>
                <Typography className={styles.forImport}>for import</Typography>
              </Box>
              <Box onClick={handleClick} className={styles.selectFileWrapper}>
                <Typography className={styles.selectFilePartOne}>
                  Select a CSV File to import
                </Typography>
                <Typography className={styles.selectFilePartTwo}>
                  or drag and down it here
                </Typography>
              </Box>
              <Box>
                <input
                  ref={fileRef!}
                  onChange={handleFileChange}
                  multiple={false}
                  type="file"
                  hidden
                  accept={'.csv'}
                />
              </Box>
              {fileName && (
                <Box
                  className={styles.showFile}
                  onClick={() => setPreviewModal(true)}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <img src={FileIcon} alt="file" />
                    <Typography
                      style={{
                        color: '#000A18',
                        fontWeight: '400',
                        fontSize: '12px',
                      }}
                    >
                      {fileName}
                    </Typography>
                  </Box>
                  <img
                    src={CloseIcon}
                    onClick={removeFile}
                    style={{ cursor: 'pointer' }}
                    alt="cross"
                  />
                </Box>
              )}
            </Box>
          )) || (
            <Box className={styles.subLevelCollapsedWrapper}>
              <Typography className={styles.collapsedText}>
                Sub Levels
              </Typography>
              <Typography
                onClick={() => handleSelectedItem('subLevels')}
                className={styles.collapsedRightText}
              >
                Edit
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          style={{
            marginBottom: '20px',
            marginLeft: 'auto',
            marginRight: '20px',
            marginTop: '20px',
          }}
        >
          <Button variant="text" onClick={() => handleClose(false)}>
            Cancel
          </Button>
          {organizationId && organizationId !== '' ? (
            <Button
              disabled={
                JSON.stringify(existingOrganization.attributes) ===
                  JSON.stringify(attributes) &&
                JSON.stringify(existingOrganization.levels) ===
                  JSON.stringify(subLevels) &&
                existingOrganization.name === organizationName
              }
              onClick={handleOrgUpdate}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{ marginLeft: '20px' }}
              onClick={saveChangesHandler}
              disabled={
                !fileName ||
                !organizationName ||
                !attributes.some((el: any) => el.value)
              }
            >
              Upload
            </Button>
          )}
        </Box>
        <PreviewOrgStructure
          openModal={previewModal}
          handleModalClose={(modalOpen: boolean) => {
            setPreviewModal(modalOpen);
          }}
          previewData={subLevels}
          orgStructure={orgStructure}
          isDisabled={
            !fileName ||
            !organizationName ||
            !attributes.some((el: any) => el.value)
          }
          uploadHandler={uploadHandler}
        />
      </Box>
    </Modal>
  );
};

export default CreateOrganization;
