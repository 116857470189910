import { REQUEST_GET } from '../utils/const';
import { requestAPI } from '../utils/fetch';
import { token } from '../utils/StateStore';
import { updateAlert } from '../utils/utils';

export const getPermissions = async (roleRequired: boolean = false) => {
  try {
    const userRoleAndPermission = await requestAPI({
      type: REQUEST_GET,
      url: `/api/permissions?roles=${roleRequired}`,
    });
    if (!userRoleAndPermission) {
      throw Error;
    }
    token?.next({
      ...token?.value!,
      userInfo: {
        ...token?.value!.userInfo!,
        permissions: userRoleAndPermission.permissions,
        roles: userRoleAndPermission.roles,
      },
    });
    return userRoleAndPermission.permissions;
  } catch (error: any) {
    updateAlert({ message: error.message, severity: 'error', state: true });
    return false;
  }
};
