import React, { useEffect, useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router';
import TextInput from '../../components/Input/TextInput/TextInput';
import { loginUser } from '../../api/authenticate';
import { LoginInfo } from '../../types/types';
import { getBrowserId, updateAlert, validateEmail } from '../../utils/utils';
import loginBackground from '../../assets/png/LoginBackground.png';
import SPLogoSmall from '../../assets/png/SPLogoSmall.png';
import { spinner, token } from '../../utils/StateStore';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { getPermissions } from '../../api/permissions';
const Login = () => {
  const navigate = useNavigate();

  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    email: '',
    password: '',
  });

  //Will take care of all the changes happering in the text input changes
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginInfo({ ...loginInfo, [event.target.name]: event.target.value });
  };

  //Sending Log in request and get token
  const handleLoginUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    spinner?.next(true);
    if (!validateEmail(loginInfo.email)) {
      updateAlert({
        message: 'Wrong email format',
        severity: 'warning',
        state: true,
      });
      return false;
    }
    const body = JSON.parse(JSON.stringify(loginInfo));
    body.client = getBrowserId();

    try {
      const userLoggedIn = await loginUser(body);
      if (!userLoggedIn) {
        return;
      } else {
        const userPermissions = await getPermissions(true);
        if (!userPermissions) {
          return;
        }
      }
      //Navigate to home screen
      if (typeof userLoggedIn !== 'boolean') {
        userLoggedIn?.userInfo?.first_login === 'pending'
          ? navigate('/first-time')
          : userLoggedIn?.userInfo?.first_login === 'partiallyLoggedIn'
          ? navigate('/first-time-template')
          : navigate('/');
      }
      setTimeout(() => spinner?.next(false), 1000);
    } catch (error: any) {
      console.error(
        '[Log In] Error while logging in due to error' + error.message
      );
    }
  };

  const handleForgotpassword = () => {
    navigate('/login/forgotpassword');
  };
  useEffect(() => {
    const subscribedToken = token?.subscribe((x) => {
      if (x?.accessToken) {
        navigate('/');
      }
    });
    return () => {
      subscribedToken?.unsubscribe();
    };
  }, [navigate]);

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        backgroundImage: `url(${loginBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box className="LoginRoot">
        <Box className="InputContainer" borderRadius="0.5rem">
          <Box
            ml="2rem"
            mb="2rem"
            display="flex"
            alignItems="center"
            onClick={() => navigate('/')}
          >
            <Box
              mr="0.5rem"
              height="1.5rem"
              width="1.5rem"
              sx={{
                backgroundImage: `url(${SPLogoSmall})`, // [TODO] using PNG image is not working
                backgroundRepeat: 'no-repeat',
                objectFit: 'contain',
                backgroundSize: 'cover',
              }}
            />
            <Box>
              <Typography variant="h-4">Workspace</Typography>
            </Box>
          </Box>
          <Box ml="2rem" mb="2rem">
            <Typography variant="h-1" fontStyle="normal">
              LOGIN
            </Typography>
          </Box>
          <form onSubmit={handleLoginUser}>
            <Box ml="2rem" display="flex" flexDirection="column">
              <Typography variant="Caption">Email</Typography>
              <TextInput
                value={loginInfo.email}
                onChange={onChange}
                name={'email'}
                placeholder={'Email'}
              />
            </Box>
            <Box ml="2rem" display="flex" flexDirection="column">
              <Typography variant="Caption">Password</Typography>
              <TextInput
                value={loginInfo.password}
                type={'password'}
                onChange={onChange}
                name={'password'}
                placeholder={'Password'}
              />
            </Box>
            <Box m="1.5rem 2.25rem 2.5rem 0" textAlign="right">
              <Typography
                onClick={handleForgotpassword}
                variant="h-3"
                color="#0451C4"
              >
                Forgot Password
              </Typography>
            </Box>

            <Button
              className="LoginButton"
              variant="contained"
              type="submit"
              style={{ marginLeft: '2rem' }}
            >
              <Typography
                fontStyle="normal"
                fontWeight="bold"
                fontSize="1rem"
                lineHeight="1.5rem"
              >
                Login User
              </Typography>
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
