import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import TickSuccessful from '../../assets/png/TickSuccessful.png';

import { useNavigate } from 'react-router';
import './index.css';

const FirstTImeUserUpdateSuccessfull = () => {
  const navigate = useNavigate();
  const handleSubmit = async () => {
    navigate('/login');
  };
  return (
    <Box className="LoginRoot">
      <Box
        className="InputContainer"
        borderRadius="0.5rem"
        boxShadow="0px 1.2px 3.6px rgba(0, 10, 24, 0.11), 0px 6.4px 14.4px rgba(0, 10, 24, 0.13), 0px 2px 8px rgba(0, 10, 24, 0.08)"
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            mr="0.5rem"
            height="2.5rem"
            width="2.5rem"
            sx={{
              backgroundImage: `url(${TickSuccessful})`, // [TODO] using PNG image is not working
              backgroundPosition: 'center',
              objectFit: 'contain',
              backgroundSize: 'cover',
            }}
          />
          <Typography mt="1rem" textAlign="center" variant="h-1">
            Password has been updated successfully
          </Typography>
          <Typography
            mt="1rem"
            textAlign="center"
            color="#80848B"
            variant="body-1"
          >
            Use your new password to login
          </Typography>
        </Box>

        <Button
          className="LoginButton"
          variant="contained"
          onClick={handleSubmit}
          color="primary" //[TODO] Overwrite existing theme and update as per project requirement
          style={{ margin: 'auto', marginTop: '3.5rem' }}
        >
          <Typography fontStyle="normal" fontWeight="bold" variant="h-3">
            Login
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default FirstTImeUserUpdateSuccessfull;
