import { REQUEST_POST, REQUEST_GET, REQUEST_PUT } from '../utils/const';
import { requestAPI } from '../utils/fetch';
import { updateAlert } from '../utils/utils';

export const createProject = async (
  projectData: any,
  file: File[],
  templatePresent: boolean
) => {
  try {
    const templateData = await requestAPI({
      type: REQUEST_POST,
      url: templatePresent ? '/api/project-from-template' : '/api/project',
      fileData: file,
      data: projectData,
      multipartPresent: true,
    });
    if (!templateData) {
      return false;
    }
    return templateData;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const getAllProjects = async () => {
  try {
    const projectsData = await requestAPI({
      type: REQUEST_GET,
      url: '/api/projects',
    });
    if (!projectsData) {
      return false;
    }
    return projectsData;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const getProjectData = async (projectId: string) => {
  try {
    const projectData = await requestAPI({
      type: REQUEST_GET,
      url: `/api/project?project_id=${projectId}`,
    });
    if (!projectData) {
      return false;
    }
    return projectData;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const updateProject = async (projectInfo: any, files: any) => {
  try {
    const response = await requestAPI({
      type: REQUEST_PUT,
      url: '/api/project',
      data: projectInfo,
      fileData: files,
      multipartPresent: true,
    });
    if (!response) {
      return false;
    }
    return response;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const updateProjectData = async (projectInfo: any, files: any) => {
  try {
    const response = await requestAPI({
      type: REQUEST_PUT,
      url: '/api/project?action=update',
      data: projectInfo,
      fileData: files,
      multipartPresent: true,
    });
    if (!response) {
      return false;
    }
    return response;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};
