import {
  Box,
  InputLabel,
  TextField,
  Typography,
  Dialog,
  Link as Navigation,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import SubmitButton from '../common/SubmitButton';

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestAPI } from '../../utils/fetch';
import { ACCESS_MODES, REQUEST_GET, REQUEST_PUT } from '../../utils/const';
import Divider from '../../assets/png/Divider.png';
import AddSection from '../../assets/png/AddSection.png';
import { DropDownIcon } from './CreateRole';
import DropdownClosed from '../../assets/png/DropdownClosed.png';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '../../assets/png/DeleteIcon.png';
import { resourceNames as table_resources } from '../../utils/const';
import { updateSnackbar } from '../../utils/utils';


const useStyles = makeStyles({
  checkBox: {
    color: '#0451C4',
  },
});
function EditRole() {
  const [open, setOpen] = useState(true);
  const [roleToEdit, setRoleToEdit] = useState<any>({
    description: '',
    name: '',
  });
  const [permissionsList, setPermissionsList] = useState<any>([]);
  const [deletedPermissions, setDeletedPermissions] = useState<any>([]);
  const [existingPermissions, setExistingPermissions] = useState<any>([]);
  const [accessValue, setAccessValue] = useState(
    new Array(permissionsList.length).fill([])
  );
  const [initialName, setInitialName] = useState('');
  const [initialDescription, setInitialDescrition] = useState('');
  const resourceNames = [table_resources.Entity, table_resources.Organization, table_resources.Projects, table_resources.Users, table_resources.Roles, table_resources.OrganisationStructure];
  let accessNames = ACCESS_MODES.map((mode) => Object.values(mode)[0]);

  const accessModeHandler = (event: any, value: any, index: number) => {
    let tempAccessArray = new Array(4).fill('');
    value.forEach((item: string) => {
      switch (item) {
        case 'create':
          tempAccessArray[0] = 'c';
          break;
        case 'read':
          tempAccessArray[1] = 'r';
          break;
        case 'update':
          tempAccessArray[2] = 'u';
          break;
        case 'delete':
          tempAccessArray[3] = 'd';
          break;
        default:
          break;
      }
    });
    let newAccess = value;
    accessValue[index] = newAccess;
    setAccessValue(accessValue);

    const newPermissionList = [...permissionsList];
    let item = { ...permissionsList[index] };
    newPermissionList[index] = item;
    item.access_mode = tempAccessArray.join('');
    item.name = `${item.resource_name}_${tempAccessArray.join('')}`;
    item.description = `${value.join(', ')} ${item.resource_name}`;
    item.accessValue = value;
    setPermissionsList(newPermissionList);
  };
  const classes = useStyles();

  const resourceNameHandler = (event: any, index: number) => {
    const newPermissionList = [...permissionsList];
    let item = { ...permissionsList[index] };
    newPermissionList[index] = item;
    item.resource_name = event.target.value;
    item.access_mode = '';
    item.resource_type = 'table';
    item.name = '';
    item.description = '';
    item.accessValue = [];
    setPermissionsList(newPermissionList);
  };
  const addSectionHandler = () => {
    setPermissionsList([
      ...permissionsList,
      {
        resource_type: 'table',
        resource_name: '',
        access_mode: '',
        name: '',
        description: '',
        accessValue: [],
      },
    ]);
  };
  const removeSectionHandler = (index: number) => {
    const list = [...permissionsList];
    list.splice(index, 1);
    setPermissionsList(list);
  };
  const removeExistingPermissionHandler = (index: number) => {
    const list = [...existingPermissions];
    const deletedPermission = list.splice(index, 1);
    setDeletedPermissions(
      [...deletedPermissions, deletedPermission[0].perm_id].flat()
    );
    setExistingPermissions(list);
  };

  const navigate = useNavigate();
  const { state }: any = useLocation();
  const dialogCloseHandler = () => {
    setOpen(false);
    state?.fromUser ? navigate(-1) : navigate('/Roles');
  };
  const roleId = state?.roleId;
  useEffect(() => {
    const fetchData = async () => {
      const response = await requestAPI({
        type: REQUEST_GET,
        url: '/api/roles',
      });

      //TODO: handling code acc to response status
      if (response) {
        const roleItems = response.filter(
          (item: any, index: any) => item.id === roleId
        );
        roleItems.length === 1 && setRoleToEdit(roleItems[0]);
        setInitialName(roleItems[0].name);
        setInitialDescrition(roleItems[0].description);
        setExistingPermissions(roleItems[0].permissions);
      } else {
        console.error(response);
      }
    };
    fetchData();
  }, []);

  const roleNameHandler = (event: any) => {
    setRoleToEdit({ ...roleToEdit, name: event.target.value });
  };
  const roleDescriptionHandler = (event: any) => {
    setRoleToEdit({ ...roleToEdit, description: event.target.value });
  };
  const sendUpdate = () => {
    if (
      initialName !== roleToEdit.name ||
      initialDescription !== roleToEdit.description
    ) {
      if (
        initialDescription !== roleToEdit.description &&
        initialName === roleToEdit.name
      ) {
        return { description: roleToEdit.description };
      } else if (
        initialName !== roleToEdit.name &&
        initialDescription === roleToEdit.description
      ) {
        return {
          name: roleToEdit.name,
        };
      } else {
        return {
          description: roleToEdit.description,
          name: roleToEdit.name,
        };
      }
    } else {
      return {};
    }
  };
  const submitHandler = async () => {
    permissionsList.map((item: any, index: any) => delete item.accessValue);
    const roleObj = {
      filter: {
        id: roleId,
      },
      update: sendUpdate(),
      deleted_permissions: deletedPermissions,
      added_permissions: permissionsList,
    };
    try {
      const response = await requestAPI({
        type: REQUEST_PUT,
        url: '/api/role',
        data: roleObj,
      });
      if (response) {
        updateSnackbar({
          message: 'Role updated',
          severity: 'success',
          state: true,
        });
        navigate(-1);
      }
    }
    catch (err: any) {
      updateSnackbar({
        message: err?.message,
        severity: 'error',
        state: true,
      });
    }

  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: '600px',
            borderRadius: '8px',
            height: '845px',
            overflow: 'hidden',
          },
        }}
        open={open}
      >
        <Box sx={{ height: '100%' }}>
          <div style={{ height: '845px' }}>
            <header style={{ padding: '32px' }}>
              <Typography
                variant="h-1"
                sx={{
                  fontFamily: 'Mulish',
                  lineHeight: '32px',
                  fontWeight: '700',
                  fontSize: '24px',
                  color: '#000A18',
                }}
              >
                Edit Role
              </Typography>
            </header>
            <div
              style={{
                overflow: 'hidden auto',
                width: '568px',
                height: '50%',
                paddingLeft: '32px',
              }}
            >
              <div
                style={{
                  justifyContent: 'space-between',
                  paddingBottom: '36px',
                  width: '536px',
                }}
              >
                <div style={{ width: '100%' }}>
                  <InputLabel
                    sx={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      fontFamily: 'Mulish',
                      paddingBottom: '4px',
                    }}
                  >
                    Role Name
                  </InputLabel>
                  <TextField
                    name="roleName"
                    size="small"
                    sx={{ width: '100%' }}
                    autoComplete="new-password"
                    value={roleToEdit.name}
                    onChange={(event: any) => roleNameHandler(event)}
                  />
                </div>
              </div>
              <div
                style={{
                  justifyContent: 'space-between',
                  paddingBottom: '36px',
                  width: '536px',
                }}
              >
                <div style={{ width: '100%' }}>
                  <InputLabel
                    sx={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      fontFamily: 'Mulish',
                      paddingBottom: '4px',
                    }}
                  >
                    Description
                  </InputLabel>
                  <TextField
                    name="roleDescription"
                    size="small"
                    sx={{ width: '100%' }}
                    value={roleToEdit.description}
                    onChange={(event) => roleDescriptionHandler(event)}
                  />
                </div>
              </div>
              {existingPermissions.map((permission: any, index: any) => (
                <div
                  key={index}
                  className="editRoleParentDiv"
                  style={{
                    marginLeft: '-32px',
                    padding: '8px 32px',
                    display: 'flex',
                  }}
                >
                  <div style={{ width: '280px', paddingRight: '24px' }}>
                    <InputLabel
                      sx={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        fontFamily: 'Mulish',
                        paddingBottom: '4px',
                      }}
                    >
                      Table
                    </InputLabel>
                    <InputLabel
                      sx={{
                        color: '#000A18',
                        lineHeight: '24px',
                        fontSize: '16px',
                        fontWeight: '400',
                      }}
                    >
                      {
                        permission.description.split(' ')[
                        permission.description.split(' ').length - 1
                        ]
                      }
                    </InputLabel>
                  </div>
                  <div style={{ width: '256px' }}>
                    <InputLabel
                      sx={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        fontFamily: 'Mulish',
                        paddingBottom: '4px',
                      }}
                    >
                      Permissions
                    </InputLabel>
                    <InputLabel
                      sx={{
                        color: '#000A18',
                        lineHeight: '24px',
                        fontSize: '16px',
                        fontWeight: '400',
                      }}
                    >
                      {permission.description
                        .split(' ')
                        .slice(0, permission.description.split(' ').length - 1)
                        .join(' ')}
                    </InputLabel>
                  </div>
                  <div
                    className="delete"
                    style={{ position: 'relative' }}
                    onClick={() => removeExistingPermissionHandler(index)}
                  >
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '-32px',
                        left: '-32px',
                        backgroundPositionX: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                  </div>
                </div>
              ))}
              {permissionsList.map((perms: any, index: any) => (
                <div key={index} className="parentDiv">
                  <div
                    className="hoveredDiv"
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '536px',
                    }}
                  >
                    <div style={{ paddingRight: '24px' }}>
                      <InputLabel
                        sx={{
                          lineHeight: '16px',
                          fontSize: '12px',
                          fontFamily: 'Mulish',
                          paddingBottom: '4px',
                        }}
                      >
                        Select Table
                      </InputLabel>
                      <Select
                        onChange={(event) => resourceNameHandler(event, index)}
                        size="small"
                        sx={{ width: '256px', fontSize: 'large' }}
                        value={perms.resource_name}
                        IconComponent={DropDownIcon}
                      >
                        {resourceNames.map((resource_name, index) => (
                          <MenuItem key={index} value={resource_name}>
                            {resource_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          lineHeight: '16px',
                          fontSize: '12px',
                          fontFamily: 'Mulish',
                          paddingBottom: '4px',
                        }}
                      >
                        Permissions
                      </InputLabel>
                      <Autocomplete
                        sx={{ width: '256px' }}
                        multiple
                        limitTags={1}
                        popupIcon={
                          <img
                            src={DropdownClosed}
                            style={{ top: '1px', right: '4px' }}
                            alt="Dropdown closed"
                          />
                        }
                        size="small"
                        options={accessNames}
                        value={perms.accessValue}
                        disableCloseOnSelect
                        // id="checkboxes-tags-demo"
                        getOptionLabel={(option: any) => option}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={
                                  <CheckBoxIcon
                                    fontSize="small"
                                    className={classes.checkBox}
                                  />
                                }
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          );
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                        onChange={(event, value) =>
                          accessModeHandler(event, value, index)
                        }
                      />
                    </div>

                    <div
                      className="delete"
                      style={{ position: 'relative' }}
                      onClick={() => removeSectionHandler(index)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="delete"
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          top: '-32px',
                          left: '-32px',
                          backgroundPositionX: 'center',
                          backgroundRepeat: 'no-repeat',
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ height: '38px', width: '100%' }}></div>
                </div>
              ))}
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  paddingBottom: '15px',
                  width: '536px',
                }}
              >
                ADD
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: '10px',
                  width: '536px',
                }}
              >
                <div>
                  <img
                    src={Divider}
                    style={{ width: '236px', height: '1px' }}
                    alt="divider"
                  />
                </div>
                <div onClick={addSectionHandler}>
                  <img src={AddSection} alt="plus" />
                </div>
                <div>
                  <img
                    src={Divider}
                    alt="divider"
                    style={{ width: '236px', height: '1px' }}
                  />
                </div>
              </div>
            </div>
            <footer
              style={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                display: 'flex',
                padding: '0px 32px 44px',
              }}
            >
              <div>
                <Navigation
                  style={{
                    padding: '0px 32px',
                    textDecoration: 'none',
                    color: '#525862',
                    fontWeight: '700',
                    fontFamily: 'Mulish',
                    cursor: 'pointer',
                  }}
                  onClick={dialogCloseHandler}
                >
                  Cancel
                </Navigation>
              </div>
              <div>
                <SubmitButton
                  onClick={submitHandler}
                  disabled={!(roleToEdit.name && roleToEdit.description)}
                  text="Confirm"
                />
              </div>
            </footer>
          </div>
        </Box>
      </Dialog>
    </>
  );
}

export default EditRole;
