import {
  Modal,
  Box,
  Typography,
  Grid,
  Link as Navigation,
  InputLabel,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SubmitButton from '../common/SubmitButton';

import { updateSnackbar } from '../../utils/utils';
import MultipleDropDown from '../common/MultipleDropDown';
import { updateEntity } from '../../api/entity';
import { getEntity } from '../../api/enitities';
import Spinner from '../../components/layout/Spinner';
import { entityInfo } from '../../utils/StateStore';

type Props = {
  open: boolean;
  handleClose: () => void;
  entityId?: any;
};

interface IEntityInfo {
  first_name: string;
  last_name: string;
  gender: string;
  entity_code: string;
  phone: string;
  email: string;
}
interface IUpdateEntity {
  filter: {
    id: string;
  };
  update: IEntityInfo;
  org_id: string;
  sublevel_ids: string[];
}

const UpdateEntity = ({ open, handleClose, entityId }: Props) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow:
      '0px 1.2px 3.6px rgba(0, 10, 24, 0.11), 0px 6.4px 14.4px rgba(0, 10, 24, 0.13), 0px 2px 8px rgba(0, 10, 24, 0.08)',
    borderRadius: '8px',
    padding: '32px',
    width: '600px',
    maxHeight: '560px',
  };
  const [orgInfo] = useState<any>(() => entityInfo?.value?.orgInfo);
  const [sublevel_Ids, setSublevel_id] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [entityData, setEntityData] = useState<IEntityInfo>({
    first_name: '',
    last_name: '',
    gender: '',
    entity_code: orgInfo?._id,
    phone: '',
    email: '',
  });

  const singleSelectedHandler = (id: string, orgSublevelSrc?: any[]) => {
    const selectedLevels: string[] = [];
    if (id && orgSublevelSrc) {
      findLevelRecursive(orgSublevelSrc, id);
    }

    function findLevelRecursive(orgSublevelSrc: any[], id: string) {
      for (let i = 0; i < orgSublevelSrc.length; i++) {
        const level = orgSublevelSrc[i];
        if (level.sublevel_id === id) {
          selectedLevels.unshift(level.sublevel_id);
          return true;
        }
        if (level.child && level.child.length >= 0) {
          const res = findLevelRecursive(level.child, id);
          if (res) {
            selectedLevels.unshift(level.sublevel_id);
            return true;
          }
        }
      }
    }
    setSublevel_id(selectedLevels);
    if (sublevel_Ids) {
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEntity(entityId, orgInfo?.id);
        if (data) {
          setEntityData({
            first_name: data?.entity?.first_name,
            last_name: data?.entity?.last_name,
            gender: data?.entity?.gender,
            entity_code: data?.entity?.entity_code,
            phone: data?.entity?.phone,
            email: data?.entity?.email,
          });
          setSublevel_id(data?.entity?.sublevel_ids);
          if (data?.entity?.sublevel_ids?.length > 1)
            setSelectedLevel(data?.entity?.sublevel_ids.splice(-1)?.[0]);
          else if (data?.entity?.sublevel_ids?.length == 1)
            setSelectedLevel(sublevel_Ids[0]);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const updateEntityHandler = async () => {
    const payload: IUpdateEntity = {
      filter: {
        id: entityId,
      },
      update: entityData,
      org_id: orgInfo?.id,
      sublevel_ids: sublevel_Ids,
    };
    const res = await updateEntity(payload);
    if (res) {
      updateSnackbar({
        message: 'Entity Updated Successfully !',
        severity: 'success',
        state: true,
      });
      handleClose();
    }
  };

  const UpdateStateHandler = (val: string, stateVariable: string) => {
    setEntityData(Object.assign({ ...entityData }, { [stateVariable]: val }));
  };

  const genderChangeHandler = (event: any) => {
    const val = event.target.value;
    UpdateStateHandler(val, 'gender');
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box m="0 0 1.5rem 0rem">
          <Typography
            fontSize="1.5rem"
            lineHeight="2rem"
            fontStyle="normal"
            fontWeight={700}
          >
            Edit entity
          </Typography>
        </Box>
        <Box>
          <Grid
            container
            sx={{
              marginTop: '15px',
            }}
          >
            <Grid item sx={{ width: '100%', marginBottom: '10px' }}>
              <InputLabel
                sx={{
                  lineHeight: '16px',
                  fontSize: '12px',
                  fontFamily: 'Mulish',
                  width: '50%',
                }}
              >
                Select class
              </InputLabel>

              <MultipleDropDown
                checkBoxRequired={false}
                singleSelectedParent={selectedLevel}
                singleSelectedHandler={singleSelectedHandler}
              />
            </Grid>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Box
                sx={{
                  maxHeight: '250px',
                }}
              >
                <Box sx={{ marginRight: '10px' }}>
                  <Grid container sx={{ margin: '15px' }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      sx={{ width: '100%', marginBottom: '36px' }}
                    >
                      <Grid item>
                        <InputLabel
                          sx={{
                            lineHeight: '16px',
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                          }}
                        >
                          First name
                        </InputLabel>
                        <TextField
                          size="small"
                          sx={{ width: '256px' }}
                          value={entityData.first_name}
                          onChange={(e) =>
                            UpdateStateHandler(e.target.value, 'first_name')
                          }
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          sx={{
                            lineHeight: '16px',
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                          }}
                        >
                          Last name
                        </InputLabel>
                        <TextField
                          size="small"
                          sx={{ width: '256px' }}
                          value={entityData.last_name}
                          onChange={(e) =>
                            UpdateStateHandler(e.target.value, 'last_name')
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="space-between"
                      sx={{ width: '100%', marginBottom: '36px' }}
                    >
                      <Grid item>
                        <InputLabel
                          sx={{
                            lineHeight: '16px',
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                            fontWeight: 700,
                          }}
                        >
                          Gender
                        </InputLabel>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="male"
                              control={<Radio size="small" />}
                              label="Male"
                              checked={entityData.gender === 'male'}
                              onChange={(e) => genderChangeHandler(e)}
                            />
                            <FormControlLabel
                              value="female"
                              control={<Radio size="small" />}
                              label="Female"
                              sx={{ color: '#000A18' }}
                              checked={entityData.gender === 'female'}
                              onChange={(e) => genderChangeHandler(e)}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <InputLabel
                          sx={{
                            lineHeight: '16px',
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                          }}
                        >
                          School code
                        </InputLabel>
                        <TextField
                          placeholder={''}
                          name="school code"
                          size="small"
                          value={entityData.entity_code}
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      sx={{ width: '100%', marginBottom: '36px' }}
                    >
                      <Grid item>
                        <InputLabel
                          sx={{
                            lineHeight: '16px',
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                          }}
                        >
                          Phone number (Optional)
                        </InputLabel>
                        <TextField
                          size="small"
                          sx={{ width: '256px' }}
                          value={entityData.phone}
                          onChange={(e) =>
                            UpdateStateHandler(e.target.value, 'phone')
                          }
                        />
                      </Grid>
                      <Grid item>
                        <InputLabel
                          sx={{
                            lineHeight: '16px',
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                          }}
                        >
                          Email (Optional)
                        </InputLabel>
                        <TextField
                          size="small"
                          sx={{ width: '256px' }}
                          type={'tel'}
                          value={entityData.email}
                          onChange={(e) =>
                            UpdateStateHandler(e.target.value, 'email')
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Grid
                  item
                  sx={{
                    textAlign: 'end',
                    borderTop: '1px solid #b8babe',
                    marginTop: '52px',
                    paddingTop: '25px',
                  }}
                >
                  <Navigation
                    style={{
                      padding: '0px 32px',
                      textDecoration: 'none',
                      color: '#525862',
                      fontWeight: '700',
                      fontFamily: 'Mulish',
                      cursor: 'pointer',
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Navigation>
                  <SubmitButton
                    disabled={sublevel_Ids.length ? false : true}
                    onClick={updateEntityHandler}
                    text="Save Changes"
                  />
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateEntity;
