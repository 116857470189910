import { useEffect, useState } from 'react';
import { fieldMaxLimits } from '../../utils/const';
import { FieldProjectType } from '../../types/types';
import TextOption from './TextOptions';
import styles from '../ProjectTemplate/createTemplate.module.css';

type Props = {
  updateField: (field: FieldProjectType, pos: number) => void;
  fieldData: any;
  pos: number;
  callFrom?:string;
  editable?:boolean;
};
const Textbox = ({ updateField, pos, fieldData, callFrom,editable}: Props) => {
  const [textBox, setTextBox] = useState<FieldProjectType>({...fieldData,type:fieldData.type});

  const addLabel = (pos: number) => {
    if (textBox.label && Array.isArray(textBox.label)) {
      let slice1 = textBox?.label?.slice(0, pos + 1);
      let slice2 = textBox?.label?.slice(pos + 1);

      setTextBox({ ...textBox, label: [...slice1, {}, ...slice2] });
    } else if (typeof textBox.label == 'string') {
      setTextBox({ ...textBox, label: [textBox.label, {}] });
    } else {
      setTextBox({ ...textBox, label: [{}] });
    }
  };

  const updateOptionValue = (value: {} , pos: number,valueType:string = 'label') => {
    let arr;
    if (Array.isArray(textBox.label) && valueType === 'label') {
      arr = textBox.label;
      arr[pos] = value;
      setTextBox({ ...textBox, label: arr });
    }else if(Array.isArray(textBox.values) && valueType === 'values'){
      arr = textBox.values;
      arr[pos] = value;
      setTextBox({ ...textBox, values: arr });
    } else {
      setTextBox({ ...textBox, label: [value] });
    }
  };
  const removeLabel = (pos: number) => {
    if (textBox?.label?.length === 1) {
      return;
    }
    if (Array.isArray(textBox.label)) {
      textBox?.label?.splice(pos, 1);
      setTextBox({ ...textBox, options: textBox.label });
    }
  };
  useEffect(() => {
    updateField({...textBox,type:fieldData.type}, pos);
  }, [textBox]);
  return (
    <div className={callFrom === 'projectPreview' ? styles.textBoxWrapperPreview : styles.textBoxWrapperNormal}>
      {Array.isArray(textBox.label) ? (
        textBox.label.map((option, i) => {
          return <TextOption
            addOption={addLabel}
            removeOption={removeLabel}
            pos={i}
            option={typeof option === 'string' ? option : {label:{key:option?.key || '',value:option?.value || ''},values:{key:(textBox?.values && textBox?.values[i] && textBox?.values[i].key || ''),value:(textBox?.values && textBox?.values[i] && textBox?.values[i].value || '')}} /* || {label:{key:'',value:''},values:{key:'',value:''}} */}
            displayRemove={textBox.label.length > 1}
            updateOptionValue={updateOptionValue}
            displayAdd={
              textBox.label.length
                ? textBox.label.length < fieldMaxLimits.TEXTBOXLABEL
                : true
            }
            callFrom={callFrom}
            editable={editable}
            fieldType={fieldData.type}
          />
        })
      ) : 
        (<TextOption
          addOption={addLabel}
          removeOption={removeLabel}
          pos={0}
          option={textBox.label}
          displayRemove={false}
          updateOptionValue={updateOptionValue}
          displayAdd={true}
          fieldType={fieldData.type}
        /> 
      )}
    </div>
  );
};

export default Textbox;
