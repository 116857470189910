import { Modal, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  open: boolean;
  handleClose: (confirm: boolean) => void;
  mainHeading: string;
  buttonText: string;
  secondaryHeading: string;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '434px',
  bgcolor: 'white',
  minHeight : '248px',
  borderRadius: '8px',
};
const Confirm = ({
  open,
  handleClose,
  mainHeading,
  secondaryHeading,
  buttonText,
}: Props) => {
  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style} display="flex" flexDirection="column" justifyContent={'space-between'} p={'0 2rem'}>
        <Box
          sx={{
            mt: '2rem',
          }}>
          <Typography variant="h-1" sx={{}}>
            {mainHeading}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body-1">{secondaryHeading}</Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          marginBottom={'2rem'}
        >
          <Button sx={{ color: '#525862' }} variant="text" onClick={() => handleClose(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClose(true)}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default Confirm;
