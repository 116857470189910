import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import TickSuccessful from '../../assets/png/TickSuccessful.png';
import loginBackground from '../../assets/png/LoginBackground.png';

import { useNavigate } from 'react-router';
import './index.css';

const PasswordUpdateSuccessful = () => {
  const navigate = useNavigate();
  const handleSubmit = async () => {
    navigate('/login');
  };
  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        backgroundImage: `url(${loginBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box className="LoginRoot">
        <Box className="InputContainer" borderRadius="0.5rem">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              mr="0.5rem"
              height="2.5rem"
              width="2.5rem"
              sx={{
                backgroundImage: `url(${TickSuccessful})`, // [TODO] using PNG image is not working
                backgroundPosition: 'center',
                objectFit: 'contain',
                backgroundSize: 'cover',
              }}
            />
            <Typography
              mt="1rem"
              textAlign="center"
              fontWeight="700"
              fontSize="1.5rem"
              lineHeight="2rem"
              fontStyle="normal"
            >
              Password has been reset successfully
            </Typography>
            <Typography
              mt="1rem"
              textAlign="center"
              color="#80848B"
              fontWeight="400"
              fontSize="1rem"
              lineHeight="1.5rem"
              fontStyle="normal"
            >
              Use your new password to login
            </Typography>
          </Box>

          <Button
            className="LoginButton"
            variant="contained"
            onClick={handleSubmit}
            color="primary" //[TODO] Overwrite existing theme and update as per project requirement
            style={{ margin: 'auto', marginTop: '3.5rem' }}
          >
            <Typography
              fontStyle="normal"
              fontWeight="bold"
              fontSize="1rem"
              lineHeight="1.5rem"
            >
              Login
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PasswordUpdateSuccessful;
