import { requestAPI } from '../utils/fetch';
import {
  REQUEST_DELETE,
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
} from '../utils/const';
import { updateAlert } from '../utils/utils';

export const getOrganizationList = async (projectId: string) => {
  try {
    const organiztionList = await requestAPI({
      type: REQUEST_GET,
      url: `/api/organizations?project_id=${projectId}`,
    });
    if (!organiztionList) {
      return false;
    }
    return organiztionList.organizations;
  } catch (error: any) {
    //updateAlert({ message: error.message, severity: 'error', state: true });
  }
  return false;
};

export const getOrganizationStructure = async (projectId?: any) => {
  try {
    const projectStructure = await requestAPI({
      type: REQUEST_GET,
      url: projectId
        ? `/api/organization-structure?projectId=` + projectId
        : '/api/organization-structure',
    });
    if (!projectStructure) {
      return false;
    }
    return projectStructure.OrganizationStructures;
  } catch (error: any) {
    console.error('Inside error', error);
  }
  return false;
};

export const getAllOrganizationStructures = async () => {
  try {
    const projectStructure = await requestAPI({
      type: REQUEST_GET,
      url: '/api/organization-structure',
    });
    if (!projectStructure) {
      return false;
    }
    return projectStructure.OrganizationStructures;
  } catch (error: any) {
    console.error('Inside error', error);
  }
  return false;
};

export const createOrganisationStructure = async (structData: any) => {
  try {
    const structureData = await requestAPI({
      type: REQUEST_POST,
      url: '/api/organization-structure',
      data: structData,
    });
    if (!structureData) {
      return false;
    }
    return structureData;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const getOrganizationLevels = async (projectId: string) => {
  try {
    const data = await requestAPI({
      type: REQUEST_GET,
      url: `/api/organization-with-levels?id=${projectId}`,
    });
    if (!data) {
      return false;
    }
    return data;
  } catch (error: any) {
    console.error('Inside error', error);
  }
  return false;
};

export const updateOrganisationStructure = async (structData: any) => {
  try {
    const structureData = await requestAPI({
      type: REQUEST_PUT,
      url: '/api/organization-structure',
      data: structData,
    });
    if (!structureData) {
      return false;
    }
    return structureData;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const createOrganization = async (organizationBody: any) => {
  try {
    const createdOrganization = await requestAPI({
      type: REQUEST_POST,
      url: '/api/organization',
      data: organizationBody,
    });
    if (!createdOrganization) {
      return false;
    }
    return createdOrganization;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const deleteOrganizationStructure = async (body: any) => {
  try {
    const createdOrganization = await requestAPI({
      type: REQUEST_DELETE,
      url: '/api/organization-structure',
      data: body,
    });
    if (!createdOrganization) {
      return false;
    }
    return createdOrganization;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const updateOrganization = async (organizationBody: any) => {
  try {
    const updatedOrganization = await requestAPI({
      type: REQUEST_PUT,
      url: '/api/organization',
      data: organizationBody,
    });
    if (!updatedOrganization) {
      return false;
    }
    return updatedOrganization;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const deleteOrganizationData = async (body: any) => {
  try {
    const orgToDel = await requestAPI({
      type: REQUEST_DELETE,
      url: '/api/organization',
      data: body,
    });
    if (!orgToDel) {
      return false;
    }
    return orgToDel;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};
