import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandLess from '../../../assets/png/ExpandLess.png';
import ExpandMore from '../../../assets/png/ExpandMore.png';
import { hasChildren, LeftDrawerMenuList } from './LeftDrawerMenuList';
import { Icon } from './LeftDrawerMenuList';
import { useNavigate } from 'react-router';
import { selectedLeftNavigationField, token } from '../../../utils/StateStore';

const LeftDrawerMenuItems = ({ expandDrawer }: { expandDrawer: boolean }) => {

  const [selectedItemName, setSelectedItemName] = useState(
    LeftDrawerMenuList[0]?.title
  );
  const [menuItem, setMenuItems] = useState<any[]>([]);
  useEffect(() => {
    selectedLeftNavigationField?.subscribe((x) => {
      setSelectedItemName(x! !== '' ? x! : selectedItemName);
    });
  }, []);

  function filterMenuItems(itemList: any[], permissions: any[]): any[] {
    const resultList: any[] = []

    const checkIfRowAllowed = (row: any) => {
      if( row.resourcePermission?.toLowerCase() == 'all') {
        return true;
      } else {
        if(permissions.findIndex((p: any) =>(p.resource_type == 'all' )) >= 0) {
          return true;
        }
        else {
          const permissionIndex = permissions.findIndex(p =>  row.resourcePermission?.toLowerCase() === p.resource_name?.toLowerCase())
          if(permissionIndex >= 0) {
            const {access_mode} = permissions[permissionIndex];
            if((access_mode.includes('c') || access_mode.includes('r') || access_mode.includes('u') || access_mode.includes('d'))) {
              return true;
            }
          }
        }
      }

      return false;
    }

    for (let i = 0; i < itemList.length; i++) {
      let row = itemList[i];

      // If any child have permission present, add to list, otherise skip.
      if (row?.items?.length) {
        const childRows = filterMenuItems(row.items, permissions);
        if(childRows.length) {
          row.items = childRows;
          let { items, ...newRow } = row;
          newRow.items = childRows;
          resultList.push(newRow);
        }
      } else {
        let isAllowed = checkIfRowAllowed(row) || false;
        isAllowed && resultList.push(row);
      }
    }
    return resultList;
  }

  useEffect(() => {
    const permissions = token?.value?.userInfo?.permissions
    const _menuItem = filterMenuItems(LeftDrawerMenuList, permissions ?? []);
    setMenuItems(_menuItem)
  }, [token?.value?.userInfo?.permissions])

  return (
    <>
      {menuItem.map((item, key) => (
        <MenuItem
          key={key}
          item={item}
          expandDrawer={expandDrawer}
          showMultiLevelText={expandDrawer ? true : false}
          setSelectedItemName={setSelectedItemName}
          selectedItemName={selectedItemName}
        />
      ))}
    </>
  );
};

const MenuItem = ({
  item,
  expandDrawer,
  showMultiLevelText,
  setSelectedItemName,
  selectedItemName,
}: any) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return (
    <Component
      item={item}
      expandDrawer={expandDrawer}
      showMultiLevelText={showMultiLevelText}
      setSelectedItemName={setSelectedItemName}
      selectedItemName={selectedItemName}
    />
  );
};

const SingleLevel = ({
  item,
  showMultiLevelText,
  setSelectedItemName,
  selectedItemName,
}: any) => {
  const navigate = useNavigate();
  const handleClick = (title: string, link: string) => {
    setSelectedItemName(title);
    navigate(link);
  };
  //Define an interface
  return (
    <ListItem
      style={{
        borderLeft: selectedItemName === item.title ? '3px solid #0451C4' : '',
      }}
      button
      onClick={() => handleClick(item.title, item.pathname)}
    >
      <ListItemIcon>
        {selectedItemName === item.title ? item.iconHover : item.icon}
      </ListItemIcon>

      {showMultiLevelText ? (
        <ListItemText
          style={{
            color: selectedItemName === item.title ? '#0451C4' : 'black',
          }}
          primary={item.title}
          sx={{ textAlign: 'left' }}
        />
      ) : (
        <></>
      )}
    </ListItem>
  );
};

const MultiLevel = ({
  item,
  expandDrawer,
  setSelectedItemName,
  selectedItemName,
}: any) => {
  //define interface
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = (title: string) => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem
        style={{
          borderLeft:
            selectedItemName === item.title ? '3px solid #0451C4' : '',
        }}
        button
        onClick={() => {
          handleClick(item.title);
        }}
      >
        <ListItemIcon>
          {selectedItemName === item.title && open ? item.iconHover : item.icon}
        </ListItemIcon>
        {expandDrawer ? (
          <ListItemText
            style={{
              color:
                selectedItemName === item.title && open ? '#0451C4' : 'black',
            }}
            primary={item.title}
          />
        ) : (
          <></>
        )}
        {expandDrawer ? (
          open ? (
            <Icon imagePath={ExpandLess} height="1.5rem" width="1.5rem" />
          ) : (
            <Icon imagePath={ExpandMore} height="1.5rem" width="1.5rem" />
          )
        ) : (
          <></>
        )}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          zIndex: 99,
          backgroundColor: 'white',
          marginLeft: expandDrawer ? '' : '70px !important',
          width: '240px',
          boxShadow: expandDrawer ? '' : '0px 1.2px 3.6px rgba(0, 0, 0, 0.11)',
        }}
      >
        <List
          component="div"
          disablePadding
          style={{
            marginTop: expandDrawer ? '' : '-55px',
          }}
        >
          {children.map((child: any, key: any) => (
            expandDrawer ?
              <MenuItem
                key={key}
                item={child}
                showMultiLevelText={expandDrawer}
                setSelectedItemName={setSelectedItemName}
                selectedItemName={selectedItemName}
              /> : (<></>)
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default LeftDrawerMenuItems;


