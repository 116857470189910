import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { getTemplateData } from '../../../api/template';
import {
  /* ProjectTemplateSectionType , */ ProjectSectionProjectType,
} from '../../../types/types';
import Typography from '@mui/material/Typography';
import SectionPreviewAccordian from '../../../pages/common/Accordion/SectionPreviewAccordian';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import { getProjectData, updateProjectData } from '../../../api/project';
import styles from './preview.module.css';
import CloseCircullarIcon from '../../../assets/png/CloseCircullar.png';

type Props = {
  open: boolean;
  handleClose: (confirm: boolean) => void;
  modalType?: string;
  modalId: string;
  isNewProjectCreation: boolean;
  jsonData?: any;
  callFrom?: string;
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '52%',
  bgcolor: 'white',
  height: '82.51%',
  borderRadius: '8px',
};
const PreviewWithAccordian = ({
  // from all project, preview of project template and preview of edit template
  open,
  handleClose,
  modalType,
  modalId,
  isNewProjectCreation,
  jsonData,
  callFrom,
}: Props) => {
  const [previewData, setPreviewData] = useState<{
    name: string;
    sections: ProjectSectionProjectType[];
  }>({ name: '', sections: [] });
  const [files, setFiles] = useState<File[]>([]);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<number | false>(0);

  const saveChangesHandler = async () => {
    if (modalType == 'project') {
      const data = await updateProjectData(previewData, files);
      if (data) {
        handleClose(false);
      }
    }
    if (modalType == 'template')
      navigate('/editTemplate', { state: { templateId: modalId } });
  };
  const updateFiles = (file: File[], action: string, name?: string) => {
    if (action === 'add') {
      setFiles([...files, ...file]);
    } else {
      //delete
      setFiles(files.filter((file) => file?.name !== name));
    }
  };
  useEffect(() => {
    async function fetchMyAPI() {
      if (jsonData) {
        setPreviewData(jsonData);
      } else if (modalId && modalId !== '') {
        const data =
          modalType == 'template'
            ? await getTemplateData(modalId)
            : await getProjectData(modalId);
        if (data && typeof data !== 'boolean') {
          setPreviewData(data!);
        }
      }
    }

    fetchMyAPI();
  }, [modalId, jsonData]);
  const updateSectionsData = (
    pos: number,
    section: ProjectSectionProjectType
  ) => {
    let updatePosArray = previewData.sections;
    updatePosArray[pos] = section;
    setPreviewData({
      ...previewData,
      sections: updatePosArray,
    });
  };
  const publishHandler = (modalId: string) => {
    navigate('/create-project', { state: { templateId: modalId } });
  };
  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style} className={styles.modal}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box sx={{ overflowY: 'auto' }}>
            <Box
              sx={{ m: '1rem 1rem 1rem 2rem' }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h-1">
                {previewData.name ? previewData.name : modalType + ' name'}
              </Typography>
              {callFrom === 'editTemplate' && (
                <img
                  src={CloseCircullarIcon}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleClose(false)}
                  alt="close button"
                />
              )}
            </Box>
            <Box>
              {previewData.sections &&
                previewData.sections.length > 0 &&
                previewData.sections.map(
                  (section: ProjectSectionProjectType, i: number) => (
                    <SectionPreviewAccordian
                      modalType={modalType}
                      sectionData={section}
                      updateSectionsData={updateSectionsData}
                      updateFiles={updateFiles}
                      pos={i}
                      expanded={expanded}
                      setExpanded={(index) => setExpanded(index)}
                      callFrom={callFrom}
                    />
                  )
                )}
            </Box>
          </Box>

          {callFrom !== 'editTemplate' && (
            <Box
              p="2rem"
              borderTop="1px solid #E5E6E8"
              height="6.5rem"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button variant="text" onClick={() => handleClose(false)}>
                Cancel
              </Button>
              {isNewProjectCreation ? (
                <Button
                  variant="contained"
                  sx={{ mr: '2rem' }}
                  onClick={() => publishHandler(modalId)}
                >
                  Create
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ mr: '2rem' }}
                  onClick={saveChangesHandler}
                >
                  {modalType === 'template' ? 'Edit' : 'Update'} {modalType}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default PreviewWithAccordian;
