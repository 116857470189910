export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT_SP_WORKSPACE;
export const REQUEST_GET = 'GET';
export const REQUEST_POST = 'POST';
export const REQUEST_DELETE = 'DELETE';
export const REQUEST_PUT = 'PUT';
export const ACCESS_MODES = [
  { c: 'create' },
  { r: 'read' },
  { u: 'update' },
  { d: 'delete' },
];
export const NAME_MSG = 'Please enter role name';
export const ROLE_DESCRIPTION_MSG = 'Please enter role description';
export const PERMISSION_NAME_MSG = 'Please enter permission name';
export const PERMISSION_DESCRIPTION_MSG = 'Please enter permission description';
export const filters = ['All', 'Active', 'Deactivated'];
export const FieldTypes = {
  TEXTBOX: 'textbox',
  IMAGE: 'image',
  VIDEO: 'video',
  DROPDOWN: 'dropdown',
  CHECKBOX: 'checkbox',
  MULTIPLECHOICE: 'multiplechoice',
  RADIO: 'radio',
  DATE: 'date',
  TEXTAREA: 'textarea',
};

export const fieldMaxLimits = {
  TEXTBOXLABEL: 3,
  DROPDOWNOPTION: 8,
  CHECKBOXOPTION: 8,
  MULTIPLECHOICEOPTION: 8,
};
//single source of truth for Table Name dropdown during roles assignment
export enum resourceNames {
  All = 'all',
  Projects = 'Projects',
  Users = 'Users',
  Roles = 'Roles',
  Organization = 'Organizations',
  Entity = 'Entitys',
  OrganisationStructure = 'Organization_Structure',
}

/*New Route to be added here
 element here denotes the key in the dynamic component array which will be rendered when route hits, hence make sure to add component in dynamicComponents
 and this route will be rendered based on the resurce permission passed here
 */
export const pagesRoutes = [
  {
    resourcePermission: resourceNames.Projects,
    path: 'projectTemplate',
    element: 'ProjectTemplate',
  },
  {
    resourcePermission: resourceNames.Entity,
    path: '/add-entity',
    element: 'AddEntity',
  },
  {
    resourcePermission: resourceNames.Entity,
    path: '/update-entity',
    element: 'UpdateEntity',
  },
  {
    resourcePermission: resourceNames.Entity,
    path: '/list-entity',
    element: 'EntityList',
  },
  {
    resourcePermission: resourceNames.Users,
    path: 'listUsers',
    element: 'ListUsers',
  },
  {
    resourcePermission: resourceNames.Users,
    path: 'createUser',
    element: 'CreateUser',
  },
  {
    resourcePermission: resourceNames.Users,
    path: 'editUser',
    element: 'EditUser',
  },
  {
    resourcePermission: resourceNames.Roles,
    path: 'userRole',
    element: 'UserRole',
  },
  {
    resourcePermission: resourceNames.Roles,
    path: 'usersRole',
    element: 'UsersRole',
  },
  {
    resourcePermission: resourceNames.Users,
    path: 'first-time',
    element: 'FirstTimeUserLogin',
  },
  {
    resourcePermission: resourceNames.Projects,
    path: 'editTemplate',
    element: 'EditTemplate',
    props: { callFrom: 'templateEdit' },
  },
  {
    resourcePermission: resourceNames.Projects,
    path: 'createTemplate',
    element: 'EditTemplate',
    props: { callFrom: 'templateCreate' },
  },
  {
    resourcePermission: resourceNames.Projects,
    path: 'create-form',
    element: 'EditTemplate',
    props: { callFrom: 'formCreation' },
  },
  {
    resourcePermission: resourceNames.Projects,
    path: 'edit-form',
    element: 'EditTemplate',
    props: { callFrom: 'formEdit' },
  },
  {
    resourcePermission: resourceNames.Users,
    path: 'first-time/sucess',
    element: 'FirstTImeUserUpdateSuccessfull',
  },
  {
    resourcePermission: resourceNames.Users,
    path: 'createRole',
    element: 'CreateRole',
  },
  { resourcePermission: resourceNames.Roles, path: 'Roles', element: 'Roles' },
  {
    resourcePermission: resourceNames.Roles,
    path: 'editRole',
    element: 'EditRole',
  },
  {
    resourcePermission: resourceNames.Projects,
    path: 'new-project',
    element: 'NewProject',
  },
  {
    resourcePermission: resourceNames.Projects,
    path: 'all-project',
    element: 'AllProject',
  },
  {
    resourcePermission: resourceNames.Organization,
    path: 'project-list',
    element: 'ProjectList',
  },
  {
    resourcePermission: resourceNames.Organization,
    path: 'organisation-list',
    element: 'OrganisationList',
  },
  {
    resourcePermission: resourceNames.Organization,
    path: 'assign-form',
    element: 'OrganisationList',
  },
  {
    resourcePermission: resourceNames.Users,
    path: 'form-list',
    element: 'FormList',
  },
  {
    resourcePermission: resourceNames.Users,
    path: 'onboard-user',
    element: 'OnboardUsers',
  },
  {
    resourcePermission: resourceNames.Users,
    path: 'attach-user',
    element: 'AttachUser',
  },
  {
    resourcePermission: resourceNames.Projects,
    path: 'create-project',
    element: 'CreateProject',
    props: { callFrom: 'projectCreate' },
  },
  {
    resourcePermission: resourceNames.Projects,
    path: 'edit-project',
    element: 'CreateProject',
    props: { callFrom: 'projectEdit' },
  },
  {
    resourcePermission: resourceNames.Projects,
    path: '/preview-project',
    element: 'PreviewProject',
  },
  {
    resourcePermission: resourceNames.Users,
    path: '/first-time-template/create-user',
    element: 'CreateUser',
    props: { fromFirstTimeTemplate: true },
  },
  {
    resourcePermission: resourceNames.Roles,
    path: '/first-time-template/create-role',
    element: 'CreateRole',
    props: { fromFirstTimeTemplate: true },
  },
  {
    resourcePermission: resourceNames.Users,
    path: '/first-time-template/create-template',
    element: 'EditTemplate',
    props: { fromFirstTimeTemplate: true },
  },
  {
    resourcePermission: resourceNames.Users,
    path: '/first-time-template',
    element: 'FirstLoginModal',
  },
  {
    resourcePermission: resourceNames.Entity,
    path: '/list-entity',
    element: 'EntityList',
  },
  {
    resourcePermission: resourceNames.Entity,
    path: '/entity-assigned-form',
    element: 'EntityFormAssigned',
  },
  {
    resourcePermission: resourceNames.Entity,
    path: '/entity-assigned-form/:proId',
    element: 'EntityStudentAssigned',
  },
  {
    resourcePermission: resourceNames.Entity,
    path: '/form-data-preview/',
    element: 'FormDataPreview',
  },
];
