import { useState, ChangeEvent } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MultipleDropDownRoot from './MultipleDropDownRoot';
import Checkbox from '@mui/material/Checkbox';
import { MultiNodeI } from './MultipleDropDown';
import TickCheck from '../../assets/png/TickCheck.png';
import Box from '@mui/material/Box';

type Props = {
  structure: string[];
  node: any;
  currLevel: number;
  formSublevelData: any;
  updateSingleSrcObj: (updatedObj: MultiNodeI, pathArr: string[]) => void;
  currPath: string[];
  checkboxRequired: boolean;
  singleSelected: string;
  updateSingleSelected: (id: string) => void;
  parentLabel: string;
  updateParentLabel: (value: string) => void;
};

const MultipleDropDownNode = ({
  structure,
  node,
  currLevel,
  formSublevelData,
  updateSingleSrcObj,
  currPath,
  checkboxRequired,
  singleSelected,
  updateSingleSelected,
  parentLabel,
  updateParentLabel,
}: Props) => {
  const { child, sublevel_name: value, id } = node;

  const [showChildren, setShowChildren] = useState(true);
  const handleClick = (event: any) => {
    setShowChildren(!showChildren);
  };
  const handleCurrCheckNode = (event: ChangeEvent<HTMLInputElement>) => {
    //preventing list toggle when checkbox is changed.
    if(formSublevelData.child.length)
    setShowChildren(showChildren);
    formSublevelData.checked = event.target.checked;
    updateSingleSrcObj(formSublevelData, currPath);
  };
  const getCheckedState = (type: string) => {
    let count = 0;
    formSublevelData.child.forEach((sublevel: any) => {
      if (sublevel.checked) {
        count++;
      }
    });
    if (formSublevelData.child.length === 0 && type === 'parent') {
      return formSublevelData.checked;
    }
    return type === 'parent'
      ? count === formSublevelData.child.length
      : count > 0 && count < formSublevelData.child.length;
  };
  const handleSingleSelect = () => {
    if (checkboxRequired) {
      return;
    }
    updateSingleSelected(id);
  };
  return (
    <div>
      <div style={{ marginBottom: '10px' }} onClick={child && child.length > 0 ? handleClick : formSublevelData.disabled ? ()=>{} : handleSingleSelect}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: formSublevelData.disabled ? 'not-allowed' : 'pointer',
            paddingRight: '10px'
          }}

        >
          <Box display="flex" alignItems="center" justifyContent="center"   >
            {child && child.length > 0 ? (
              <div>
                {showChildren ? (
                  <ExpandMoreIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </div>
            ) : (
              <></>
            )}
            <Box display="flex" alignItems="center" justifyContent="center" >
              {typeof formSublevelData !== 'undefined' &&
                Object.keys(formSublevelData).length > 0 &&
                checkboxRequired && (
                  <Checkbox
                    onChange={handleCurrCheckNode}
                    checked={getCheckedState('parent')}
                    size="small"
                    indeterminate={getCheckedState('child')}
                    disabled={formSublevelData.disabled ? true : false}
                  />
                )}
              <Box color={formSublevelData.disabled ? 'rgb(170, 170, 170)': 'black'}>
                {structure[currLevel]} : {value}
              </Box>
            </Box>
          </Box>
          {!checkboxRequired && singleSelected === id && (
            <div>
              <img style={{display:"flex", alignItems:"center", justifyContent:"center"}} src={TickCheck} alt="tick Check" />
            </div>
          )}
        </div>
      </div>
      <ul style={{ paddingLeft: '10px' }}>
        {showChildren && (
          <MultipleDropDownRoot
            previewData={child}
            structure={structure}
            currLevel={currLevel + 1}
            updateSingleSrcObj={updateSingleSrcObj}
            formSublevelData={formSublevelData.child}
            currPath={currPath}
            checkboxRequired={checkboxRequired}
            singleSelected={singleSelected}
            updateSingleSelected={updateSingleSelected}
            parentLabel={parentLabel}
            updateParentLabel={updateParentLabel}
          />
        )}
      </ul>
    </div>
  );
};

export default MultipleDropDownNode;
