import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormAccordianContent from './form/FormAccordianContent';

type Props = {
  category: IFormCategory;
  updateCategory: (categoryField: IFormCategory, pos: number) => void;
  updateFiles: (file: File[], action: string, name?: string) => void;
  pos: number;
};

const EntityFormCategory = ({
  category,
  updateCategory,
  updateFiles,
  pos,
}: Props) => {
  const updateField = (fieldData: any, posField: number) => {
    let updatePosArray = category.fields;
    updatePosArray[posField] = fieldData;
    updateCategory({ ...category, fields: updatePosArray }, pos);
  };

  return (
    <Box>
      <Typography variant="body-2">{category.title}</Typography>
      <Box>
        {category &&
          category.fields &&
          category.fields.map((field: any, index: number) => (
            <Box>
              <FormAccordianContent
                field={field}
                index={index}
                valueInput={true}
                updateField={updateField}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
};
export interface IFormCategory {
  title: string;
  fields: FormField[];
}
export interface FormField {
  key: string;
  label: {
    key: string;
    value: string;
  }[];
  options?: { key: string; value: string }[];
  type: string;
  values: { key: string; value: string }[];
  seq: number;
}
export default EntityFormCategory;
