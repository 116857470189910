import Box from '@mui/material/Box';
import { useState } from 'react';
import Close from '../../assets/png/Close.png';
import Confirm from '../../components/modal/Confirm';
import MediaPreview from '../../components/modal/MediaPreview';
import { FieldTypes } from '../../utils/const';
import { base64MimeType } from '../../utils/utils';
type Props = {
  file: string;
  pos: number;
  type: string;
  deleteMedia: (pos: number, name: string, existing: boolean) => void;
  fieldData?: any;
  mimeType?: string;
  existing: boolean;
  editable?: boolean;
  projectEdit?: string;
};
const Media = ({
  file,
  pos,
  deleteMedia,
  fieldData,
  type,
  mimeType,
  existing,
  editable,
  projectEdit,
}: Props) => {
  const [confirm, setConfirm] = useState(false);
  const [preview, setPreview] = useState(false);
  const handleModalClose = async (confirm: boolean) => {
    confirm
      ? deleteMedia(pos, file.substr(file.lastIndexOf('/') + 1), existing)
      : setConfirm(false);
    setConfirm(false);
  };
  const handlePreviewModal = (event: any) => {
    event.preventDefault();
    setPreview(true);
  };

  //necessary to check for specific null check as if not done will thrown
  //unnecessary error when we are trying to pass it or use it
  if (!mimeType) {
    const mimeData = base64MimeType(file);
    if (mimeData !== null) {
      mimeType = mimeData;
    }
  }

  if (!mimeType || mimeType === null) {
    return <></>;
  }
  return (
    <>
      <Box sx={{ position: 'relative', top: 0, left: 0 }}>
        <Box onClick={handlePreviewModal}>
          {type === FieldTypes.IMAGE ? (
            <Box
              mr="1.5rem"
              height="6.5rem"
              width="6.5rem"
              sx={{
                top: 0,
                left: 0,
                position: 'relative',
                backgroundImage: `url(${file})`,
                backgroundRepeat: 'no-repeat',
                objectFit: 'contain',
                backgroundSize: 'cover',
              }}
            />
          ) : (
            <Box mr="1.5rem" height="6.5rem" width="6.5rem">
              <video height="100%" width="100%" controls>
                <source src={file} type={mimeType} />
              </video>
            </Box>
          )}
        </Box>
          <Box
            height="1.5rem"
            width="1.5rem"
            onClick={() => setConfirm(true)}
            sx={{
              position: 'absolute',
              ...(!projectEdit && editable !== false
                ? { backgroundImage: `url(${Close})` }
                : {}),
              left: '5rem',
              top: '0',
            }}
          />
      </Box>
      <Confirm
        open={confirm}
        handleClose={handleModalClose}
        buttonText={'Delete'}
        mainHeading={'Delete this photo?'}
        secondaryHeading={'This photo will be permanently deleted.'}
      />
      <MediaPreview
        file={file}
        open={preview}
        type={type}
        mimeType={mimeType!}
        handleClose={() => setPreview(false)}
      />
    </>
  );
};

export default Media;
