import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import { useState } from 'react';
import { ProjectSectionProjectType } from '../../../types/types';
import Section from "../Section";

type Props = {
  section: any;
  setProjectData: (projectData: {
    name: string;
    sections: ProjectSectionProjectType[];
  }) => void;
  projectData: any;
  updateFiles: (file: File[], action: string, pos?: string) => void;
  deleteSection: (confirm: number) => void;
  pos: number;
  callFrom?: string;
  editable?: boolean;
  projectEdit?: string;
  setExpanded?:(index:number | false) =>void;
  expanded?:number | boolean; 
};

const SectionEditAccordian = ({
  section,
  setProjectData,
  projectData,
  updateFiles,
  deleteSection,
  pos,
  callFrom,
  editable,
  projectEdit,
  setExpanded,
  expanded
}: Props) => {

  const handleChange = (panel: number) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded && setExpanded(isExpanded ? panel : false);
  };
  const updateSectionsData = (
    pos: number,
    section: ProjectSectionProjectType
  ) => {
    let updatePosArray = projectData.sections;
    updatePosArray[pos] = section;
    // setSections({ ...sections, attributes: updatePosArray });
    setProjectData({
      ...projectData,
      sections: updatePosArray,
    });
  };
  return (
    <Box
      sx={{
        m: '2rem',
        border: '1px solid #B8BABE',
        borderRadius: '0.25rem',
      }}
      key={pos}
    >
      <Accordion
        expanded={expanded === pos}
        onChange={handleChange(pos)}
      >
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography
              variant="h-3"
              display={expanded === pos ? 'none' : 'block'}
              // visibility={expanded === 'panel1' ? 'hidden' : 'visible'}
            >
              {section.title ? section.title : 'Section Name'}
            </Typography>
            {editable && (
              <Box>
                <Button
                  variant="text"
                  sx={{
                    color: 'red',
                    padding: 0,
                    minWidth: '3.125rem',
                    mr: '2rem',
                  }}
                  onClick={(e)=>deleteSection(pos)}
                >
                  Delete
                </Button>
                <Button
                  variant="text"
                  sx={{ padding: 0, minWidth: '3.125rem', mr: '1.5rem' }}
                >
                  {expanded === pos ? 'Close' : 'Edit'}
                </Button>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Section
              sectionData={section}
              updateSectionsData={updateSectionsData}
              key={section.key}
              pos={pos}
              deleteSection={deleteSection}
              updateFiles={updateFiles}
              callFrom={callFrom}
              editable={editable}
              projectEdit={projectEdit}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SectionEditAccordian;
