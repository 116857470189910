import { Box } from '@mui/system';
import TextInput from '../../components/Input/TextInput/TextInput';
import { Button, Typography } from '@mui/material';
import loginBackground from '../../assets/png/LoginBackground.png';
import SPLogoSmall from '../../assets/png/SPLogoSmall.png';
import { useEffect, useState } from 'react';
import './index.css';
import { flow } from '../../utils/StateStore';
import { resendOTP, verifyFlow } from '../../api/flow';
import { useNavigate } from 'react-router';
import { updateAlert } from '../../utils/utils';

const OTP = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [counter, setCounter] = useState(29);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (otp === '') {
      return;
    }
    const verifiedFlow = await verifyFlow({
      flow_type: flow?.value?.flowType!,
      otp,
      flow_id: flow?.value?.flowId!,
      user_id: flow?.value?.userId!,
    });

    if (!verifiedFlow) {
      return;
    }
    if (verifiedFlow === 'Attempt Exceeded') {
      navigate('/login');
      return;
    }
    navigate('/login/updatepassword'); //[TODO] this navigate to should come from const or from the previous screen
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
  };

  const [flowInfo, setFlowInfo] = useState({ email: '', phone: '' });
  useEffect(() => {
    setFlowInfo({ email: flow?.value?.email!, phone: flow?.value?.phone! });
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [counter]);

  const handleResentButton = async () => {
    const otpSent = await resendOTP({
      flow_id: flow?.value?.flowId!,
      flow_type: flow?.value?.flowType!,
      user_id: flow?.value?.userId!,
    });
    if (!otpSent) {
      return false;
    }
    updateAlert({
      message: 'OTP successfully sent',
      severity: 'success',
      state: true,
    });
  };
  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        backgroundImage: `url(${loginBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box className="LoginRoot">
        <Box className="InputContainer" borderRadius="0.5rem">
          <Box ml="2rem" mb="2rem" display="flex" alignItems="center">
            <Box
              mr="0.5rem"
              height="1.5rem"
              width="1.5rem"
              sx={{
                backgroundImage: `url(${SPLogoSmall})`,
                backgroundRepeat: 'no-repeat',
                objectFit: 'contain',
                backgroundSize: 'cover',
              }}
            />
            <Box>
              <Typography
                fontWeight="800"
                fontSize="1.125rem"
                lineHeight="1.25rem"
                fontStyle="normal"
              >
                Workspace
              </Typography>
            </Box>
          </Box>
          <Box ml="2rem" mb="2rem">
            <Typography
              fontWeight="700"
              fontSize="1.5rem"
              lineHeight="2rem"
              fontStyle="normal"
            >
              Verify OTP
            </Typography>
          </Box>
          <Box ml="2rem" mb="2rem">
            <Typography
              fontWeight="400"
              fontSize="0.875rem"
              lineHeight="1.25rem"
              fontStyle="normal"
            >
              Enter the OTP sent to {<b>{flowInfo.email}</b>} and{<br />}
              {<b>{flowInfo.phone}</b>}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box ml="2rem">
              <Typography
                fontStyle="normal"
                fontWeight="400"
                fontSize="0.75rem"
                lineHeight="1rem"
              >
                Enter OTP
              </Typography>
              <TextInput
                value={otp}
                onChange={onChange}
                name={'otp'}
                placeholder={'otp'}
              />
            </Box>

            <Button
              className="LoginButton"
              variant="contained"
              type="submit"
              color="primary" //[TODO] Overwrite existing theme and update as per project requirement
              style={{ marginLeft: '2rem', marginTop: '3.5rem' }}
            >
              <Typography
                fontStyle="normal"
                fontWeight="bold"
                fontSize="1rem"
                lineHeight="1.5rem"
              >
                Continue
              </Typography>
            </Button>
          </form>
          <Box
            mt="2.125rem"
            ml="2rem"
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Typography
              color="#80848B"
              fontWeight="400"
              fontSize="0.875rem"
              lineHeight="1.25rem"
              fontStyle="normal"
            >
              Not received your OTP?
            </Typography>
            <Button
              variant="text"
              onClick={handleResentButton}
              disabled={counter !== 0}
            >
              <Typography
                // color={counter === 0 ? '#0451C4' : 'grey'}
                fontWeight="700"
                fontSize="1rem"
                lineHeight="1.5rem"
                fontStyle="normal"
              >
                Resend OTP
                <span style={{ color: 'green' }}>
                  {counter !== 0 ? <span> in {counter} </span> : <></>}
                </span>
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OTP;
