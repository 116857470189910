import MultipleDropDownNode from './MultipleDropDownNode';
import { Box } from '@mui/material';
import { MultiNodeI } from './MultipleDropDown';

type Props = {
  structure: string[];
  previewData: any;
  currLevel: number;
  formSublevelData: any;
  updateSingleSrcObj: (updatedObj: MultiNodeI, pathArr: string[]) => void;
  currPath: string[];
  checkboxRequired: boolean;
  singleSelected: string;
  updateSingleSelected: (id: string) => void;
  parentLabel: string;
  updateParentLabel: (value: string) => void;
};

const MultipleDropDownRoot = ({
  structure,
  previewData,
  currLevel,
  formSublevelData,
  updateSingleSrcObj,
  currPath,
  checkboxRequired,
  singleSelected,
  updateSingleSelected,
  parentLabel,
  updateParentLabel,
}: Props) => {
  const getFormSubLevelData = (id: string) => {
    if (formSublevelData) {
      const data = formSublevelData.filter(
        (formSublevel: any) => formSublevel.sublevel_id === id
      );
      return { ...data[0] };
    } else
      return {
        sublevel_id: '',
        checked: false,
        child: [],
        all_child: false,
      };
  };

  return (
    <Box ml="2rem">
      {previewData &&
        previewData.length > 0 &&
        previewData.map((node: any, index: number) => (
          <Box key={index}>
            <MultipleDropDownNode
              structure={structure}
              node={node}
              currLevel={currLevel}
              formSublevelData={getFormSubLevelData(
                node._id ? node._id : node.sublevel_id
              )}
              updateSingleSrcObj={updateSingleSrcObj}
              currPath={currPath.concat(node.sublevel_id)}
              checkboxRequired={checkboxRequired}
              singleSelected={singleSelected}
              updateSingleSelected={updateSingleSelected}
              parentLabel={parentLabel.concat(node.sublevel_name)}
              updateParentLabel={updateParentLabel}
            />
          </Box>
        ))}
    </Box>
  );
};

export default MultipleDropDownRoot;
