import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import { useNavigate, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { getSingleEntityFilledFormData } from '../../api/form_data';

type Props = {};

const FormDataPreview = (props: Props) => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { formId, entityId, orgId, sublevels, formName } = state
    ? {
        entityId: state.entityId ? state.entityId : '',
        formId: state.formId ? state.formId : '',
        orgId: state.orgId ? state.orgId : '',
        sublevels: state.sublevels ? state.sublevels : [],
        formName: state.formName ? state.formName : '',
      }
    : { entityId: '', formId: '', orgId: '', sublevels: [], formName: '' };
  const backClickHandler = () => {
    navigate(-1);
  };
  const [formData, setFormData] = useState<{
    categories: [];
    entityId: string;
    formId: string;
    id: string;
    organizationId: string;
  }>({
    id: '',
    categories: [],
    entityId: '',
    formId: '',
    organizationId: '',
  });
  useEffect(() => {
    async function getFormData() {
      const resp = await getSingleEntityFilledFormData(
        formId,
        entityId,
        orgId,
        sublevels
      );
      setFormData(resp);
    }
    getFormData();
  }, []);

  return (
    <Box style={{ height: '100%' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        m="2rem"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            onClick={backClickHandler}
            style={{ paddingRight: '8px', cursor: 'pointer' }}
            src={ArrowLeft}
            alt="left arrow"
          />
          <Typography variant="h-1">{formName || 'Form'}</Typography>
        </div>
      </Box>
      <Box marginLeft="2rem">
        {formData &&
          formData.categories.length &&
          formData.categories.map((cat: any) => (
            <Box>
              <Typography variant="h-2" marginBottom="1rem">
                {cat.title}
              </Typography>
              <Box marginBottom="2rem">
                {cat.fields.map((field: any) => (
                  <Box>
                    {field.label.map((lab: any) => (
                      <Box marginBottom="1rem">
                        <Box>
                          <Typography variant="body-2"> {lab.value}</Typography>
                        </Box>
                        <Box>
                          <Typography variant="body-1">
                            {field.values[0].value}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default FormDataPreview;
