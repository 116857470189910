import { REQUEST_GET, REQUEST_PUT } from '../utils/const';
import { requestAPI } from '../utils/fetch';
import { updateAlert } from '../utils/utils';

export const getTemplateData = async (templateId: string) => {
  try {
    const templateData = await requestAPI({
      type: REQUEST_GET,
      url: `/api/project-template?project_template_id=${templateId}`,
    });
    if (!templateData) {
      return false;
    }
    return templateData.project_template;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const getAllTemplates = async () => {
  try {
    const templateData = await requestAPI({
      type: REQUEST_GET,
      url: `/api/project-templates`,
    });
    if (!templateData) {
      return false;
    }
    return templateData.project_templates;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const updateTemplate = async (projectInfo: any, files: any) => {
  try {
    const response = await requestAPI({
      type: REQUEST_PUT,
      url: '/api/project-template',
      data: projectInfo,
      fileData: files,
      multipartPresent: true,
    });
    if (!response) {
      return false;
    }
    return response;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};
