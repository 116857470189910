// import React from 'react';
import { Box } from '@mui/system';
import TextInput from '../../components/Input/TextInput/TextInput';
import { Button, Typography } from '@mui/material';
import loginBackground from '../../assets/png/LoginBackground.png';
import SPLogoSmall from '../../assets/png/SPLogoSmall.png';
import { useState } from 'react';
import './index.css';
import { updateForgotPassword } from '../../api/authenticate';
import { flow } from '../../utils/StateStore';
import { useNavigate } from 'react-router';

const SetNewPassword = () => {
  const navigate = useNavigate();
  const [passwords, setPasswords] = useState({
    newpassword: '',
    confirmpassword: '',
  });

  //Will take care of all the changes happering in the text input changes
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, [event.target.name]: event.target.value });
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (passwords.newpassword !== passwords.confirmpassword) {
      //[TODO] tell user
      return;
    }
    const updtedPassword = updateForgotPassword({
      flow_id: flow?.value?.flowId!,
      flow_type: flow?.value?.flowType!,
      new_password: passwords.newpassword,
      user_id: flow?.value?.userId!,
    });
    if (!updtedPassword) {
      return;
    }
    flow?.next({ email: '', flowId: '', flowType: '', phone: '', userId: '' });
    navigate('/login/updatepassword/successful');
  };
  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        backgroundImage: `url(${loginBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box className="LoginRoot">
        <Box className="InputContainer" borderRadius="0.5rem">
          <Box ml="2rem" display="flex" alignItems="center">
            <Box
              mr="0.5rem"
              height="1.5rem"
              width="1.5rem"
              sx={{
                backgroundImage: `url(${SPLogoSmall})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Box>
              <Typography
                fontWeight="800"
                fontSize="1.125rem"
                lineHeight="1.25rem"
                fontStyle="normal"
              >
                Workspace
              </Typography>
            </Box>
          </Box>
          <Box ml="2rem" mb="2rem" mt="2rem">
            <Typography
              fontWeight="700"
              fontSize="1.5rem"
              lineHeight="2rem"
              fontStyle="normal"
            >
              Setup a new password
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box ml="2rem">
              <Typography
                fontStyle="normal"
                fontWeight="400"
                fontSize="0.75rem"
                lineHeight="1rem"
              >
                New password
              </Typography>
              <TextInput
                value={passwords.newpassword}
                type={'password'}
                onChange={onChange}
                name={'newpassword'}
                placeholder={'Password'}
              />
            </Box>{' '}
            <Box ml="2rem">
              <Typography
                fontStyle="normal"
                fontWeight="400"
                fontSize="0.75rem"
                lineHeight="1rem"
              >
                Confirm password
              </Typography>
              <TextInput
                value={passwords.confirmpassword}
                type={'password'}
                onChange={onChange}
                name={'confirmpassword'}
                placeholder={'Confirm Password'}
              />
            </Box>
            <Button
              className="LoginButton"
              variant="contained"
              type="submit"
              style={{ marginLeft: '2rem', marginTop: '4rem' }}
            >
              <Typography
                fontStyle="normal"
                fontWeight="bold"
                fontSize="1rem"
                lineHeight="1.5rem"
              >
                Reset password
              </Typography>
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default SetNewPassword;
