import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import MultipleDropDown from '../../pages/common/MultipleDropDown';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import EntityFormCategory, { IFormCategory } from './EntityFormCategory';
import { Autocomplete, Button, TextField } from '@mui/material';
import { createFormData, updateFormData } from '../../api/form_data';
import { entityInfo } from '../../utils/StateStore';
import { OrgData } from '../../types/types';
import { makeStyles } from '@mui/styles';
import { updateSnackbar } from '../../utils/utils';

type Props = {
  open: boolean;
  handleClose: (confirm: boolean) => void;
  formId: string;
  selectedEntityInfo: any; //TODO add proper type to it
  selectedLevels: string[];
  singleSelectedHandler: (id: string, orgSublevelSrc?: any[]) => void;
  isUpdate?: boolean;
  orgInfo: OrgData;
};
const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
    },
  },
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '35%',
  bgcolor: 'white',
  height: '95.51%',
  borderRadius: '8px',
};
const EntityForm = ({
  open,
  handleClose,
  formId,
  selectedEntityInfo,
  singleSelectedHandler,
  selectedLevels, //TODO no need in case of update
  isUpdate = false,
  orgInfo,
}: Props) => {
  const classes = useStyles();
  const [entityPersonalInfo, setEntityPersonalInfo] = useState({
    name: selectedEntityInfo?.first_name
      ? selectedEntityInfo?.first_name + ' ' + selectedEntityInfo?.last_name
      : '',
    code: selectedEntityInfo?.entity_code
      ? selectedEntityInfo?.entity_code
      : '',
    level: selectedLevels ? selectedLevels : '',
    gender: selectedEntityInfo?.gender ? selectedEntityInfo?.gender : 'male',
    formData_id: selectedEntityInfo?.formData_id
      ? selectedEntityInfo?.formData_id
      : '',
    entity_id: selectedEntityInfo?._id ? selectedEntityInfo?._id : '',
  });
  const currentFormInfo = entityInfo?.value?.formInfo.filter(
    (form) => form.id === formId
  )[0];
  const [formData, setFormData] = useState<any>(
    currentFormInfo
      ? currentFormInfo
      : {
          form_id: formId,
          organization_id: orgInfo.id, //Set ID
          entity_id: entityPersonalInfo.entity_id,
          categories: [], //have to change it to common array
          sublevels: selectedLevels, //Verify if its correct
          formData_id: entityPersonalInfo.formData_id,
        }
  );
  const [files, setFiles] = useState<File[]>([]);
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEntityPersonalInfo({
      ...entityPersonalInfo,
      [event.target.name]: event.target.value,
    });
  };

  const [availableEntities, setAvailableEntities] = useState<any[]>([]);

  const updateCategory = (updatedCategoryData: IFormCategory, pos: number) => {
    let updatePosArray = formData.categories;
    if (updatePosArray && updatePosArray.length >= pos) {
      updatePosArray[pos] = updatedCategoryData;
      setFormData({ ...formData, categories: updatePosArray });
    }
  };
  const updateFiles = (file: File[], action: string, name?: string) => {
    if (action === 'add') {
      setFiles([...files, ...file]);
    } else {
      //delete
      setFiles(files.filter((file) => file?.name !== name));
    }
  };
  const handleEntity = (e: any, value: any) => {
    setEntityPersonalInfo({
      ...entityPersonalInfo,
      name: value ? value.first_name + ' ' + value.last_name : '',
      code: value ? value.entity_code : '',
      entity_id: value ? value.id : '',
    });
  };
  const handleSubmit = () => {
    if (isUpdate) {
      const updatedData = updateFormData({
        form_id: formId,
        organization_id: orgInfo.id, //Set ID
        entity_id: entityPersonalInfo.entity_id,
        categories: formData.categories, //have to change it to common array
        sublevels: selectedLevels, //Verify if its correct
        formData_id: entityPersonalInfo.formData_id,
      });
      if (!updatedData) {
        console.error('Form Data not updated');
      }
    } else {
      const createdData = createFormData({
        form_id: formId,
        organization_id: orgInfo.id, //Set ID
        categories: formData.categories, //have to change it to common array
        sublevels: selectedLevels, //Verify if its correct
        formData_id: entityPersonalInfo.formData_id, //Set ID
        entity_id: entityPersonalInfo.entity_id
          ? entityPersonalInfo.entity_id
          : '',
      });
      if (!createdData) {
        console.error('Form Data not Created');
      }
    }
    handleClose(false);
  };
  const handleGenderChange = (e: any) => {
    setEntityPersonalInfo({ ...entityPersonalInfo, gender: e.target.value });
  };
  useEffect(() => {
    async function getFormInfo() {
      const formData = entityInfo?.value?.formInfo.filter(
        (form) => form.id === formId
      );
      //update forms with values
      setFormData(formData ? { ...formData[0] } : {});
    }
    getFormInfo();
  }, [formId]);
  useEffect(() => {
    const existingEntities = entityInfo?.value?.availableEntities || [];
    const tempEntities = existingEntities.filter(
      (entity) =>
        JSON.stringify(entity.sublevel_ids) === JSON.stringify(selectedLevels)
    );
    setAvailableEntities(tempEntities);
  }, [selectedLevels]);

  //[TODO] Currently option and other data is saved twice in formdata table as well
  //Remove it from there
  // const updateFormDataValues = () => {
  //   const categories = selectedEntityInfo.categories
  //     ? selectedEntityInfo.categories
  //     : [];
  //   if (categories.length) {
  //     const updateCategories = formData.categories.map((category: any) => {
  //       const updatedFieldCategories = categories.map((cat: any) => {
  //         if (cat.id === category.id) {
  //           const updatedField = category.fields.map((field: any) => {
  //             cat.fields.map((valueField: any) => {
  //               if (valueField.key === field.key) {
  //                 return { ...field, values: valueField.values };
  //               }
  //             });
  //           });

  //           return updatedField;
  //         }
  //       });
  //       return updatedFieldCategories;
  //     });
  //     return { ...formData, categories: updateCategories };
  //   } else {
  //     return formData;
  //   }
  // };
  useEffect(() => {
    setEntityPersonalInfo({
      name: selectedEntityInfo?.first_name
        ? selectedEntityInfo?.first_name + ' ' + selectedEntityInfo?.last_name
        : '',
      code: selectedEntityInfo?.entity_code
        ? selectedEntityInfo?.entity_code
        : '',
      level: selectedLevels ? selectedLevels : '',
      gender: selectedEntityInfo?.gender ? selectedEntityInfo?.gender : 'male',
      formData_id: selectedEntityInfo?.formData_id
        ? selectedEntityInfo?.formData_id
        : '',
      entity_id: selectedEntityInfo?._id ? selectedEntityInfo?._id : '',
    });
    setFormData({
      ...formData,
      categories:
        selectedEntityInfo.categories && selectedEntityInfo.categories.length
          ? selectedEntityInfo.categories
          : formData.categories,
    });
  }, [selectedEntityInfo]);
  return (
    <Modal
      open={open}
      onClose={() => {
        setEntityPersonalInfo({
          name: '',
          code: '',
          level: '',
          gender: 'male',
          entity_id: '',
          formData_id: '',
        });
        handleClose(false);
      }}
    >
      <Box sx={style}>
        <Box margin="2rem" height="100%">
          <Box>
            <Typography variant="h-1">Entity Card</Typography>
          </Box>
          <Box marginTop="2.5rem" sx={{ height: '78%', overflowY: 'auto' }}>
            <Typography variant="body-2" color="#525862">
              PERSONAL INFORMATION
            </Typography>

            <Box
              display="grid"
              gridTemplateColumns="auto auto"
              marginTop="1.5rem"
              columnGap="1rem"
              rowGap="1rem"
            >
              <Box display="flex" flexDirection="column" justifyContent="left">
                <Typography variant="Caption">Student name</Typography>

                <Autocomplete
                  id="free-solo-entity"
                  classes={classes}
                  freeSolo
                  options={availableEntities || []}
                  onChange={(event, value) => handleEntity(event, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        padding: 0,
                        height: '1rem',
                        '& .MuiOutlinedInput-root': {
                          paddingRight: '10px!important',
                        },
                      }}
                    />
                  )}
                  getOptionLabel={(option: any) =>
                    option.first_name + ' ' + option.last_name
                  }
                />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="left">
                <Typography variant="Caption">Entity code</Typography>
                <TextField
                  onChange={handleTextFieldChange}
                  name="code"
                  placeholder="code"
                  value={entityPersonalInfo.code}
                  disabled={true}
                  sx={{ height: '40px' }}
                />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="left">
                <Typography variant="Caption">School</Typography>
                <TextField
                  name="orgName"
                  disabled={true}
                  onChange={handleTextFieldChange}
                  placeholder="Org"
                  value={entityInfo?.value?.orgInfo.name || ''}
                  sx={{ height: '40px' }}
                />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="left">
                <Typography variant="Caption">Select class</Typography>
                <MultipleDropDown
                  checkBoxRequired={false}
                  singleSelectedParent={
                    selectedLevels.length
                      ? selectedLevels[selectedLevels.length - 1]
                      : ''
                  }
                  singleSelectedHandler={singleSelectedHandler}
                  disabled={true}
                />
              </Box>
            </Box>
            <Box marginTop="2.25rem" marginBottom="2rem">
              <Typography variant="body-2" color="#525862">
                Gender
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={entityPersonalInfo.gender}
                onChange={(e) => handleGenderChange(e)}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </Box>
            <Divider />
            <Box marginTop="2rem">
              <Box>
                <Typography>ENTITY DESCRIPTION</Typography>
              </Box>
              <Box>
                {formData &&
                  formData.categories &&
                  formData.categories.map(
                    (category: IFormCategory, pos: number) => (
                      <EntityFormCategory
                        category={category}
                        updateCategory={updateCategory}
                        updateFiles={updateFiles}
                        pos={pos}
                      />
                    )
                  )}
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box
            margin="1rem"
            display="flex"
            alignItems="flex-end"
            justifyContent="end"
          >
            <Button variant="text" onClick={() => handleClose(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={entityPersonalInfo.entity_id === ''}
              onClick={() => {
                if (entityPersonalInfo.entity_id === '') {
                  updateSnackbar({
                    state: true,
                    severity: 'warning',
                    message: 'Please select Student first',
                  });
                } else {
                  handleSubmit();
                }
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EntityForm;
