import { Box } from '@mui/system';
import TextInput from '../../components/Input/TextInput/TextInput';
import { Button, Typography } from '@mui/material';
import loginBackground from '../../assets/png/LoginBackground.png';
import SPLogoSmall from '../../assets/png/SPLogoSmall.png';
import { useState } from 'react';
import './index.css';
import { createFlow } from '../../api/flow';
import { useNavigate } from 'react-router';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email === '') {
      return;
    }

    //1. Create a flow object in store and update it
    //2. transfer to otp screen
    const createdFlow = await createFlow({
      email,
      flow_type: 'forgotpassword',
    });
    if (!createdFlow) {
      return;
    }
    navigate('/otp');
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        backgroundImage: `url(${loginBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box className="LoginRoot">
        <Box className="InputContainer" borderRadius="0.5rem">
          <Box ml="2rem" display="flex" alignItems="center">
            <Box
              mr="0.5rem"
              height="1.5rem"
              width="1.5rem"
              sx={{
                backgroundImage: `url(${SPLogoSmall})`,
                backgroundRepeat: 'no-repeat',
                objectFit: 'contain',
                backgroundSize: 'cover',
              }}
            />
            <Box>
              <Typography
                fontWeight="800"
                fontSize="1.125rem"
                lineHeight="1.25rem"
                fontStyle="normal"
              >
                Workspace
              </Typography>
            </Box>
          </Box>
          <Box ml="2rem" mb="2rem" mt="2rem">
            <Typography
              fontWeight="700"
              fontSize="1.5rem"
              lineHeight="2rem"
              fontStyle="normal"
            >
              Forgot password?
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box ml="2rem">
              <Typography
                fontStyle="normal"
                fontWeight="400"
                fontSize="0.75rem"
                lineHeight="1rem"
              >
                Email
              </Typography>
              <TextInput
                value={email}
                onChange={onChange}
                name={'email'}
                placeholder={'Email'}
              />
            </Box>

            <Button
              className="LoginButton"
              variant="contained"
              type="submit"
              color="primary" //[TODO] Overwrite existing theme and update as per project requirement
              style={{ marginLeft: '2rem', marginTop: '3.5rem' }}
            >
              <Typography
                fontStyle="normal"
                fontWeight="bold"
                fontSize="1rem"
                lineHeight="1.5rem"
              >
                Continue
              </Typography>
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
