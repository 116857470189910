import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Alert,
  Box,
  Dialog,
  Grid,
  InputLabel,
  Link as Navigation,
  Snackbar,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import './Styles.css';
import { UserInfo } from '../../types/types';
import { requestAPI } from '../../utils/fetch';
import { REQUEST_GET, REQUEST_POST } from '../../utils/const';
import DropdownClosed from '../../assets/png/DropdownClosed.png';
import { selectedLeftNavigationField, token } from '../../utils/StateStore';
import CreateRole from '../Role/CreateRole';
import {
  getPermissionForPopup,
  refreshTokenAndPermission,
  updateAlert,
  updateSnackbar,
} from '../../utils/utils';
import SubmitButton from '../common/SubmitButton';
import Error from '../../assets/png/Error.png';
import { setUserFirstLoggedIn } from '../CreateTemplate/FirstLoginModal';

function CreateUser(props: { fromFirstTimeTemplate?: boolean }) {
  const navigate = useNavigate();
  interface UserAllInfo extends Omit<UserInfo, 'id' | 'roles' | 'permissions'> {
    password: string;
  }
  const [userOpen, setUserOpen] = useState(true);
  const [userInfo, setUserInfo] = useState<UserAllInfo>({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    phone: '',
  });

  const [roles, setRoles] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<string[]>([]);
  const changeUserInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };
  const [roleOpen, setRoleOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [currentUser, setCurrentUser] = useState<{ id?: string } | null>(null);
  const [roleCreatePerm, setRoleCreatePerm] = useState(false);

  // const userPermissions = token?.value?.userInfo?.permissions;
  useEffect(() => {
    (async () => {
      try {
        const hasRoleCreatePerm = await getPermissionForPopup('roles', 'c');
        setRoleCreatePerm(hasRoleCreatePerm);
        const hasPerm = await getPermissionForPopup('Users', 'c');
        if (!hasPerm) {
          updateAlert({
            message: 'You are not authorized to create users',
            severity: 'warning',
            state: true,
          });
          navigate(-1);
        } else {
          const response = await requestAPI({
            type: REQUEST_GET,
            url: '/api/roles',
          });
          let responseRoles: string[] = [];
          //TODO: handling code acc to response status
          if (response) {
            response.forEach((role: any) => {
              responseRoles.push(role.name);
            });
            setRoles(response);
          } else {
            console.error(response);
          }
        }
      } catch (err) {
        console.error(err);
        setSnackbarOpen(true);
        setOpenAlert(true);
        setAlertMsg('Error: roles not fetched');
      }
    })();
    const subscribedToken = token?.subscribe((x) => {
      if (x?.userInfo) {
        setCurrentUser(x.userInfo);
      }
    });
    return () => {
      subscribedToken?.unsubscribe();
    };
  }, [userOpen]);

  const roleArr: any = [];
  const roleChangeHandler = (e: any, value: any) => {
    value.forEach((element: any) => {
      roleArr.push(element.id);
    });
    setSelectedRole(roleArr);
  };

  const submitHandler = async () => {
    try {
      if (!validateEmail(userInfo.email)) {
        updateSnackbar({
          state: true,
          severity: 'error',
          message: 'Please enter a valid email id',
        });
      } else {
        const reqObj = {
          email: userInfo.email,
          password: userInfo.password,
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          phone: userInfo.phone,
          role_ids: selectedRole,
        };
        const response = await requestAPI({
          type: REQUEST_POST,
          url: '/api/user-assign-roles',
          data: reqObj,
        });
        //TODO: handling code acc to response status
        if (response) {
          setSnackbarOpen(true);
          navigate('/listUsers');
          if (props?.fromFirstTimeTemplate && currentUser?.id) {
            setUserFirstLoggedIn({ userId: currentUser?.id });
          }
          updateSnackbar({
            state: true,
            severity: 'success',
            message: 'User added',
          });
        } else {
          console.error(response);
        }
      }
    } catch (e: any) {
      if (e.code && e.code === 403) {
        const ref = await refreshTokenAndPermission();
        console.log('Refresh' + ref ? ' Done' : ' Not done');
      } else {
        console.error(e);
        setOpenAlert(true);
        setSnackbarOpen(true);
        setAlertMsg(e?.message);
      }
    }
  };

  const createRoleHandler = () => {
    setUserOpen(false);
    setRoleOpen(true);
  };
  const dialogCloseHandler = () => {
    setUserOpen(false);
    selectedLeftNavigationField.next('Overview');
    navigate(-1);
  };
  const handleUserFromRole = (flag: boolean) => {
    setUserOpen(flag);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            width: '536px',
            borderRadius: '8px',
            height: '560px',
            padding: '32px',
          },
        }}
        onClose={dialogCloseHandler}
        open={userOpen}
      >
        <Box sx={{ height: '100%' }}>
          {openAlert && (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarOpen}
              onClose={handleClose}
              sx={{ paddingTop: '4px' }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{
                  width: '536px',
                  padding: ' 0px 12px 0px 9.63px',
                  height: '28px',
                  alignItems: 'center',
                }}
                icon={<img src={Error} alt="error" />}
              >
                {alertMsg}
              </Alert>
            </Snackbar>
          )}
          <Grid sx={{ paddingBottom: '32px' }} item>
            <Typography
              variant="h-1"
              style={{
                fontFamily: 'Mulish',
                lineHeight: '32px',
                fontWeight: '700',
                fontSize: '24px',
                color: '#000A18',
              }}
            >
              Create new user
            </Typography>
          </Grid>
          <Grid
            sx={{ height: '100%' }}
            container
            overflow="auto"
            maxHeight={'75%'}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  First name
                </InputLabel>
                <TextField
                  placeholder="Please enter first name"
                  name="first_name"
                  size="small"
                  value={userInfo.first_name}
                  onChange={changeUserInfo}
                  sx={{ width: '256px' }}
                />
              </Grid>
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Last name
                </InputLabel>
                <TextField
                  name="last_name"
                  size="small"
                  placeholder="Please enter last name"
                  value={userInfo.last_name}
                  onChange={changeUserInfo}
                  sx={{ width: '256px' }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Email
                </InputLabel>
                <TextField
                  placeholder="Please enter emailid"
                  name="email"
                  size="small"
                  value={userInfo.email}
                  onChange={changeUserInfo}
                  sx={{ width: '256px' }}
                />
              </Grid>
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Password
                </InputLabel>
                <TextField
                  size="small"
                  type="password"
                  placeholder="Please enter password"
                  name="password"
                  value={userInfo.password}
                  onChange={changeUserInfo}
                  sx={{ width: '256px' }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Phone no.
                </InputLabel>
                <TextField
                  name="phone"
                  size="small"
                  type="tel"
                  placeholder="Please enter phone number"
                  value={userInfo.phone}
                  onChange={changeUserInfo}
                  sx={{ width: '256px' }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Assign role/Roles
                </InputLabel>
                <Autocomplete
                  sx={{ width: '100%' }}
                  popupIcon={<img src={DropdownClosed} alt="Dropdown closed" />}
                  multiple
                  size="small"
                  id="tags-outlined"
                  options={roles}
                  getOptionLabel={(option: any) => option.name}
                  filterSelectedOptions
                  onChange={(event, value) => roleChangeHandler(event, value)}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </Grid>
            </Grid>

            {roleCreatePerm && (
              <Grid container>
                <Grid item xs={12}>
                  <Navigation
                    underline="none"
                    sx={{
                      color: '#0451C4',
                      fontWeight: '700',
                      fontSize: '16px',
                      lineHeight: '24px',
                      cursor: 'pointer',
                    }}
                    onClick={createRoleHandler}
                  >
                    Create new role
                  </Navigation>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            paddingTop={'32px'}
          >
            <Grid item>
              <Navigation
                style={{
                  padding: '0px 32px',
                  textDecoration: 'none',
                  color: '#525862',
                  fontWeight: '700',
                  fontFamily: 'Mulish',
                  cursor: 'pointer',
                }}
                onClick={dialogCloseHandler}
              >
                Cancel
              </Navigation>
              <SubmitButton
                onClick={submitHandler}
                disabled={
                  !(
                    userInfo.first_name &&
                    userInfo.last_name &&
                    userInfo.email &&
                    userInfo.password &&
                    userInfo.phone
                  )
                }
                text="Create User"
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      {roleCreatePerm && (
        <CreateRole
          open={roleOpen}
          fromUser
          setOpen={setRoleOpen}
          handleUserFromRole={handleUserFromRole}
        />
      )}
    </>
  );
}

export default CreateUser;
