import { BehaviorSubject } from 'rxjs';
import {
  FlowData,
  userData,
  AlertData,
  EntityInfo,
  ProjectTypeInfo,
} from '../types/types';

const token = new BehaviorSubject<userData | null>({
  accessToken: undefined,
  pwdExpiry: null,
  userInfo: undefined,
});

const flow = new BehaviorSubject<FlowData | null>({
  flowId: '',
  flowType: '',
  email: '',
  phone: '',
  userId: '',
});

const alert = new BehaviorSubject<AlertData | null>({
  state: false,
  message: '',
  severity: 'info',
});

const snackbar = new BehaviorSubject<AlertData | null>({
  state: false,
  message: '',
  severity: 'info',
});

const entityInfo = new BehaviorSubject<EntityInfo>({
  orgInfo: {
    id: '',
    name: '',
    alias: '',
    project_id: '',
    attributes: [],
    levels: [],
  },
  formInfo: [],
  orgStructure: { id: '', projectId: '', Sublevel: [], attributes: [] },
  availableEntities: [],
});

const projectTypeInfo = new BehaviorSubject<ProjectTypeInfo[]>([]);

const spinner = new BehaviorSubject<boolean>(false);
const selectedLeftNavigationField = new BehaviorSubject<string>('');

export {
  token,
  alert,
  flow,
  spinner,
  selectedLeftNavigationField,
  snackbar,
  entityInfo,
  projectTypeInfo,
};
