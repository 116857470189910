import { useEffect, useState } from 'react';
import { getOrganizationHandlerLevels } from '../../api/org_handler';
import MultipleDropDownRoot from './MultipleDropDownRoot';
import { entityInfo, token } from '../../utils/StateStore';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { fetchOrganisationData } from '../../utils/utils';
import { EntityInfo } from '../../types/types';
import { MenuProps } from '@mui/material';
type Props = {
  orgSublevelSrc?: any[];
  orgSublevelSrcHandler?: (val: any[]) => void;
  checkBoxRequired: boolean;
  chipDataParent?: string[];
  chipDataHandler?: (values: string[]) => void;
  singleSelectedParent?: string;
  singleSelectedHandler?: (values: string, orgSublevelSrc?: any[]) => void;
  disabled?: boolean;
  showDisabled?: boolean;
};
const MultipleDropDown = ({
  orgSublevelSrc,
  orgSublevelSrcHandler,
  checkBoxRequired,
  chipDataParent,
  chipDataHandler,
  singleSelectedParent = '',
  singleSelectedHandler,
  disabled = false,
  showDisabled = false,
}: Props) => {
  const [orgSublevelSingleSrc, setOrgSublevelSingleSrc] = useState<any[]>(
    orgSublevelSrc ?? []
  );
  const [singleSelected, setSingleSelected] = useState('');
  const [chipData, setChipData] = useState<string[]>([]);
  const [structure, setStructure] = useState<string[]>(
    () => entityInfo?.value?.orgStructure?.Sublevel
  );

  const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  const MenuProps :Partial<MenuProps>= {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin:{
      vertical:'top',
      horizontal:'left'
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.4
      },
    },
  };

  const levelRecursive = async (
    level: any,
    assignedLevel: any,
    currUserId: string
  ) => {
    let checked = false;
    let disabled = false;
    if (assignedLevel && assignedLevel.child?.length) {
      checked =
        assignedLevel.sublevel_id === level.id && assignedLevel.assigned_user;
      if (checkBoxRequired)
        disabled =
          assignedLevel.assigned_user &&
          assignedLevel.assigned_user !== currUserId;
      else {
        //disable only leaf nodes.
        if (level.parentId !== entityInfo?.value.orgInfo.id) {
          disabled = assignedLevel.assigned_user === currUserId ? false : true;
        }
      }
      if (disabled && !showDisabled) return;
    }
    if (!level.child || level.child?.length <= 0) {
      return {
        sublevel_id: level.id,
        checked,
        child: [],
        all_child: checked,
        id: level.id,
        parent_id: level.parentId,
        sublevel_name: level.value,
        assignedlevel_id: assignedLevel?.id,
        disabled,
      };
    }
    const respLevelObjData = level.child;
    const prRespLevelTempObj: any =
      respLevelObjData &&
      respLevelObjData.length > 0 &&
      respLevelObjData.map(async (lev: any) => {
        const levelData = await levelRecursive(
          lev,
          assignedLevel.child?.filter(
            (assigned: any) => assigned.sublevel_id === level.id
          )[0] || null,
          currUserId
        );
        let checkedParent = false;
        if (assignedLevel && assignedLevel.child?.length) {
          const _assignedLevel = assignedLevel.child.filter(
            (assigned: any) => assigned.sublevel_id === lev.id
          )[0];
          checkedParent = _assignedLevel.assigned_user ? true : false;
          if (checkBoxRequired)
            disabled = _assignedLevel.assigned_user && _assignedLevel.assigned_user !== currUserId
          else {
            //disable only leaf nodes.
            if (lev.parentId !== entityInfo?.value.orgInfo.id) {
              disabled =
                _assignedLevel.assigned_user === currUserId ? false : true;
            }
          }
          if (disabled && !showDisabled) return;
        }
        return {
          sublevel_id: lev.id,
          checked: checkedParent,
          child: levelData.child,
          all_child: checkedParent,
          id: lev.id,
          parent_id: lev.parentId,
          sublevel_name: lev.value,
          assignedlevel_id: assignedLevel?.id,
          disabled,
        };
      });
    const respLevelTempObj = await Promise.all(prRespLevelTempObj);
    const IsparentDisabled = respLevelTempObj.filter((x:any) => !x?.disabled).length ? false : true
    if (IsparentDisabled && !showDisabled)
      return
    const respLevelObj = {
      ...level,
      sublevel_id: level.id,
      checked: checked,
      all_child: checked,
      id: level.id,
      parent_id: level.parentId,
      sublevel_name: level.value,
      child: respLevelTempObj ? respLevelTempObj.filter((x) => x) : [],
      assignedlevel_id: assignedLevel?.id,
      disabled: IsparentDisabled,
    };

    return respLevelObj;
  };
  const getCheckedState = (type: string, sublevelData: any) => {
    let count = 0;
    sublevelData.child.forEach((sublevel: any) => {
      if (sublevel.checked) {
        count++;
      }
    });
    if (sublevelData.child.length === 0 && type === 'parent') {
      return sublevelData.checked;
    }
    return type === 'parent'
      ? count === sublevelData.child.length
      : count > 0 && count < sublevelData.child.length;
  };
  const levelChipRecursive = async (level: any, label: string) => {
    if (!level.child || level.child.length <= 0) {
      if (!checkBoxRequired && singleSelected !== level.sublevel_id) {
        return null;
      }
      if (level.disabled) return null;
      const parent = getCheckedState('parent', level);
      const child = getCheckedState('child', level);
      if (
        parent ||
        child ||
        (!checkBoxRequired && singleSelected === level.sublevel_id)
      ) {
        return label.concat(' ', level.sublevel_name);
      }
      return null;
    }
    const respLevelObjData = level.child;
    let updatedChip = '';
    const prRespLevelTempObj: any =
      respLevelObjData &&
      respLevelObjData.length > 0 &&
      respLevelObjData.map(async (lev: any) => {
        const levelData = await levelChipRecursive(
          lev,
          label.concat(level.sublevel_name)
        );
        return levelData;
      });
    const respLevelTempObj = await Promise.all(prRespLevelTempObj);
    respLevelTempObj.forEach((temp: any) => {
      if (temp) {
        const chip = updatedChip.concat(temp + ',');
        updatedChip = chip;
      }
    });
    return updatedChip;
  };

  const updateAllChildCheck = (node: MultiNodeI[], updatedCheck: boolean) => {
    const updatedNode = node.map((childNode) => {
      let upadtedChilds: MultiNodeI[] = [];
      if (childNode.child && childNode.child.length > 0) {
        upadtedChilds = updateAllChildCheck(childNode.child, updatedCheck);
      }
      return {
        ...childNode,
        checked: updatedCheck,
        all_child: updatedCheck,
        child: upadtedChilds ? upadtedChilds : [],
      };
    });
    return updatedNode;
  };

  const updateParentCheck = (currNode: MultiNodeI) => {
    let childCheckedLen = 0;
    currNode &&
      currNode.child.forEach((node: MultiNodeI) => {
        if (node.checked) {
          childCheckedLen++;
        }
      });
    if (childCheckedLen === currNode.child.length) {
      currNode.checked = true;
      currNode.all_child = true;
    } else {
      currNode.checked = false;
      currNode.all_child = false;
    }
    return currNode;
  };
  const updateFormSublevelObj = (
    currNode: MultiNodeI,
    updatedObj: MultiNodeI,
    pathArr: string[],
    currLevel: number
  ) => {
    const updatedFormSublevelObj = currNode.child.map(
      (sublevel: MultiNodeI) => {
        if (
          (sublevel.sublevel_id === pathArr[currLevel] &&
            pathArr.length - 1 === currLevel) ||
          pathArr.length === 1
        ) {
          // leave as it is if the sublevel is disabled means assigned to another entity
          if (sublevel.disabled) return sublevel;
          //0. when reached parent then verify child checks and update its own value then and there

          //1. update check of the current
          sublevel.checked = updatedObj.checked;
          //2. update check of all the child
          const updatedChild = updateAllChildCheck(
            sublevel.child,
            updatedObj.checked
          );
          //return fully updated Obj
          // simply loop and

          sublevel.child = updatedChild;
          return sublevel;
        } else if (
          sublevel.sublevel_id === pathArr[currLevel] &&
          pathArr.length - 2 >= currLevel
        ) {
          const updatedValue = updateFormSublevelObj(
            sublevel,
            updatedObj,
            pathArr,
            currLevel + 1
          );
          const updatedCurrNode = updateParentCheck(updatedValue);
          return updatedCurrNode;
        } else {
          return sublevel;
        }
      }
    );
    const updateCurrNode: MultiNodeI = {
      ...currNode,
      child: updatedFormSublevelObj,
    };
    return updateCurrNode;
  };

  const updateSingleSrcObj = (updatedObj: MultiNodeI, pathArr: string[]) => {
    //trace to the path and do 3 things

    let currLevel = 0;
    const updatedFormSublevelObj = orgSublevelSingleSrc.map(
      (sublevel: MultiNodeI) => {
        if (sublevel.sublevel_id === pathArr[currLevel]) {
          const updatedValue = updateFormSublevelObj(
            sublevel,
            updatedObj,
            pathArr,
            currLevel + 1
          );

          const updatedCurrNode = updateParentCheck(updatedValue);
          return updatedCurrNode;
        } else return sublevel;
      }
    );
    if (orgSublevelSrcHandler)
      orgSublevelSrcHandler(JSON.parse(JSON.stringify(updatedFormSublevelObj)));
    setOrgSublevelSingleSrc(JSON.parse(JSON.stringify(updatedFormSublevelObj)));
  };

  const handleChange = (event: SelectChangeEvent<typeof chipData>) => {
    const {
      target: { value },
    } = event;
    if (chipDataHandler)
      chipDataHandler(typeof value === 'string' ? value.split(',') : value);
    else
      setChipData(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
  };

  useEffect(() => {
    async function updateOrgSublevelObj() {
      let _entityInfo: EntityInfo = entityInfo?.value;
      //checks for entity's org info in the store and call api if not present
      if (!_entityInfo.orgInfo.id) {
        const entityInfo = await fetchOrganisationData();
        if (entityInfo) {
          _entityInfo = entityInfo;
        }
      }
      const org = _entityInfo.orgInfo;
      // Set Structure array
      setStructure(_entityInfo.orgStructure?.Sublevel);
      //when edit profile we need to get org handler sublevel data
      //TODO get orgLevel info from store itself
      const assignedLevel = await getOrganizationHandlerLevels(org.id);
      if (org) {
        //in add a entity we need to org handler sublevel data but rest can be avoided
        const orgLevels: any[] = org.levels;
        //here add checked value if assigned level is present
        const dataObj = orgLevels?.map((org) =>
          levelRecursive(
            org,
            assignedLevel && assignedLevel.child && assignedLevel.child.length
              ? assignedLevel.child.filter(
                  (assigned: any) => assigned.sublevel_id === org.id
                )[0] || []
              : [],
            token?.value?.userInfo?.id || ''
          )
        );

        let data = await Promise.all(dataObj);
        if (data.length !== orgLevels.length) {
          console.error('not all org levels are fetched');
        }
        if (!showDisabled) {
          data = data.filter((level:any) => level.child.length)
        }
        setOrgSublevelSingleSrc(data);
      }
    }

    if (!(orgSublevelSrc && orgSublevelSrc?.length)) updateOrgSublevelObj();
  }, []);
  useEffect(() => {
    setSingleSelected(singleSelectedParent);
  }, [singleSelectedParent]);

  useEffect(() => {
    async function updateChipData() {
      const values = orgSublevelSingleSrc.map((level) =>
        levelChipRecursive(level, '')
      );
      let chipValue: string[] = [];
      const allValues = await Promise.all(values);
      const updatedValues = allValues.filter((temp) => !!temp);
      updatedValues.forEach((test: any) => {
        const temp = test.split(',');
        const tempObj = temp.filter((tes: any) => !!tes);
        tempObj.map((tes: any) => chipValue.push(tes));
      });

      // setChipData(chipValue);
      if (chipDataHandler) chipDataHandler(chipValue);
      setChipData(chipValue);
    }
    updateChipData();
    if (singleSelectedHandler) {
      singleSelectedHandler(
        singleSelected === '' && singleSelectedParent !== ''
          ? singleSelectedParent
          : singleSelected,
        orgSublevelSingleSrc
      );
    }
  }, [orgSublevelSingleSrc, singleSelected]);

  const handleDelete = (chipData: string) => {
    try {
      const levelData = chipData.split(' ');
      if (levelData.length !== structure?.length)
        throw "Levels doesn't match with structure";

      let array = orgSublevelSingleSrc;
      let data = null;
      for (let i = 0; i < levelData.length; i++) {
        let levelLabel = levelData[i];
        data = array.find((x) => x.sublevel_name === levelLabel);
        if (!data) throw 'Unable to find the selected chipdata in data source';
        data.checked = data.all_child = false;
        array = data.child;
      }
      data.checked = false;
      if (orgSublevelSrcHandler)
        orgSublevelSrcHandler([...orgSublevelSingleSrc]);
      setOrgSublevelSingleSrc([...orgSublevelSingleSrc]);
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <Box  sx={{Width: '-webkit-fill-available'}}> 
      <Select
        sx={{width:'100%'}}
        labelId="demo-simple-select-standard-label"
        SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8} }}
        id="demo-simple-select-standard"
        multiple
        disabled={disabled}
        value={chipDataParent || chipData}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => {
              return checkBoxRequired === true ? (
                <Chip
                  key={value}
                  label={value}
                  onDelete={() => handleDelete(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ) : (
                <span>{value}</span>
              );
            })}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        <MultipleDropDownRoot
          currLevel={0}
          currPath={[]}
          formSublevelData={orgSublevelSingleSrc}
          previewData={orgSublevelSingleSrc}
          structure={structure ?? []}
          updateSingleSrcObj={updateSingleSrcObj}
          checkboxRequired={checkBoxRequired}
          key={'key'}
          singleSelected={
            singleSelected === '' && singleSelectedParent !== ''
              ? singleSelectedParent
              : singleSelected
          }
          updateSingleSelected={(id: string) => setSingleSelected(id)}
          parentLabel={''}
          updateParentLabel={(checkedData: string) =>
            chipDataHandler?.([...chipData, checkedData])
          }
        />
      </Select>
    </Box>
  );
};

export type MultiNodeI = {
  sublevel_id: string;
  checked: boolean;
  child: MultiNodeI[];
  all_child: boolean;
  id?: string;
  state?: string;
  parent_id?: string;
  all_sublevel?: boolean;
  sublevel_name: string;
  disabled: boolean;
};

export default MultipleDropDown;
