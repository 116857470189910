import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { alert } from '../../utils/StateStore';
import { useNavigate } from 'react-router';

type Props = {};

const AlertBox = (props: Props) => {
  const [popUpAlert, setPopUpAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const subscribedAlert = alert?.subscribe((x) => {
      setPopUpAlert(x?.state!);
      if (x?.message.includes('[Token]:')) {
        navigate('/login');
      }
    });
    return () => {
      subscribedAlert.unsubscribe();
    };
  }, []);
  return popUpAlert ? (
    <Alert severity={alert?.value?.severity}>{alert?.value?.message}</Alert>
  ) : (
    <></>
  );
};

export default AlertBox;
