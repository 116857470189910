import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useEffect, useState } from 'react';
import MultipleDropDown from '../common/MultipleDropDown';
import Button from '@mui/material/Button';
import EntityForm from '../../components/modal/EntityForm';
import SearchField from '../common/SearchField';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PaginationItem from '@mui/material/PaginationItem';
import { secondaryColor } from '../../theme';
import { deleteFormData, getEntityFilledFormData } from '../../api/form_data';
import { filterData, updateSnackbar } from '../../utils/utils';
import { entityInfo } from '../../utils/StateStore';
import { OrgLevel } from '../../types/types';

type Props = {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EntityStudentAssigned = (props: Props) => {
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const getIntialLevel = (level: OrgLevel[]) => {
    if (!level.length) {
      return [];
    }
    let tempSublevel = [level[0].id];
    let childVal = getIntialLevel(level[0].child);
    const updatedValue: string[] =
      childVal && childVal.length
        ? tempSublevel.concat(childVal)
        : tempSublevel;
    return updatedValue;
  };

  const { formName, formId, orgInfo } = state
    ? {
        formName: state.formName ? state.formName : '',
        formId: state.formId ? state.formId : '',
        orgInfo: state.orgInfo,
      }
    : { formName: '', formId: '', orgInfo: {} };
  const [value, setValue] = useState(0);
  const [studentAssigned, setStudentAssigned] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [entityList, setEntityList] = useState<any[]>([]);
  const [completeEntityList, setCompleteEntityList] = useState<any[]>([]);
  const [entityFormData, setEntityFormData] = useState<any[]>([]);
  const [sublevelIds, setSublevelIds] = useState<string[]>([]);
  const [selectedEntityInfo, setSelectedEntityInfo] = useState<any>({});

  const backClickHandler = () => {
    navigate(-1);
  };
  let filteredData: any;
  filteredData = filteredData ? filteredData : entityList;
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(entityList.length / rowsPerPage)
  );
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  let resetHandler = (textRef: any) => {
    setSearchText('');
    textRef.current.value = '';
  };
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);

  const handleView = (val: any) => {
    if (sublevelIds && sublevelIds.length) {
      navigate('/form-data-preview', {
        state: {
          formId: formId,
          entityId: val._id,
          orgId: orgInfo.id,
          sublevels: sublevelIds,
          formName: formName,
        },
      });
      handleEntitySelect({
        ...val,
        categories: getEntityFormData(val.formData_id),
      });
    }
  };
  const handleDelete = async (entityId: string) => {
    const deleted = await deleteFormData({
      form_id: formId,
      organization_id: orgInfo.id,
      entity_id: entityId,
      sublevel_ids: sublevelIds,
    });
    if (!deleted) {
      console.error('Entity did not get deleted');
    }
    const filledData = await fetchEntityFilledData();
    console.log('filledData' + filledData);
  };
  const getEntityFormData = (formDataId: string) => {
    const data = entityFormData.filter(
      (data) => data.formData_id === formDataId
    );
    return data && data.length ? data[0].categories : [];
  };
  const handleEdit = (val: any) => {
    if (sublevelIds && sublevelIds.length) {
      handleEntitySelect({
        ...val,
        categories: getEntityFormData(val.formData_id),
      });
      setIsUpdate(true);
      setFormModal(true);
    }
  };
  filteredData = filterData(filteredData, searchText, 'users');

  const singleSelectedHandler = (id: string, orgSublevelSrc?: any[]) => {
    const selectedLevels: string[] = [];

    if (id && orgSublevelSrc) {
      findLevelRecursive(orgSublevelSrc, id);
    }

    function findLevelRecursive(orgSublevelSrc: any[], id: string) {
      for (let i = 0; i < orgSublevelSrc.length; i++) {
        const level = orgSublevelSrc[i];
        if (level.sublevel_id === id) {
          selectedLevels.unshift(level.sublevel_id);
          return true;
        }
        if (level.child && level.child.length >= 0) {
          const res = findLevelRecursive(level.child, id);
          if (res) {
            selectedLevels.unshift(level.sublevel_id);
            return true;
          }
        }
      }
    }
    if (id && id !== '' && orgSublevelSrc && orgSublevelSrc.length === 0) {
      sublevelIds.forEach((level) => selectedLevels.push(level));
    } else if (!selectedLevels.length) {
      const tempSublevel = getIntialLevel(entityInfo?.value?.orgInfo?.levels);
      setSublevelIds(tempSublevel);
      tempSublevel.forEach((level) => selectedLevels.push(level));
    } else {
      setSublevelIds(selectedLevels);
    }
    const filteredEntity = completeEntityList.filter(
      (entity) =>
        JSON.stringify(entity.sublevel_ids) === JSON.stringify(selectedLevels)
    );
    setEntityList(filteredEntity);
  };
  const handleEntitySelect = (entityInfo: any) => {
    setSelectedEntityInfo(entityInfo);
  };
  const fetchEntityFilledData = async () => {
    try {
      // const filledData = await getEntityFilledFormData(formId, orgInfo.id);
      const filledData = await getEntityFilledFormData(
        formId,
        entityInfo?.value?.orgInfo.id || ''
      );
      if (!filledData) {
        return;
      }
      const data = filledData
        .filter((exist: any) => !!exist)
        .map((data: any) => {
          return {
            entityData: { ...data.entity, formData_id: data.id },
            entityFormData: {
              categories: [...data.categories],
              formData_id: data.id,
            },
          }; //[TODO] verify id exist or not
        });
      //[TODO] filter based on sublevelID also
      const entityData = data.map((d: any) => d.entityData);
      setCompleteEntityList(entityData);
      const selectedEntityData = entityData.filter(
        (entity: any) =>
          JSON.stringify(entity.sublevel_ids) === JSON.stringify(sublevelIds)
      );
      setEntityList(selectedEntityData);
      setEntityFormData(data.map((d: any) => d.entityFormData));
      return true;
    } catch (error) {
      console.error('EntityFilledData not fethched');
      return false;
    }
    return false;
  };
  useEffect(() => {
    async function fetchData() {
      const entityData = await fetchEntityFilledData();
      console.log('data' + entityData ? 'not' : '' + ' Done');
    }
    setStudentAssigned(true);
    fetchData();
    setEntityList([]);
    setCompleteEntityList([]);
  }, []);

  return (
    <Box marginLeft="2rem">
      <Box marginTop="2rem" display="flex" alignItems="center">
        <Box>
          <img
            onClick={backClickHandler}
            style={{ paddingRight: '8px', cursor: 'pointer' }}
            src={ArrowLeft}
            alt="left arrow"
          />
        </Box>
        <Box>
          <Typography variant="h-1">
            {formName ? formName : 'Project Name'}
          </Typography>
        </Box>
      </Box>
      <Box marginTop="2.688rem" sx={{ width: '100%' }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="All students card"
              {...a11yProps(0)}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            />
            <Tab
              label="Consolidated card"
              {...a11yProps(1)}
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box marginBottom="0.5rem" width="290px">
            <Typography>Select Class</Typography>
            <MultipleDropDown
              checkBoxRequired={false}
              singleSelectedHandler={singleSelectedHandler}
              singleSelectedParent={sublevelIds[sublevelIds.length - 1]}
            />
          </Box>
          {studentAssigned ? (
            <>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box marginLeft="-2rem">
                    <SearchField
                      onchange={inputHandler}
                      onClear={resetHandler}
                      defaultValue={searchText}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      sx={{ marginRight: '1.5rem' }}
                      onClick={() => {
                        if (sublevelIds && sublevelIds.length) {
                          setFormModal(true);
                        } else {
                          updateSnackbar({
                            state: true,
                            severity: 'warning',
                            message: 'Please select Sublevel first',
                          });
                        }
                      }}
                    >
                      Fill Student Card
                    </Button>
                    <Button variant="contained" disabled={true}>
                      Send Student Card
                    </Button>
                  </Box>
                </Box>
                <TableContainer sx={{ width: '95%', m: '2rem' }}>
                  <Table aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          width="3.96%"
                          style={{ fontWeight: '700' }}
                        ></TableCell>
                        <TableCell
                          width="22.5%"
                          style={{ fontWeight: '700' }}
                          align="left"
                        >
                          Name
                        </TableCell>
                        <TableCell
                          width="22.5%"
                          style={{ fontWeight: '700' }}
                          align="left"
                        >
                          Gender
                        </TableCell>
                        <TableCell
                          width="22.5%"
                          style={{ fontWeight: '700' }}
                          align="left"
                        >
                          Student code
                        </TableCell>
                        <TableCell
                          width="16.84%"
                          style={{ fontWeight: '700' }}
                          align="left"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {filteredData.length !== 0 ? (
                      <TableBody>
                        {filteredData
                          .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                          .map((val: any, index: number) => (
                            <TableRow key={index.toString() + '' + val.name}>
                              <TableCell component="th" scope="row">
                                {(page - 1) * rowsPerPage + index + 1}.
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {val.first_name + ' ' + val.last_name}
                              </TableCell>
                              <TableCell align="left">
                                <Box display="flex">{val.gender}</Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box display="flex">{val.entity_code}</Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box display="flex">
                                  <Button
                                    variant="text"
                                    onClick={() => handleView(val)}
                                  >
                                    View
                                  </Button>
                                  <Button
                                    variant="text"
                                    onClick={() => handleEdit(val)}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    variant="text"
                                    onClick={() => handleDelete(val._id)}
                                  >
                                    Delete
                                  </Button>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <TableRow sx={{ backgroundColor: '#FAFAFA' }}>
                        <TableCell
                          align="center"
                          colSpan={8}
                          sx={{ color: '#525862' }}
                        >
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </TableContainer>
              </Box>
              <Box mr="2rem" mt="2rem" pb="1rem">
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                >
                  {filteredData.length > rowsPerPage && (
                    <Typography variant="body-1">
                      {(page - 1) * rowsPerPage + 1}-
                      {entityList.length < page * rowsPerPage
                        ? entityList.length
                        : page * rowsPerPage}{' '}
                      of {entityList.length} items
                    </Typography>
                  )}
                  {filteredData.length > 0 && (
                    <Pagination
                      renderItem={(item) => (
                        <PaginationItem
                          components={{
                            previous: previousIcon,
                            next: nextIcon,
                          }}
                          {...item}
                        />
                      )}
                      page={page}
                      count={noOfPages}
                      shape="rounded"
                      onChange={handleChangePage}
                    />
                  )}
                  {filteredData.length === 0 && (
                    <Typography variant="body-1">
                      Zero student present
                    </Typography>
                  )}
                </Stack>
              </Box>
            </>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              marginTop="10%"
            >
              <Box>
                <svg
                  width="125"
                  height="124"
                  viewBox="0 0 125 124"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="80.0001"
                    height="99"
                    rx="5"
                    transform="matrix(-0.994522 0.104529 0.104529 0.994522 91.0625 15)"
                    fill="#6897DC"
                  />
                  <path
                    d="M113.5 8.00175C113.5 5.23964 111.26 3.00079 108.498 3.00176L87.8744 3.00901L38.5 3.00901C35.7386 3.00901 33.5 5.24759 33.5 8.00901L33.5 102C33.5 104.761 35.7386 107 38.5 107L108.5 107C111.261 107 113.5 104.761 113.5 102L113.5 26.4304L113.5 8.00175Z"
                    fill="#F0F5FB"
                  />
                  <rect
                    x="43.5"
                    y="15"
                    width="60"
                    height="3"
                    rx="1.5"
                    fill="#2C6DCD"
                  />
                  <rect
                    x="43.5"
                    y="28"
                    width="60"
                    height="3"
                    rx="1.5"
                    fill="#2C6DCD"
                  />
                  <rect
                    x="43.5"
                    y="41"
                    width="60"
                    height="3"
                    rx="1.5"
                    fill="#2C6DCD"
                  />
                  <rect
                    x="43.5"
                    y="54"
                    width="32"
                    height="3"
                    rx="1.5"
                    fill="#2C6DCD"
                  />
                  <rect
                    x="43.5"
                    y="67"
                    width="45"
                    height="3"
                    rx="1.5"
                    fill="#2C6DCD"
                  />
                </svg>
              </Box>
              <Box marginTop="1.625rem">
                <Typography variant="h-2">
                  You haven’t not added any student card
                </Typography>
              </Box>
              <Box marginTop="2.75rem">
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsUpdate(false);
                    setFormModal(true);
                  }}
                >
                  Fill student card
                </Button>
              </Box>
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
      </Box>
      <EntityForm
        open={formModal}
        handleClose={async () => {
          setTimeout(async () => {
            const fetchdata = await fetchEntityFilledData();
            console.log('fetchdata' + fetchdata ? 'not' : '' + ' Done');
          }, 1000);

          setFormModal(false);
        }}
        formId={formId}
        orgInfo={orgInfo ? orgInfo : ''} //TODO remove this conditon
        selectedEntityInfo={selectedEntityInfo}
        selectedLevels={sublevelIds}
        singleSelectedHandler={singleSelectedHandler}
        isUpdate={isUpdate}
      />
    </Box>
  );
};

export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};
export default EntityStudentAssigned;
