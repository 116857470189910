import { useState, ChangeEvent } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Preview from './Preview';
import { Checkbox } from '@mui/material';

type Props = {
  structure: string[];
  node: any;
  currLevel: number;
  formSublevelData: any;
  updateSingleSrcObj: (updatedObj: NodeI, pathArr: string[]) => void;
  currPath: string[];
  callFrom: any;
};

const PreviewNode = ({
  structure,
  node,
  currLevel,
  formSublevelData,
  updateSingleSrcObj,
  currPath,
  callFrom,
}: Props) => {
  const { child, value } = node;

  const [showChildren, setShowChildren] = useState(false);

  const handleClick = () => {
    setShowChildren(!showChildren);
  };
  const handleCurrCheckNode = (event: ChangeEvent<HTMLInputElement>) => {
    formSublevelData.checked = event.target.checked;
    updateSingleSrcObj(formSublevelData, currPath);
  };
  const getCheckedState = (type: string) => {
    let count = 0;
    formSublevelData.child.forEach((sublevel: any) => {
      if (sublevel.checked) {
        count++;
      }
    });
    if (formSublevelData.child.length === 0 && type === 'parent') {
      return formSublevelData.checked;
    }
    return type === 'parent'
      ? count === formSublevelData.child.length
      : count > 0 && count < formSublevelData.child.length;
  };
  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {typeof formSublevelData !== 'undefined' &&
            Object.keys(formSublevelData).length > 0 &&
            (callFrom === 'editForm' || callFrom === 'previewForm') && (
              <Checkbox
                onChange={handleCurrCheckNode}
                checked={getCheckedState('parent')}
                size="small"
                indeterminate={getCheckedState('child')}
              />
            )}
          {structure[currLevel]}: {value}
          {child && child.length > 0 ? (
            <>
              {showChildren ? (
                <ExpandMoreIcon onClick={handleClick} />
              ) : (
                <ChevronRightIcon onClick={handleClick} />
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ul style={{ paddingLeft: '10px' }}>
        {showChildren && (
          <Preview
            previewData={child}
            structure={structure}
            currLevel={currLevel + 1}
            updateSingleSrcObj={updateSingleSrcObj}
            formSublevelData={formSublevelData.child}
            currPath={currPath}
            callFrom={callFrom}
          />
        )}
      </ul>
    </>
  );
};

export default PreviewNode;

export type NodeI = {
  sublevel_id: string;
  checked: boolean;
  child: NodeI[];
  all_child: boolean;
  id?: string;
  state?: string;
  parent_id?: string;
  all_sublevel?: boolean;
};
