import {
  Modal,
  Box,
  Typography,
  Grid,
  Link as Navigation,
  InputLabel,
  TextField,
} from '@mui/material';
import SubmitButton from '../../pages/common/SubmitButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useEffect, useState } from 'react';
import React from 'react';
import MultipleDropDown from '../../pages/common/MultipleDropDown';
import { getUserInfo, updatePersonalInfo } from '../../api/user-profile';
import {
  createOrgHandlerSublevelHandler,
  getOrganizationHandlerLevels,
  updateOrgHandlerSublevelHandler,
} from '../../api/org_handler';
import { entityInfo, token } from '../../utils/StateStore';
import {
  fetchOrganisationData,
  updateSnackbar,
  validateEmail,
} from '../../utils/utils';
import { EntityInfo } from '../../types/types';
import { getPermissionForPopup } from '../../utils/utils';

type Props = {
  open: boolean;
  handleClose: () => void;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: '0px' }}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const EditProfile = ({ open, handleClose }: Props) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow:
      '0px 1.2px 3.6px rgba(0, 10, 24, 0.11), 0px 6.4px 14.4px rgba(0, 10, 24, 0.13), 0px 2px 8px rgba(0, 10, 24, 0.08)',
    borderRadius: '8px',
    padding: '32px',
    width: '600px',
    // height: '656px',
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [orgInfo, setOrgInfo] = useState<any>({});
  const [orgCode, setOrgCode] = useState<any>('');
  const [fname, setFname] = useState<any>('');
  const [lname, setLname] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [phoneNumber, setPhoneNumber] = useState<any>('');
  const [orgSublevelSingleSrc, setOrgSublevelSingleSrc] = useState<any[]>([]);
  const [chipData, setChipData] = useState<string[]>([]);
  const [orgInfoAction, setOrgInfoAction] = useState<string>();
  const [hasEntityPerm, setHasEntityPerm] = useState<boolean>(false);

  useEffect(() => {
    async function fetchDropDownSource() {
      const hasPerm = await getPermissionForPopup('Entity', 'c');
      const hasPerm1 = await getPermissionForPopup('Entity', 'r');
      const hasPerm2 = await getPermissionForPopup('Entity', 'u');
      const hasPerm3 = await getPermissionForPopup('Entity', 'd');
      const hasPerm4 = await getPermissionForPopup('entity', 'c');
      const hasPerm5 = await getPermissionForPopup('entity', 'r');
      const hasPerm6 = await getPermissionForPopup('entity', 'u');
      const hasPerm7 = await getPermissionForPopup('entity', 'd');
      const isSuperAdmin = await getPermissionForPopup(
        'permission',
        'Super Admin'
      );
      if (!isSuperAdmin) {
        if (
          hasPerm ||
          hasPerm1 ||
          hasPerm2 ||
          hasPerm3 ||
          hasPerm4 ||
          hasPerm5 ||
          hasPerm6 ||
          hasPerm7
        )
          setHasEntityPerm(true);
      }
      let _entityInfo: EntityInfo = entityInfo?.value;
      //checks for entity's org info in the store and call api if not present
      if (!_entityInfo.orgInfo.id) {
        const entityInfo = await fetchOrganisationData();
        if (entityInfo) {
          _entityInfo = entityInfo;
        }
      }
      if (_entityInfo) {
        const org = _entityInfo.orgInfo;
        setOrgInfo(org);
        setOrgCode(org.attributes[0].value);
        //when edit profile we need to get org handler sublevel data
        const assignedLevel = await getOrganizationHandlerLevels(org.id);
        const orgLevels: any[] = org.levels;
        if (assignedLevel && assignedLevel.child && assignedLevel.child.length)
          setOrgInfoAction('u');
        else setOrgInfoAction('c');
        //here add checked value if assigned level is present
        const dataObj = orgLevels?.map((org) =>
          levelRecursive(
            org,
            assignedLevel && assignedLevel.child && assignedLevel.child.length
              ? assignedLevel.child.filter(
                  (assigned: any) => assigned.sublevel_id === org.id
                )[0] || []
              : [],
            token?.value?.userInfo?.id || ''
          )
        );
        const data = await Promise.all(dataObj);
        if (data.length !== orgLevels.length) {
          console.error('not all org levels are fetched');
        }
        setOrgSublevelSingleSrc(data);
      }
    }
    const fetchUserInfo = async (id: string) => {
      const res = await getUserInfo(id);
      if (res) {
        const userInfo = res;
        setFname(userInfo?.first_name);
        setLname(userInfo?.last_name);
        setEmail(userInfo?.email);
        setPhoneNumber(userInfo?.phone);
      }
    };
    if (open) {
      fetchDropDownSource();
      if (token?.value?.userInfo?.id) fetchUserInfo(token?.value?.userInfo.id);
    }
    setValue(0);
  }, [open]);

  const levelRecursive = async (
    level: any,
    assignedLevel: any,
    currUserId: string
  ) => {
    let checked = false;
    let disabled = false;
    if (assignedLevel && assignedLevel.child?.length) {
      checked =
        assignedLevel.sublevel_id === level.id && assignedLevel.assigned_user;
      disabled =
        assignedLevel.assigned_user &&
        assignedLevel.assigned_user !== currUserId;
    }
    if (!level.child || level.child?.length <= 0) {
      return {
        sublevel_id: level.id,
        checked,
        child: [],
        all_child: checked,
        id: level.id,
        parent_id: level.parentId,
        sublevel_name: level.value,
        assignedlevel_id: assignedLevel ? assignedLevel.id : '',
        disabled,
      };
    }
    const respLevelObjData = level.child;
    const prRespLevelTempObj: any =
      respLevelObjData &&
      respLevelObjData.length > 0 &&
      respLevelObjData.map(async (lev: any) => {
        const levelData = await levelRecursive(
          lev,
          assignedLevel.child?.filter(
            (assigned: any) => assigned.sublevel_id === level.id
          )[0] || null,
          currUserId
        );
        let checkedParent = false;
        let disabled = false;
        if (assignedLevel && assignedLevel.child?.length) {
          const _assignedLevel = assignedLevel.child.filter(
            (assigned: any) => assigned.sublevel_id === lev.id
          )[0];
          checkedParent = _assignedLevel.assigned_user ? true : false;
          disabled =
            _assignedLevel.assigned_user &&
            _assignedLevel.assigned_user !== currUserId;
        }
        return {
          sublevel_id: lev.id,
          checked: checkedParent,
          child: levelData.child,
          all_child: checkedParent,
          id: lev.id,
          parent_id: lev.parentId,
          sublevel_name: lev.value,
          assignedlevel_id: assignedLevel ? assignedLevel.id : '',
          disabled,
        };
      });
    const respLevelTempObj = await Promise.all(prRespLevelTempObj);
    const respLevelObj = {
      ...level,
      sublevel_id: level.id,
      checked: checked,
      all_child: checked,
      id: level.id,
      parent_id: level.parentId,
      sublevel_name: level.value,
      child: respLevelTempObj ? respLevelTempObj : [],
      assignedlevel_id: assignedLevel ? assignedLevel.id : '',
      disabled,
    };

    return respLevelObj;
  };

  const fnameChangeHandler = (e: any) => {
    setFname(e.target.value);
  };
  const lnameChangeHandler = (e: any) => {
    setLname(e.target.value);
  };
  const emailChangeHandler = (e: any) => {
    setEmail(e.target.value);
  };
  const phoneNumberChangeHandler = (e: any) => {
    setPhoneNumber(e.target.value);
  };
  const updateUserInfoHandler = async () => {
    //userInfo Tab selected
    if (value === 0) {
      if (!validateEmail(email)) {
        updateSnackbar({
          message: 'Wrong email format',
          severity: 'warning',
          state: true,
        });
        return false;
      }
      // put UserInfo here
      const userObj = {
        filter: {
          id: token?.value?.userInfo?.id,
        },
        update: {
          email: email,
          first_name: fname,
          last_name: lname,
          phone: phoneNumber,
        },
      };
      const res = await updatePersonalInfo(userObj);
      if (res)
        updateSnackbar({
          state: true,
          severity: 'success',
          message: `User's Info Updated Successfully`,
        });
    }
    if (value === 1) {
      // Org Info Tab Selected
      if (!(orgInfo && orgSublevelSingleSrc && orgSublevelSingleSrc.length)) {
        // updateSnackbar({
        //   state: true,
        //   severity: 'error',
        //   message: `Unable to fetch Organisation's Sublevel info`,
        // });
        return;
      }
      const postDataPR = orgSublevelSingleSrc.map(async (level) => {
        return OrgSubHandlerData(level);
      });
      const postData = {
        organization: {
          organization_id: orgInfo?.id,
          child: await Promise.all(postDataPR),
        },
      };
      if (!orgInfoAction) return;
      const orgSublevelRes =
        orgInfoAction === 'u'
          ? await updateOrgHandlerSublevelHandler(postData)
          : orgInfoAction === 'c'
          ? await createOrgHandlerSublevelHandler(postData)
          : null;
      if (orgSublevelRes) {
        const createdIds = orgSublevelRes?.organization_id;
        if (orgInfoAction === 'c' && createdIds.length) {
          //api returns undefined if there is an exception while creating orgHandler & sublevel relations
          if (createdIds.includes(undefined))
            updateSnackbar({
              state: true,
              severity: 'error',
              message: `Error Assigning Organisation's levels.`,
            });
          return;
        }
        updateSnackbar({
          state: true,
          severity: 'success',
          message: `Organisation's Sublevel Info ${
            orgInfoAction === 'u' ? 'Updated' : 'Created'
          } Successfully`,
        });
      }
    }
    handleClose();
  };
  const OrgSubHandlerData = async (level: any) => {
    let result;
    if (!level.child || level.child?.length <= 0) {
      result = {
        sublevel_id: level.sublevel_id,
        checked: level.checked,
        child: [],
      };
    } else {
      let child = level.child;
      const orgSubHandlerChild: any = child
        .filter((level: any) => !level.disabled)
        .map(async (level: any) => {
          const levelData = await OrgSubHandlerData(level);
          result = {
            sublevel_id: level.sublevel_id,
            checked: level.checked,
            child: levelData.child,
          };
          return Object.assign(
            result,
            orgInfoAction === 'u' ? { id: level.assignedlevel_id } : {}
          );
        });
      const resp = await Promise.all(orgSubHandlerChild);

      result = {
        sublevel_id: level.sublevel_id,
        checked: resp.length === level.child.length ? level.checked : false,
        child: resp ?? [],
      };
    }
    return Object.assign(
      result,
      orgInfoAction === 'u' ? { id: level.assignedlevel_id } : {}
    );
  };

  const chipDataHandler = (val: string[]) => {
    setChipData(val);
  };

  const OrgSublevelSrcHandler = (val: any[]) => {
    setOrgSublevelSingleSrc([...val]);
  };

  const closeHandler = () => {
    //Reset state here
    setValue(0);
    handleClose();
    //resetting state to default value
    setFname('');
    setLname('');
    setEmail('');
    setPhoneNumber('');
    setOrgSublevelSingleSrc([]);
    setChipData([]);
  };

  return hasEntityPerm ? (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box m="0 0 1.5rem 0rem">
          <Typography
            fontSize="1.5rem"
            lineHeight="2rem"
            fontStyle="normal"
            fontWeight={700}
          >
            Edit Profile
          </Typography>
        </Box>
        <Box>
          <form>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Personal info"
                    {...a11yProps(0)}
                    sx={{ textTransform: 'none', fontSize: '16px' }}
                  />
                  <Tab
                    label="Organisation info"
                    {...a11yProps(1)}
                    sx={{ textTransform: 'none', fontSize: '16px' }}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{ marginBottom: '36px', marginTop: '15px' }}
                >
                  <Grid item sx={{ marginRight: '24px' }}>
                    <InputLabel
                      sx={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        fontFamily: 'Mulish',
                      }}
                    >
                      First name
                    </InputLabel>
                    <TextField
                      required
                      size="small"
                      sx={{ width: '256px' }}
                      value={fname}
                      onChange={(event) => fnameChangeHandler(event)}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel
                      sx={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        fontFamily: 'Mulish',
                      }}
                    >
                      Last name
                    </InputLabel>
                    <TextField
                      required
                      size="small"
                      sx={{ width: '256px' }}
                      value={lname}
                      onChange={(event) => lnameChangeHandler(event)}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ marginBottom: '36px' }}>
                  <Grid item sx={{ width: '100%' }}>
                    <InputLabel
                      sx={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        fontFamily: 'Mulish',
                      }}
                    >
                      School name
                    </InputLabel>
                    <TextField
                      placeholder={orgInfo?.name || ''}
                      name="school"
                      size="small"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ width: '100%', marginBottom: '36px' }}>
                  <Grid item sx={{ width: '100%' }}>
                    <InputLabel
                      sx={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        fontFamily: 'Mulish',
                      }}
                    >
                      School code
                    </InputLabel>
                    <TextField
                      placeholder={orgCode != '' ? orgCode : ''}
                      name="school code"
                      size="small"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item sx={{ marginRight: '24px' }}>
                    <InputLabel
                      sx={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        fontFamily: 'Mulish',
                      }}
                    >
                      Email
                    </InputLabel>
                    <TextField
                      required
                      size="small"
                      sx={{ width: '256px' }}
                      value={email}
                      onChange={(event) => emailChangeHandler(event)}
                    />
                  </Grid>
                  <Grid item>
                    <InputLabel
                      sx={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        fontFamily: 'Mulish',
                      }}
                    >
                      Phone number
                    </InputLabel>
                    <TextField
                      required
                      size="small"
                      sx={{ width: '256px' }}
                      type={'tel'}
                      value={phoneNumber}
                      onChange={(event) => phoneNumberChangeHandler(event)}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{ marginBottom: '36px', marginTop: '15px' }}
                >
                  <Grid container sx={{ marginBottom: '36px' }}>
                    <Grid item sx={{ width: '100%' }}>
                      <InputLabel
                        sx={{
                          lineHeight: '16px',
                          fontSize: '12px',
                          fontFamily: 'Mulish',
                        }}
                      >
                        School name
                      </InputLabel>
                      <TextField
                        placeholder={orgInfo?.name || ''}
                        name="school"
                        size="small"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: '100%', marginBottom: '36px' }}>
                    <Grid item sx={{ width: '100%' }}>
                      <InputLabel
                        sx={{
                          lineHeight: '16px',
                          fontSize: '12px',
                          fontFamily: 'Mulish',
                        }}
                      >
                        School code
                      </InputLabel>
                      <TextField
                        placeholder={orgCode != '' ? orgCode : ''}
                        name="school code"
                        size="small"
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ width: '50%', marginBottom: '36px' }}>
                    <Grid item sx={{ width: '100%' }}>
                      <InputLabel
                        sx={{
                          lineHeight: '16px',
                          fontSize: '12px',
                          fontFamily: 'Mulish',
                        }}
                      >
                        Select class
                      </InputLabel>
                      {
                        <MultipleDropDown
                          orgSublevelSrc={orgSublevelSingleSrc}
                          orgSublevelSrcHandler={OrgSublevelSrcHandler}
                          checkBoxRequired={true}
                          chipDataParent={chipData}
                          chipDataHandler={chipDataHandler}
                          showDisabled={true}
                        />
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{ marginTop: '15px' }}
            >
              <Grid item>
                <Navigation
                  style={{
                    padding: '0px 32px',
                    textDecoration: 'none',
                    color: '#525862',
                    fontWeight: '700',
                    fontFamily: 'Mulish',
                    cursor: 'pointer',
                  }}
                  onClick={closeHandler}
                >
                  Cancel
                </Navigation>
                <SubmitButton
                  onClick={updateUserInfoHandler}
                  text="Save Changes"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Modal>
  ) : (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box m="0 0 1.5rem 0rem">
          <Typography
            fontSize="1.5rem"
            lineHeight="2rem"
            fontStyle="normal"
            fontWeight={700}
          >
            Edit Profile
          </Typography>
        </Box>
        <Box>
          <form>
            <Box sx={{ width: '100%' }}>
              <Grid container sx={{ marginBottom: '36px', marginTop: '15px' }}>
                <Grid item sx={{ marginRight: '24px' }}>
                  <InputLabel
                    sx={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      fontFamily: 'Mulish',
                    }}
                  >
                    First name
                  </InputLabel>
                  <TextField
                    required
                    size="small"
                    sx={{ width: '256px' }}
                    value={fname}
                    onChange={(event) => fnameChangeHandler(event)}
                  />
                </Grid>
                <Grid item>
                  <InputLabel
                    sx={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      fontFamily: 'Mulish',
                    }}
                  >
                    Last name
                  </InputLabel>
                  <TextField
                    required
                    size="small"
                    sx={{ width: '256px' }}
                    value={lname}
                    onChange={(event) => lnameChangeHandler(event)}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item sx={{ marginRight: '24px' }}>
                  <InputLabel
                    sx={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      fontFamily: 'Mulish',
                    }}
                  >
                    Email
                  </InputLabel>
                  <TextField
                    required
                    size="small"
                    sx={{ width: '256px' }}
                    value={email}
                    onChange={(event) => emailChangeHandler(event)}
                  />
                </Grid>
                <Grid item>
                  <InputLabel
                    sx={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      fontFamily: 'Mulish',
                    }}
                  >
                    Phone number
                  </InputLabel>
                  <TextField
                    required
                    size="small"
                    sx={{ width: '256px' }}
                    type={'tel'}
                    value={phoneNumber}
                    onChange={(event) => phoneNumberChangeHandler(event)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              sx={{ marginTop: '15px' }}
            >
              <Grid item>
                <Navigation
                  style={{
                    padding: '0px 32px',
                    textDecoration: 'none',
                    color: '#525862',
                    fontWeight: '700',
                    fontFamily: 'Mulish',
                    cursor: 'pointer',
                  }}
                  onClick={closeHandler}
                >
                  Cancel
                </Navigation>
                <SubmitButton
                  onClick={updateUserInfoHandler}
                  text="Save Changes"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditProfile;
