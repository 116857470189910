import { Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styles from './createTemplate.module.css';
const AddDivider = (props: any) => {
  return (
    <>
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px',
          gap: '16px',
          width: '100%',
          height: '32px',
        }}
      >
        <hr
          className={props.hrstyle ? styles[props.hrstyle] : styles.solid}
        ></hr>
        <button onClick={props.clickHandler} className={styles.addSectionBtn}>
          <AddCircleIcon />
        </button>
        <hr
          className={props.hrstyle ? styles[props.hrstyle] : styles.solid}
        ></hr>
      </Grid>
    </>
  );
};

export default AddDivider;
