import { REQUEST_GET, REQUEST_POST, REQUEST_PUT } from '../utils/const';
import { requestAPI } from '../utils/fetch';
import { updateAlert } from '../utils/utils';

export const getOrganizationHandlerLevels = async (orgId: string) => {
  try {
    const data = await requestAPI({
      type: REQUEST_GET,
      url: `/api/org-handler-sublevel?organization_id=${orgId}`,
    });
    if (!data) {
      return false;
    }
    return data;
  } catch (error: any) {
    console.error('Inside error', error);
  }
  return false;
};

export const updateOrgHandlerSublevelHandler = async (orgHandlerLevels: any) => {
  try {
    const res = await requestAPI({
      type: REQUEST_PUT,
      url: '/api/org-handler-sublevel',
      data: orgHandlerLevels,
    });
    if (!res) {
      return false;
    }
    return res;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const createOrgHandlerSublevelHandler = async (orgHandlerLevels: any) => {
  try {
    const createdOrganization = await requestAPI({
      type: REQUEST_POST,
      url: '/api/org-handler-sublevel',
      data: orgHandlerLevels,
    });
    if (!createdOrganization) {
      return false;
    }
    return createdOrganization;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};