import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createForm, getForm, updateForm } from '../../../api/form';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormAccordianContent from './FormAccordianContent';
import { updateSnackbar } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';

type Props = {
  open: boolean;
  handleClose: (confirm: boolean) => void;
  handlePublish: (confirm: boolean) => void; //verfiy parameter
  projectId: string;
  formId?: string;
  formJsonData?: any;
  publish?: boolean;
  alias?: string;
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '35%',
  bgcolor: 'white',
  height: '90.51vh',
  borderRadius: '8px',
};

const FormPreviewAndDataFilling = ({
  open,
  handleClose,
  handlePublish,
  projectId,
  formId,
  formJsonData,
  publish = false,
  alias,
}: Props) => {
  const [form, setForm] = useState({
    name: '',
    categories: [],
    project_id: projectId ? projectId : '',
    id: formId ? formId : '',
  });
  const [activeIndex, setActiveIndex] = useState<{
    prevIndex: number;
    currIndex: number;
  }>({
    prevIndex: 1,
    currIndex: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    async function getFormData() {
      let formData;

      if (formJsonData) {
        formData = { ...formJsonData };
      } else if (formId && formId !== '') {
        const formObj = await getForm({ formId });
        formData = formObj[0];
      }

      setForm({
        ...form,
        name: formData.name,
        categories: formData.categories,
      });
    }
    if (open) {
      getFormData();
    }
    setActiveIndex({ currIndex: 0, prevIndex: 1 });
  }, [formId, open]);

  const handleUpdateSave = async () => {
    const response =
      formId && formId !== ''
        ? await updateForm({ ...formJsonData, form_id: formId })
        : await createForm({
            form: formJsonData,
            project: {
              alias: alias,
              id: projectId,
            },
          });
    if (!response) {
      const respMsg = formId && formId !== '' ? 'updated' : 'created';
      console.error('Form not ' + respMsg);
    }
    updateSnackbar({
      state: true,
      severity: 'success',
      message: 'Form Updated',
    });
    handleClose(false);
    navigate(-1);
  };
  //add formData check
  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          position="relative"
        >
          <Box sx={{ overflowY: 'auto' }}>
            <Box m="2rem">
              <Typography variant="h-1">Form Preview</Typography>
            </Box>
            <Box height="85vh">
              <Box>
                <Typography variant="Caption" ml="1rem">
                  Form Name
                </Typography>
                <br />
                <Box mx="1rem">
                  <TextField
                    disabled={true}
                    value={form.name}
                    placeholder="Form Name"
                    contentEditable={false}
                    name="title"
                    size="small"
                    fullWidth
                    sx={{ width: '100%', marginBottom: '10px' }}
                  />
                </Box>
              </Box>
              <Box mt="1rem" height="70%">
                <Box sx={{ maxHeight: '100%', overflowY: 'auto', mx: '1rem' }}>
                  {form.categories.map((category: any, index: number) => (
                    <Box mb="1rem">
                      <Accordion
                        sx={{ border: '1px solid #B8BABE' }}
                        expanded={
                          activeIndex.currIndex === index &&
                          activeIndex.prevIndex !== index
                        }
                      >
                        <AccordionSummary
                          onClick={() => {
                            setActiveIndex({
                              ...activeIndex,
                              currIndex:
                                activeIndex.currIndex === index ? -1 : index,
                              prevIndex: activeIndex.currIndex,
                            });
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography> {category.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {category.fields.map(
                              (field: any, index: number) => (
                                <Box>
                                  <FormAccordianContent
                                    field={field}
                                    index={index}
                                  />
                                </Box>
                              )
                            )}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            bottom="0"
            right="0"
            width="100%"
            borderTop="1px solid #E5E6E8"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            position="absolute"
            bgcolor="white"
          >
            <Box m="2rem">
              <Button variant="text" onClick={() => handleClose(false)}>
                Cancel
              </Button>
              {publish ? (
                <Button
                  variant="contained"
                  onClick={() => handlePublish(false)}
                >
                  Publish
                </Button>
              ) : (
                <Button variant="contained" onClick={() => handleUpdateSave()}>
                  {formId && formId !== '' ? 'Update' : 'Save'}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default FormPreviewAndDataFilling;
