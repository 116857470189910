import { useEffect, useState } from 'react';
import { REQUEST_GET } from '../../utils/const';
import { requestAPI } from '../../utils/fetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { secondaryColor } from '../../theme';
import {
  updateAlert,
  getPermissionForPopup,
  filterData,
} from '../../utils/utils';
import SearchField from '../common/SearchField';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import { Button } from '@mui/material';

function OnboardUsers() {
  const [users, setUsers] = useState<any>([]);
  const rowsPerPage = 20;
  const { state }: any = useLocation();
  const { projectId } = state;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(users.length / rowsPerPage)
  );
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await requestAPI({
        type: REQUEST_GET,
        url: '/api/users?roles=true',
      });
      if (response) {
        setUsers(response);
        setNoOfPages(Math.ceil(response.length / rowsPerPage));
      } else {
        console.error(response);
      }
    };
    fetchData();
  }, []);

  const [page, setPage] = useState(1);
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await requestAPI({
        type: REQUEST_GET,
        url: '/api/users?roles=true',
      });
      if (response) {
        setUsers(response);
      } else {
        console.error(response);
      }
    };
    fetchData();
  }, []);

  const editClickHandler = async (
    emailid: string,
    id: string,
    projectId: string
  ) => {
    const hasPerm = await getPermissionForPopup('Users', 'u');
    hasPerm
      ? navigate('/editUser', {
          state: { email: emailid, user_id: id, projectId },
        })
      : updateAlert({
          message: 'You are not authorized to edit roles',
          severity: 'warning',
          state: true,
        });
  };
  let resetHandler = (textRef: any) => {
    setInputText('');
    textRef.current.value = '';
  };
  const backClickHandler = () => {
    navigate(-1);
  };

  interface UserInterface {
    roles: any;
    email: string;
    first_name: string;
    id: string;
    last_name: string;
    phone: string;
    pwd_expiry: string;
  }

  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const filteredData = filterData(users, inputText, 'users');
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);
  return (
    <>
      <div style={{ width: window.innerWidth - 283 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="2rem"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              onClick={backClickHandler}
              style={{ paddingRight: '8px', cursor: 'pointer' }}
              src={ArrowLeft}
              alt="left arrow"
            />
            <Box>
              <Typography variant="h-1">Onboard User</Typography>
            </Box>
          </div>
        </Box>
        <SearchField
          onchange={inputHandler}
          onClear={resetHandler}
          defaultValue={inputText}
        />
        <TableContainer
          sx={{
            width: '95%',
            m: '2rem',
          }}
          component={Paper}
        >
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width="5.96%" style={{ fontWeight: '700' }}>
                  Sr. No
                </TableCell>
                <TableCell width="24.59%" style={{ fontWeight: '700' }}>
                  Name
                </TableCell>
                <TableCell
                  width="24.59%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Email ID
                </TableCell>

                <TableCell
                  width="12.41%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((val: UserInterface, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {(page - 1) * rowsPerPage + index + 1}.
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {val.first_name}&nbsp;{val.last_name}
                    </TableCell>
                    <TableCell align="left">{val.email}</TableCell>
                    <TableCell align="left">
                      <Box display={'flex'}>
                        <Button
                          sx={{
                            color: '#0451C4',
                            backgroundColor: 'white',
                            border: 'none',
                            fontWeight: '700',
                            fontFamily: 'Mulish',
                            fontSize: '16px',
                            lineHeight: '24px',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            editClickHandler(val.email, val.id, projectId)
                          }
                        >
                          Edit
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mr="2rem" mt="2rem" pb="1rem">
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="end"
          >
            {filteredData.length > rowsPerPage && (
              <Typography variant="body-1">
                {(page - 1) * rowsPerPage + 1}-
                {users.length < page * rowsPerPage
                  ? users.length
                  : page * rowsPerPage}{' '}
                of {users.length} items
              </Typography>
            )}
            {filteredData.length > 0 && (
              <Pagination
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: previousIcon,
                      next: nextIcon,
                    }}
                    {...item}
                  />
                )}
                page={page}
                count={noOfPages}
                shape="rounded"
                onChange={handleChangePage}
              />
            )}
            {filteredData.length === 0 && (
              <Typography variant="body-1">Zero users present</Typography>
            )}
          </Stack>
        </Box>
      </div>
    </>
  );
}
export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};
export default OnboardUsers;
