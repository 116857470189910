import { useEffect, useRef, useState } from 'react';
import {Box, Typography} from '@mui/material';
import AddImage from '../../assets/png/AddImage.png';
import Media from './Media';
import { FieldProjectType, IMedia } from '../../types/types';
import TextInput from '../../components/Input/TextInput/TextInput';
import { getUniqueFileName } from '../../utils/utils';
import styles from "../ProjectTemplate/ProjectTemplate.module.css"
import NextIcon from '../../assets/png/Next.png';
import PrevIcon from '../../assets/png/Prev.png';

type Props = {
  updateField: (field: FieldProjectType, pos: number) => void;
  updateFiles: (file: any[], action: string, name?: string) => void;
  pos: number;
  type: string;
  fieldData: FieldProjectType; 
  editable?:boolean;
  projectEdit?:string;
  editFromData?:boolean;
};

const ImageVideo = ({
  pos,
  updateField,
  type,
  updateFiles,
  fieldData,
  editable,
  editFromData = true,
  projectEdit
}: Props) => {
  const [imageVideo, setImageVideo] = useState<{
    label: {key?:string,value?:string}[];
    seq: number;
    type: string;
    medias: any[];
    key: string;
  }>({
    label: fieldData.label,
    seq: fieldData.seq,
    type: type,
    medias: fieldData.medias ? fieldData.medias : [],
    key: fieldData.key ? fieldData.key : '',
  });
  const fileRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLInputElement>(null);
  const [scrollPosition,setScrollPosition] = useState<number>(0)
  const [displayFiles, setDisplayFiles] = useState<File[]>([]);
  const [dataFiles, setDataFiles] = useState<any[]>([]);
  const [exisitingMedias, setExisitingMedias] = useState([]);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let label = fieldData && fieldData.label || [{key:'',value:''}]
    setImageVideo({ ...imageVideo, label:[{key:label && Array.isArray(label) && label[0] && label[0].key || '',value:event.target.value || ''}] });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const reader = new FileReader();
    const updatedFile = new File(
      [event!.target!.files![0]],
      getUniqueFileName(event!.target!.files![0].name)
    );
    reader.readAsDataURL(event!.target!.files![0]);
    reader.onload = (readerEvent) => {
      setDisplayFiles([
        ...displayFiles,
        { type: type, data: readerEvent!.target!.result },
      ]);
    };
    addMedia(updatedFile.name); //[TODO] update name to unique name
    updateFiles([updatedFile], 'add');
    setDataFiles([...dataFiles, updatedFile]);
    //reset file input
    event.target.value = '';
  };
  const handleClick = () => {
    // open file input box on click of other element
    fileRef!.current!.click();
  };
  const deleteMedia = (pos: number, name: string = '', existing: boolean) => {
    if (!existing) {
      updateFiles(
        [],
        'delete',
        dataFiles[pos]?.name ? dataFiles[pos]?.name : name
      );
      displayFiles.splice(pos, 1);
      setDisplayFiles([...displayFiles]);
    } else {
      //update from list of field
      const updatedMedia = imageVideo?.medias.filter(
        (media) => typeof media === 'string' || media.name !== name
      );
      setImageVideo({
        ...imageVideo,
        medias: [...updatedMedia],
      });
      exisitingMedias.splice(pos, 1);
      setExisitingMedias([...exisitingMedias]);
    }
  };

  const addMedia = (addedMedia: string) => {
    setImageVideo({
      ...imageVideo,
      medias: [...imageVideo?.medias, addedMedia],
    });
  };

  useEffect(() => {
    updateField(imageVideo, pos);
  }, [imageVideo]);
  useEffect(() => {
    setExisitingMedias(
      JSON.parse(JSON.stringify(fieldData?.medias ? fieldData?.medias : []))
    );
  }, []);
  const changeHandler = (value:string) => {
    let position = scrollPosition
    if(scrollRef && scrollRef.current && value === 'left'){
      position = position + scrollRef?.current?.offsetWidth > scrollRef.current.scrollWidth ? position : position + scrollRef?.current?.offsetWidth
    }
    else if(scrollRef && scrollRef.current && value === 'right'){
      position = position - scrollRef?.current?.offsetWidth
      position = position < 0 ? 0 : position
    }
    setScrollPosition(position)
  }
  useEffect(() => {
    if(scrollRef && scrollRef.current){
      scrollRef?.current?.scrollTo(scrollPosition - 50,0)
    }
  },[scrollPosition])
  return (
    <Box
      width="100%"
      style={{backgroundColor: !editFromData || !editable ? "#FFFFFF" : "#F5F5F6",marginTop:editable ? '56px' : "0px",borderBottom : `${editable ? '1' : '0'}px solid #B8BABE`}}
    >
     {editable && <Box mx="1rem">
        <TextInput
          value={fieldData && fieldData.label && (Array.isArray(fieldData.label) ? (fieldData.label[0] && fieldData.label[0].value || '') : (fieldData.label || '')) || ''}
          onChange={onChange}
          name="label"
          placeholder="Image Video Label"
          width={'100%'}
          editable={editable && editFromData}
        />
      </Box> || 
        <div
        style={{
          fontSize: '14px',
          color: '#36454F',
          fontWeight: '400',
          fontStyle: 'Mulish',
          marginLeft: '20px',
        }}
      >
        <Typography
          style={{
            fontSize: '14px',
            fontWeight: '700',
          }}
        >
          {(fieldData.label &&
            Array.isArray(fieldData.label) &&
            fieldData.label[0] &&
            fieldData.label[0].value) ||
            ''}
        </Typography>
      </div>
      }

      <div className={styles.imageVideoContainer}>
        {projectEdit && scrollRef && scrollRef.current && scrollRef?.current?.scrollWidth >=  window?.innerWidth -200 && <div className={styles.imageVideoSliderLeft} onClick={() => changeHandler('left')}><img src={PrevIcon} style={{width:7}} alt="Prev"/></div>}
        <div className={styles.imageVideoWrapper} ref={scrollRef}>
        {displayFiles &&
          displayFiles.map((file: File, pos: number) => (
            <Media
              file={file.data}
              type={file.type}
              pos={pos}
              deleteMedia={deleteMedia}
              existing={false}
              editable={editable}
              projectEdit={projectEdit}
            />
          ))}
           {exisitingMedias &&
            exisitingMedias?.map((media: IMedia, pos: number) => (
            <Media
              file={media?.media_path + media?.name}
              type={media?.type}
              pos={pos}
              deleteMedia={deleteMedia}
              mimeType={media?.mime_type}
              existing={true}
              editable={editable}
              projectEdit={projectEdit}
            />
          ))}
        {!projectEdit && editable && <Box mr="1.5rem">
          <Box onClick={handleClick}>
            <img src={AddImage} alt="logo" />
          </Box>
          <Box>
            <input
              ref={fileRef!}
              onChange={handleFileChange}
              multiple={false}
              type="file"
              hidden
              accept={`${type}/*`}
            />
          </Box>
        </Box>}
        </div>
        {projectEdit && scrollRef && scrollRef.current && scrollRef?.current?.scrollWidth >=  window?.innerWidth - 200 && <div className={styles.imageVideoSliderRight} onClick={() => changeHandler('right')}><img src={NextIcon} style={{width:7}} alt="Next"/></div>}
      </div>
    </Box>
  );
};

export default ImageVideo;
export interface File {
  type: string;
  data: any;
}
