import { Box } from '@mui/system';
import './index.css';
type Props = {};

const Spinner = (props: Props) => {
  return (
    <Box className="InputContainer" alignItems="center">
      <div className="loader"></div>
    </Box>
  );
};

export default Spinner;
