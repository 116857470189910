import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseCircullar from '../../assets/png/CloseCircullar.png';
import { FieldTypes } from '../../utils/const';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45.625rem',
  bgcolor: 'white',
};
type Props = {
  open: boolean;
  file: string;
  mimeType: string;
  type: string;
  handleClose: (confirm: boolean) => void;
};
const MediaPreview = ({ handleClose, open, file, type, mimeType }: Props) => {
  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style} display="flex" flexDirection="column">
        <Box
          sx={{
            position: 'relative',
            top: 0,
            left: 0,
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            onClick={() => handleClose(false)}
            sx={{
              position: 'absolute',
              left: '95%',
              top: '-7%',
            }}
          >
            <img src={CloseCircullar} />
          </Box>
          <Box>
            {type === FieldTypes.IMAGE ? (
              <Box
                height="34.125rem"
                sx={{
                  backgroundImage: `url(${file})`,
                  backgroundRepeat: 'no-repeat',
                  objectFit: 'contain',
                  backgroundSize: 'cover',
                }}
              />
            ) : (
              <Box display="flex" justifyContent="space-around">
                <video controls>
                  <source src={file} type={mimeType} />
                </video>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MediaPreview;
