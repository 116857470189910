import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import React from 'react';
import { snackbar } from '../../../utils/StateStore';
import TickIcon from '../../../assets/png/TickIcon.png';
import Error from '../../../assets/png/Error.png';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

const BasicSnackbar = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  useEffect(() => {
    const subscribedAlert = snackbar?.subscribe((x) => {
      setSnackbarOpen(x?.state!);
    });
    return () => {
      subscribedAlert.unsubscribe();
    };
  }, []);
  return snackbarOpen ? (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          iconMapping={{
            success: <img src={TickIcon} alt="tick icon" />,
            error: <img src={Error} alt="error icon" />,
          }}
          onClose={handleClose}
          severity={snackbar.value?.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.value?.message}
        </Alert>
      </Snackbar>
    </Stack>
  ) : (
    <></>
  );
};
export default BasicSnackbar;
