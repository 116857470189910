import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Alert,
  Box,
  Dialog,
  Grid,
  InputLabel,
  Link as Navigation,
  Snackbar,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation } from 'react-router-dom';
import './Styles.css';
import { UserInfo } from '../../types/types';
import { requestAPI } from '../../utils/fetch';
import {
  REQUEST_GET,
  REQUEST_PUT,
  REQUEST_POST,
  REQUEST_DELETE,
} from '../../utils/const';
import DropdownClosed from '../../assets/png/DropdownClosed.png';
import { selectedLeftNavigationField } from '../../utils/StateStore';
import {
  getPermissionForPopup,
  updateAlert,
  updateSnackbar,
} from '../../utils/utils';
import SubmitButton from '../common/SubmitButton';
import Error from '../../assets/png/Error.png';

function EditUser() {
  const navigate = useNavigate();

  interface UserAllInfo extends Omit<UserInfo, 'id' | 'roles' | 'permissions'> {
    password: string;
    roles: string[];
  }
  const [userOpen, setUserOpen] = useState(true);
  const [userInfoToEdit, setuserInfoToEdit] = useState<UserAllInfo>({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    phone: '',
    roles: [],
  });

  const [initialRoleIds, setInitialRoleIds] = useState<String[]>([]);
  const { state }: any = useLocation();
  const email = state?.email;
  let rolesArr: any = [];
  let unselectedRoleIds: any = [];
  useEffect(() => {
    const fetchData = async () => {
      const response = await requestAPI({
        type: REQUEST_GET,
        url: '/api/users?roles=true',
      });

      //TODO: handling code acc to response status
      if (response) {
        const userItems = response.filter(
          (item: any, index: any) => item.email === email
        );
        userItems.length === 1 && setuserInfoToEdit(userItems[0]);
        userItems[0].roles.forEach((role: any) => {
          rolesArr.push(role.role_id);
        });
        setInitialRoleIds(rolesArr);
      } else {
        console.error(response);
      }
    };
    fetchData();
  }, []);

  const userFirstNameHandler = (event: any) => {
    setuserInfoToEdit({ ...userInfoToEdit, first_name: event.target.value });
  };
  const userLastNameHandler = (event: any) => {
    setuserInfoToEdit({ ...userInfoToEdit, last_name: event.target.value });
  };
  const userPhoneHandler = (event: any) => {
    setuserInfoToEdit({ ...userInfoToEdit, phone: event.target.value });
  };
  const userEmailHandler = (event: any) => {
    setuserInfoToEdit({ ...userInfoToEdit, email: event.target.value });
  };
  const userPasswordHandler = (event: any) => {
    setuserInfoToEdit({ ...userInfoToEdit, password: event.target.value });
  };

  const addRole = async (item: any) => {
    const response = await requestAPI({
      type: REQUEST_POST,
      url: '/api/user-roles',
      data: { user_id: state.user_id, role_ids: [item] },
    });
    //TODO: handling code acc to response status
    if (response) {
    } else {
      console.error(response);
    }
  };
  const delRole = async (item: any) => {
    const response = await requestAPI({
      type: REQUEST_DELETE,
      url: '/api/user-roles',
      data: { user_id: state.user_id, role_ids: [item] },
    });
    //TODO: handling code acc to response status
    if (response) {
    } else {
      console.error(response);
    }
  };

  const sendUpdate = () => {
    const finalArr: any[] = [];
    const initialArr: any[] = initialRoleIds;
    userInfoToEdit.roles.forEach((item: any) => {
      if (
        (item.role_id && !initialArr.includes(item.role_id)) ||
        (item.id && !initialArr.includes(item.id))
      ) {
        addRole(item.role_id ? item.role_id : item.id);
      }
      finalArr.push(item.role_id ? item.role_id : item.id);
    });
    initialRoleIds.forEach((item: any) => {
      if (!finalArr.includes(item)) delRole(item);
    });
    const reqObj = {
      email: userInfoToEdit.email,
      newPassword: userInfoToEdit.password,
      first_name: userInfoToEdit.first_name,
      last_name: userInfoToEdit.last_name,
      phone: userInfoToEdit.phone,
    };
    return reqObj;
  };

  const [roles, setRoles] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const hasPerm = await getPermissionForPopup('Users', 'u');
        if (!hasPerm) {
          updateAlert({
            message: 'You are not authorized to update users',
            severity: 'warning',
            state: true,
          });
          navigate(-1);
        } else {
          if (state?.projectId) {
            const response = await requestAPI({
              type: REQUEST_POST,
              url: `/api/target-roles`,
              data: { project_id: state?.projectId },
            });
            let responseRoles: string[] = [];
            //TODO: handling code acc to response status
            if (response) {
              response.forEach((role: any) => {
                if (rolesArr.indexOf(role.id) >= 0) {
                } else {
                  unselectedRoleIds.push(role);
                }
                responseRoles.push(role.name);
              });
              setRoles(unselectedRoleIds);
            } else {
              console.error(response);
            }
          } else if (state?.organisationId) {
            const response = await requestAPI({
              type: REQUEST_POST,
              url: `/api/target-roles`,
              data: { organization_id: state?.organisationId },
            });
            let responseRoles: string[] = [];
            //TODO: handling code acc to response status
            if (response) {
              response.forEach((role: any) => {
                if (rolesArr.indexOf(role.id) >= 0) {
                } else {
                  unselectedRoleIds.push(role);
                }
                responseRoles.push(role.name);
              });
              setRoles(unselectedRoleIds);
            } else {
              console.error(response);
            }
          } else {
            const response = await requestAPI({
              type: REQUEST_GET,
              url: '/api/roles',
            });
            let responseRoles: string[] = [];
            //TODO: handling code acc to response status
            if (response) {
              response.forEach((role: any) => {
                if (rolesArr.indexOf(role.id) >= 0) {
                } else {
                  unselectedRoleIds.push(role);
                }
                responseRoles.push(role.name);
              });
              setRoles(unselectedRoleIds);
            } else {
              console.error(response);
            }
          }
        }
      } catch (err) {
        console.error(err);
        setSnackbarOpen(true);
        setOpenAlert(true);
        setAlertMsg('Error: roles not fetched');
      }
    })();
  }, []);

  const roleChangeHandler = (e: any, value: any) => {
    setuserInfoToEdit({ ...userInfoToEdit, roles: value });
  };

  const submitHandler = async () => {
    const userObj = {
      filter: {
        id: state.user_id,
      },
      update: sendUpdate(),
    };
    const response = await requestAPI({
      type: REQUEST_PUT,
      url: '/api/user',
      data: userObj,
    });
    if (response) {
      navigate(-1);
      updateSnackbar({
        state: true,
        severity: 'success',
        message: 'User Updated',
      });
    }
  };
  const dialogCloseHandler = () => {
    setUserOpen(false);
    selectedLeftNavigationField.next('Overview');
    navigate(-1);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            width: '536px',
            borderRadius: '8px',
            height: '560px',
            padding: '32px',
          },
        }}
        onClose={dialogCloseHandler}
        open={userOpen}
      >
        <Box sx={{ height: '100%' }}>
          {openAlert && (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarOpen}
              onClose={handleClose}
              sx={{ paddingTop: '4px' }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{
                  width: '536px',
                  padding: ' 0px 12px 0px 9.63px',
                  height: '28px',
                  alignItems: 'center',
                }}
                icon={<img src={Error} />}
              >
                {alertMsg}
              </Alert>
            </Snackbar>
          )}
          <Grid item={true} paddingBottom={'32px'}>
            <Typography
              variant="h-1"
              style={{
                fontFamily: 'Mulish',
                lineHeight: '32px',
                fontWeight: '700',
                fontSize: '24px',
                color: '#000A18',
              }}
            >
              Edit User
            </Typography>
          </Grid>
          <Grid sx={{ height: '100%' }} container overflow ='auto' maxHeight={'75%'}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  First name
                </InputLabel>
                <TextField
                  placeholder="Please enter first name"
                  name="first_name"
                  size="small"
                  value={userInfoToEdit.first_name}
                  sx={{ width: '256px' }}
                  // defaultValue={initialFirstName}
                  onChange={(event: any) => userFirstNameHandler(event)}
                />
              </Grid>
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Last name
                </InputLabel>
                <TextField
                  name="last_name"
                  size="small"
                  placeholder="Please enter last name"
                  value={userInfoToEdit.last_name}
                  sx={{ width: '256px' }}
                  // defaultValue={initialLastName}
                  onChange={(event: any) => userLastNameHandler(event)}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Email
                </InputLabel>
                <TextField
                  placeholder="Please enter emailid"
                  name="email"
                  size="small"
                  value={userInfoToEdit.email}
                  sx={{ width: '256px' }}
                  // defaultValue={initialEmail}
                  onChange={(event: any) => userEmailHandler(event)}
                />
              </Grid>
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Password
                </InputLabel>
                <TextField
                  size="small"
                  type="password"
                  placeholder="Please enter password"
                  name="password"
                  sx={{ width: '256px' }}
                  onChange={(event: any) => userPasswordHandler(event)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Phone no.
                </InputLabel>
                <TextField
                  name="phone"
                  size="small"
                  type="tel"
                  placeholder="Please enter phone number"
                  sx={{ width: '256px' }}
                  value={userInfoToEdit.phone}
                  autoComplete="new-password"
                  onChange={(event: any) => userPhoneHandler(event)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                  }}
                >
                  Assign role/Roles
                </InputLabel>
                <Autocomplete
                  sx={{ width: '100%' }}
                  popupIcon={<img src={DropdownClosed} />}
                  multiple
                  size="small"
                  id="tags-outlined"
                  options={roles}
                  getOptionLabel={(option: any) => option.name}
                  filterSelectedOptions
                  value={userInfoToEdit.roles}
                  onChange={(event, value) => roleChangeHandler(event, value)}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="flex-end" paddingTop={'32px'}>
            <Grid item>
              <Navigation
                style={{
                  padding: '0px 32px',
                  textDecoration: 'none',
                  color: '#525862',
                  fontWeight: '700',
                  fontFamily: 'Mulish',
                  cursor: 'pointer',
                }}
                onClick={dialogCloseHandler}
              >
                Cancel
              </Navigation>
              <SubmitButton
                onClick={submitHandler}
                //   disabled={
                //     !(
                //       userInfoToEdit.first_name &&
                //       userInfoToEdit.last_name &&
                //       userInfoToEdit.email &&
                //       userInfoToEdit.password &&
                //       userInfoToEdit.phone
                //     )
                //   }
                text="Update"
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
}

export default EditUser;
