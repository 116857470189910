import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PaginationItem from '@mui/material/PaginationItem';
import Typography from '@mui/material/Typography';
import { secondaryColor } from '../../theme';
import { getAllProjects } from '../../api/project';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import Spinner from '../../components/layout/Spinner';

type Props = {};

const ProjectList = (props: Props) => {
  const [projects, setProjects] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(projects.length / rowsPerPage)
  );
  const navigate = useNavigate();

  let filteredData: any;
  filteredData = filteredData ? filteredData : projects;
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);

  const orgListClickHandler = (id: string, name: string) => {
    navigate('/organisation-list', {
      state: { projectId: id, projectName: name, callFrom: 'projectList' },
    });
  };

  const formListClickHandler = (id: string, name: string, alias: string) => {
    navigate('/form-list', {
      state: { projectId: id, projectName: name, projectAlias: alias },
    });
  };

  const backClickHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllProjects();
      if (data) {
        setProjects(data);
        setIsLoading(false);
        setNoOfPages(Math.ceil(data.length / rowsPerPage));
      }
    };
    fetchData();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <Box>
      <Box
        sx={{ m: ['2.25rem 2rem 2rem 2rem'] }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            onClick={backClickHandler}
            style={{ paddingRight: '8px', cursor: 'pointer' }}
            src={ArrowLeft}
            alt="left arrow"
          />
          <Typography variant="h-1">Project List</Typography>
        </div>
      </Box>
      <Box>
        <TableContainer sx={{ width: '95%', m: '2rem' }}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  width="3.96%"
                  style={{ fontWeight: '700' }}
                ></TableCell>

                <TableCell
                  width="27.2%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Project Name
                </TableCell>
                <TableCell
                  style={{ fontWeight: '700', width: '27.2%' }}
                  align="left"
                >
                  Project Key
                </TableCell>
                <TableCell
                  width="37.2%"
                  style={{ fontWeight: '700' }}
                  align="left"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((val: any, index: number) => (
                  <TableRow key={index.toString() + '..' + val.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      key={index.toString() + '1' + val.name}
                    >
                      {(page - 1) * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '18px',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }}
                      align="left"
                      key={index.toString() + '2' + val.name}
                    >
                      {val.name}
                    </TableCell>
                    <TableCell
                      sx={{ padding: '18px', fontSize: '14px' }}
                      align="left"
                      key={index.toString() + '3' + val.name}
                    >
                      {val.alias}
                    </TableCell>
                    <TableCell
                      align="left"
                      key={index.toString() + '4' + val.name}
                    >
                      <Box>
                        <Button
                          sx={{ padding: 0, fontWeight: 100 }}
                          onClick={() => orgListClickHandler(val._id, val.name)}
                        >
                          View Organisation list
                        </Button>
                        <Button
                          sx={{ paddingLeft: '16px', fontWeight: 100 }}
                          onClick={() =>
                            formListClickHandler(val._id, val.name, val.alias)
                          }
                        >
                          View Form list
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mr="2rem" mt="2rem" pb="1rem">
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="end"
        >
          {filteredData.length > rowsPerPage && (
            <Typography variant="body-1">
              {(page - 1) * rowsPerPage + 1}-
              {projects.length < page * rowsPerPage
                ? projects.length
                : page * rowsPerPage}{' '}
              of {projects.length} items
            </Typography>
          )}
          {filteredData.length > 0 && (
            <Pagination
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: previousIcon,
                    next: nextIcon,
                  }}
                  {...item}
                />
              )}
              page={page}
              count={noOfPages}
              shape="rounded"
              onChange={handleChangePage}
            />
          )}
          {filteredData.length === 0 && (
            <Typography variant="body-1">Zero projects present</Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};
export default ProjectList;
