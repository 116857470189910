import { REQUEST_GET } from "../utils/const";
import { requestAPI } from "../utils/fetch";
import { updateAlert } from "../utils/utils";

export const getAllEntities = async (orgId : string) => {
    try {
        const response = await requestAPI({
            type: REQUEST_GET,
            url: `/api/org-entities?org_id=${orgId}`,
          });
      if (!response) {
        return false;
      }
      return response;
    } catch (error: any) {
      updateAlert({ state: true, severity: 'error', message: error.message });
    }
  };

  export const getEntity = async (entityId : any,orgId: any) => {
    try {
        const response = await requestAPI({
            type: REQUEST_GET,
            url: `/api/entity?id=${entityId}&org_id=${orgId}`,
          });
      if (!response) {
        return false;
      }
      return response;
    } catch (error: any) {
      updateAlert({ state: true, severity: 'error', message: error.message });
    }
  };