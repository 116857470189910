import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
} from '@mui/material';
import SPLogoSmall from '../../assets/png/SPLogoSmall.png';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import { useState } from 'react';
// import { token } from '../../utils/StateStore';
import { logoutUser } from '../../api/authenticate';
import UpdatePassword from '../modal/UpdatePassword';
import { token } from '../../utils/StateStore';
import { neutralBodyColor } from '../../theme';
import EditProfile from '../modal/EditProfile';

type Props = { isAuthenticated: boolean };

const Navbar = ({ isAuthenticated }: Props) => {
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const handlePasswordModalClose = () => setOpenPasswordModal(false);
  const handlePasswordModalOpen = () => setOpenPasswordModal(true);
  const handleProfileModalOpen = () => setOpenProfileModal(true);
  const handleProfileModalClose = () => setOpenProfileModal(false);
  const navigate = useNavigate();
  const handleLoginLogout = async (action: string) => {
    if (action === 'login') {
      navigate('/login');
    } else {
      const success = await logoutUser();
      if (success) {
        navigate('/login');
      }
    }
  };

  const handleProfileHandler = async () => {
    handleProfileModalOpen();
  };

  const handleChangePassword = async () => {
    handlePasswordModalOpen();
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loggedInUserLinks = () => (
    <Box
      display="flex"
      flexDirection="column"
      width="12.5rem"
      alignItems="flex-start"
      color="black"
    >
      <Button
        sx={{ color: neutralBodyColor }}
        onClick={() => handleProfileHandler()}
      >
        Profile
      </Button>
      {token?.value?.userInfo?.first_login !== 'pending' ? (
        <Button
          sx={{ color: neutralBodyColor }}
          onClick={() => handleChangePassword()}
        >
          Change Password
        </Button>
      ) : (
        <></>
      )}

      <Button
        sx={{ color: neutralBodyColor }}
        onClick={() => handleLoginLogout('logout')}
      >
        Logout
      </Button>
    </Box>
  );
  const loggedOutUserLinks = () => (
    <Button onClick={() => handleLoginLogout('login')}>Login</Button>
  );
  const handleLogoClick = () => {
    navigate('/');
  };
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="4rem"
        sx={{
          boxShadow: '0px 1px 0px rgba(24, 25, 31, 0.12)',
        }}
      >
        <Box
          ml="1.5rem"
          display="flex"
          alignItems="center"
          onClick={handleLogoClick}
        >
          <Box
            mr="0.5rem"
            height="1.5rem"
            width="1.5rem"
            sx={{
              backgroundImage: `url(${SPLogoSmall})`,
              backgroundRepeat: 'no-repeat',
              objectFit: 'contain',
              backgroundSize: 'cover',
            }}
          />
          <Typography
            ml="0.5rem"
            fontWeight="800"
            fontSize="1.125rem"
            lineHeight="1.25rem"
            fontStyle="normal"
          >
            Workspace
          </Typography>
        </Box>
        {isAuthenticated !== null && isAuthenticated ? (
          <>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar
                sx={{ m: '1rem 1.5rem 1rem 0', height: '2rem', width: '2rem' }}
              >
                {token?.value?.userInfo?.first_name[0] +
                  '' +
                  token?.value?.userInfo?.last_name[0]}
              </Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 40,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>{loggedInUserLinks()}</MenuItem>
            </Menu>
          </>
        ) : (
          loggedOutUserLinks()
        )}
      </Box>
      <UpdatePassword
        open={openPasswordModal}
        handleClose={handlePasswordModalClose}
      />
      <EditProfile
        open={openProfileModal}
        handleClose={handleProfileModalClose}
      />
    </>
  );
};

export default Navbar;
