import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  Switch,
  styled,
  Select,
  MenuItem,
  ListItemText,
  Stack,
} from '@mui/material';
import NoTemplates from '../../assets/png/NoTemplates.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import SearchField from '../common/SearchField';
import { filterData, updateAlert, updateSnackbar } from '../../utils/utils';
import DropdownTick from '../../assets/png/DropdownTick.png';
import { filters, REQUEST_DELETE, REQUEST_GET } from '../../utils/const';
import { DropDownIcon } from '../Role/CreateRole';
import { requestAPI } from '../../utils/fetch';
import styles from './ProjectTemplate.module.css';
import Confirm from '../../components/modal/Confirm';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { secondaryColor } from '../../theme';
import { updateTemplate } from '../../api/template';
import PreviewWithAccordian from '../../components/modal/Preview/PreviewWithAccordion';
import Spinner from '../../components/layout/Spinner';

const ProjectTemplate = () => {
  const navigate = useNavigate();
  const createTemplateHandler = () => {
    navigate('/createTemplate', { state: { templateId: '' } });
  };
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [templates, setTemplates] = useState<any[]>([]);
  const [inputText, setInputText] = useState('');
  const [filterName, setFilterName] = useState(filters[0]);
  const [idToDelete, setIdToDelete] = useState('');
  const [testOpen, setTestOpen] = useState(false);
  const [templateToDeleteName, setTemplateToDeleteName] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(templates.length / rowsPerPage)
  );
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    (async () => {
      try {
        const response = await requestAPI({
          type: REQUEST_GET,
          url: '/api/project-templates',
        });
        if (response) {
          setTemplates(response.project_templates);
          setNoOfPages(
            Math.ceil(response.project_templates.length / rowsPerPage)
          );
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    })();
  }, []);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 4,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? '#177ddc' : '#0451C4',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12.63,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 100,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 100,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  const filterChange = (event: any) => {
    setFilterName(event.target.value);
  };
  const handleActiveChange = async (id: any, index: number) => {
    //TODO: Set in template post api by changing the state of disabled attribute
    //TODO: refetch the templates api, set in templates
    const arr = [...templates];
    // arr[index].disabled = !arr[index].disabled;
    let template = arr.find((temp) => temp._id == id);
    template.disabled = !template.disabled;
    await updateTemplate({ id: id, disabled: template.disabled }, []);

    setTemplates(arr);
  };
  let filteredData: any;
  filteredData = filteredData ? filteredData : templates;
  if (filterName === 'Active') {
    filteredData = filteredData.filter(
      (item: any, i: number) => templates[i].disabled === false
    );
  } else if (filterName === 'Deactivated') {
    filteredData = filteredData.filter(
      (item: any, i: number) => templates[i].disabled === true
    );
  }
  filteredData = filterData(filteredData, inputText, 'templates');
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);
  const editClickHandler = (id: any) => {
    navigate('/editTemplate', { state: { templateId: id } });
  };
  const testClose = async (confirm: boolean) => {
    setTestOpen(confirm);
    if (confirm === true) {
      try {
        const response = await requestAPI({
          type: REQUEST_DELETE,
          url: '/api/project-template',
          data: { project_template_id: idToDelete },
        });
        if (response) {
          const templatesResponse = await requestAPI({
            type: REQUEST_GET,
            url: '/api/project-templates',
          });

          setTemplates(templatesResponse.project_templates);
          updateSnackbar({
            state: true,
            severity: 'error',
            message: 'Template removed',
          });
        } else {
          console.error('error in deleting template');
        }
        setTestOpen(false);
      } catch (error: any) {
        setTestOpen(false);
        updateAlert({ state: true, severity: 'error', message: error.message });
      }
    }
  };
  const deleteClickHandler = async (id: any) => {
    setTestOpen(true);

    setIdToDelete(id);
    const response = await requestAPI({
      type: REQUEST_GET,
      url: `/api/project-template?project_template_id=${id}`,
    });
    setTemplateToDeleteName(response.project_template.name);
  };
  let resetHandler = (textRef: any) => {
    setInputText('');
    textRef.current.value = '';
  };

  return (
    <>
      <div style={{ width: window.innerWidth - 283 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="2rem"
        >
          <Typography variant="h-1">Project Template</Typography>
          {templates.length >= 1 && (
            <Button variant="contained" onClick={createTemplateHandler}>
              Create
            </Button>
          )}
        </Box>
        {templates.length >= 1 ? (
          isLoading ? (
            <Spinner />
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  paddingTop: '4px',
                  paddingBottom: '10px',
                }}
              >
                <SearchField
                  onchange={inputHandler}
                  onClear={resetHandler}
                  defaultValue={inputText}
                />
                <div style={{ paddingLeft: '60px' }}>
                  <Select
                    size="small"
                    value={filterName}
                    onChange={filterChange}
                    IconComponent={DropDownIcon}
                    renderValue={(selected) => `View: ${selected}`}
                  >
                    {filters.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        {name === filterName && (
                          <img src={DropdownTick} alt="dropdown tick" />
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <TableContainer>
                <Table>
                  <TableBody>
                    {filteredData
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map((item: any, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: '3.563rem',
                          }}
                          className={styles.rowDiv}
                        >
                          <TableCell component="th" scope="row">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                cursor: 'pointer',
                                display: 'inline-block',
                              }}
                              onClick={() => editClickHandler(item?._id)}
                            >
                              {item.name}
                            </div>
                            <div className={styles.previewDeleteDiv}>
                              <button
                                className={styles.previewButton}
                                onClick={() => {
                                  setTemplateId(item?._id);
                                  setPreviewOpen(true);
                                }}
                              >
                                Preview
                              </button>
                              <button
                                className={styles.deleteButton}
                                onClick={() => deleteClickHandler(item?._id)}
                              >
                                Delete
                              </button>
                            </div>
                          </TableCell>
                          <TableCell
                            sx={{ justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <AntSwitch
                              checked={!filteredData[index].disabled}
                              onChange={(event) =>
                                handleActiveChange(item?._id, index)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mr="2rem" mt="2rem" pb="1rem">
                <Stack
                  spacing={2}
                  direction={filteredData.length === 0 ? 'column' : 'row'}
                  alignItems="center"
                  justifyContent="end"
                >
                  {filteredData.length > rowsPerPage && (
                    <Typography variant="body-1">
                      {(page - 1) * rowsPerPage + 1}-
                      {filteredData.length < page * rowsPerPage
                        ? filteredData.length
                        : page * rowsPerPage}{' '}
                      of {filteredData.length} templates
                    </Typography>
                  )}
                  {filteredData.length > 0 && (
                    <Pagination
                      renderItem={(item) => (
                        <PaginationItem
                          components={{
                            previous: previousIcon,
                            next: nextIcon,
                          }}
                          {...item}
                        />
                      )}
                      page={page}
                      count={noOfPages}
                      shape="rounded"
                      onChange={handleChangePage}
                    />
                  )}
                  <div>
                    {filteredData.length === 0 && (
                      <Typography variant="body-1">
                        Zero templates present
                      </Typography>
                    )}
                  </div>
                </Stack>
              </Box>
            </>
          )
        ) : isLoading ? (
          <Spinner />
        ) : (
          <div
            style={{
              display: 'flex',
              paddingTop: '62px',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <img src={NoTemplates} alt="no templates" />
            </div>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                paddingTop: '26px',
                flexDirection: 'column',
              }}
            >
              You haven't created any template
              <span style={{ height: '44px' }} />
              <Button variant="contained" onClick={createTemplateHandler}>
                Create new template
              </Button>
            </div>
          </div>
        )}
      </div>
      <PreviewWithAccordian
        open={previewOpen}
        handleClose={(previewOpen: boolean) => {
          setPreviewOpen(previewOpen);
        }}
        modalType="template"
        modalId={templateId}
        isNewProjectCreation={false}
        callFrom="editTemplate"
      />
      <Confirm
        open={testOpen}
        handleClose={testClose}
        buttonText={'Delete'}
        mainHeading={'Delete this template?'}
        secondaryHeading={`"${templateToDeleteName}" will be permanently deleted.`}
      />
    </>
  );
};

export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export default ProjectTemplate;
