import { TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddOption from '../../assets/png/AddOption.png';
import { FieldTypes } from '../../utils/const';
import DeleteOption from '../../assets/png/DeleteOption.png';
import { Checkbox, Radio } from '@mui/material';

type Props = {
  addOption: (pos: number) => void;
  removeOption: (pos: number) => void;
  pos: number;
  option: any;
  displayRemove: boolean;
  updateOptionValue: (
    value: { key: string; value: string | boolean },
    pos: number,
    valueType?: string
  ) => void;
  displayAdd: boolean;
  fieldType?: string;
  callFrom?: string;
  editable?: boolean;
};

const Option = ({
  addOption,
  removeOption,
  pos,
  option,
  displayRemove,
  updateOptionValue,
  displayAdd,
  fieldType,
  callFrom,
  editable,
}: Props) => {
  const [optionValue, setOptionValue] = useState({
    options: { key: '', value: '' },
    values: { key: '', value: '' },
  });
  useEffect(() => {
    setOptionValue(option);
  }, [option]);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof optionValue != 'string') {
      setOptionValue((prevState) => {
        return {
          ...prevState,
          [event.target.name]: {
            key:
              event.target.name === 'options'
                ? optionValue.options.key
                : optionValue.values.key,
            value:
              event.target.name === 'values'
                ? event.target.checked
                : event.target.value,
          },
        };
      });
    }
    updateOptionValue(
      {
        key:
          event.target.name === 'options'
            ? optionValue.options.key
            : optionValue.values.key,
        value:
          event.target.name === 'values'
            ? event.target.checked
            : event.target.value,
      },
      pos,
      event.target.name
    );
    if (event.target.name === 'options' && optionValue.values.key) {
      updateOptionValue(
        { key: optionValue.values.key || '', value: event.target.value },
        (pos = fieldType === FieldTypes.CHECKBOX ? pos : 0),
        'values'
      );
    }
  };
  return (
    <>
      {(editable && (
        <div
          style={{
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {fieldType === FieldTypes.CHECKBOX && (
            <Checkbox
              name="values"
              onChange={onChange}
              checked={
                typeof optionValue === 'string'
                  ? false
                  : optionValue.values.key
                  ? true
                  : false
              }
              disabled={callFrom === 'projectCreate' || !editable}
            />
          )}
          {fieldType === FieldTypes.MULTIPLECHOICE && (
            <Radio
              name="values"
              onChange={onChange}
              checked={
                typeof optionValue === 'string'
                  ? false
                  : optionValue.values.key
                  ? true
                  : false
              }
              disabled={callFrom === 'projectCreate' || !editable}
            />
          )}
          <TextField
            value={
              typeof optionValue === 'string'
                ? optionValue
                : optionValue.options.value
            }
            onChange={onChange}
            name="options"
            placeholder="Add Option Name"
            size="small"
            sx={{ width: '95%', height: '40px', paddingLeft: '16px' }}
            disabled={!editable}
          />
          {displayAdd && (
            <img
              onClick={() => addOption(pos)}
              src={AddOption}
              alt="add option"
              style={{ cursor: 'pointer', margin: '0 0.3rem' }}
            />
          )}

          {displayRemove && (
            <img
              onClick={() => removeOption(pos)}
              src={DeleteOption}
              alt="delete option"
              style={{ cursor: 'pointer', marginRight: '0.3rem' }}
            />
          )}
        </div>
      )) ||
        (optionValue.values.key && (
          <Typography
            style={{
              fontSize: '14px',
              color: '#36454F',
              fontWeight: '400',
              fontStyle: 'Mulish',
            }}
          >
            {optionValue.options.value + ',' || ''} &nbsp;
          </Typography>
        ))}
      <div style={{ height: '1rem' }} />
    </>
  );
};

export default Option;
