import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchField from '../common/SearchField';
import { useEffect, useState } from 'react';
import { filterData } from '../../utils/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PaginationItem from '@mui/material/PaginationItem';
import { secondaryColor } from '../../theme';
import { getAllTemplates } from '../../api/template';
import PreviewWithAccordian from '../../components/modal/Preview/PreviewWithAccordion';
import Spinner from '../../components/layout/Spinner';
type Props = {};

const NewProject = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [project, setProject] = useState<any>([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(project.length / rowsPerPage)
  );
  const inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const filteredData = filterData(project, searchText, 'project');
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);
  const [previewModalView, setPreviewModalView] = useState(false);
  const [templateId, setTemplateId] = useState('');

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  let resetHandler = (textRef: any) => {
    setSearchText('');
    textRef.current.value = '';
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllTemplates();
      if (data) {
        setProject(data);
        setIsLoading(false);
        setNoOfPages(Math.ceil(data.length / rowsPerPage));
      }
    };
    fetchData();
  }, []);
  const previewHandler = (id: string) => {
    setTemplateId(id);
    setPreviewModalView(true);
  };
  return (
    <Box>
      <Box sx={{ m: ['2.25rem 0 2rem 2rem'] }}>
        <Typography variant="h-1">Create Project</Typography>
      </Box>
      <Box sx={{ m: ['2.25rem 0 2rem 0'] }}>
        <SearchField
          onchange={inputHandler}
          onClear={resetHandler}
          defaultValue={searchText}
        />
      </Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box sx={{ m: ['2.25rem 0 2rem 2rem'] }}>
            <Typography variant="h-2">
              Select a template to create a project
            </Typography>
          </Box>
          <Box>
            <TableContainer sx={{ width: '95%', m: '2rem' }}>
              <Table aria-label="sticky table">
                <TableBody>
                  {filteredData
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((val: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box>
                              <Typography>{val.name}</Typography>
                            </Box>
                            <Box>
                              <Button onClick={() => previewHandler(val._id)}>
                                Preview
                              </Button>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box mr="2rem" mt="2rem" pb="1rem">
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="end"
            >
              {filteredData.length > rowsPerPage && (
                <Typography variant="body-1">
                  {(page - 1) * rowsPerPage + 1}-
                  {project.length < page * rowsPerPage
                    ? project.length
                    : page * rowsPerPage}{' '}
                  of {project.length} items
                </Typography>
              )}
              {filteredData.length > 0 && (
                <Pagination
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        previous: previousIcon,
                        next: nextIcon,
                      }}
                      {...item}
                    />
                  )}
                  page={page}
                  count={noOfPages}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              )}
              {filteredData.length === 0 && (
                <Typography variant="body-1">Zero projects present</Typography>
              )}
            </Stack>
          </Box>
        </>
      )}
      <PreviewWithAccordian
        open={previewModalView}
        handleClose={(previewOpen: boolean) => {
          setPreviewModalView(previewOpen);
        }}
        modalId={templateId}
        modalType="template"
        isNewProjectCreation={true}
      />
    </Box>
  );
};

export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};
export default NewProject;
