import {
  Modal,
  Box,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from '@mui/material';
import { useState } from 'react';
import EyeOff from '../../assets/png/Eye_off.png';
import EyeOpen from '../../assets/png/Eye.png';
import {
  updateLoggedInPasswordHandler,
  logoutUser,
} from '../../api/authenticate';
import { useNavigate } from 'react-router';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const UpdatePassword = ({ open, handleClose }: Props) => {
  const navigate = useNavigate();

  const [passwords, setPasswords] = useState({
    currPassword: '',
    newPassword: '',
    confirmPassword: '',
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, [event.target.name]: event.target.value });
  };
  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (passwords.newPassword !== passwords.confirmPassword) {
        //[TODO] tell user
        return;
      }
      const updatedPassword = await updateLoggedInPasswordHandler({
        new_password: passwords.newPassword,
        old_password: passwords.currPassword,
      });

      if (!updatedPassword) {
        return false;
      }
      const success = await logoutUser();
      if (success) {
        handleClose();
        navigate('first-time/sucess');
      }
    } catch (error) {}
  };
  const style = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 410,
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow:
      '0px 1.2px 3.6px rgba(0, 10, 24, 0.11), 0px 6.4px 14.4px rgba(0, 10, 24, 0.13), 0px 2px 8px rgba(0, 10, 24, 0.08)',
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box m="2rem 0 1.5rem 2rem">
          <Typography fontSize="1.5rem" lineHeight="2rem" fontStyle="normal">
            Change password
          </Typography>
        </Box>
        <Box>
          <form onSubmit={onSubmitHandler}>
            <Box ml="2rem">
              <OutlinedInput
                value={passwords.currPassword}
                type={'password'}
                onChange={onChange}
                name={'currPassword'}
                placeholder="Current password"
                sx={{
                  height: '2.5rem',
                  width: '21.625rem',
                  marginBottom: '1.75rem',
                }}
              />
            </Box>
            <Box ml="2rem" mb=".5rem">
              <OutlinedInput
                type={passwords.showNewPassword ? 'text' : 'password'}
                value={passwords.newPassword}
                name={'newPassword'}
                sx={{
                  height: '2.5rem',
                  width: '21.625rem',
                  marginBottom: '1.75rem',
                }}
                onChange={onChange}
                placeholder="New password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setPasswords({
                          ...passwords,
                          showNewPassword: !passwords.showNewPassword,
                        });
                      }}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => {
                        event.preventDefault();
                      }}
                    >
                      {passwords.showNewPassword ? (
                        <img src={EyeOpen} alt="logo" />
                      ) : (
                        <img src={EyeOff} alt="logo" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Box ml="2rem">
              <OutlinedInput
                sx={{
                  height: '2.5rem',
                  width: '21.625rem',
                  marginBottom: '1.75rem',
                }}
                type={passwords.showConfirmPassword ? 'text' : 'password'}
                value={passwords.confirmPassword}
                name={'confirmPassword'}
                onChange={onChange}
                placeholder="Confirm Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setPasswords({
                          ...passwords,
                          showConfirmPassword: !passwords.showConfirmPassword,
                        });
                      }}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => {
                        event.preventDefault();
                      }}
                    >
                      {passwords.showConfirmPassword ? (
                        <img src={EyeOpen} alt="logo" />
                      ) : (
                        <img src={EyeOff} alt="logo" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>

            <Box display="flex" justifyContent="right" m="2rem 2rem 2rem 2rem">
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" type="submit">
                Change Password
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdatePassword;
