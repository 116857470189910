import {
  Modal,
  Box,
  Typography,
  Grid,
  Link as Navigation,
  InputLabel,
  TextField,
  Tab,
  Tabs,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import SubmitButton from '../common/SubmitButton';
import AddDivider from '../ProjectTemplate/AddDivider';
import styles from '../../components/modal/AddOrganizationStructue.module.css';
import * as XLSX from 'xlsx';
import { updateSnackbar } from '../../utils/utils';
import CloseIcon from '../../assets/png/CrossIcon.png';
import FileIcon from '../../assets/png/CSVFileIcon.png';
import MultipleDropDown from '../common/MultipleDropDown';
import SpAccordion from '../common/Accordion/SpAccordion';
import { createEntity } from '../../api/entity';
import { entityInfo } from '../../utils/StateStore';

type Props = {
  open: boolean;
  handleClose: () => void;
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface IEntityInfo {
  first_name: string;
  last_name: string;
  gender: string;
  entity_code: string;
  phone: string;
  email: string;
}
interface Students {
  isActive: boolean;
  data: IEntityInfo;
}
interface ICreateEntity {
  org_id: string;
  sublevel_ids: string[];
  entities: IEntityInfo[];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: '0px' }}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AddEntity = ({ open, handleClose }: Props) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow:
      '0px 1.2px 3.6px rgba(0, 10, 24, 0.11), 0px 6.4px 14.4px rgba(0, 10, 24, 0.13), 0px 2px 8px rgba(0, 10, 24, 0.08)',
    borderRadius: '8px',
    padding: '32px',
    width: '600px',
    maxHeight: '560px',
  };
  const [value, setValue] = React.useState(0);
  const [orgInfo] = useState<any>(() => entityInfo?.value?.orgInfo);
  const [sublevel_Ids, setSublevel_id] = useState<string[]>([]);
  const [accordionActiveIndex, setAccordionActiveIndex] = useState<
    number | undefined
  >(0);
  const [iEntityInfo, setIEntityInfo] = useState<Students[]>([
    {
      isActive: true,
      data: {
        first_name: '',
        last_name: '',
        gender: 'male',
        entity_code: '',
        phone: '',
        email: '',
      },
    },
  ]);
  const [entitiesExcel, setEntitiesExcel] = useState<IEntityInfo[]>([]);

  const singleSelectedHandler = (id: string, orgSublevelSrc?: any[]) => {
    const selectedLevels: string[] = [];
    if (id && orgSublevelSrc) {
      findLevelRecursive(orgSublevelSrc, id);
    }

    function findLevelRecursive(orgSublevelSrc: any[], id: string) {
      for (let i = 0; i < orgSublevelSrc.length; i++) {
        const level = orgSublevelSrc[i];
        if (level.sublevel_id === id) {
          selectedLevels.unshift(level.sublevel_id);
          return true;
        }
        if (level.child && level.child.length >= 0) {
          const res = findLevelRecursive(level.child, id);
          if (res) {
            selectedLevels.unshift(level.sublevel_id);
            return true;
          }
        }
      }
    }
    setSublevel_id(selectedLevels);
    if (sublevel_Ids) {
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fileRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<any>();

  const handleClick = () => {
    fileRef!.current!.click();
  };

  const removeFile = () => {
    setFileName('');
    setEntitiesExcel([]);
  };

  const handleDownloadTemplate = () => {
    var csv = '';

    csv +=
      'First name' +
      ',' +
      'Last name' +
      ',' +
      'Gender' +
      ',' +
      'Student code' +
      ',' +
      'Phone number (Optional)' +
      ',' +
      'Email (Optional)';

    csv += '\n';
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `StudentTemplate.csv`;
    hiddenElement.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    const data = await file?.arrayBuffer();
    const excelFile = XLSX.read(data);
    const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]];
    const excelHeaders = XLSX.utils.sheet_to_json(excelSheet, { header: 1 });
    const excelJson = XLSX.utils.sheet_to_json(excelSheet);
    if (excelHeaders && excelHeaders[0]) {
      let arr = [];
      for (let one of Object.keys(excelSheet)) {
        if (one && one[0] && one[0].charCodeAt(0) - 65 >= 6) arr.push(one);
      }
      if (arr.length > 0) {
        updateSnackbar({
          state: true,
          severity: 'warning',
          message: `Un-necessary data is present in uploaded file at column's ${arr.join(
            ', '
          )}.`,
        });
      }
      setFileName(file?.name);
      const entities: IEntityInfo[] = [];
      excelJson.forEach((x: any) => {
        if (x?.['First name'] && x?.['Last name']) {
          const entityInfo: IEntityInfo = {
            first_name: x?.['First name'],
            last_name: x?.['Last name'],
            gender: x?.['Gender'].toLowerCase(),
            entity_code: x?.['Entity code'],
            phone: x?.['Phone number (Optional)'] ?? '',
            email: x?.['Email (Optional)'] ?? '',
          };
          if (entityInfo) entities.push(entityInfo);
        }
      });
      setEntitiesExcel(entities ?? []);
    } else {
      updateSnackbar({
        state: true,
        severity: 'error',
        message:
          "Uploaded file doesn't contain valid data! Please upload valid file",
      });
    }
  };

  const uploadExcelHandler = async () => {
    const payload: ICreateEntity = {
      entities: entitiesExcel,
      org_id: orgInfo.id,
      sublevel_ids: sublevel_Ids,
    };
    const res = await createEntity(payload);
    if (res) {
      if (res.entities.length !== payload.entities.length) {
        updateSnackbar({
          message: 'Not all entities are created !',
          severity: 'error',
          state: true,
        });
      } else {
        updateSnackbar({
          message: 'Excel Uploaded Successfully !',
          severity: 'success',
          state: true,
        });
        handleClose();
      }
    }
  };

  const createEntityManualHandler = async () => {
    const entities: IEntityInfo[] = [];
    iEntityInfo.forEach((element) => {
      if (element.data.first_name && element.data.last_name)
        entities.push(element.data);
    });
    if (!entities.length) {
      updateSnackbar({
        message: 'Please Add Entity Info !',
        severity: 'warning',
        state: true,
      });
      return;
    }
    const payload: ICreateEntity = {
      entities: entities,
      org_id: orgInfo.id,
      sublevel_ids: sublevel_Ids,
    };
    const res = await createEntity(payload);
    if (res) {
      if (res.entities.length !== payload.entities.length) {
        updateSnackbar({
          message: 'Not all entities are created !',
          severity: 'error',
          state: true,
        });
      } else {
        updateSnackbar({
          message: 'Entities Saved Successfully !',
          severity: 'success',
          state: true,
        });
        handleClose();
      }
    }
  };

  const addStudent = () => {
    const studentData: IEntityInfo = {
      first_name: '',
      last_name: '',
      gender: 'male',
      entity_code: '',
      phone: '',
      email: '',
    };
    const newStudent = {
      isActive: true,
      data: studentData,
    } as Students;

    setIEntityInfo([...iEntityInfo, newStudent]);
    editClickHandler(iEntityInfo?.length);
  };

  const deleteClickHandler = (index: number) => {
    let students = [...iEntityInfo];
    students.splice(index, 1);
    setIEntityInfo(students);
    editClickHandler(students?.length - 1);
  };

  const editClickHandler = (index: any) => {
    setAccordionActiveIndex(index);
  };

  const UpdateState = (val: string, index: number, stateVariable: string) => {
    let info = iEntityInfo;
    info[index].data = { ...info[index].data, [stateVariable]: val };
    setIEntityInfo([...info]);
  };

  const genderChangeHandler = (event: any, index: number) => {
    const val = event.target.value;
    UpdateState(val, index, 'gender');
  };
  const fnameChangeHandler = (event: any, index: number) => {
    const val = event.target.value;
    UpdateState(val, index, 'first_name');
  };
  const lnameChangeHandler = (event: any, index: number) => {
    const val = event.target.value;
    UpdateState(val, index, 'last_name');
  };
  const emailChangeHandler = (event: any, index: number) => {
    const val = event.target.value;
    UpdateState(val, index, 'email');
  };
  const phoneNumberChangeHandler = (event: any, index: number) => {
    const val = event.target.value;
    UpdateState(val, index, 'phone');
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box m="0 0 1.5rem 0rem">
            <Typography
              fontSize="1.5rem"
              lineHeight="2rem"
              fontStyle="normal"
              fontWeight={700}
            >
              Add an entity
            </Typography>
          </Box>
          <Box>
            <Grid
              container
              sx={{
                marginTop: '15px',
                width: '100%'
              }}
            >
              <Grid item sx={{ width: '30%', marginBottom: '10px' }}>
                <InputLabel
                  sx={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontFamily: 'Mulish',
                    width: '50%',
                  }}
                >
                  Select class
                </InputLabel>

                <MultipleDropDown
                  checkBoxRequired={false}
                  singleSelectedHandler={singleSelectedHandler}
                  showDisabled={false}
                />
              </Grid>
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Add manually"
                      {...a11yProps(0)}
                      sx={{ textTransform: 'none', fontSize: '16px' }}
                    />
                    <Tab
                      label="Import from CSV file"
                      {...a11yProps(1)}
                      sx={{ textTransform: 'none', fontSize: '16px' }}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <Box
                    sx={{
                      maxHeight: '250px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    {iEntityInfo?.map((student: Students, i: number) => {
                      let isActive = i === accordionActiveIndex;
                      const title = `${student.data.first_name} ${student.data.last_name}`;

                      return (
                        <Box sx={{ marginRight: '10px' }} key={`Box${i}`}>
                          <SpAccordion
                            mode="edit"
                            pos={i}
                            editHandler={() => editClickHandler(i)}
                            deleteHandler={() => deleteClickHandler(i)}
                            isActive={isActive}
                            title={title.trim() ? title : `Student - ${i + 1}`}
                            setAccordionActiveIndex={setAccordionActiveIndex}
                          >
                            <Grid container sx={{ margin: '15px' }}>
                              <Grid
                                item
                                sx={{ fontWeight: 700, marginBottom: '36px' }}
                              >
                                {`Student - ${i + 1}`}
                              </Grid>
                              <Grid
                                container
                                justifyContent="space-between"
                                sx={{ width: '100%', marginBottom: '36px' }}
                              >
                                <Grid item>
                                  <InputLabel
                                    sx={{
                                      lineHeight: '16px',
                                      fontSize: '12px',
                                      fontFamily: 'Mulish',
                                    }}
                                  >
                                    First name
                                  </InputLabel>
                                  <TextField
                                    size="small"
                                    sx={{ width: '256px' }}
                                    value={student.data.first_name}
                                    onChange={(e) => fnameChangeHandler(e, i)}
                                  />
                                </Grid>
                                <Grid item>
                                  <InputLabel
                                    sx={{
                                      lineHeight: '16px',
                                      fontSize: '12px',
                                      fontFamily: 'Mulish',
                                    }}
                                  >
                                    Last name
                                  </InputLabel>
                                  <TextField
                                    size="small"
                                    sx={{ width: '256px' }}
                                    value={student.data.last_name}
                                    onChange={(e) => lnameChangeHandler(e, i)}
                                  />
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                justifyContent="space-between"
                                sx={{ width: '100%', marginBottom: '36px' }}
                              >
                                <Grid item>
                                  <InputLabel
                                    sx={{
                                      lineHeight: '16px',
                                      fontSize: '12px',
                                      fontFamily: 'Mulish',
                                      fontWeight: 700,
                                    }}
                                  >
                                    Gender
                                  </InputLabel>
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                    >
                                      <FormControlLabel
                                        value="male"
                                        control={<Radio size="small" />}
                                        label="Male"
                                        checked={student.data.gender === 'male'}
                                        onChange={(e) =>
                                          genderChangeHandler(e, i)
                                        }
                                      />
                                      <FormControlLabel
                                        value="female"
                                        control={<Radio size="small" />}
                                        label="Female"
                                        sx={{ color: '#000A18' }}
                                        checked={
                                          student.data.gender === 'female'
                                        }
                                        onChange={(e) =>
                                          genderChangeHandler(e, i)
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item>
                                  <InputLabel
                                    sx={{
                                      lineHeight: '16px',
                                      fontSize: '12px',
                                      fontFamily: 'Mulish',
                                    }}
                                  >
                                    Student code
                                  </InputLabel>
                                  <TextField
                                    placeholder={''}
                                    name="Student code"
                                    size="small"
                                    value={student.data.entity_code}
                                    onChange={(e: any) => {
                                      UpdateState(
                                        e.target.value,
                                        i,
                                        'entity_code'
                                      );
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                justifyContent="space-between"
                                sx={{ width: '100%', marginBottom: '36px' }}
                              >
                                <Grid item>
                                  <InputLabel
                                    sx={{
                                      lineHeight: '16px',
                                      fontSize: '12px',
                                      fontFamily: 'Mulish',
                                    }}
                                  >
                                    Phone number (Optional)
                                  </InputLabel>
                                  <TextField
                                    size="small"
                                    sx={{ width: '256px' }}
                                    value={student.data.phone}
                                    onChange={(e) =>
                                      phoneNumberChangeHandler(e, i)
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <InputLabel
                                    sx={{
                                      lineHeight: '16px',
                                      fontSize: '12px',
                                      fontFamily: 'Mulish',
                                    }}
                                  >
                                    Email (Optional)
                                  </InputLabel>
                                  <TextField
                                    size="small"
                                    sx={{ width: '256px' }}
                                    type={'tel'}
                                    value={student.data.email}
                                    onChange={(e) => emailChangeHandler(e, i)}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </SpAccordion>
                        </Box>
                      );
                    })}

                    <Grid container justifyContent="space-around">
                      <InputLabel
                        sx={{
                          lineHeight: '20px',
                          fontSize: '14px',
                          fontFamily: 'Mulish',
                          fontWeight: 700,
                          textAlign: 'center',
                          color: '#b8babe',
                          marginTop: '35px',
                        }}
                      >
                        ADD ANOTHER ENTITY
                      </InputLabel>
                    </Grid>
                    <Grid container justifyContent="space-around">
                      <AddDivider
                        hrstyle={'addStudentModal'}
                        clickHandler={addStudent}
                      ></AddDivider>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        textAlign: 'end',
                        borderTop: '1px solid #b8babe',
                        marginTop: '52px',
                        paddingTop: '25px',
                      }}
                    >
                      <Navigation
                        style={{
                          padding: '0px 32px',
                          textDecoration: 'none',
                          color: '#525862',
                          fontWeight: '700',
                          fontFamily: 'Mulish',
                          cursor: 'pointer',
                        }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Navigation>
                      <SubmitButton
                        disabled={sublevel_Ids.length ? false : true}
                        onClick={createEntityManualHandler}
                        text="Add"
                      />
                    </Grid>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid
                    container
                    justifyContent="space-between"
                    sx={{ marginBottom: '36px', marginTop: '15px' }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '5px',
                        fontWeight: '700',
                      }}
                    >
                      <Typography
                        onClick={handleDownloadTemplate}
                        className={styles.downloadCsvTemplate}
                        sx={{ fontWeight: '700' }}
                      >
                        Download CSV Template
                      </Typography>
                      <Typography
                        className={styles.forImport}
                        sx={{ fontWeight: '700' }}
                      >
                        for import
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid>
                    <Box
                      onClick={handleClick}
                      className={styles.selectFileWrapper}
                    >
                      <Typography
                        className={styles.selectFilePartOne}
                        sx={{ fontWeight: '700' }}
                      >
                        Select a CSV File to import
                      </Typography>
                      <Typography className={styles.selectFilePartTwo}>
                        or drag and down it here
                      </Typography>
                    </Box>
                    <Box>
                      <input
                        ref={fileRef!}
                        onChange={handleFileChange}
                        multiple={false}
                        type="file"
                        hidden
                        accept={'.csv'}
                      />
                    </Box>
                    {fileName && (
                      <Box className={styles.showFile}>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <img src={FileIcon} alt="file" />
                          <Typography
                            style={{
                              color: '#000A18',
                              fontWeight: '400',
                              fontSize: '12px',
                            }}
                          >
                            {fileName}
                          </Typography>
                        </Box>
                        <img
                          src={CloseIcon}
                          onClick={removeFile}
                          style={{ cursor: 'pointer' }}
                          alt="cross"
                        />
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      textAlign: 'end',
                      borderTop: '1px solid #b8babe',
                      marginTop: '52px',
                      paddingTop: '35px',
                    }}
                  >
                    <Navigation
                      style={{
                        padding: '0px 32px',
                        textDecoration: 'none',
                        color: '#525862',
                        fontWeight: '700',
                        fontFamily: 'Mulish',
                        cursor: 'pointer',
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Navigation>
                    <SubmitButton
                      onClick={uploadExcelHandler}
                      disabled={
                        sublevel_Ids.length && entitiesExcel?.length
                          ? false
                          : true
                      }
                      text="Upload"
                    />
                  </Grid>
                </TabPanel>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddEntity;
