//WIP on Accordion

import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { REQUEST_GET, REQUEST_PUT, REQUEST_POST } from '../../utils/const';
import { requestAPI } from '../../utils/fetch';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import Confirm from '../../components/modal/Confirm';
import SpAccordion from '../common/Accordion/SpAccordion';
import styles from './createTemplate.module.css';
import Section from '../common/Section';
import AddDivider from './AddDivider';
import { IMedia, ProjectSectionProjectType } from '../../types/types';
import { updateSnackbar, updateAlert } from '../../utils/utils';
import PreviewWithAccordian from '../../components/modal/Preview/PreviewWithAccordion';
import { setUserFirstLoggedIn } from '../CreateTemplate/FirstLoginModal';
import { token } from '../../utils/StateStore';
import { createForm, getForm, updateForm } from '../../api/form';
import FormPreviewAndDataFilling from '../../components/modal/form/FormPreviewAndDataFilling';

type Props = { fromFirstTimeTemplate?: boolean; callFrom?: string };

const EditTemplate = (props: Props) => {
  const [templateName, setTemplateName] = useState<any>('');
  const [sectionToDel, setSectionToDel] = useState<any>();
  const { state }: any = useLocation();
  const [testOpen, setTestOpen] = useState(false);
  const [SaveChangesOpen, setSaveChangesOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [projectInfo, setProjectInfo] = useState<{
    name: string;
    sections: ProjectSectionProjectType[];
  }>({
    name: '',
    sections: [],
  });
  const [accordionActiveIndex, setAccordionActiveIndex] = useState<
    number | undefined
  >(undefined);
  const [currentUser, setCurrentUser] = useState<{ id?: string } | null>(null);
  const navigate = useNavigate();
  let response: any;
  const [formPreviewOpen, setFormPreviewOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (
          props.callFrom &&
          props.callFrom === 'formEdit' &&
          state?.formId !== ''
        ) {
          const formId = state?.formId;
          const data = await getForm({ formId: formId });
          if (data) {
            var dataObj = {
              project_template: {
                id: data.forms[0].id,
                name: data.forms[0].name,
                sections: data.forms[0].categories,
              },
            };

            const formData = Object.assign({}, dataObj);

            formData.project_template.sections.forEach((category: any) => {
              category.subsections = [{ fields: '' }];
              category.subsections[0].fields = [...category.fields];
            });
            setProjectInfo(dataObj?.project_template);
            setTemplateName(dataObj?.project_template?.name);
          }
        } else {
          const templateId = state?.templateId;

          response = await requestAPI({
            type: REQUEST_GET,
            url: `/api/project-template?project_template_id=${templateId}`,
          });
          setProjectInfo(response?.project_template);

          setTemplateName(response?.project_template?.name);
        }
      } catch (err) {
        console.error(err);
      }
    })();
    const subscribedToken = token?.subscribe((x) => {
      if (x?.userInfo) {
        setCurrentUser(x.userInfo);
      }
    });
    return () => {
      subscribedToken?.unsubscribe();
    };
  }, [state]);

  const testClose = async (confirm: boolean) => {
    setTestOpen(confirm);
    if (confirm === true) {
      let newProj = { ...projectInfo };
      setAccordionActiveIndex(undefined)
      newProj.sections.splice(sectionToDel, 1);
      setProjectInfo(newProj);
      setTestOpen(false);
    }
  };

  const SaveChangesClose = async (confirm: boolean) => {
    setSaveChangesOpen(confirm);
    if (confirm === true) {
      saveChangesHandler();
      setSaveChangesOpen(false);
    }
  };

  const saveChangesModalHandler = () => {
    setSaveChangesOpen(true);
  };

  const saveChangesHandler = async () => {
    try {
      if (
        (props.callFrom && props.callFrom === 'formCreation') ||
        props.callFrom === 'formEdit'
      ) {
        var formObj = {
          form: {
            name: projectInfo?.name,
            categories: [...projectInfo.sections],
          },
          project: {
            alias: state?.alias,
            id: state?.templateId,
          },
        };
        formObj.form.categories.forEach((cat: any) => {
          cat.fields =
            cat.subsections[0] && cat.subsections[0].fields
              ? cat.subsections[0].fields
              : [];
        });
        if (props.callFrom && props.callFrom === 'formCreation') {
          let formStructCreated = await createForm(formObj);
          if (formStructCreated) {
            updateSnackbar({
              state: true,
              severity: 'success',
              message: 'Form structure created successfully',
            });
            navigate(-1);
          }
        } else {
          let formStructUpdated = await updateForm({
            name: formObj.form.name,
            categories: formObj.form.categories,
            form_id: state?.formId,
          });
          if (formStructUpdated) {
            updateSnackbar({
              state: true,
              severity: 'success',
              message: 'Form structure updated successfully',
            });
            navigate(-1);
          }
        }
      } else {
        const response = await requestAPI({
          type: state?.templateId !== '' ? REQUEST_PUT : REQUEST_POST,
          url: '/api/project-template',
          data: projectInfo,
          fileData: files,
          multipartPresent: true,
        });
        if (response) {
          navigate('/projectTemplate');
          if (props?.fromFirstTimeTemplate && currentUser?.id) {
            setUserFirstLoggedIn({ userId: currentUser?.id });
          }
          if (state?.templateId === '') {
            updateSnackbar({
              state: true,
              severity: 'success',
              message: 'Template Created',
            });
          } else {
            updateSnackbar({
              state: true,
              severity: 'success',
              message: 'Template Updated',
            });
          }
        }
      }
    } catch (err: any) {
      updateAlert({
        message: (err && err.message) || '',
        severity: 'warning',
        state: true,
      });
      console.error(err);
    }
  };

  const previewHandler = () => {
    setPreviewOpen(true);
  };
  const previewFormHandler = () => {
    setFormPreviewOpen(true);
  };
  const backClickHandler = () => {
    navigate(-1);
  };
  const nameChangeHandler = (event: any) => {
    var projectInfoCopy = projectInfo;
    projectInfoCopy.name = event?.target?.value;
    setProjectInfo(projectInfoCopy);
    setTemplateName(event?.target?.value);
    setProjectInfo({ ...projectInfo, name: event?.target?.value });
  };

  const navigatePublishOrgList = () => {
    navigate('/assign-form', {
      state: {
        projectId: state?.templateId,
        projectName: projectInfo.name,
        callFrom: 'editForm',
        formId: state?.formId,
      },
    });
  };

  const addSection = () => {
    const newSection = {
      key: '',
      title: '',
      subsections: [],
      seq: 1,
    } as ProjectSectionProjectType;

    setProjectInfo({
      ...projectInfo,
      sections: [...projectInfo.sections, newSection],
    });
    editClickHandler(projectInfo?.sections?.length);
    // setSections([...sections, newSection]);
  };
  const deleteSection = (pos: number) => {
    projectInfo.sections.splice(pos, 1);
    setProjectInfo({
      ...projectInfo,
      sections: projectInfo.sections,
    });
    // setSections({ ...sections, attributes: deletedPosArray });
  };
  const deleteClickHandler = (index: number) => {
    setTestOpen(true);
    setSectionToDel(index);
  };
  const editClickHandler = (index: any) => {
    setAccordionActiveIndex(index);
  };

  const updateSectionsData = (
    pos: number,
    section: ProjectSectionProjectType // ProjectTemplateProjectSectionType
  ) => {
    let updatePosArray = projectInfo.sections;
    updatePosArray[pos] = section;
    // setSections({ ...sections, attributes: updatePosArray });
    setProjectInfo({
      ...projectInfo,
      sections: updatePosArray,
    });
  };
  const updateFiles = (file: File[], action: string, name?: string) => {
    if (action === 'add') {
      setFiles([...files, ...file]);
    } else {
      //delete
      setFiles(files.filter((file) => file?.name !== name));
    }
  };
  const getFormObj = () => {
    let formObj = {
      name: projectInfo?.name,
      categories: [...projectInfo.sections],
    };
    formObj.categories.forEach((cat: any) => {
      cat.fields =
        cat.subsections[0] && cat.subsections[0].fields
          ? cat.subsections[0].fields
          : [];
    });
    return formObj;
  };
  return (
    projectInfo && (
      <>
        <div style={{ width: window.innerWidth - 283, padding: '0 2rem' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="2rem 0"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                onClick={backClickHandler}
                style={{ paddingRight: '8px', cursor: 'pointer' }}
                src={ArrowLeft}
                alt="left arrow"
              />
              {(props?.callFrom && props.callFrom === 'formCreation') ||
                props.callFrom === 'formEdit' ? (
                state?.templateId !== '' && state?.formId !== '' ? (
                  <Typography variant="h-1">Edit Form</Typography>
                ) : (
                  <Typography variant="h-1">Create Form</Typography>
                )
              ) : state?.templateId !== '' ? (
                <Typography variant="h-1">Edit Template</Typography>
              ) : (
                <Typography variant="h-1">Create new template</Typography>
              )}
            </div>
            {(props?.callFrom && props.callFrom === 'formCreation') ||
              props.callFrom === 'formEdit' ? (
              <div className={styles.btnList}>
                <Button
                  sx={{ border: '2px solid #0451c4' }}
                  onClick={previewFormHandler}
                >
                  Preview
                </Button>
                <Button onClick={saveChangesModalHandler} variant="contained">
                  Save
                </Button>
                <Button onClick={() => navigate(-1)}>Close</Button>
              </div>
            ) : state?.templateId !== '' ? (
              <div className={styles.btnList}>
                <Button
                  sx={{ border: '2px solid #0451c4' }}
                  onClick={previewHandler}
                >
                  Preview
                </Button>
                <Button onClick={saveChangesModalHandler} variant="contained">
                  {props?.fromFirstTimeTemplate ? 'Publish' : 'Save Changes'}
                </Button>
              </div>
            ) : (
              <div className={styles.btnList}>
                <Button
                  sx={{ border: '2px solid #0451c4' }}
                  onClick={previewHandler}
                >
                  Preview
                </Button>
                <Button onClick={saveChangesModalHandler} variant="contained">
                  Publish
                </Button>
                <Button onClick={() => navigate(-1)}>Close</Button>
              </div>
            )}
          </Box>
          <div style={{ paddingBottom: '36px' }}>
            <InputLabel
              sx={{
                lineHeight: '16px',
                fontSize: '12px',
                fontWeight: '400',
                fontFamily: 'Mulish',
                paddingBottom: '4px',
              }}
              required
            >
              {(props?.callFrom && props.callFrom === 'formCreation') ||
                props.callFrom === 'formEdit'
                ? 'Form Name'
                : 'Template Name'}
            </InputLabel>
            <TextField
              sx={{ width: '100%' }}
              value={templateName}
              onChange={(event: any) => nameChangeHandler(event)}
            />
          </div>
          <div>
            {projectInfo?.sections?.length > 0 &&
              projectInfo?.sections?.map((section: any, i: number) => {
                let isActive = i === accordionActiveIndex;
                return (
                  <SpAccordion
                    title={section.title}
                    mode="edit"
                    pos={i}
                    editHandler={() => editClickHandler(i)}
                    deleteHandler={() => deleteClickHandler(i)}
                    isActive={isActive}
                    setAccordionActiveIndex={setAccordionActiveIndex}
                  >
                    <Grid item width="100%">
                      <Section
                        sectionData={section}
                        updateSectionsData={updateSectionsData}
                        key={section.key ? section.key : section.id}
                        pos={i}
                        deleteSection={deleteSection}
                        updateFiles={updateFiles}
                        editable={true}
                        callFrom={props.callFrom}
                      />
                    </Grid>
                  </SpAccordion>
                );
              })}
            <Grid container justifyContent="space-around">
              <label
                className={styles.addLabel}
                style={{ marginBottom: '15px' }}
              >
                {(props?.callFrom && props.callFrom === 'formCreation') ||
                  props.callFrom === 'formEdit'
                  ? 'ADD CATEGORY'
                  : 'ADD SECTION'}
              </label>
            </Grid>
            <Grid container justifyContent="space-around">
              <AddDivider clickHandler={addSection}></AddDivider>
            </Grid>
          </div>
          {props?.callFrom &&
            (props.callFrom === 'formCreation' ||
              props.callFrom === 'formEdit') && (
              <Box display="flex" justifyContent="center" m="2rem">
                <Button variant="contained" onClick={navigatePublishOrgList}>
                  Publish
                </Button>
              </Box>
            )}
        </div>
        <Confirm
          open={SaveChangesOpen}
          handleClose={SaveChangesClose}
          buttonText={'Save'}
          mainHeading={
            props?.callFrom &&
              (props.callFrom === 'formCreation' || props.callFrom === 'formEdit')
              ? 'Save the form'
              : 'Save the template?'
          }
          secondaryHeading={`The details will be saved`}
        />
        <Confirm
          open={testOpen}
          handleClose={testClose}
          buttonText={'Delete'}
          mainHeading={
            props?.callFrom &&
              (props.callFrom === 'formCreation' || props.callFrom === 'formEdit')
              ? 'Delete this category?'
              : 'Delete this section?'
          }
          secondaryHeading={`This section will be deleted from this template.`}
        />
        {previewOpen && (
          <PreviewWithAccordian
            open={previewOpen}
            handleClose={(previewOpen: boolean) => {
              setPreviewOpen(previewOpen);
            }}
            modalType="template"
            modalId={state?.templateId}
            isNewProjectCreation={false}
            callFrom={'editTemplate'}
            jsonData={projectInfo}
          />
        )}
        {formPreviewOpen && (
          <FormPreviewAndDataFilling
            open={formPreviewOpen}
            handleClose={(formPreviewOpen: boolean) => {
              setFormPreviewOpen(formPreviewOpen);
            }}
            formJsonData={getFormObj()}
            formId={state?.formId ? state?.formId : ''}
            projectId={state?.templateId}
            handlePublish={() => {
              //[TODO] Handle publish when filling value
            }}
            alias={state?.alias ? state?.alias : ''}
          />
        )}
      </>
    )
  );
};

export default EditTemplate;
export interface ProjectTemplateType {
  name: string;
  sections: ProjectTemplateProjectSectionType[];
  disabled?: boolean;
}
export interface ProjectTemplateSectionType {
  //
  key?: string;
  title: string;
  subsections: SubSectionTemplateType[];
  seq: number;
}
export interface ProjectTemplateProjectSectionType {
  key?: string;
  title: string;
  subsections: SubSectionProjectTemplateType[];
  seq: number;
}

export interface SubSectionProjectTemplateType {
  title: string;
  project_template_id: string;
  fields: FieldProjectType[];
}
export interface SubSectionTemplateType {
  title: string;
  project_template_id: string;
  fields: FieldType[];
}

export interface FieldType {
  key?: string;
  label: string | string[];
  type: string;
  options?: string[];
  seq: number;
  required?: boolean;
  medias?: string[] | IMedia[];
}

export interface FieldProjectType {
  key?: string;
  label: { key?: string; value?: string }[];
  options?: { key?: string; value?: string }[];
  values?: { key?: string; value?: string }[];
  type: string;
  seq: number;
  required?: boolean;
  medias?: string[] | IMedia[];
}
