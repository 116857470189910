import {
  Autocomplete,
  Box,
  Dialog,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Link as Navigation,
  Typography,
  Checkbox,
  Snackbar,
  Alert,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect, useState } from 'react';
import { ACCESS_MODES, REQUEST_POST } from '../../utils/const';
import { requestAPI } from '../../utils/fetch';
import AddSection from '../../assets/png/AddSection.png';
import Divider from '../../assets/png/Divider.png';
import DropdownClosed from '../../assets/png/DropdownClosed.png';
import DeleteIcon from '../../assets/png/DeleteIcon.png';
import { RoleCreationInfo } from '../../types/types';
import './CreateRoleStyles.css';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SubmitButton from '../common/SubmitButton';
import {
  getPermissionForPopup,
  updateAlert,
  updateSnackbar,
} from '../../utils/utils';
import { selectedLeftNavigationField, token } from '../../utils/StateStore';
import Error from '../../assets/png/Error.png';
import { setUserFirstLoggedIn } from '../CreateTemplate/FirstLoginModal';
import { resourceNames as table_resources } from '../../utils/const';

export const DropDownIcon = (props: any) => (
  <img
    {...props}
    src={DropdownClosed}
    style={{ color: '#000A18', right: '14px', top: '17px' }}
  />
);
const useStyles = makeStyles({
  checkBox: {
    color: '#0451C4',
  },
});

function CreateRole({
  open,
  setOpen,
  fromUser,
  handleUserFromRole,
  handleRolesLoading,
  fromFirstTimeTemplate,
}: any) {
  const [roleInfo, setRoleInfo] = useState<RoleCreationInfo>({
    roleName: '',
    roleDescription: '',
  });
  const [opened, setOpened] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const [currentUser, setCurrentUser] = useState<{ id?: string } | null>(null);

  const resourceNames = [table_resources.Entity, table_resources.Organization, table_resources.Projects, table_resources.Users, table_resources.Roles, table_resources.OrganisationStructure];
  let accessNames = ACCESS_MODES.map((mode) => Object.values(mode)[0]);
  // const { state }: any = useLocation();
  const navigate = useNavigate();
  const [permissionsList, setPermissionsList] = useState<any>([
    {
      name: '',
      resource_type: 'table',
      resource_name: '',
      access_mode: '',
      description: '',
      accessValue: [],
    },
  ]);
  const [accessValue, setAccessValue] = useState(
    new Array(permissionsList.length).fill([])
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    const checkPermission = async () => {
      const hasPerm = await getPermissionForPopup('Roles', 'c');
      if (!hasPerm) {
        updateAlert({
          message: 'You are not authorized to create roles',
          severity: 'warning',
          state: true,
        });
        setOpen !== undefined && setOpen(false);
        navigate('/');
      } else {
        setOpened(true);
      }
    };
    checkPermission();
    const subscribedToken = token?.subscribe((x) => {
      if (x?.userInfo) {
        setCurrentUser(x.userInfo);
      }
    });
    return () => {
      subscribedToken?.unsubscribe();
    };
  }, []);

  const classes = useStyles();
  const roleNameHandler = (event: any) => {
    let newRoleInfo = { ...roleInfo };
    newRoleInfo.roleName = event.target.value
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/ +/g, ' ')
      .replace(/ /g, '_');
    setRoleInfo(newRoleInfo);
  };
  const roleDescriptionHandler = (event: any) => {
    let newRoleInfo = { ...roleInfo };
    newRoleInfo.roleDescription = event.target.value
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/ +/g, ' ');
    setRoleInfo(newRoleInfo);
  };
  const accessModeHandler = (event: any, value: any, index: number) => {
    let tempAccessArray = new Array(4).fill('');
    value.forEach((item: string) => {
      switch (item) {
        case 'create':
          tempAccessArray[0] = 'c';
          break;
        case 'read':
          tempAccessArray[1] = 'r';
          break;
        case 'update':
          tempAccessArray[2] = 'u';
          break;
        case 'delete':
          tempAccessArray[3] = 'd';
          break;
        default:
          break;
      }
    });
    let newAccess = value;
    accessValue[index] = newAccess;
    setAccessValue(accessValue);

    const newPermissionList = [...permissionsList];
    let item = { ...permissionsList[index] };
    newPermissionList[index] = item;
    item.access_mode = tempAccessArray.join('');
    item.name = `${item.resource_name}_${tempAccessArray.join('')}`;
    item.description = `${value.join(', ')} ${item.resource_name}`;
    item.accessValue = value;
    setPermissionsList(newPermissionList);
  };

  const resourceNameHandler = (event: any, index: number) => {
    const newPermissionList = [...permissionsList];
    let item = { ...permissionsList[index] };
    newPermissionList[index] = item;
    item.resource_name = event.target.value;
    item.access_mode = '';
    item.resource_type = 'table';
    item.name = '';
    item.description = '';
    item.accessValue = [];
    setPermissionsList(newPermissionList);
  };

  const dialogCloseHandler = () => {
    setPermissionsList([
      {
        name: '',
        resource_type: 'table',

        resource_name: '',
        access_mode: '',
        description: '',
        accessValue: [],
      },
    ]);
    selectedLeftNavigationField.next('Overview');

    if (fromUser) {
      handleRolesLoading !== undefined && handleRolesLoading(true);
      handleUserFromRole !== undefined && handleUserFromRole(true);
      setOpen !== undefined && setOpen(false);
    } else {
      setOpened(false);
      navigate(-1);
    }
    // props.fromUser ? navigate(-1) : navigate('/');
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  const submitHandler = async () => {
    // permissionsList.map((item: any, index: any) => delete item.accessValue);
    try {
      const roleObj = {
        role: {
          name: roleInfo.roleName,
          description: roleInfo.roleDescription,
        },
        permissions: permissionsList.map(
          ({
            name,
            resource_type,
            resource_name,
            access_mode,
            description,
          }: any) => ({
            name,
            resource_type,
            resource_name,
            access_mode,
            description,
          })
        ),
      };
      const response = await requestAPI({
        type: REQUEST_POST,
        url: '/api/role',
        data: roleObj,
      });
      if (response) {
        //TODO: code to be performed after successful creation of role
        if (fromFirstTimeTemplate && currentUser?.id) {
          setUserFirstLoggedIn({ userId: currentUser?.id });
        }
        if (fromUser) {
          handleUserFromRole !== undefined && handleUserFromRole(true);
          setOpen !== undefined && setOpen(false);
          setPermissionsList([
            {
              name: '',
              resource_type: 'table',

              resource_name: '',
              access_mode: '',
              description: '',
              accessValue: [],
            },
          ]);
        } else {
          navigate('/Roles', { state: { fromRole: true } });
          setSnackbarOpen(true);
          updateSnackbar({
            state: true,
            severity: 'success',
            message: 'Role added',
          });
        }
      } else {
        console.error(response);
      }
    } catch (e: any) {
      console.error('inside catch', e);
      setOpenAlert(true);
      setSnackbarOpen(true);
      setAlertMsg('Error creating role');
    }
  };

  const addSectionHandler = () => {
    setPermissionsList([
      ...permissionsList,
      {
        resource_type: 'table',
        resource_name: '',
        access_mode: '',
        name: '',
        description: '',
        accessValue: [],
      },
    ]);
  };
  const removeSectionHandler = (index: number) => {
    const list = [...permissionsList];
    list.splice(index, 1);
    setPermissionsList(list);
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: '600px',
            borderRadius: '8px',
            height: '845px',
            overflow: 'hidden',
          },
        }}
        open={open !== undefined && fromUser ? open : opened}
      >
        <Box sx={{ height: '100%' }}>
          <div style={{ height: '845px' }}>
            {openAlert && (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                onClose={handleClose}
                sx={{ paddingTop: '4px', top: '32px !important' }}
              >
                <Alert
                  onClose={handleClose}
                  severity="error"
                  sx={{
                    width: '536px',
                    padding: ' 0px 12px 0px 9.63px',
                    height: '28px',
                    alignItems: 'center',
                  }}
                  icon={<img src={Error} />}
                >
                  {alertMsg}
                </Alert>
              </Snackbar>
            )}
            <header style={{ padding: '32px' }}>
              <Typography
                variant="h-1"
                sx={{
                  fontFamily: 'Mulish',
                  lineHeight: '32px',
                  fontWeight: '700',
                  fontSize: '24px!important',
                  color: '#000A18',
                }}
              >
                Create new role
              </Typography>
            </header>
            <div
              style={{
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '42%',
                width: '568px',
                paddingLeft: '32px',
              }}
            >
              <div
                style={{
                  justifyContent: 'space-between',
                  paddingBottom: '32px',
                  width: '536px',
                }}
              >
                <div style={{ width: '100%' }}>
                  <InputLabel
                    sx={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      fontFamily: 'Mulish',
                      paddingBottom: '4px',
                    }}
                  >
                    Role Name
                  </InputLabel>
                  <TextField
                    name="first_name"
                    size="small"
                    sx={{ width: '100%' }}
                    onChange={(event) => roleNameHandler(event)}
                  />
                </div>
              </div>
              <div
                style={{
                  justifyContent: 'space-between',
                  paddingBottom: '32px',
                  width: '536px',
                }}
              >
                <div style={{ width: '100%' }}>
                  <InputLabel
                    sx={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      fontFamily: 'Mulish',
                      paddingBottom: '4px',
                    }}
                  >
                    Description
                  </InputLabel>
                  <TextField
                    name="first_name"
                    size="small"
                    sx={{ width: '100%' }}
                    onChange={(event) => roleDescriptionHandler(event)}
                  />
                </div>
              </div>
              <div style={{display: 'flex', flexDirection:'column', justifyContent:'space-between'}}>
              {permissionsList.map((perms: any, index: any) => {
                return (
                  <div key={index} className="parentDiv">
                    <div
                      className="hoveredDiv"
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '536px',
                      }}
                    >
                      <div style={{ paddingRight: '24px' }}>
                        <InputLabel
                          sx={{
                            lineHeight: '16px',
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                            paddingBottom: '4px',
                          }}
                        >
                          Select Table
                        </InputLabel>
                        <Select
                          onChange={(event) =>
                            resourceNameHandler(event, index)
                          }
                          size="small"
                          sx={{ width: '256px', fontSize: 'large' }}
                          value={perms.resource_name}
                          IconComponent={DropDownIcon}
                        >
                          {resourceNames.map((resource_name, index) => (
                            <MenuItem key={index} value={resource_name}>
                              {resource_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div>
                        <InputLabel
                          sx={{
                            lineHeight: '16px',
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                            paddingBottom: '4px',
                          }}
                        >
                          Permissions
                        </InputLabel>
                        <Autocomplete
                          sx={{ width: '256px' }}
                          multiple
                          popupIcon={
                            <img
                              src={DropdownClosed}
                              style={{ top: '1px', right: '4px' }}
                            />
                          }
                          size="small"
                          options={accessNames}
                          value={perms?.accessValue || []}
                          disableCloseOnSelect
                          id="checkboxes-tags-demo"
                          getOptionLabel={(option: any) => option}
                          renderOption={(props, option, { selected }) => {
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon
                                      fontSize="small"
                                      className={classes.checkBox}
                                    />
                                  }
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            );
                          }}
                          renderInput={(params: any) => (
                            <TextField {...params} />
                          )}
                          onChange={(event, value) =>
                            accessModeHandler(event, value, index)
                          }
                        />
                      </div>
                      {permissionsList.length > 1 && (
                        <div
                          className="delete"
                          style={{ position: 'relative' }}
                          onClick={() => removeSectionHandler(index)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="delete icon"
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '-32px',
                              left: '-32px',
                              backgroundPositionX: 'center',
                              backgroundRepeat: 'no-repeat',
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div style={{ height: '38px', width: '100%' }}></div>
                  </div>
                );
              })}
              </div>
              <div
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  paddingBottom: '15px',
                  width: '536px',
                }}
              >
                ADD
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: '10px',
                  width: '536px',
                }}
              >
                <div>
                  <img
                    src={Divider}
                    style={{ width: '236px', height: '1px' }}
                    alt="divider image"
                  />
                </div>
                <div onClick={addSectionHandler}>
                  <img src={AddSection} alt="plus icon" />
                </div>
                <div>
                  <img
                    src={Divider}
                    alt="divider image"
                    style={{ width: '236px', height: '1px' }}
                  />
                </div>
              </div>
            </div>
            <footer
              style={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                display: 'flex',
                padding: '16px 32px',
              }}
            >
              <div>
                <Navigation
                  style={{
                    padding: '0px 32px',
                    textDecoration: 'none',
                    color: '#525862',
                    fontWeight: '700',
                    fontFamily: 'Mulish',
                    cursor: 'pointer',
                  }}
                  onClick={dialogCloseHandler}
                >
                  Cancel
                </Navigation>
              </div>
              <div>
                <SubmitButton
                  onClick={submitHandler}
                  disabled={!(roleInfo.roleName && roleInfo.roleDescription)}
                  text="Add Role"
                />
              </div>
            </footer>
          </div>
        </Box>
      </Dialog>
    </>
  );
}

export default CreateRole;
