import FirstLoginModal from '../pages/CreateTemplate/FirstLoginModal';
import AddEntity from '../pages/Entities/AddEntity';
import EntityList from '../pages/Entities/EntityList';
import UpdateEntity from '../pages/Entities/UpdateEntity';
import FormList from '../pages/Forms/FormList';
import FirstTImeUserUpdateSuccessfull from '../pages/Login/FirstTImeUserUpdateSuccessfull';
import FirstTimeUserLogin from '../pages/Login/FirstTimeUserLogin';
import AttachUser from '../pages/Organisation/AttachUser';
import OnboardUsers from '../pages/Organisation/OnboardUsers';
import OrganisationList from '../pages/Organisation/OrganisationList';
import ProjectList from '../pages/Organisation/ProjectList';
import AllProject from '../pages/Project/AllProject';
import CreateProject from '../pages/Project/CreateProject';
import NewProject from '../pages/Project/NewProject';
import PreviewProject from '../pages/Project/PreviewProject';
import EditTemplate from '../pages/ProjectTemplate/EditTemplate';
import ProjectTemplate from '../pages/ProjectTemplate/ProjectTemplate';
import CreateRole from '../pages/Role/CreateRole';
import EditRole from '../pages/Role/EditRole';
import Roles from '../pages/Role/Roles';
import UserRole from '../pages/Role/UserRole';
import UsersRole from '../pages/Role/UsersRole';
import CreateUser from '../pages/User/CreateUser';
import EditUser from '../pages/User/EditUser';
import ListUsers from '../pages/User/ListUsers';
import EntityFormAssigned from '../pages/Entities/EntityFormAssigned';
import EntityStudentAssigned from '../pages/Entities/EntityStudentAssigned';
import FormDataPreview from '../pages/Entities/FormDataPreview';

let Components: any = {};

Components['ListUsers'] = ListUsers;
Components['UserRole'] = UserRole;
Components['AddEntity'] = AddEntity;
Components['FormList'] = FormList;
Components['FirstTImeUserUpdateSuccessfull'] = FirstTImeUserUpdateSuccessfull;
Components['FirstTimeUserLogin'] = FirstTimeUserLogin;
Components['AttachUser'] = AttachUser;
Components['OnboardUsers'] = OnboardUsers;
Components['OrganisationList'] = OrganisationList;
Components['ProjectList'] = ProjectList;
Components['AllProject'] = AllProject;
Components['CreateProject'] = CreateProject;
Components['NewProject'] = NewProject;
Components['PreviewProject'] = PreviewProject;
Components['EditTemplate'] = EditTemplate;
Components['ProjectTemplate'] = ProjectTemplate;
Components['CreateRole'] = CreateRole;
Components['EditRole'] = EditRole;
Components['Roles'] = Roles;
Components['UsersRole'] = UsersRole;
Components['CreateUser'] = CreateUser;
Components['EditUser'] = EditUser;
Components['EntityList'] = EntityList;
Components['UpdateEntity'] = UpdateEntity;
Components['FirstLoginModal'] = FirstLoginModal;
Components['EntityList'] = EntityList;
Components['EntityFormAssigned'] = EntityFormAssigned;
Components['EntityStudentAssigned'] = EntityStudentAssigned;
Components['FormDataPreview'] = FormDataPreview;

export default Components;
