import { Box, Typography, Button } from '@mui/material';
import TextInput from '../../components/Input/TextInput/TextInput';
import SPLogoSmall from '../../assets/png/SPLogoSmall.png';
import { useState } from 'react';
import { firstPasswordUpdate, logoutUser } from '../../api/authenticate';
import { useNavigate } from 'react-router';

type Props = {};

const FirstTimeUserLogin = (props: Props) => {
  const navigate = useNavigate();

  const [passwords, setPasswords] = useState({
    newpassword: '',
    confirmpassword: '',
  });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (passwords.newpassword !== passwords.confirmpassword) {
      //[TODO] tell user
      return;
    }
    const firstPasswordUpdated = await firstPasswordUpdate({
      new_password: passwords.newpassword,
    });
    if (!firstPasswordUpdated) {
      return false;
    }
    logoutUser();
    navigate('/first-time/sucess');
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, [event.target.name]: event.target.value });
  };
  return (
    <Box className="LoginRoot">
      <Box
        className="InputContainer"
        borderRadius="0.5rem"
        boxShadow="0px 1.2px 3.6px rgba(0, 10, 24, 0.11), 0px 6.4px 14.4px rgba(0, 10, 24, 0.13), 0px 2px 8px rgba(0, 10, 24, 0.08)"
      >
        <Box ml="2rem" display="flex" alignItems="center">
          <Box
            mr="0.5rem"
            height="1.5rem"
            width="1.5rem"
            sx={{
              backgroundImage: `url(${SPLogoSmall})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Box>
            <Typography variant="h-4">Workspace</Typography>
          </Box>
        </Box>
        <Box ml="2rem" mb="2rem" mt="2rem">
          <Typography variant="h-1">Update password</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box ml="2rem">
            <Typography variant="Caption">New password</Typography>
            <TextInput
              value={passwords.newpassword}
              type={'password'}
              onChange={onChange}
              name={'newpassword'}
              placeholder={'Password'}
            />
          </Box>{' '}
          <Box ml="2rem">
            <Typography variant="Caption">Confirm password</Typography>
            <TextInput
              value={passwords.confirmpassword}
              type={'password'}
              onChange={onChange}
              name={'confirmpassword'}
              placeholder={'Confirm Password'}
            />
          </Box>
          <Button
            className="LoginButton"
            variant="contained"
            type="submit"
            color="primary"
            style={{ marginLeft: '2rem', marginTop: '4rem' }}
          >
            <Typography variant="h-3">Update password</Typography>
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default FirstTimeUserLogin;
