import { REQUEST_GET } from '../utils/const';
import { requestAPI } from '../utils/fetch';
import { updateAlert } from '../utils/utils';

export const getOrgInfo = async (allLevel: boolean = false) => {
  try {
    const orgInfo = await requestAPI({
      type: REQUEST_GET,
      url: `/api/user-organisations?all_level=${allLevel}`,
    });
    if (!orgInfo) {
      return false;
    }
    return orgInfo;
  } catch (error: any) {
  }
};

export const getOrgSublevelInfo = async (orgId: any) => {
  try {
    const orgSublevelInfo = await requestAPI({
      type: REQUEST_GET,
      url: `/api/org-handler-sublevel?organization_id=${orgId}`,
    });
    if (!orgSublevelInfo) {
      return false;
    }
    return orgSublevelInfo;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};
