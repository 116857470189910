import { TextField, TextareaAutosize, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import AddOption from '../../assets/png/AddOption.png';
import DeleteOption from '../../assets/png/DeleteOption.png';
import styles from '../ProjectTemplate/ProjectTemplate.module.css';
import { useLocation } from 'react-router-dom';

type Props = {
  addOption: (pos: number) => void;
  removeOption: (pos: number) => void;
  pos: number;
  option: any /* string | {
    label: { key: string; value: string };
    values: { key: string; value: string };
  }; */;
  displayRemove: boolean;
  updateOptionValue: (
    value: { key?: string; value?: string },
    pos: number,
    keyName: string
  ) => void;
  displayAdd: boolean;
  callFrom?: string;
  editable?: boolean;
  fieldType?: string;
};

const TextOption = ({
  addOption,
  removeOption,
  pos,
  option,
  displayRemove,
  updateOptionValue,
  displayAdd,
  callFrom,
  editable,
  fieldType,
}: Props) => {
  const [optionValue, setOptionValue] = useState({
    label: { key: '', value: '' },
    values: { key: '', value: '' },
  });
  const location = useLocation();
  useEffect(() => {
    setOptionValue(option);
  }, [option]);
  const onChange = (event: any /* React.ChangeEvent<HTMLInputElement> */) => {
    setOptionValue((prevState: any) => {
      return {
        ...prevState,
        [event.target.name]: {
          key: '',
          value: event.target.value,
        },
      };
    });
    updateOptionValue(
      { key: '', value: event.target.value },
      pos,
      event.target.name
    );
  };
  return (
    <>
      {(editable && (
        <div
          style={{
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection:
              fieldType !== 'textarea' &&
              callFrom !== 'formCreation' &&
              callFrom !== 'formEdit'
                ? 'row'
                : 'column',
          }}
        >
          <Box
            sx={{
              display: 'inline',
              width: '100%',
              marginBottom:
                callFrom === 'formCreation' || callFrom === 'formEdit' ? 2 : 0,
              marginLeft:
                callFrom === 'formCreation' || callFrom === 'formEdit' ? 0 : 4,
            }}
          >
            {(callFrom === 'formCreation' || callFrom === 'formEdit') && (
              <Box
                component="div"
                sx={{
                  display: 'inline',
                  fontWeight: '700',
                  verticalAlign: 'middle',
                  marginLeft:
                    location?.pathname === '/login' ||
                    location?.pathname === '/first-time'
                      ? '0px'
                      : '36px',
                }}
              >
                Q.
              </Box>
            )}
            <TextField
              value={
                typeof optionValue === 'string'
                  ? optionValue
                  : optionValue?.label?.value
              }
              onChange={onChange}
              name="label"
              placeholder={
                callFrom === 'formCreation' || callFrom === 'formEdit'
                  ? 'Add Question'
                  : 'Add label Name'
              }
              size="small"
              sx={{
                width:
                  callFrom === 'formCreation' || callFrom === 'formEdit'
                    ? '90%'
                    : '95%',
                height: '40px',
                paddingLeft:
                  callFrom === 'formCreation' || callFrom === 'formEdit'
                    ? 3
                    : '16px',
                marginBottom: fieldType !== 'textarea' ? 0 : 2,
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault()
                }
              }}
            />
          </Box>
          {fieldType && fieldType === 'textarea' ? (
            <TextareaAutosize
              maxRows={6}
              minRows={4}
              value={
                (typeof optionValue === 'object' &&
                  optionValue?.values?.value) ||
                ''
              }
              onChange={onChange}
              name="option"
              aria-label="empty textarea"
              placeholder="Add Textarea..."
              className={styles.textAreaField}
              disabled={callFrom === 'projectEdit' ? false : true}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault()
                }
              }}
            />
          ) : (
            <TextField
              value={
                (typeof optionValue === 'object' &&
                  optionValue?.values?.value) ||
                ''
              }
              onChange={onChange}
              name="values"
              placeholder="Add value Name"
              size="small"
              sx={{ width: '95%', height: '40px', paddingLeft: '16px' }}
              disabled={callFrom === 'projectEdit' ? false : true}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault()
                }
              }}
            />
          )}
          {displayAdd &&
            fieldType !== 'textarea' &&
            callFrom !== 'formCreation' &&
            callFrom !== 'formEdit' && (
              <img
                onClick={() => addOption(pos)}
                src={AddOption}
                alt="add option"
                style={{ cursor: 'pointer', margin: '0 0.3rem' }}
              />
            )}

          {displayRemove && fieldType !== 'textarea' && (
            <img
              onClick={() => removeOption(pos)}
              src={DeleteOption}
              alt="delete option"
              style={{ cursor: 'pointer', marginRight: '0.3rem' }}
            />
          )}
        </div>
      )) || (
        <div
          style={{
            width: '100%',
            alignItems: 'center',
            flexDirection: 'row',
            marginLeft: '20px',
            display: 'inline-flex',
            fontSize: '14px',
          }}
        >
          <div
            style={{
              fontSize: '14px',
              color: '#525862',
              fontWeight: '400',
              fontStyle: 'Mulish',
              display: 'inline-flex',
            }}
          >
            <Typography
              style={{
                fontSize: '14px',
                color: '#36454F',
                fontWeight: '700',
                fontStyle: 'Mulish',
              }}
            >
              {optionValue.label.value} :
            </Typography>
            <Typography
              style={{
                fontSize: '14px',
                color: '#36454F',
              }}
            >
              &nbsp;
              {optionValue.values.value || ' '}
            </Typography>
          </div>
        </div>
      )}
      <div style={{ height: '1rem' }} />
    </>
  );
};

export default TextOption;
