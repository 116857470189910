import {
  REQUEST_DELETE,
  REQUEST_POST,
  REQUEST_GET,
  REQUEST_PUT,
} from '../utils/const';
import { requestAPI } from '../utils/fetch';
import { updateAlert } from '../utils/utils';

export const createForm = async (formData: any) => {
  try {
    const formCreated = await requestAPI({
      type: REQUEST_POST,
      url: '/api/form',
      data: formData,
    });
    if (!formCreated) {
      return false;
    }
    return formCreated;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const getForm = async (id: { projectId?: string; formId?: string }) => {
  try {
    const { projectId, formId } = id;
    const formResponse = await requestAPI({
      type: REQUEST_GET,
      url: formId
        ? `/api/form?form_id=${formId}`
        : `/api/form?project_id=${projectId}`,
    });
    if (!formResponse) {
      return false;
    }
    return formResponse.forms;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};
export const removeForm = async (formData: any) => {
  try {
    const response = await requestAPI({
      type: REQUEST_DELETE,
      url: '/api/form',
      data: formData,
    });
    if (!response) {
      return false;
    }
    return response;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const createFormCopy = async (formData: any) => {
  try {
    const formResponse = await requestAPI({
      type: REQUEST_POST,
      url: `/api/form-copy`,
      data: formData,
    });
    if (!formResponse) {
      return false;
    }
    return formResponse;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const updateForm = async (formBody: any) => {
  try {
    const updatedForm = await requestAPI({
      type: REQUEST_PUT,
      url: '/api/form',
      data: formBody,
    });
    if (!updatedForm) {
      return false;
    }
    return updatedForm;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const getOrgHandlerForm = async (
  organizationId: string,
  projectId: string
) => {
  try {
    const formResponse = await requestAPI({
      type: REQUEST_GET,
      url: `/api/org-handlers-form?organization_id=${organizationId}&project_id=${projectId}`,
    });
    if (!formResponse) {
      return false;
    }
    return formResponse;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};
