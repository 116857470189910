import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getTemplateData } from '../../api/template';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import SectionEditAccordian from '../common/Accordion/SectionEditAccordian';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import {
  createProject,
  getProjectData,
  updateProject,
} from '../../api/project';
import Grid from '@mui/material/Grid';
import AddDivider from '../ProjectTemplate/AddDivider';
import { ProjectSectionProjectType } from '../../types/types';
import styles from './createProject.module.css';
import { getRandomString, updateAlert } from '../../utils/utils';
import { useMediaQuery } from '@mui/material';

type Props = { callFrom?: string; editable?: boolean };

const CreateProject = ({ callFrom, editable = true }: Props) => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const [files, setFiles] = useState<File[]>([]);
  const [projectData, setProjectData] = useState<{
    name: string;
    alias?: string;
    sections: ProjectSectionProjectType[];
  }>({ name: '', alias: '', sections: [] });
  const [expanded, setExpanded] = useState<number | false>(0);
  const [templateName, setTemplateName] = useState('');
  useEffect(() => {
    async function fetchMyAPI() {
      const templateId = state?.templateId;
      const projectId = state?.projectId;

      if (templateId && templateId !== '') {
        const data = await getTemplateData(templateId);
        if (typeof data !== 'boolean') {
          setProjectData({ ...projectData, sections: data.sections });
          setTemplateName(data.name);
        }
      } else if (projectId && projectId !== '') {
        if (projectId && projectId !== '') {
          const data = await getProjectData(projectId);
          if (typeof data !== 'boolean') {
            setProjectData(data!);
          }
        }
      }
    }
    fetchMyAPI();
  }, [state?.projectId, state?.templateId]);
  const deleteSection = (pos: number) => {
    projectData.sections.splice(pos, 1);
    setProjectData({
      ...projectData,
      sections: projectData.sections,
    });
  };
  const addSection = () => {
    const newSection = {
      key: '',
      title: '',
      subsections: [],
      seq: 1,
    } as ProjectSectionProjectType;

    setProjectData({
      ...projectData,
      sections: [...projectData.sections, newSection],
    });
  };
  const updateFiles = (file: File[], action: string, name?: string) => {
    if (action === 'add') {
      setFiles([...files, ...file]);
    } else {
      //delete
      setFiles(files.filter((file) => file?.name !== name));
    }
  };
  const createProjectHandler = async () => {
    let projectCreated;
    if (projectData.name === '') {
      updateAlert({
        state: true,
        severity: 'warning',
        message: 'Please fill Project Name',
      });
      return;
    }
    projectData['alias'] = getProjectAlias(projectData.name, 'pro');
    if (!state?.templateId) {
      projectCreated = await createProject(projectData, files, false);
    } else {
      projectCreated = await createProject(projectData, files, true);
    }
    if (projectCreated) {
      navigate('/all-project');
    }
  };

  const saveChangesHandler = async () => {
    const data = await updateProject(projectData, files);
    if (data) {
      navigate(-1);
    }
  };
  const backClickHandler = () => {
    navigate(-1);
  };
  const screenSize = useMediaQuery('(max-width: 1300px)');
  return (
    <Box m={screenSize ? '0rem 5rem 0rem 0rem' : '0rem'}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        m={screenSize ? '2rem 5rem 2rem 2rem' : '2rem'}
      >
        {callFrom === 'projectPreview' || state?.projectId ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              onClick={backClickHandler}
              style={{ paddingRight: '8px', cursor: 'pointer' }}
              src={ArrowLeft}
              alt="left arrow"
            />
            <Typography variant="h-1">
              {projectData?.name || 'Project'}
            </Typography>
          </div>
        ) : (
          <Box>
            <Typography variant="h-1">New project Structure</Typography>
          </Box>
        )}
        <Box>
          {!editable ? (
            <Button variant="contained" onClick={saveChangesHandler}>
              Edit Project
            </Button>
          ) : !state?.projectId ? (
            <>
              <Button
                variant="text"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Close
              </Button>
              <Button variant="contained" onClick={createProjectHandler}>
                Create Project
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={saveChangesHandler}>
              Save Changes
            </Button>
          )}
        </Box>
      </Box>
      {!state?.templateId &&
        !state?.projectId &&
        templateName !== '' &&
        templateName && (
          <Box mx="2rem" mb="2.25rem" display="flex" flexDirection="column">
            <Typography variant="Caption" mb="0.25rem">
              Template name
            </Typography>
            <Input
              disabled={true}
              value={templateName}
              sx={{
                border: '1px solid #B8BABE',
                p: '0.5rem 1rem',
                borderRadius: '0.25rem',
              }}
            />
          </Box>
        )}

      <Box mx="2rem" display="flex" flexDirection="column">
        <Typography variant="Caption" mb="0.25rem">
          Project name
        </Typography>
        <Input
          value={projectData.name}
          sx={{
            border: '1px solid #B8BABE',
            p: '0.5rem 1rem',
            borderRadius: '0.25rem',
          }}
          name="name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setProjectData({
              ...projectData,
              [event.target.name]: event.target.value,
            })
          }
          disabled={!editable}
        />
      </Box>
      {projectData?.sections?.length > 0 &&
        projectData?.sections?.map((section: any, pos: number) => (
          <SectionEditAccordian
            section={section}
            setProjectData={setProjectData}
            projectData={projectData}
            deleteSection={deleteSection}
            updateFiles={updateFiles}
            pos={pos}
            callFrom={callFrom === 'projectEdit' ? 'projectCreate' : callFrom}
            editable={editable}
            projectEdit={state?.projectId}
            expanded={expanded}
            setExpanded={(index) => setExpanded(index)}
          />
        ))}
      {editable && (
        <Grid container justifyContent="space-around">
          <label className={styles.addLabel} style={{ marginBottom: '15px' }}>
            ADD SECTION
          </label>
        </Grid>
      )}
      {editable && (
        <Grid container justifyContent="space-around">
          <AddDivider clickHandler={addSection}></AddDivider>
        </Grid>
      )}
    </Box>
  );
};
export const getProjectAlias = (projectName: string, type: string) => {
  let aliasName = 'Default title';
  const ignoreWords = [
    'for',
    'in',
    'the',
    'and',
    'is',
    'that',
    'there',
    'their',
    'of',
    'a',
    'an',
  ];

  const updatedProjectName = projectName
    .split(' ')
    .filter((word: string) => !ignoreWords.includes(word));
  let tempProjectNameArray = updatedProjectName.join('').match(/(\w+)/g);
  let projectNameArray =
    tempProjectNameArray === null
      ? ['Default', 'Title', 'Created']
      : tempProjectNameArray;
  let regex = /\b(\w)/g;

  if (projectNameArray.length === 1) {
    regex = /\b(\w){3}/g;
  } else if (projectNameArray.length === 2) {
    regex = /\b(\w){2}/g;
  } else {
    regex = /\b(\w)/g;
  }
  const str = projectNameArray.join(' ');
  const matches = str.match(regex);
  aliasName = matches!.join('');
  const randomString = getRandomString(3);
  return aliasName.toLowerCase() + '_' + type + '_' + randomString;
};
export default CreateProject;
