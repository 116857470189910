import { Dialog, Grid, Link, Typography } from '@mui/material';
import CreateNewTemplate from '../../assets/png/CreateNewTemplate.png';
import CreateNewRole from '../../assets/png/CreateNewRole.png';
import CreateNewUser from '../../assets/png/CreateNewUser.png';
import { useState } from 'react';
import SubmitButton from '../common/SubmitButton';
import { useNavigate } from 'react-router-dom';
import "./FirstLoginModal.css"
import { requestAPI } from '../../utils/fetch';
import { REQUEST_PUT } from '../../utils/const';


const FirstLoginModal = () => {
  const [navigateTo, setNavigateTo] = useState('');
  const [openState, setOpenState] = useState(true);
  const navigate = useNavigate();
  const cancelHandler = () => {
    setOpenState(false);
    setNavigateTo('')
  };
  const confirmHandler = () => {
    if(navigateTo === "/first-time-template/create-template"){
      navigate(navigateTo,{ state: { templateId: '' }});
    }else {
      navigate(navigateTo);
    }
  };
  const chooseOptionsStyle:React.CSSProperties = {
    width: '256px',
    height: '160px',
    boxSizing: 'border-box',
    border: '2px solid #E5E6E8',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    marginRight: '24px',
    marginBottom: '24px',
    cursor: 'pointer'
  }
  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            width: '42.8vw',
            borderRadius: '8px',
            height: '65.18vh',
            padding: '32px',
            overflowY: 'hidden',
          },
        }}
        open={openState}
      >
        <Typography
          variant="h-1"
          style={{
            fontFamily: 'Mulish',
            lineHeight: '32px',
            fontWeight: '700',
            fontSize: '24px',
            color: '#000A18',
            paddingBottom: '35px',
          }}
        >
          Choose an option to continue
        </Typography>

        <Grid container className="chooseOptionsContainer">
          <div
           style={{...chooseOptionsStyle,border:`2px solid ${navigateTo === '/first-time-template/create-template' ? "#0451C4" : "#E5E6E8"}`}}
           title="create new template"
          >
            <img
              onClick={() => setNavigateTo('/first-time-template/create-template')}
              src={CreateNewTemplate}
              alt="create new template"
            />
            <div
            className="chooseOptionsSections"
            >
              Create new template
            </div>
          </div>
          <div
           style={{...chooseOptionsStyle,border:`2px solid ${navigateTo === '/first-time-template/create-user' ? "#0451C4" : "#E5E6E8"}`}}
           title="create new user"
          >
            <img
              onClick={() => setNavigateTo('/first-time-template/create-user')}
              src={CreateNewUser}
              alt="create new user"
            />
            <div className="chooseOptionsSections">
              Create new user
            </div>
          </div>
          <div
           style={{...chooseOptionsStyle,border:`2px solid ${navigateTo === '/first-time-template/create-role' ? "#0451C4" : "#E5E6E8"}`}}
           title="create new role"
          >
            <img
              onClick={() => setNavigateTo('/first-time-template/create-role')}
              src={CreateNewRole}
              alt="create new role"
            />
            <div className="chooseOptionsSections">
              Create new role
            </div>
          </div>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <footer style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              style={{
                padding: '0px 32px',
                textDecoration: 'none',
                color: '#525862',
                fontWeight: '700',
                fontFamily: 'Mulish',
                cursor: 'pointer',
              }}
              onClick={cancelHandler}
            >
              Cancel
            </Link>
            <SubmitButton text="Confirm" onClick={confirmHandler} />
          </footer>
        </Grid>
      </Dialog>
    </>
  );
};

export default FirstLoginModal;


export const setUserFirstLoggedIn = async ({userId}:{userId?:string}) => {
  const userObj = {
    filter: {
      id: userId,
    },
    update: {first_login:'loggedIn'},
  };
  const response = await requestAPI({
    type: REQUEST_PUT,
    url:'/api/user',
    data: userObj,
  });
  console.log("??>> response ",response)
}