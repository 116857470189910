import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { token } from '../../utils/StateStore';
import { RoleInfo } from '../../types/types';
import { REQUEST_GET } from '../../utils/const';
import { requestAPI } from '../../utils/fetch';

function UserRole() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      const value = token?.value?.userInfo
      const response = await requestAPI({
        type: REQUEST_GET,
        url: `/api/user?email=${value?.email}&roles=true`,
      });
      //TODO: handling code acc to response status
      if (response) {
        setUsers(response.roles);
      } else {
        console.error(response);
      }
    })();
  }, []);

  return (
    <>
      <Link to="/">
        <button>Home Page</button>
      </Link>
      <p>
        {users.map((value: RoleInfo, index) => (
          <li key={index}>{value.name}</li>
        ))}
      </p>
    </>
  );
}

export default UserRole;
