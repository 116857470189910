import { Box } from '@mui/system';
// import NavLinks from '../../navLinks/NavLinks';
const Home = () => {
  return (
    <Box>
      <Box sx={{ marginLeft: '40%', marginTop: '12%' }}>
        <svg
          width="124"
          height="124"
          viewBox="0 0 124 124"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="2"
            y="15"
            width="108"
            height="62.6992"
            rx="4"
            fill="#6897DC"
          />
          <rect
            x="14"
            y="26.9424"
            width="108"
            height="71.6563"
            rx="4"
            fill="#F0F5FB"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M122 36.8946L14 36.8946V35.8994L122 35.8994V36.8946Z"
            fill="#6897DC"
          />
          <rect
            x="24"
            y="41.8711"
            width="88"
            height="13.9332"
            rx="2"
            fill="#DCE7F7"
          />
          <rect
            x="24"
            y="58.79"
            width="88"
            height="19.9045"
            rx="2"
            fill="#DCE7F7"
          />
          <path
            d="M89.0308 53.6378C96.8616 62.8871 95.6519 76.6892 86.3036 84.4634C76.9554 92.2376 63.0307 91.0217 55.1998 81.7723C47.3689 72.523 48.5786 58.7209 57.9269 50.9467C67.2752 43.1725 81.1999 44.3885 89.0308 53.6378Z"
            fill="white"
            fill-opacity="0.61"
            stroke="#2C6DCD"
            stroke-width="4"
            stroke-linecap="round"
          />
          <path
            d="M85.4668 85.1475L103.302 106.214"
            stroke="#2C6DCD"
            stroke-width="4"
            stroke-linecap="round"
          />
          <path
            d="M72.9592 72.2257H70.0034V70.4426C70.0034 69.6962 70.0782 69.1433 70.2279 68.7839C70.4025 68.3968 70.7766 67.8854 71.3503 67.2496L73.2585 65.1347C73.6576 64.6647 73.8571 64.0566 73.8571 63.3101C73.8571 62.5914 73.6451 61.997 73.2211 61.527C72.822 61.057 72.2857 60.8221 71.6122 60.8221C70.9388 60.8221 70.3776 61.057 69.9286 61.527C69.4796 61.9693 69.2302 62.5637 69.1803 63.3101H66C66.1746 61.5961 66.7732 60.2553 67.7959 59.2878C68.8435 58.2925 70.1531 57.7949 71.7245 57.7949C73.2959 57.7949 74.568 58.2787 75.5408 59.2463C76.5136 60.1862 77 61.4856 77 63.1443C77 64.2777 76.7007 65.2453 76.102 66.047C75.7279 66.5999 75.254 67.1666 74.6803 67.7472C74.0816 68.3554 73.6701 68.8115 73.4456 69.1156C73.1213 69.6132 72.9592 70.2214 72.9592 70.9402V72.2257ZM69.517 76.5383C69.517 75.9578 69.7166 75.4602 70.1156 75.0455C70.5147 74.6032 70.9887 74.382 71.5374 74.382C72.0862 74.382 72.5601 74.5894 72.9592 75.004C73.3583 75.4187 73.5578 75.9163 73.5578 76.4969C73.5578 77.1051 73.3583 77.6303 72.9592 78.0727C72.585 78.4873 72.1236 78.6947 71.5748 78.6947C71.0261 78.6947 70.5397 78.4873 70.1156 78.0727C69.7166 77.6303 69.517 77.1189 69.517 76.5383Z"
            fill="#6897DC"
          />
          <ellipse cx="25" cy="31.9191" rx="1" ry="0.995226" fill="#6897DC" />
          <ellipse cx="19" cy="31.9191" rx="1" ry="0.995226" fill="#6897DC" />
          <ellipse cx="31" cy="31.9191" rx="1" ry="0.995226" fill="#6897DC" />
        </svg>
      </Box>
      <div style={{ marginLeft: '40%', padding: '15px' }}>Coming soon</div>
    </Box>
  );
};

export default Home;
