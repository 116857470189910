import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Button,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import AddEntity from './AddEntity';
import { getAllEntities } from '../../api/enitities';
import { secondaryColor } from '../../theme';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UpdateEntity from './UpdateEntity';
import Confirm from '../../components/modal/Confirm';
import { deleteEntity } from '../../api/entity';
import { updateSnackbar } from '../../utils/utils';
import { entityInfo } from '../../utils/StateStore';
import Spinner from '../../components/layout/Spinner';

type Props = {};

const EntityList = (props: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orgInfo] = useState<any>(() => entityInfo?.value?.orgInfo);
  const [addEntityModal, setAddEntityModal] = useState(false);
  const [updateEntityModal, setUpdateEntityModal] = useState(false);
  const [entities, setEntities] = useState<any>([]);
  const [entityId, setEntityId] = useState<any>('');
  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const [noOfPages, setNoOfPages] = useState(
    Math.ceil(entities.length / rowsPerPage)
  );
  const [handleModalOpen, sethandleModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
  const [nameToDelete, setNameToDelete] = useState('');
  const [reload, setReload] = useState(false);
  const [orgStructure] = useState<string[]>(
    () => entityInfo?.value?.orgStructure?.Sublevel || []
  );

  const handleModalClose = async (confirm: boolean) => {
    sethandleModalOpen(confirm);
    if (confirm === true) {
      const res = await deleteEntity({
        entity_id: idToDelete,
        org_id: orgInfo?.id,
      });
      if (res) {
        setReload(true);
        sethandleModalOpen(false);
        updateSnackbar({
          state: true,
          severity: 'success',
          message: 'entity removed successfully',
        });
      }
    }
  };

  let filteredData: any;
  filteredData = entities;
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllEntities(orgInfo?.id);
      if (data) {
        setEntities(Object.values(data.entities));
        setIsLoading(false);
        setNoOfPages(
          Math.ceil(Object.values(data.entities).length / rowsPerPage)
        );
      }
    };
    if (orgInfo?.id && !addEntityModal && !updateEntityModal) fetchData();
  }, [orgInfo, addEntityModal, updateEntityModal, reload]);
  useEffect(() => {
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
  }, [filteredData]);

  const addEntityModalHandler = () => {
    setAddEntityModal(true);
  };

  const updateEntityModalHandler = (id: any) => {
    setEntityId(id);
    setUpdateEntityModal(true);
  };

  const backClickHandler = () => {
    navigate(-1);
  };

  const removeClickHandler = async (
    id: string,
    first_name: string,
    last_name: string
  ) => {
    setNameToDelete(first_name + ' ' + last_name);
    sethandleModalOpen(true);
    setIdToDelete(id);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Box>
        <Box sx={{ m: ['2.25rem 2rem 2rem 2rem'] }}>
          {' '}
          <Box display="flex" justifyContent="space-between">
            <div>
              <img
                onClick={backClickHandler}
                style={{ paddingRight: '8px', cursor: 'pointer' }}
                src={ArrowLeft}
                alt="left arrow"
              />

              <Typography alignItems="start" variant="h-1">
                Entities
              </Typography>
            </div>
            {entities.length > 0 ? (
              <Button
                style={{ alignItems: 'end' }}
                variant="contained"
                onClick={addEntityModalHandler}
              >
                Add
              </Button>
            ) : null}
          </Box>
          {/* </div> */}
        </Box>
        {entities.length <= 0 ? (
          <Box sx={{ padding: '5%' }}>
            <Box sx={{ marginLeft: '40%' }}>
              <svg
                width="117"
                height="116"
                viewBox="0 0 117 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.5" width="116" height="116" rx="58" fill="#F0F5FB" />
                <g clipPath="url(#clip0_5994_24772)">
                  <path
                    d="M59.5226 72.6062C68.7599 72.6062 76.2417 65.1244 76.2417 55.8871C76.2417 46.6498 68.7599 39.168 59.5226 39.168C50.2853 39.168 42.8035 46.6498 42.8035 55.8871C42.8035 65.1244 50.2853 72.6062 59.5226 72.6062Z"
                    fill="#2C6DCD"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M59.6858 79.9219C67.7244 79.9219 74.6683 81.3252 80.3314 83.1765C78.4176 85.7753 77.2868 88.9862 77.2868 92.4612C77.2868 101.118 84.3043 108.135 92.9609 108.135C94.5272 108.135 96.0399 107.906 97.467 107.478V108.984H21.9045V92.0312C21.9045 92.0312 36.0725 79.9219 59.6858 79.9219Z"
                    fill="#2C6DCD"
                  />
                  <path
                    d="M38.2505 27.0832L59.5 20L80.7494 27.0832L59.5 32.7497L38.2505 27.0832Z"
                    fill="#2C6DCD"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M70.126 36.9998V28.5H74.3759V36.9998H70.126Z"
                    fill="#2C6DCD"
                  />
                </g>
                <path
                  d="M105.501 92.4612C105.501 99.3865 99.8865 105.001 92.9612 105.001C86.0359 105.001 80.4219 99.3865 80.4219 92.4612C80.4219 85.5359 86.0359 79.9219 92.9612 79.9219C99.8865 79.9219 105.501 85.5359 105.501 92.4612Z"
                  fill="#2C6DCD"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M91.9163 98.7307L91.9163 86.1914L94.0061 86.1914L94.0061 98.7307L91.9163 98.7307Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M86.6914 91.418H99.2307V93.5079H86.6914V91.418Z"
                  fill="white"
                />
                <defs>
                  <clipPath id="clip0_5994_24772">
                    <rect
                      x="12.5"
                      y="12"
                      width="93"
                      height="93"
                      rx="46.5"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Box>
            <div style={{ marginLeft: '33%', padding: '15px' }}>
              You haven't added any entity
            </div>
            <Button
              variant="contained"
              onClick={addEntityModalHandler}
              sx={{ marginLeft: '38%' }}
            >
              Add a entity
            </Button>
          </Box>
        ) : (
          <>
            <Box>
              <TableContainer sx={{ width: '95%', m: '2rem' }}>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        width="3.96%"
                        style={{ fontWeight: '700' }}
                      ></TableCell>

                      <TableCell
                        width="15%"
                        style={{ fontWeight: '700' }}
                        align="left"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: '700', width: '15%' }}
                        align="left"
                      >
                        Gender
                      </TableCell>
                      {orgStructure.map((level, i: number) => (
                        <TableCell
                          style={{ fontWeight: '700', width: '12%' }}
                          align="left"
                          key={`sublevelHeader${i}`}
                        >
                          {level}
                        </TableCell>
                      ))}
                      <TableCell
                        style={{ fontWeight: '700', width: '15%' }}
                        align="left"
                      >
                        Entity Code
                      </TableCell>
                      <TableCell
                        width="37.2%"
                        style={{ fontWeight: '700' }}
                        align="left"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(filteredData) &&
                      filteredData
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((val: any, index: number) => (
                          <TableRow
                            key={index.toString() + '..' + val.first_name}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              key={index.toString() + '1' + val.first_name}
                            >
                              {(page - 1) * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: '18px',
                                fontSize: '14px',
                                cursor: 'pointer',
                              }}
                              align="left"
                              key={index.toString() + '2' + val.first_name}
                            >
                              {val.first_name + ' ' + val.last_name}
                            </TableCell>
                            <TableCell
                              sx={{ padding: '18px', fontSize: '14px' }}
                              align="left"
                              key={index.toString() + '3' + val.gender}
                            >
                              {val.gender}
                            </TableCell>
                            {val.sublevel_ids.map((level: any, i: number) => (
                              <TableCell
                                key={`sublevelLabel${index}${i}`}
                                sx={{ padding: '18px', fontSize: '14px' }}
                                align="left"
                              >
                                {level?.value}
                              </TableCell>
                            ))}
                            <TableCell
                              sx={{ padding: '18px', fontSize: '14px' }}
                              align="left"
                              key={index.toString() + '3' + val.entity_code}
                            >
                              {val.entity_code}
                            </TableCell>
                            <TableCell align="left">
                              <Box display="flex">
                                <Button
                                  sx={{
                                    padding: 0,
                                    fontWeight: 700,
                                    marginLeft: '-30px',
                                  }}
                                  onClick={() => {
                                    updateEntityModalHandler(val.id);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  sx={{
                                    padding: 0,
                                    fontWeight: 700,
                                    color: 'red',
                                  }}
                                  onClick={() => {
                                    removeClickHandler(
                                      val.id,
                                      val.first_name,
                                      val.last_name
                                    );
                                  }}
                                >
                                  Remove
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box mr="2rem" mt="2rem" pb="1rem">
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="end"
              >
                {filteredData.length > rowsPerPage && (
                  <Typography variant="body-1">
                    {(page - 1) * rowsPerPage + 1}-
                    {entities.length < page * rowsPerPage
                      ? entities.length
                      : page * rowsPerPage}{' '}
                    of {entities.length} items
                  </Typography>
                )}
                {filteredData.length > 0 && (
                  <Pagination
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: previousIcon,
                          next: nextIcon,
                        }}
                        {...item}
                      />
                    )}
                    page={page}
                    count={noOfPages}
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                )}
                {filteredData.length === 0 && (
                  <Typography variant="body-1">
                    Zero entities present
                  </Typography>
                )}
              </Stack>
            </Box>
          </>
        )}

        <Confirm
          open={handleModalOpen}
          handleClose={handleModalClose}
          buttonText={'Remove'}
          mainHeading={'Remove this entity?'}
          secondaryHeading={
            'Are you sure you want to remove this entity (' +
            nameToDelete +
            ')?'
          }
        />
        {addEntityModal && (
          <AddEntity
            open={addEntityModal}
            handleClose={() => {
              setAddEntityModal(false);
            }}
          />
        )}
        {updateEntityModal && (
          <UpdateEntity
            open={updateEntityModal}
            handleClose={() => {
              setUpdateEntityModal(false);
            }}
            entityId={entityId}
          />
        )}
      </Box>
    </>
  );
};
export const previousIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Prev
      </Typography>
      <ArrowBackIosNewIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export const nextIcon = () => {
  return (
    <>
      <Typography variant="h-3" sx={{ color: secondaryColor }}>
        Next
      </Typography>

      <ArrowForwardIosIcon color="primary" sx={{ height: '0.75rem' }} />
    </>
  );
};

export default EntityList;
