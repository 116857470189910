// import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import TextInput from '../../components/Input/TextInput/TextInput';
import { FieldTypes } from '../../utils/const';

type Props = {
  updateField: (field: any, pos: number) => void;
  fieldData: any;
  pos: number;
  isTemplate?: boolean;
};

const Dates = ({ updateField, pos, fieldData, isTemplate = false }: Props) => {
  const [date, setDate] = useState<any>({
    ...fieldData,
    type: FieldTypes.DATE,
  });
  // const dateValue =
  //   fieldData.values && fieldData.values[0].value
  //     ? fieldData.values[0].value
  //     : new Date().toISOString().split('T')[0];
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate({
      ...date,
      label: Array.isArray(fieldData.label)
        ? [{ key: '', value: event.target.value }]
        : event.target.value,
    });
  };
  useEffect(() => {
    updateField(date, pos);
  }, [date]);
  return (
    <Box ml="2rem" mb="2rem" display="flex" flexDirection="column">
      {fieldData.type !== 'textbox' && (
        <TextInput
          value={
            Array.isArray(fieldData.label)
              ? fieldData.label[0].value
              : fieldData.label
          }
          onChange={onChange}
          name="label"
          placeholder="Add Label"
        />
      )}
      {/* {!isTemplate ? (
        <TextField
          sx={{
            width: '21.625rem',
            marginLeft: '1rem',
          }}
          name="someDate"
          InputLabelProps={{ shrink: true, required: true }}
          type="date"
          defaultValue={dateValue}
          disabled={!isTemplate}
        />
      ) : (
        <></>
      )} */}
    </Box>
  );
};

export default Dates;
