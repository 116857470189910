import { ICreateEntity } from "../types/types";
import { REQUEST_DELETE, REQUEST_POST, REQUEST_PUT } from "../utils/const";
import { requestAPI } from "../utils/fetch";
import { updateAlert } from "../utils/utils";

  export const createEntity = async (payload: ICreateEntity) => {
    try {
      const response = await requestAPI({
        type: REQUEST_POST,
        url: '/api/entity',
        data: payload,
      });
      if (!response) {
        return false;
      }
      return response;
    } catch (error: any) {
      updateAlert({ state: true, severity: 'error', message: error.message });
    }
  };

  export const updateEntity = async (payload: any) => {
    try {
      const response = await requestAPI({
        type: REQUEST_PUT,
        url: '/api/entity',
        data: payload,
      });
      if (!response) {
        return false;
      }
      return response;
    } catch (error: any) {
      updateAlert({ state: true, severity: 'error', message: error.message });
    }
  };

  export const deleteEntity = async (payload: any) => {
    try {
      const response = await requestAPI({
        type: REQUEST_DELETE,
        url: '/api/entity',
        data: payload
      });
      if (!response) {
        return false;
      }
      return response;
    } catch (error: any) {
      updateAlert({ state: true, severity: 'error', message: error.message });
    }
  };