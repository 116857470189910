import { OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import { useRef } from 'react';
import styles from './searchField.module.css';

function SearchField({ onchange, onClear, inputText }: any) {
  const textRef = useRef();
  return (
    <OutlinedInput
      inputRef={textRef}
      endAdornment={
        <InputAdornment position="end">
          <ClearIcon
            onClick={() => {
              onClear(textRef);
            }}
            className={
              textRef &&
              textRef.current &&
              textRef.current['value'] &&
              textRef.current['value']['length'] > 0
                ? styles.clearSearchIcon
                : styles.hideClearSearchIcon
            }
          />
        </InputAdornment>
      }
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      defaultValue={inputText}
      value={inputText}
      sx={{ ml: '2rem', height: '10px' }}
      onChange={onchange}
      placeholder="Search"
      autoComplete="off"
    />
  );
}

export default SearchField;
