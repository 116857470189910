import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseCircullarIcon from '../../assets/png/CloseCircullar.png';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Input,
} from '@mui/material';
import DeleteOption from '../../assets/png/DeleteOption.png';
import AddDivider from '../../pages/ProjectTemplate/AddDivider';
import styles from '../../pages/ProjectTemplate/createTemplate.module.css';
import {
  createOrganisationStructure,
  updateOrganisationStructure,
} from '../../api/organisation';
import { updateSnackbar } from '../../utils/utils';
import React from 'react';
import Confirm from './Confirm';

type Props = {
  open: boolean;
  handleClose: (confirm: boolean) => void;
  projectId: string;
  jsonData?: any;
  isEdit?: boolean;
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '35%',
  bgcolor: 'white',
  height: '95.51%',
  borderRadius: '8px',
};
const CreateOrgStructure = ({
  open,
  handleClose,
  projectId,
  jsonData,
  isEdit,
}: Props) => {
  const [attrs, setAttrs] = useState<any>(
    jsonData ? jsonData.attributes : ['']
  );
  const [subLevels, setSubLevels] = useState<any>(
    jsonData ? [...jsonData.Sublevel] : ['']
  );
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [saveEditOrgStructModal, setSaveEditOrgStructModal] = useState(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const saveEditOrgStructModalClose = async (confirm: boolean) => {
    setSaveEditOrgStructModal(confirm);
    if (confirm === true) {
      const finalSublevels: string[] = [];
      subLevels.forEach((item: string) => {
        if (item !== '') finalSublevels.push(item);
      });
      if (!isEdit) {
        let structData = {
          organizationStructureData: {
            projectId: projectId,
            Sublevel: subLevels,
            attributes: attrs,
          },
        };
        let orgStructCreated = await createOrganisationStructure(structData);
        if (orgStructCreated) {
          window.location.reload();
        }
      } else {
        let structData = {
          organizationStructureData: {
            projectId: projectId,
            Sublevel: subLevels,
            attributes: attrs,
          },
          project_id: projectId,
          structure_id: jsonData.id,
        };
        let orgStructUpdated = await updateOrganisationStructure(structData);
        if (orgStructUpdated) {
          setSaveEditOrgStructModal(false);
          handleClose(false);
          window.location.reload();
        }
      }
    }
  };
  const saveChangesHandler = async (confirm: boolean) => {
    if (isEdit) setSaveEditOrgStructModal(true);
    else {
      if (confirm === true) {
        const finalSublevels: string[] = [];
        subLevels.forEach((item: string) => {
          if (item !== '') finalSublevels.push(item);
        });
        let structData = {
          organizationStructureData: {
            projectId: projectId,
            Sublevel: subLevels,
            attributes: attrs,
          },
        };
        let orgStructCreated = await createOrganisationStructure(structData);
        if (orgStructCreated) {
          window.location.reload();
        }
      }
    }
  };
  const addAttributesHandler = () => {
    if (attrs[attrs.length - 1] && attrs[attrs.length - 1] !== '') {
      const attribute = '';
      setAttrs([...attrs, attribute]);
    } else {
      updateSnackbar({
        state: true,
        severity: 'error',
        message: 'Attribute value cannot be empty',
      });
    }
  };
  const delAttributesHandler = (pos: number) => {
    const allAttributes = [...attrs];
    allAttributes.splice(pos, 1);
    setAttrs(allAttributes);
  };
  const handleAttribute = (e: any, i: number, attr: any) => {
    const allAttributes = [...attrs];
    if (typeof allAttributes[i] !== 'string') {
      allAttributes[i].value = e.target.value;
    } else {
      allAttributes[i] = e.target.value;
    }
    setAttrs(allAttributes);
  };
  const addSublevelHandler = () => {
    if (
      subLevels[subLevels.length - 1] &&
      subLevels[subLevels.length - 1] !== ''
    ) {
      if (subLevels.length < 7) {
        const newLevel = '';
        setSubLevels([...subLevels, newLevel]);
      }
    } else {
      updateSnackbar({
        state: true,
        severity: 'error',
        message: 'Sublevel value cannot be empty',
      });
    }
  };
  const delSublevelHandler = (pos: number) => {
    const allLevels = [...subLevels];
    allLevels.splice(pos, 1);
    setSubLevels(allLevels);
  };
  const handleSubLevel = (e: any, i: number) => {
    const allLevels = [...subLevels];
    allLevels[i] = e.target.value;
    setSubLevels(allLevels);
  };
  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box sx={{ overflowY: 'auto' }}>
            <Box
              sx={{ m: '2rem 2rem 2rem 2rem' }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h-1">
                Create Organisation Structure
              </Typography>

              <img
                src={CloseCircullarIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => handleClose(false)}
                alt="close button"
              />
            </Box>
            <Box
              sx={{
                border: '1px solid #B8BABE',
                marginLeft: '32px',
                marginRight: '32px',
              }}
            >
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box>
                    <Typography variant="h-3">Attributes</Typography>

                    <Typography
                      variant="h-3"
                      sx={{ color: '#0451C4', marginLeft: '365px' }}
                    >
                      {expanded === 'panel1' ? 'Close' : 'Edit'}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ minHeight: '60px' }}>
                    {attrs.map((attr: any, index: number, arr: any) => (
                      <Box sx={{ marginBottom: '26px' }}>
                        <Input
                          value={attr.label || attr.value || attr || ''}
                          placeholder="Add label name"
                          sx={{
                            padding: '0.5rem 1rem',
                            border: '1px solid #B8BABE',
                            height: '20px',
                            width: '400px',
                            left: '32px',
                            borderRadius: '0px',
                          }}
                          name="name"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleAttribute(event, index, attr)}
                        />
                        {arr.length > 1 && (
                          <img
                            onClick={() => delAttributesHandler(index)}
                            src={DeleteOption}
                            alt="delete option"
                            style={{
                              cursor: 'pointer',
                              marginLeft: '35px',
                              marginRight: '25px',
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>

                  <Grid container justifyContent="space-around">
                    <label
                      className={styles.addLabel}
                      style={{ marginBottom: '15px' }}
                    >
                      ADD ATTRIBUTE LEVEL
                    </label>
                  </Grid>
                  <Grid container justifyContent="space-around">
                    <AddDivider
                      clickHandler={addAttributesHandler}
                      hrstyle={'modalDivider'}
                    ></AddDivider>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box
              sx={{
                border: '1px solid #B8BABE',
                marginLeft: '32px',
                marginRight: '32px',
                marginTop: '40px',
              }}
            >
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Box>
                    <Typography variant="h-3">Sublevels</Typography>
                    <Typography
                      variant="h-3"
                      sx={{ color: '#0451C4', marginLeft: '365px' }}
                    >
                      {expanded === 'panel2' ? 'Close' : 'Edit'}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ minHeight: '92px', backgroundColor: '#FAFAFA' }}>
                    {subLevels.map((subLevel: any, index: number, arr: any) => (
                      <Box sx={{ marginBottom: '26px' }}>
                        <Input
                          value={subLevel || ''}
                          placeholder="Add label name"
                          sx={{
                            padding: '0.5rem 1rem',
                            border: '1px solid #B8BABE',
                            height: '20px',
                            width: '400px',
                            left: '32px',
                            borderRadius: '0px',
                          }}
                          name="name"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleSubLevel(event, index)}
                        />
                        {arr.length > 1 && (
                          <img
                            onClick={() => delSublevelHandler(index)}
                            src={DeleteOption}
                            alt="delete option"
                            style={{
                              cursor: 'pointer',
                              marginLeft: '35px',
                              marginRight: '25px',
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                  <Grid container justifyContent="space-around">
                    <label
                      className={styles.addLabel}
                      style={{ marginBottom: '15px' }}
                    >
                      ADD SUBLEVEL
                    </label>
                  </Grid>
                  <Grid container justifyContent="space-around">
                    <AddDivider
                      clickHandler={addSublevelHandler}
                      hrstyle={'modalDivider'}
                    ></AddDivider>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>

          <Box
            p="2rem"
            borderTop="1px solid #E5E6E8"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button variant="text" onClick={() => handleClose(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ mr: '2rem' }}
              onClick={() => {
                saveChangesHandler(true);
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
        {saveEditOrgStructModal && (
          <Confirm
            open={saveEditOrgStructModal}
            handleClose={saveEditOrgStructModalClose}
            buttonText={'Save'}
            mainHeading={'Save changes?'}
            secondaryHeading={`All your previous organisation data might get deleted`}
          />
        )}
      </Box>
    </Modal>
  );
};

export default CreateOrgStructure;
