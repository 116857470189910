import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import { token } from './utils/StateStore';
import ForgotPassword from './pages/Login/ForgotPassword';
import SetNewPassword from './pages/Login/SetNewPassword';
import Otp from './pages/common/Otp';
import PasswordUpdateSuccessful from './pages/Login/PasswordUpdateSuccessful';
import '@fontsource/mulish';
import Alert from './pages/common/AlertBox';
import { customTheme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import BasicSnackbar from './pages/common/BasicSnackbar/BasicSnackbar';
import { loadProjectTypeInfo, refreshTokenAndPermission } from './utils/utils';
import ProtectedApp from './components/layout/ProtectedApp';
import Spinner from './components/layout/Spinner';

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const refreshToken = async () => {
      setIsLoading(true);
      if (
        token?.value?.accessToken === undefined ||
        token?.value?.accessToken === null
      ) {
        await refreshTokenAndPermission();
        setIsLoading(false);
      }
    };
    refreshToken();
    loadProjectTypeInfo();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <ThemeProvider theme={customTheme}>
      <div style={{ height: '100vh', width: '100%', overflowX: 'hidden' }}>
        <Router>
          <Alert />
          <Routes>
            <Route path="/*" element={<ProtectedApp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/forgotpassword" element={<ForgotPassword />} />
            <Route path="/login/updatepassword" element={<SetNewPassword />} />
            <Route
              path="/login/updatepassword/successful"
              element={<PasswordUpdateSuccessful />}
            />
            <Route path="/otp" element={<Otp />} />
          </Routes>
          <BasicSnackbar />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
