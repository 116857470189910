import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getTemplateData } from '../../api/template';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowLeft from '../../assets/png/ArrowLeft.png';
import { getProjectData } from '../../api/project';
import { ProjectSectionProjectType } from '../../types/types';
import styles from './createProject.module.css';
import Section from '../common/Section';
import BasicMenu from '../common/ProjectMenuItem';
import PreviewWithAccordian from '../../components/modal/Preview/PreviewWithAccordion';

type Props = {};

const PreviewProject = (props: Props) => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const [files, setFiles] = useState<File[]>([]);
  const [previewModalView, setPreviewModalView] = useState(false);
  const [projectData, setProjectData] = useState<{
    name: string;
    alias?: string;
    sections: ProjectSectionProjectType[];
  }>({ name: '', alias: '', sections: [] });
  useEffect(() => {
    async function fetchMyAPI() {
      const templateId = state?.templateId;
      const projectId = state?.projectId;
      if (templateId && templateId !== '') {
        const data = await getTemplateData(templateId);
        if (typeof data !== 'boolean') {
          setProjectData(data!);
        }
      } else if (projectId && projectId !== '') {
        if (projectId && projectId !== '') {
          const data = await getProjectData(projectId);
          if (typeof data !== 'boolean') {
            setProjectData(data!);
          }
        }
      }
    }
    fetchMyAPI();
  }, [state?.projectId, state?.templateId]);
  const deleteSection = (pos: number) => {
    projectData.sections.splice(pos, 1);
    setProjectData({
      ...projectData,
      sections: projectData.sections,
    });
  };
  const updateSectionsData = (
    pos: number,
    section: ProjectSectionProjectType
  ) => {
    let updatePosArray = projectData.sections;
    updatePosArray[pos] = section;
    // setSections({ ...sections, attributes: updatePosArray });
    setProjectData({
      ...projectData,
      sections: updatePosArray,
    });
  };

  const updateFiles = (file: File[], action: string, name?: string) => {
    if (action === 'add') {
      setFiles([...files, ...file]);
    } else {
      setFiles(files.filter((file) => file?.name !== name));
    }
  };
  const previewModalHandler = () => {
    setPreviewModalView(!previewModalView);
  };
  const backClickHandler = () => {
    navigate(-1);
  };
  return (
    <Box style={{ height: '100%' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        m="2rem"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            onClick={backClickHandler}
            style={{ paddingRight: '8px', cursor: 'pointer' }}
            src={ArrowLeft}
            alt="left arrow"
          />
          <Typography variant="h-1">
            {projectData?.name || 'Project'}
          </Typography>
        </div>
        <BasicMenu
          previewModalHandler={previewModalHandler}
          projectId={state?.projectId}
          callFrom="preview"
        />
      </Box>
      <div className={styles.previewProjectWrapper}>
        {projectData?.sections?.length > 0 &&
          projectData?.sections?.map((section: any, pos: number) => (
            <Section
              updateSectionsData={updateSectionsData}
              sectionData={section}
              key={section.key}
              pos={pos}
              deleteSection={deleteSection}
              updateFiles={updateFiles}
              editable={false}
              callFrom={'projectPreview'}
            />
          ))}
      </div>
      {previewModalView && (
        <PreviewWithAccordian
          open={previewModalView}
          handleClose={(previewOpen: boolean) => {
            setPreviewModalView(previewOpen);
            window.location.reload();
          }}
          modalId={state?.projectId}
          modalType="project"
          isNewProjectCreation={false}
        />
      )}
    </Box>
  );
};
export default PreviewProject;
