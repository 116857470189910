import {
  REQUEST_DELETE,
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
} from '../utils/const';
import { requestAPI } from '../utils/fetch';
import { updateAlert } from '../utils/utils';

export const getEntityFilledFormData = async (
  formId: string,
  organizationId: string
) => {
  try {
    if (formId === '' || organizationId === '') {
      return false;
    }
    const formResponse = await requestAPI({
      type: REQUEST_GET,
      url: `/api/org-filled-entities?form_id=${formId}&org_id=${organizationId}`,
    });
    if (!formResponse) {
      return false;
    }
    return formResponse.filled_entities;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const getSingleEntityFilledFormData = async (
  formId: string,
  entityId: string,
  orgId: String,
  sublevelIds: string[]
) => {
  try {
    if (
      formId === '' ||
      entityId === '' ||
      orgId === '' ||
      !sublevelIds.length
    ) {
      return false;
    }
    let sublevel = '';
    sublevelIds.forEach((sub) => {
      sublevel = sublevel.concat(`&sublevel_ids=${sub}`);
    });
    const formResponse = await requestAPI({
      type: REQUEST_GET,
      url: `/api/form-data?form_id=${formId}&entity_id=${entityId}&organization_id=${orgId}${sublevel}`,
    });
    if (!formResponse) {
      return false;
    }
    return formResponse;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};
export const deleteFormData = async (formData: any) => {
  try {
    const response = await requestAPI({
      type: REQUEST_DELETE,
      url: '/api/form-data',
      data: formData,
    });
    if (!response) {
      return false;
    }
    return response;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const updateFormData = async (formData: any) => {
  try {
    const response = await requestAPI({
      type: REQUEST_PUT,
      url: '/api/form-data',
      data: formData,
    });
    if (!response) {
      return false;
    }
    return response;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};

export const createFormData = async (formData: any) => {
  try {
    const response = await requestAPI({
      type: REQUEST_POST,
      url: '/api/form-data',
      data: formData,
    });
    if (!response) {
      return false;
    }
    return response;
  } catch (error: any) {
    updateAlert({ state: true, severity: 'error', message: error.message });
  }
};
