import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import TextInput from '../../components/Input/TextInput/TextInput';
import styles from '../ProjectTemplate/Section.module.css';
import { FieldTypes } from '../../utils/const';
import DeleteIcon from '../../assets/png/DeleteIcon.png';
import { FieldProjectType, SubSectionProjectType } from '../../types/types';
import Fields from './Fields';

type Props = {
  updateFiles: (file: File[], action: string, name?: string) => void;
  updateSubSection: (subsection: SubSectionProjectType, pos: number) => void;
  deleteSubSection: (pos: number) => void;
  pos: number;
  key: string;
  subSectionData: SubSectionProjectType;
  withoutTitle: boolean;
  callFrom?: string;
  editable?: boolean;
  projectEdit?: string;
};

const Subsection = ({
  updateSubSection,
  deleteSubSection,
  pos,
  key,
  subSectionData,
  updateFiles,
  withoutTitle,
  callFrom,
  editable,
  projectEdit,
}: Props) => {
  const [subSection, setSubSection] =
    useState<SubSectionProjectType>(subSectionData);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubSection({ ...subSection, [event.target.name]: event.target.value });
  };
  useEffect(() => {
    updateSubSection(subSection, pos);
  }, [subSection]);
  useEffect(() => {
    if (
      subSectionData &&
      subSectionData.id &&
      subSection &&
      subSection.id &&
      subSection.id !== subSectionData.id
    ) {
      setSubSection(subSectionData);
    }
  }, [subSectionData]);
  const deleteField = (pos: number) => {
    let updatePosArray = subSection.fields;
    updatePosArray.splice(pos, 1);
    setSubSection({
      ...subSection,
      fields: updatePosArray,
    });
  };

  const updateField = (field: FieldProjectType, pos: number) => {
    let updatePosArray = subSection.fields;
    updatePosArray[pos] = field;
    setSubSection({
      ...subSection,
      fields: updatePosArray,
    });
  };
  const addNewField = () => {
    const newField = {
      key: '',
      label: [{ key: '', value: '' }],
      type: FieldTypes.TEXTBOX,
      seq: 1,
    } as FieldProjectType;

    setSubSection({
      ...subSection,
      fields: [...subSection.fields, newField],
    });
  };
  return (
    <div className={editable ? styles.subContainer : styles.subContainerText}>
      {editable && callFrom !== 'formEdit' && callFrom !== 'formCreation' && (
        <button
          onClick={() => {
            deleteSubSection(pos);
          }}
          className={styles.deleteSubButton}
        >
          {' '}
          <img src={DeleteIcon} alt="delete icon" />{' '}
        </button>
      )}

      <div>
        {(!withoutTitle && editable && (
          <>
            {callFrom && callFrom !== 'formEdit' && (
              <Typography
                variant="Caption"
                sx={{
                  fontFamily: 'Mulish',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: '#525862',
                  marginLeft: '16px',
                }}
              >
                Sub Section title
              </Typography>
            )}
            <br />
            {callFrom && callFrom !== 'formEdit' && (
              <TextInput
                value={subSection.title}
                onChange={onChange}
                name="title"
                placeholder="Attribute Name"
                customClassName={styles.subSectionTitle}
                editable={editable}
              ></TextInput>
            )}
          </>
        )) || (
          <Typography
            style={{
              marginLeft: '16px',
              color: '#525862',
              marginBottom: '36px',
              fontWeight: '700',
              fontSize: '18px',
              fontStyle: 'Mulish',
            }}
          >
            {subSection.title}
          </Typography>
        )}
        <div>
          {subSection.fields &&
            subSection.fields.length > 0 &&
            subSection.fields.map((field: any, i) => (
              <Fields
                updateField={updateField}
                deleteField={deleteField}
                key={'attribute' + i}
                pos={i}
                fieldData={field}
                updateFiles={updateFiles}
                callFrom={callFrom}
                editable={editable}
                projectEdit={projectEdit}
              />
            ))}
        </div>
        {subSection.fields.length > 0 && editable ? (
          <Grid item className={styles.addNextFieldsSection}>
            <button onClick={addNewField} className={styles.addNextFieldsBtn}>
              <span className={styles.addNextFieldsBtnText}>
                {callFrom && callFrom === 'formCreation'
                  ? 'Add next question'
                  : 'Add next field'}
              </span>
            </button>
          </Grid>
        ) : (
          editable && (
            <Grid item className={styles.addFieldsSection}>
              <button onClick={addNewField} className={styles.addFieldsBtn}>
                <span className={styles.addFieldsBtnText}>Add fields</span>
              </button>
            </Grid>
          )
        )}
      </div>
    </div>
  );
};

export default Subsection;
