import { Grid } from '@mui/material';
import styles from './SpAccordion.module.css';

const SpAccordion = (props: any) => {
  const openBtnName = props.mode && props.mode == 'edit' ? 'Edit' : 'Open';
  return (
    <>
      {props.isActive ? (
        <Grid
          container
          justifyContent="space-around"
          className={
            props.mode !== 'edit' ? styles.firstSection : styles.firstSectionEdit
          }
          sx={{ width: '95%' }}
        >
          <Grid item sx={{ width: '95%' }}>
            <div className={styles.accBtnList}>
              {props.mode && props.mode === 'edit' && (
                <button
                  className={styles.delSectionBtn}
                  onClick={() => props.deleteHandler(props.pos)}
                >
                  Delete
                </button>
              )}
              <button
                className={styles.closeSectionBtn}
                onClick={() => {
                  props.setAccordionActiveIndex(undefined);
                }}
              >
                Close
              </button>
            </div>
          </Grid>
          {props.children}
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="space-around"
          className={
            props.mode !== 'edit' ? styles.firstSection : styles.firstSectionEdit
          }
          sx={{ width: '95%%' }}
        >
          <Grid item sx={{ width: '95%' }}>
            <div className={styles.accordionTitle}>{props.title}</div>
            <div className={styles.accBtnList}>
              {props.mode && props.mode === 'edit' ? (
                <button
                  className={styles.closeSectionBtn}
                  onClick={() => props.editHandler(props.pos)}
                >
                  {openBtnName}
                </button>
              ) : (
                <button
                  className={styles.closeSectionBtn}
                  onClick={() => props.editHandler(props.pos)} //TODO: make changes after create is implemented
                >
                  {openBtnName}
                </button>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SpAccordion;
