import { REQUEST_GET, REQUEST_PUT } from "../utils/const";
import { requestAPI } from "../utils/fetch";
import { updateAlert } from "../utils/utils";

export const getUserInfo = async (userId : string) => {
    try {
        const response = await requestAPI({
            type: REQUEST_GET,
            url: `/api/user?id=${userId}`,
          });
      if (!response) {
        return false;
      }
      return response;
    } catch (error: any) {
      updateAlert({ state: true, severity: 'error', message: error.message });
    }
  };

  export const updatePersonalInfo = async (userObj: any) => {
    try {
      const response = await requestAPI({
        type: REQUEST_PUT,
        url: '/api/user',
        data: userObj,
      });
      if (!response) {
        return false;
      }
      return response;
    } catch (error: any) {
      updateAlert({ state: true, severity: 'error', message: error.message });
    }
  };