import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseCircullarIcon from '../../assets/png/CloseCircullar.png';
import { Input } from '@mui/material';

type Props = {
  open: boolean;
  handleClose: (confirm: boolean) => void;
  handleEdit: (confirm: boolean) => void;
  handleDelete: (confirm: boolean) => void;
  projectId: string;
  editable?: boolean;
  jsonData?: any;
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '35%',
  bgcolor: 'white',
  height: '95.51%',
  borderRadius: '8px',
};

const ViewOrgStructure = ({
  open,
  handleClose,
  jsonData,
  handleEdit,
  handleDelete,
}: Props) => {
  let initialAttrs: any = [];
  if (jsonData && jsonData.attributes) {
    jsonData.attributes.map((item: any) => {
      if (item) {
        if (typeof item == 'string' && item !== '') {
          initialAttrs.push(item);
        } else {
          if (item.label !== '') {
            initialAttrs.push(item.label);
          }
        }
      }
      return '';
    });
  }
  const [attrs] = useState<any>(jsonData.attributes ? initialAttrs : []);
  const [subLevels] = useState<any>(jsonData.Sublevel ? jsonData.Sublevel : []);
  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box sx={{ overflowY: 'auto' }}>
            <Box
              sx={{ m: '2rem 2rem 2rem 2rem' }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h-1">Organisation Structure</Typography>

              <img
                src={CloseCircullarIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => handleClose(false)}
                alt="close button"
              />
            </Box>
            {attrs.length > 0 && (
              <>
                <Box
                  sx={{ m: '2rem 0 2rem 2rem' }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h-3">Attributes</Typography>
                </Box>
                <Box sx={{ minHeight: '60px' }}>
                  {attrs.map((attr: any, index: number, arr: any) => (
                    <Box sx={{ marginBottom: '26px' }}>
                      <Input
                        value={attr.label || attr || ''}
                        placeholder="Add label name"
                        sx={{
                          border: '1px solid #B8BABE',
                          height: '20px',
                          width: '400px',
                          left: '32px',
                          borderRadius: '0px',
                        }}
                        name="name"
                        disabled
                      />
                    </Box>
                  ))}
                </Box>
              </>
            )}
            {subLevels.length > 0 && (
              <>
                <Box
                  sx={{ m: '0 0 2rem 2rem' }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h-3">Sublevels</Typography>
                </Box>

                <Box sx={{ minHeight: '92px', backgroundColor: '#FAFAFA' }}>
                  {subLevels.map((subLevel: any, index: number, arr: any) => (
                    <Box sx={{ marginBottom: '26px' }}>
                      <Input
                        value={subLevel || ''}
                        placeholder="Add label name"
                        sx={{
                          border: '1px solid #B8BABE',
                          height: '20px',
                          width: '400px',
                          left: '32px',
                          borderRadius: '0px',
                        }}
                        name="name"
                        disabled
                      />
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Box>

          <Box
            p="2rem"
            borderTop="1px solid #E5E6E8"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              variant="text"
              sx={{ color: '#CE1515;' }}
              onClick={() => handleDelete(true)}
            >
              Delete Structure
            </Button>
            <Button variant="text" onClick={() => handleClose(false)}>
              Close
            </Button>
            <Button variant="contained" onClick={() => handleEdit(false)}>
              Edit Structure
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewOrgStructure;
