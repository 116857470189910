import { encode } from 'js-base64';
import jwt_decode from 'jwt-decode';
import { refreshToken } from '../api/authenticate';
import { getPermissions } from '../api/permissions';
import { AlertData, EntityInfo, Permission } from '../types/types';
import {
  alert,
  entityInfo,
  projectTypeInfo,
  snackbar,
  spinner,
  token,
} from './StateStore';
import { getOrgInfo } from '../api/user-org-role-info';
import ProjectSepecificJSON from '../assets/json/project_specific_data.json';

export const getRandomString = (len: number) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getBrowserId = () => {
  const userAgent = window.navigator.userAgent;
  return encode(userAgent);
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getUserInfoFromToken = (token: string) => {
  const decoded: any = jwt_decode(token);
  const {
    sub: id,
    email,
    first_name,
    last_name,
    pwd_expiry,
    phone,
    first_login,
  } = decoded;
  const userInfo = {
    email,
    first_name,
    last_name,
    phone,
    id,
    first_login,
    permissions: [],
    roles: [],
  };
  const userData = { userInfo, pwdExpiry: pwd_expiry, accessToken: token };
  return userData;
};

export const isExpired = (expiryData: number) => {
  return expiryData < Math.round(Date.now() / 1000) - 5000; //5000 milliseconds are added as a buffer time
};

export const updateAlert = (alertInfo: AlertData) => {
  alert?.next(alertInfo);
  setTimeout(() => {
    alert?.next({ message: '', state: false, severity: 'info' });
  }, 3000);
};

export const updateSnackbar = (alertInfo: AlertData) => {
  snackbar?.next(alertInfo);
  setTimeout(() => {
    snackbar?.next({ message: '', state: false, severity: 'info' });
  }, 3000);
};

export const updateLoading = () => {
  spinner?.next(true);
  setTimeout(() => {
    spinner?.next(false);
  }, 3000);
};

export const getPermissionForPopup = async (
  tableName: string,
  permissionType: string
) => {
  let userPermissions: Permission[];
  if (token?.value?.userInfo?.permissions.length === 0) {
    userPermissions = await getPermissions(true);
  } else {
    userPermissions = token?.value?.userInfo?.permissions
      ? token?.value?.userInfo?.permissions
      : [];
  }
  if (
    userPermissions?.some(
      (permission: any) =>
        (permission.name.includes(tableName) &&
          permission.name.includes(permissionType)) ||
        permission.name.includes('super') ||
        permission.name.includes('super_admin')
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const refreshTokenAndPermission = async () => {
  const token = await refreshToken();
  if (token) {
    const userPermissions = await getPermissions(true);
    if (!userPermissions) {
      console.error('Unable to fetch Permissions');
    }
    return true;
  }
  return false;
};

export const filterData = (
  array: any,
  inputText: any,
  filterParameter: String
) =>
  array.filter((item: any) => {
    //if no input the return the original
    if (inputText === '') {
      return item;
    }
    //return the item which contains the user input
    else {
      switch (filterParameter) {
        case 'roles':
          return (
            item.name.toLowerCase().includes(inputText) ||
            item.description.toLowerCase().includes(inputText)
          );
        case 'users':
          return `${item.first_name} ${item.last_name}`
            .toLowerCase()
            .includes(inputText);
        case 'templates':
          return item.name.toLowerCase().includes(inputText);
        case 'assignedForms':
          return item.form_name.toLowerCase().includes(inputText);
        default:
          return item.name.toLowerCase().includes(inputText);
      }
    }
  });

export const getUniqueFileName = (originalName: string) => {
  const uniqueSuffix = Date.now() + '-' + Math.round(Math.random() * 1e9);
  originalName = originalName.replaceAll(' ', '-');
  return (
    originalName.substr(0, originalName.lastIndexOf('.')) +
    '-' +
    uniqueSuffix +
    '.' +
    originalName.substr(originalName.lastIndexOf('.') + 1)
  );
};

export const base64MimeType = (encoded: string) => {
  var result = null;

  if (typeof encoded !== 'string') {
    return result;
  }

  var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
};

export const fetchOrganisationData = async () => {
  const orgInfo = await getOrgInfo(true);
  if (orgInfo) {
    const _entity: EntityInfo = {
      orgInfo: orgInfo.organization,
      orgStructure: orgInfo.org_structre,
      formInfo: [],
      availableEntities: orgInfo.available_entities,
    };
    entityInfo?.next(_entity);
    return _entity;
  } else
    // updateSnackbar({
    //   message:
    //     'Unable to fetch Entity Information, please contact to system Administrator!',
    //   severity: 'error',
    //   state: true,
    // });
  return false;
};

export const loadProjectTypeInfo = async () => {
  const projectSepecificJSON: any = ProjectSepecificJSON;
  projectTypeInfo?.next([...projectSepecificJSON]);
};
