import * as React from 'react';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMore from '../../assets/png/ExpandMore.png';
import ExpandLess from '../../assets/png/ExpandLess.png';

type Props = {
  projectId?: string;
  previewModalHandler: () => void;
  callFrom?: string;
};

export default function ProjectMenuItem({
  previewModalHandler,
  projectId,
  callFrom,
}: Props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value: string) => {
    if (value === 'Edit Project data' && projectId) previewModalHandler();
    else if (value === 'Edit Project structure')
      navigate('/edit-project', { state: { projectId: projectId } });
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant={callFrom === 'preview' ? 'contained' : 'text'}
        sx={
          callFrom === 'preview'
            ? {}
            : {
                border: '1px solid #E5E6E8',
                borderRadius: '4px 0px 0px 4px',
                color: 'black',
                fontFamily: 'Mulish',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '24px',
                justifyContent: 'space-evenly',
              }
        }
      >
        {callFrom === 'preview' ? 'Edit Project' : 'Edit'}
        {callFrom === 'preview' ? (
          <img
            src={open ? ExpandLess : ExpandMore}
            alt="dropdown tick"
            style={{ marginLeft: 4 }}
          />
        ) : null}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClose('Edit Project data')}>
          Edit Project data
        </MenuItem>
        <MenuItem onClick={() => handleClose('Edit Project structure')}>
          Edit Project structure
        </MenuItem>
      </Menu>
    </>
  );
}
