import { Box } from '@mui/system';
import Overview from '../../../assets/png/Overview.png';
import OverviewHover from '../../../assets/png/OverviewHover.png';
import ProjectTemplate from '../../../assets/png/ProjectTemplate.png';
import ProjectTemplateHover from '../../../assets/png/ProjectTemplateHover.png';
import Users from '../../../assets/png/Users.png';
import UsersHover from '../../../assets/png/UsersHover.png';
import { resourceNames } from '../../../utils/const';

interface props {
  imagePath: string;
  height?: string;
  width?: string;
}
export const Icon = ({ imagePath, height, width }: props) => {
  return (
    <Box
      sx={{
        height: height ? height : '100%',
        width: width ? width : '100%',
        float: 'right',
        backgroundImage: `url(${imagePath})`,
        backgroundRepeat: 'no-repeat',
        objectFit: 'contain',
        backgroundSize: 'cover',
      }}
    />
  );
};

export function hasChildren(item: any) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}
//[TODO] Future This list would come from the backend.
//[TODO] In Future  we need a check to verify if user has access to following menu item or not
//        we may use permission code to create UI item on left bar
//[TODO] add on click link variable
//[TODO] introduce types for the menuList
export const LeftDrawerMenuList = [
  {
    icon: <Icon imagePath={Overview} height="1.5rem" width="1.5rem" />,
    iconHover: (
      <Icon imagePath={OverviewHover} height="1.5rem" width="1.5rem" />
    ),
    title: 'Overview',
    pathname: '',
    resourcePermission: resourceNames.All,
  },
  {
    icon: <Icon imagePath={ProjectTemplate} height="1.5rem" width="1.5rem" />,
    iconHover: (
      <Icon imagePath={ProjectTemplateHover} height="1.5rem" width="1.5rem" />
    ),
    title: 'Organisation',
    items: [
      {
        title: 'Project List',
        pathname: 'project-list',
        resourcePermission: resourceNames.Organization,
      },
    ],
  },
  {
    icon: <Icon imagePath={ProjectTemplate} height="1.5rem" width="1.5rem" />,
    iconHover: (
      <Icon imagePath={ProjectTemplateHover} height="1.5rem" width="1.5rem" />
    ),
    title: 'Project template',
    pathname: 'projectTemplate',
    resourcePermission: resourceNames.Projects,
  },
  {
    icon: <Icon imagePath={ProjectTemplate} height="1.5rem" width="1.5rem" />,
    iconHover: (
      <Icon imagePath={ProjectTemplateHover} height="1.5rem" width="1.5rem" />
    ),
    title: 'Projects',
    items: [
      {
        title: 'New project',
        pathname: 'new-project',
        resourcePermission: resourceNames.Projects,
      },
      {
        title: 'All projects',
        pathname: 'all-project',
        resourcePermission: resourceNames.Projects,
      },
    ],
  },
  {
    icon: <Icon imagePath={Users} height="1.5rem" width="1.5rem" />,
    iconHover: <Icon imagePath={UsersHover} height="1.5rem" width="1.5rem" />,
    title: 'Users',
    items: [
      {
        title: 'All users',
        pathname: 'listUsers',
        resourcePermission: resourceNames.Users,
      },
      {
        title: 'Roles',
        pathname: 'Roles',
        resourcePermission: resourceNames.Roles,
      },
      {
        title: 'Create Role',
        pathname: 'createRole',
        resourcePermission: resourceNames.Roles,
      },

      {
        title: 'Create users',
        pathname: 'createUser',
        resourcePermission: resourceNames.Users,
      },
    ],
  },
  {
    icon: <Icon imagePath={Users} height="1.5rem" width="1.5rem" />,
    iconHover: <Icon imagePath={UsersHover} height="1.5rem" width="1.5rem" />,
    title: 'Entity',
    items: [
      {
        title: 'All Entities',
        pathname: 'list-entity',
        resourcePermission: resourceNames.Entity,
      },
      {
        title: 'Assigned Forms',
        pathname: 'entity-assigned-form',
        resourcePermission: resourceNames.Entity,
      },
    ],
  },
];
