import { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OptionField from './OptionField';
import ImageVideo from './ImageVideo';
import { FieldProjectType } from '../../types/types';
import { FieldTypes } from '../../utils/const';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '../../assets/png/DeleteIcon.png';
import styles from '../ProjectTemplate/createTemplate.module.css';
import Dates from './Dates';
import Text from './Text';
import { projectTypeInfo } from '../../utils/StateStore';

type Props = {
  updateField: (field: any, pos: number) => void;
  deleteField: (pos: number) => void;
  updateFiles: (file: File[], action: string, name?: string) => void;
  pos: number;
  fieldData: FieldProjectType;
  callFrom?: string;
  editable?: boolean;
  projectEdit?: string;
};

const Fields = ({
  updateField,
  deleteField,
  pos,
  fieldData,
  updateFiles,
  callFrom,
  editable,
  projectEdit,
}: Props) => {
  const [field, setField] = useState<FieldProjectType>(fieldData);

  const [fieldChoice, setFieldChoice] = useState(FieldTypes.TEXTBOX);
  const [projectTypeData] = useState(
    projectTypeInfo &&
      projectTypeInfo.value.filter((proType) => proType.type === 'ORC')[0] //[TODO] Later when we proper implement type of template and project then we remove this hardcoding
  );
  useEffect(() => {
    updateField(field, pos);
    setFieldChoice(fieldData?.type);
  }, [field]);
  useEffect(() => {
    if (
      fieldData &&
      fieldData.key &&
      field &&
      field.key &&
      field.key !== fieldData.key
    ) {
      setField(fieldData);
    }
  }, [fieldData]);
  const handleChange = (event: SelectChangeEvent) => {
    setFieldChoice(event.target.value as string);
    updateField(
      {
        key: '',
        label: [{ key: '', value: '' }],
        type: event.target.value,
        options: [{ key: '', value: '' }],
        seq: 1,
      },
      pos
    );
  };
  const getField = (type: string) => {
    switch (type) {
      case FieldTypes.TEXTBOX:
      case FieldTypes.TEXTAREA:
        return (
          <Text
            updateField={updateField}
            pos={pos}
            fieldData={fieldData}
            callFrom={callFrom}
            editable={editable}
          />
        );
      case FieldTypes.IMAGE:
        return (
          <ImageVideo
            fieldData={fieldData}
            updateField={updateField}
            pos={pos}
            updateFiles={updateFiles}
            type="image"
            editable={editable}
            projectEdit={projectEdit}
          />
        );
      case FieldTypes.VIDEO:
        return (
          <ImageVideo
            fieldData={fieldData}
            updateField={updateField}
            pos={pos}
            updateFiles={updateFiles}
            type="video"
            editable={editable}
          />
        );
      case FieldTypes.DROPDOWN:
      case FieldTypes.CHECKBOX:
      case FieldTypes.RADIO:
      case FieldTypes.MULTIPLECHOICE:
        return (
          !(projectTypeData &&
            projectTypeData.fields.filter(
              (field) =>
                field.type === FieldTypes.DROPDOWN ||
                FieldTypes.CHECKBOX ||
                FieldTypes.RADIO ||
                FieldTypes.MULTIPLECHOICE
            ))[0].options.length ||
          (callFrom !== 'formCreation' && callFrom !== 'formEdit' && (
            <OptionField
              updateField={updateField}
              pos={pos}
              fieldData={fieldData}
              callFrom={callFrom}
              editable={editable}
            />
          ))
        );
      case FieldTypes.DATE:
        return callFrom === 'projectPreview' ? (
          <Text
            updateField={updateField}
            pos={pos}
            fieldData={fieldData}
            callFrom={callFrom}
            editable={editable}
          />
        ) : (
          <Dates updateField={updateField} pos={pos} fieldData={fieldData} />
        );
      default:
        break;
    }
  };
  return (
    <div className={styles.fieldContainer}>
      {editable ? (
        <Box
          borderTop="1px solid #B8BABE"
          bgcolor="#F5F5F6"
          borderLeft="1px solid #B8BABE"
          borderRight="1px solid #B8BABE"
          borderBottom="1px solid #B8BABE"
        >
          <Box
            mx="1rem"
            mt="1.5rem"
            mb="3.5rem"
            display="flex"
            flexDirection="column"
          >
            {editable && (
              <div className={styles.deleteFieldDiv}>
                <button
                  onClick={() => {
                    deleteField(pos);
                  }}
                  className={styles.deleteFieldButton}
                >
                  <img src={DeleteIcon} alt="delete icon" />
                </button>
              </div>
            )}
            <Typography
              variant="Caption"
              sx={{
                height: '16px',
                left: '0px',
                top: '0px',
                fontFamily: 'Mulish',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '16px',
                color: '#525862',
                marginBottom: '4px',
              }}
            >
              Field Type
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fieldChoice}
              label="Age"
              onChange={handleChange}
              sx={{
                width: '17.813rem',
                height: '2.5rem',
              }}
              disabled={fieldData.key || !editable ? true : false}
            >
              {((projectTypeData &&
                projectTypeData.allowedFields.includes(FieldTypes.TEXTBOX)) ||
                (callFrom !== 'formCreation' && callFrom !== 'formEdit')) && (
                <MenuItem value={FieldTypes.TEXTBOX}>Text</MenuItem>
              )}
              {((projectTypeData &&
                projectTypeData.allowedFields.includes(FieldTypes.TEXTAREA)) ||
                (callFrom !== 'formCreation' && callFrom !== 'formEdit')) && (
                <MenuItem value={FieldTypes.TEXTAREA}>TextArea</MenuItem>
              )}
              {((projectTypeData &&
                projectTypeData.allowedFields.includes(FieldTypes.DROPDOWN)) ||
                (callFrom !== 'formCreation' && callFrom !== 'formEdit')) && (
                <MenuItem value={FieldTypes.DROPDOWN}>DropDown</MenuItem>
              )}
              {((projectTypeData &&
                projectTypeData.allowedFields.includes(FieldTypes.CHECKBOX)) ||
                (callFrom !== 'formCreation' && callFrom !== 'formEdit')) && (
                <MenuItem value={FieldTypes.CHECKBOX}>CheckBox</MenuItem>
              )}
              {((projectTypeData &&
                projectTypeData.allowedFields.includes(
                  FieldTypes.MULTIPLECHOICE
                )) ||
                (callFrom !== 'formCreation' && callFrom !== 'formEdit')) && (
                <MenuItem value={FieldTypes.MULTIPLECHOICE}>
                  MultipleChoice
                </MenuItem>
              )}
              {callFrom !== 'formCreation' && callFrom !== 'formEdit' && (
                <>
                  <MenuItem value={FieldTypes.IMAGE}>Image</MenuItem>
                  <MenuItem value={FieldTypes.VIDEO}>Video</MenuItem>
                </>
              )}

              {((projectTypeData &&
                projectTypeData.allowedFields.includes(FieldTypes.DATE)) ||
                (callFrom !== 'formCreation' && callFrom !== 'formEdit')) && (
                <MenuItem value={FieldTypes.DATE}>Date</MenuItem>
              )}
            </Select>
          </Box>
          {getField(fieldChoice)}
        </Box>
      ) : (
        <Box borderBottom="1px solid #B8BABE">{getField(fieldChoice)}</Box>
      )}
      <br></br>
    </div>
  );
};

export default Fields;
